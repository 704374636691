define("nightwatch-web/components/power-calendar/nav-with-year-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <nav class="ember-power-calendar-nav">
    <button type="button" class="ember-power-calendar-nav-control" {{on 'click' (fn @calendar.actions.moveCenter -12 'month')}}>«</button>
    <button type="button" class="ember-power-calendar-nav-control" {{on 'click' (fn @calendar.actions.moveCenter -1 'month')}}>‹</button>
    <div class="ember-power-calendar-nav-title">
      {{format-date @calendar.center 'MMMM yyyy'}}
    </div>
    <button type="button" class="ember-power-calendar-nav-control" {{on 'click' (fn @calendar.actions.moveCenter 1 'month')}}>›</button>
    <button type="button" class="ember-power-calendar-nav-control" {{on 'click' (fn @calendar.actions.moveCenter 12 'month')}}>»</button>
  </nav>
  
  */
  {
    "id": "Ldn1iyi+",
    "block": "[[[10,\"nav\"],[14,0,\"ember-power-calendar-nav\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control\"],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1,[\"actions\",\"moveCenter\"]],-12,\"month\"],null]],null],[12],[1,\"«\"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control\"],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1,[\"actions\",\"moveCenter\"]],-1,\"month\"],null]],null],[12],[1,\"‹\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ember-power-calendar-nav-title\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,1,[\"center\"]],\"MMMM yyyy\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control\"],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1,[\"actions\",\"moveCenter\"]],1,\"month\"],null]],null],[12],[1,\"›\"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control\"],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1,[\"actions\",\"moveCenter\"]],12,\"month\"],null]],null],[12],[1,\"»\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@calendar\"],false,[\"on\",\"fn\",\"format-date\"]]",
    "moduleName": "nightwatch-web/components/power-calendar/nav-with-year-buttons.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});