define("nightwatch-web/components/reports/report-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <div class="nw-panel double-offset-top">
      <div class="nw-panel__header">
        <span class="nw-panel__title">Templates</span>
      </div>
      <div class="nw-panel__body clearfix">
        {{#each @templates as |template|}}
          <div class="report-template {{if (eq @report.template_name template.name) 'report-template--selected'}} clearfix" role="button" {{on "click" (fn @setSelectedTemplate template.name)}}>
            <div class="report-template-icon-placeholder">
              <div class="report-template__icon report-template__icon--{{template.name}}"></div>
            </div>
            <div class="report-template__name">{{humanize template.name}}</div>
          </div>
        {{/each}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "8TVGUJJ+",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"nw-panel double-offset-top\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"nw-panel__header\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"nw-panel__title\"],[12],[1,\"Templates\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"nw-panel__body clearfix\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"        \"],[11,0],[16,0,[29,[\"report-template \",[52,[28,[37,3],[[30,4,[\"template_name\"]],[30,3,[\"name\"]]],null],\"report-template--selected\"],\" clearfix\"]]],[24,\"role\",\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,5],[30,3,[\"name\"]]],null]],null],[12],[1,\"\\n          \"],[10,0],[14,0,\"report-template-icon-placeholder\"],[12],[1,\"\\n            \"],[10,0],[15,0,[29,[\"report-template__icon report-template__icon--\",[30,3,[\"name\"]]]]],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"report-template__name\"],[12],[1,[28,[35,6],[[30,3,[\"name\"]]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@templates\",\"template\",\"@report\",\"@setSelectedTemplate\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"on\",\"fn\",\"humanize\"]]",
    "moduleName": "nightwatch-web/components/reports/report-templates.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});