define("nightwatch-web/components/graph/series-icon", ["exports", "ember-awesome-macros", "ember-awesome-macros/raw"], function (_exports, _emberAwesomeMacros, _raw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.iconSrc}}
    <i>
      <NwTooltip @placement="top">{{if this.isSearchConsoleSeries 'Search Console data' 'Google Analytics data' }}</NwTooltip>
      <img
        role="presentation"
        alt=""
        src={{this.iconSrc}}
        style="width: 1em;">
    </i>
  {{/if}}
  
  */
  {
    "id": "2P+2SnUU",
    "block": "[[[41,[30,0,[\"iconSrc\"]],[[[1,\"  \"],[10,\"i\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@placement\"],[\"top\"]],[[\"default\"],[[[[1,[52,[30,0,[\"isSearchConsoleSeries\"]],\"Search Console data\",\"Google Analytics data\"]]],[]]]]],[1,\"\\n    \"],[10,\"img\"],[14,\"role\",\"presentation\"],[14,\"alt\",\"\"],[15,\"src\",[30,0,[\"iconSrc\"]]],[14,5,\"width: 1em;\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"nw-tooltip\"]]",
    "moduleName": "nightwatch-web/components/graph/series-icon.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: '',
    iconSrc: (0, _emberAwesomeMacros.conditional)('isGoogleAnalyticsSeries', (0, _raw.default)('/assets/icons/ga-icon.png'), (0, _emberAwesomeMacros.conditional)('isSearchConsoleSeries', (0, _raw.default)('/assets/icons/sc-icon.png'), (0, _raw.default)(null)))
  }));

  _exports.default = _default;
});