define("nightwatch-web/utils/graphs/grouping", ["exports", "date-fns", "date-fns/parseISO", "ember-inflector"], function (_exports, _dateFns, _parseISO, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.groupData = _exports.groupMethods = void 0;
  const groupMethods = {
    sum(values) {
      const [firstVal] = values;

      switch (typeof firstVal) {
        case 'number':
          return values.reduce((total, current) => total + current, 0);

        case 'object':
          {
            return values.reduce((object, value) => {
              Object.entries(value).forEach(([key, value]) => {
                var _object$key;

                return object[key] = ((_object$key = object[key]) !== null && _object$key !== void 0 ? _object$key : 0) + value;
              });
              return object;
            }, {});
          }
      }

      throw `groupMethods: unsupported data type ${typeof firstVal}`;
    },

    average(values) {
      var _ref;

      const [firstVal] = values;

      switch (typeof firstVal) {
        case 'number':
          return (_ref = groupMethods.sum(values) / values.length) !== null && _ref !== void 0 ? _ref : 0;

        case 'object':
          {
            return Object.entries(groupMethods.sum(values)).reduce((object, [key, value]) => {
              var _ref2;

              object[key] = (_ref2 = value / values.length) !== null && _ref2 !== void 0 ? _ref2 : 0;
              return object;
            }, {});
          }
      }

      throw `groupMethods: unsupported data type ${typeof firstVal}`;
    }

  };
  _exports.groupMethods = groupMethods;

  const groupData = (data, timeUnit, groupValues) => {
    if (timeUnit === 'day') return data;
    const ranges = data.reduce((accumulator, [dateStr, value]) => {
      const date = (0, _parseISO.default)(dateStr);
      const range = accumulator.find(r => (0, _dateFns.isAfter)(date, r.start) && (0, _dateFns.isBefore)(date, r.end));

      if (range) {
        range.values.push(value);
      } else {
        accumulator.push({
          start: date,
          end: (0, _dateFns.add)(date, {
            [(0, _emberInflector.pluralize)(timeUnit)]: 1
          }),
          values: [value]
        });
      }

      return accumulator;
    }, []);
    return ranges.reduce((dataset, range) => {
      dataset.push([range.start, groupValues(range.values)]);
      return dataset;
    }, []);
  };

  _exports.groupData = groupData;
});