define("nightwatch-web/components/invoices/invoices-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <NwPageContent class="nw-page-content--single">
    {{#if @invoices.length}}
      <div class="nw-settings__invoices-table nw-table">
        <div class="nw-table__row nw-table__row--header">
          <div class="nw-table__col nw-table__col--invoice-date">Date</div>
          <div class="nw-table__col nw-table__col--invoice-type">Type</div>
          <div class="nw-table__col nw-table__col--invoice-amount">Amount</div>
          <div class="nw-table__col"></div>
        </div>
        {{#each @invoices as |invoice|}}
          <Invoices::InvoicesListRow @invoice={{invoice}} />
        {{/each}}
      </div>
    {{else}}
      <div class="text-center">No invoices.</div>
    {{/if}}
  </NwPageContent>
  
  */
  {
    "id": "vJd0/8W/",
    "block": "[[[8,[39,0],[[24,0,\"nw-page-content--single\"]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"nw-settings__invoices-table nw-table\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-table__row nw-table__row--header\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"nw-table__col nw-table__col--invoice-date\"],[12],[1,\"Date\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"nw-table__col nw-table__col--invoice-type\"],[12],[1,\"Type\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"nw-table__col nw-table__col--invoice-amount\"],[12],[1,\"Amount\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"nw-table__col\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@invoice\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"text-center\"],[12],[1,\"No invoices.\"],[13],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"@invoices\",\"invoice\"],false,[\"nw-page-content\",\"if\",\"each\",\"-track-array\",\"invoices/invoices-list-row\"]]",
    "moduleName": "nightwatch-web/components/invoices/invoices-list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});