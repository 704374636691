define("nightwatch-web/templates/dashboard/url/site-audit/view/checks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O/OeFanD",
    "block": "[[[8,[39,0],null,[[\"@to\"],[\"site_audit_section_links\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@dynamicView\"],[[30,1,[\"view\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[\"@model\"],false,[\"ember-wormhole\",\"site-audit/site-audit-section-links\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/site-audit/view/checks.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});