define("nightwatch-web/components/checkbox-with-input", ["exports", "ember-awesome-macros", "ember-uuid"], function (_exports, _emberAwesomeMacros, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="checkbox checkbox-with-input__checkbox form-checkbox">
    <input
      type="checkbox"
      checked={{this.isInputActive}}
      class="styled"
      id={{this.checkboxId}}
      {{on "change" this.onCheckboxChange}} />
    <label for={{this.checkboxId}} class="checkbox-label">
      {{this.label}}
    </label>
  </div>
  
  <input
    data-test-input
    id={{this.inputId}}
    value={{this.value}}
    disabled={{this.isInputDisabled}}
    class="form-control rt-input checkbox-with-input__input {{if this.error 'invalid'}}"
    {{on "input" (pick "target.value" this.updateValue)}}
  />
  
  {{#if this.error}}
    <div data-test-error class="form-error">{{this.error}}</div>
  {{/if}}
  
  */
  {
    "id": "HK+erqta",
    "block": "[[[10,0],[14,0,\"checkbox checkbox-with-input__checkbox form-checkbox\"],[12],[1,\"\\n  \"],[11,\"input\"],[16,\"checked\",[30,0,[\"isInputActive\"]]],[24,0,\"styled\"],[16,1,[30,0,[\"checkboxId\"]]],[24,4,\"checkbox\"],[4,[38,0],[\"change\",[30,0,[\"onCheckboxChange\"]]],null],[12],[13],[1,\"\\n  \"],[10,\"label\"],[15,\"for\",[30,0,[\"checkboxId\"]]],[14,0,\"checkbox-label\"],[12],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"input\"],[16,1,[30,0,[\"inputId\"]]],[16,2,[30,0,[\"value\"]]],[16,\"disabled\",[30,0,[\"isInputDisabled\"]]],[16,0,[29,[\"form-control rt-input checkbox-with-input__input \",[52,[30,0,[\"error\"]],\"invalid\"]]]],[4,[38,0],[\"input\",[28,[37,2],[\"target.value\",[30,0,[\"updateValue\"]]],null]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"error\"]],[[[1,\"  \"],[10,0],[14,0,\"form-error\"],[12],[1,[30,0,[\"error\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"on\",\"if\",\"pick\"]]",
    "moduleName": "nightwatch-web/components/checkbox-with-input.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    min: 0,

    init() {
      this._super(...arguments);

      this.set('inputId', (0, _emberUuid.v4)());
      this.set('checkboxId', (0, _emberUuid.v4)());
    },

    didUpdateAttrs() {
      this._super(...arguments);

      const newValue = this.value;
      const oldValue = this._previousValue;

      if (oldValue == null && newValue != oldValue) {
        Ember.run.schedule('afterRender', () => document.getElementById(this.inputId).focus());
      }
    },

    isInputActive: (0, _emberAwesomeMacros.not)('isInputDisabled'),
    isInputDisabled: (0, _emberAwesomeMacros.computed)('value', v => v == null),
    updateValue: Ember._action(function (value) {
      let numericValue = parseInt(value);

      if (isNaN(numericValue)) {
        numericValue = '';
      }

      this.set('value', numericValue);
    }),
    onCheckboxChange: Ember._action(function () {
      if (this.isInputActive) {
        this.set('value', null);
      } else {
        this.set('value', this.min);
      }
    })
  }));

  _exports.default = _default;
});