define("nightwatch-web/components/loading/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <NwIcon
    @icon="icon-small-spinner"
    class="nw-spinner"
    aria-hidden="true"
    data-test-spinner
    ...attributes
  />
  
  */
  {
    "id": "K4lekOTs",
    "block": "[[[8,[39,0],[[24,0,\"nw-spinner\"],[24,\"aria-hidden\",\"true\"],[17,1]],[[\"@icon\"],[\"icon-small-spinner\"]],null],[1,\"\\n\"]],[\"&attrs\"],false,[\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/loading/spinner.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});