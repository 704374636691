define("nightwatch-web/mixins/competitor-actions", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fetch: Ember.inject.service(),
    metrics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    updateCompetitorsGraph() {
      return this.fetch.post('/graphs/update_competitors_graph', {
        contentType: 'application/json',
        data: {
          url_id: this.url.id
        }
      });
    },

    addCompetitorToUrl(url, competitorUrl) {
      const limit = this.session.user.competitors_per_url;

      if (url.get('competitors.length') >= limit) {
        this.notifications.error(`Maximum number of competitors for your plan: ${limit}`, {
          autoClear: true,
          clearDuration: 10000
        });
        return;
      }

      const record = this.store.createRecord('competitor', {
        url
      });

      if (competitorUrl) {
        record.set('competitor_url', competitorUrl);
      }

      const competitors = url.get('competitors').toArray();
      competitors.pushObject(record);
      url.set('competitors', competitors);
    },

    deleteUnsavedCompetitors() {
      if (this.store.isDestroying || this.store.isDestroyed) return;
      this.store.peekAll('competitor').filterBy('isNew').filter(competitor => !competitor.get('isSaving')).forEach(competitor => competitor.deleteRecord());
    },

    addCompetitor: Ember._action(function (competitorUrl) {
      this.addCompetitorToUrl(this.url, competitorUrl);
    }),
    deleteCompetitor: Ember._action(function (competitor) {
      if (!competitor.get('id') || competitor.get('isNew')) {
        const competitors = competitor.get('url.competitors');
        if (competitors) competitors.removeObject(competitor);
      } else if (confirm('Are you sure you want to delete this competitor? All of its associated data wil be lost.')) {
        const competitors = this.url.get('competitors').toArray();
        competitors.removeObject(competitor);
        this.url.set('competitors', competitors);
        competitor.destroyRecord();
      }
    }),
    isSaving: Ember.computed.alias('saveCompetitor.isRunning'),
    saved: Ember.computed.alias('saveCompetitor.lastSuccessful'),
    saveCompetitor: (0, _emberConcurrency.task)(function* () {
      if (this.onboarding && (this.get('url.competitors.length') === 0 || this.get('url.competitors').mapBy('friendlyUrl').join('') === '')) {
        this.afterCompetitorSave();
        return;
      }

      yield (0, _emberConcurrency.timeout)(500);
      const promises = this.get('url.competitors').filterBy('hasDirtyAttributes').map(competitor => competitor.save());
      const records = yield Ember.RSVP.all(promises);
      this.afterCompetitorSave();
      const source = this.onboarding ? 'onboarding' : 'void';
      this.metrics.trackEvent({
        event: 'Set Competitors',
        url: this.get('url.url'),
        competitors: records.mapBy('friendlyUrl'),
        source
      });
    }).drop()
  });

  _exports.default = _default;
});