define("nightwatch-web/components/reports/report-column-sorter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="report-column-sorter">
    <div role="button" class="report-column-sorter__indicator {{if this.isDesc 'report-column-sorter__indicator--active'}}" {{on "click" (fn this.onSortChange this.block this.column.name 'desc')}}>
      <NwIcon @icon="icon-chevron-down" />
    </div>
    <div role="button" class="report-column-sorter__indicator {{if this.isAsc 'report-column-sorter__indicator--active'}}"{{on "click" (fn this.onSortChange this.block this.column.name 'asc')}}>
      <NwIcon @icon="icon-chevron-up" />
    </div>
  </div>
  
  */
  {
    "id": "880kdScH",
    "block": "[[[10,0],[14,0,\"report-column-sorter\"],[12],[1,\"\\n  \"],[11,0],[24,\"role\",\"button\"],[16,0,[29,[\"report-column-sorter__indicator \",[52,[30,0,[\"isDesc\"]],\"report-column-sorter__indicator--active\"]]]],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"onSortChange\"]],[30,0,[\"block\"]],[30,0,[\"column\",\"name\"]],\"desc\"],null]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[\"icon-chevron-down\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,\"role\",\"button\"],[16,0,[29,[\"report-column-sorter__indicator \",[52,[30,0,[\"isAsc\"]],\"report-column-sorter__indicator--active\"]]]],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"onSortChange\"]],[30,0,[\"block\"]],[30,0,[\"column\",\"name\"]],\"asc\"],null]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[\"icon-chevron-up\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"on\",\"fn\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/reports/report-column-sorter.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    sort: Ember.computed.alias('block.sort'),
    isCurrentSort: Ember.computed('block.sort', function () {
      return this.get('column.name') == this.sort;
    }),
    sortDirection: Ember.computed.alias('block.sort_direction'),
    isAsc: Ember.computed('sortDirection', 'sort', function () {
      if (!this.isCurrentSort) return false;
      return this.sortDirection == 'asc';
    }),
    isDesc: Ember.computed('sortDirection', 'sort', function () {
      if (!this.isCurrentSort) return false;
      return this.sortDirection == 'desc';
    })
  }));

  _exports.default = _default;
});