define("nightwatch-web/utils/chevron-icon-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const chevronIconHtml = '<svg class="nw-icon"><use xlink:href="#icon-chevron-right" /></svg>';
  var _default = chevronIconHtml;
  _exports.default = _default;
});