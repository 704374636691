define("nightwatch-web/utils/is-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isObjectType = void 0;

  const isObjectType = val => Object.prototype.toString.call(val) === '[object Object]';

  _exports.isObjectType = isObjectType;
});