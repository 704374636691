define("nightwatch-web/components/graph/interval-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.changedPresent}}
    <span class="small-graph-interval-change-current-value">
      {{abbreviate-number (round this.value 1)}}
    </span>
    {{#if this.isStagnant}}
      <NwIcon @icon="icon-stagnant" />
    {{else if this.wentUp}}
      <span class="green">
        <i class="small-graph-interval-change-value-icon icon-up {{if this.inverted 'color-inverted'}}"></i>
        <span class="small-graph-interval-change-value">
          {{abbreviate-number (abs this.changed)}}
        </span>
      </span>
    {{else}}
      <span class="red">
        <i class="small-graph-interval-change-value-icon icon-down {{if this.inverted 'color-inverted'}}"></i>
        <span class="small-graph-interval-change-value">
          {{abbreviate-number (abs this.changed)}}
        </span>
      </span>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "o6yFCTsw",
    "block": "[[[41,[30,0,[\"changedPresent\"]],[[[1,\"  \"],[10,1],[14,0,\"small-graph-interval-change-current-value\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"value\"]],1],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"isStagnant\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@icon\"],[\"icon-stagnant\"]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"wentUp\"]],[[[1,\"    \"],[10,1],[14,0,\"green\"],[12],[1,\"\\n      \"],[10,\"i\"],[15,0,[29,[\"small-graph-interval-change-value-icon icon-up \",[52,[30,0,[\"inverted\"]],\"color-inverted\"]]]],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"small-graph-interval-change-value\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[28,[37,4],[[30,0,[\"changed\"]]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"red\"],[12],[1,\"\\n      \"],[10,\"i\"],[15,0,[29,[\"small-graph-interval-change-value-icon icon-down \",[52,[30,0,[\"inverted\"]],\"color-inverted\"]]]],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"small-graph-interval-change-value\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[28,[37,4],[[30,0,[\"changed\"]]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]],[]]]],[]],null]],[],false,[\"if\",\"abbreviate-number\",\"round\",\"nw-icon\",\"abs\"]]",
    "moduleName": "nightwatch-web/components/graph/interval-change.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: '',
    isStagnant: Ember.computed('changed', function () {
      const changed = this.changed;
      return changed == 0 || !changed;
    }),
    wentUp: Ember.computed('changed', function () {
      return this.changed > 0;
    }),
    wentDown: Ember.computed.not('changed', function () {
      return this.changed < 0;
    }),
    changedPresent: Ember.computed('changed', function () {
      return typeof this.changed == 'number';
    })
  }));

  _exports.default = _default;
});