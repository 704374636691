define("nightwatch-web/models/pinned-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PinnedItem = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.belongsTo)('graph', {
    async: false
  }), _dec4 = (0, _model.belongsTo)('url-group', {
    async: true
  }), _dec5 = (0, _model.belongsTo)('url', {
    async: true
  }), _dec6 = (0, _model.belongsTo)('dynamic-view', {
    async: true
  }), _dec7 = Ember.computed('urlGroup.id', 'url.id', 'dynamicView.id'), _dec8 = Ember.computed('resourceId'), _dec9 = Ember.computed('isGlobalDynamicView'), _dec10 = Ember.computed('name'), _dec11 = Ember.computed('urlGroup.name', 'url.name', 'dynamicView.name'), _dec12 = Ember.computed('itemType'), _dec13 = Ember.computed('itemType'), _dec14 = Ember.computed('url', 'itemType'), _dec15 = Ember.computed('itemType'), _dec16 = Ember.computed('name', 'itemType'), _dec17 = Ember.computed('url.id', 'urlGroup.id', 'dynamicView.id'), (_class = class PinnedItem extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "itemType", _descriptor, this);

      _initializerDefineProperty(this, "item", _descriptor2, this);

      _initializerDefineProperty(this, "graph", _descriptor3, this);

      _initializerDefineProperty(this, "urlGroup", _descriptor4, this);

      _initializerDefineProperty(this, "url", _descriptor5, this);

      _initializerDefineProperty(this, "dynamicView", _descriptor6, this);
    }

    get resourceId() {
      return this.get('urlGroup.id') || this.get('dynamicView.id') || this.get('url.id');
    }

    get isGlobalDynamicView() {
      return this.get('dynamicView.id') && !this.get('url.id');
    }

    get globalDynamicView() {
      if (this.isGlobalDynamicView) {
        return this.dynamicView;
      }

      return null;
    }

    get isLoaded() {
      return Ember.isPresent(this.name);
    }

    get name() {
      switch (this.itemType) {
        case 'url':
          return this.get('url.name');

        case 'view':
          return this.get('dynamicView.name');

        case 'group':
          return this.get('urlGroup.name');

        default:
          return null;
      }
    }

    get isUrl() {
      return this.itemType === 'url';
    }

    get isView() {
      return this.itemType === 'view';
    }

    get isUrlView() {
      return this.isView && this.get('url.id') != null;
    }

    get isGroup() {
      return this.itemType === 'group';
    }

    get description() {
      if (this.itemType) {
        let itemType = Ember.String.capitalize(this.itemType);

        if (itemType === 'Url') {
          itemType = 'URL';
        }

        if (this.itemType) {
          return `${itemType}: ${this.name}`;
        }
      }

      return null;
    }

    get isBlank() {
      return [this.get('url.id'), this.get('urlGroup.id'), this.get('dynamicView.id')].compact().length === 0;
    }

    toJSON() {
      const json = {};
      this.eachRelationship(relationship => {
        json[relationship] = this.get(`${relationship}.id`);
      });
      this.eachAttribute(attribute => {
        json[attribute] = this[attribute];
      });
      return json;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "itemType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "graph", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "urlGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dynamicView", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resourceId", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "resourceId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isGlobalDynamicView", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isGlobalDynamicView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "globalDynamicView", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "globalDynamicView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isLoaded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "name", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "name"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isUrl", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "isUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isView", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "isView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isUrlView", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "isUrlView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isGroup", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "isGroup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "description", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isBlank", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "isBlank"), _class.prototype)), _class));
  _exports.default = PinnedItem;
});