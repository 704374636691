define("nightwatch-web/components/google-places-search", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <NwSelect
    @v1={{true}}
    @placeholder="Google place…"
    @searchEnabled={{true}}
    @search={{perform this.search}}
    @selected={{this.selected}}
    @allowClear={{true}}
    @onChange={{this.onPlaceSelected}} as |result|>
    {{result.place_description}}
  </NwSelect>
  
  */
  {
    "id": "9QvvwEV+",
    "block": "[[[8,[39,0],null,[[\"@v1\",\"@placeholder\",\"@searchEnabled\",\"@search\",\"@selected\",\"@allowClear\",\"@onChange\"],[true,\"Google place…\",true,[28,[37,1],[[30,0,[\"search\"]]],null],[30,0,[\"selected\"]],true,[30,0,[\"onPlaceSelected\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,1,[\"place_description\"]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"result\"],false,[\"nw-select\",\"perform\"]]",
    "moduleName": "nightwatch-web/components/google-places-search.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    fetch: Ember.inject.service(),
    notifications: Ember.inject.service(),
    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(200);
      const abortController = new AbortController();
      let response;

      try {
        response = yield this.fetch.request('/google_places/autocomplete', {
          data: {
            q: term
          },
          signal: abortController.signal
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        abortController.abort();
      }

      if (response.status == 'OK') {
        const results = response.predictions.map(result => ({
          place_id: result.place_id,
          place_description: result.description
        }));

        if (Ember.isPresent(term)) {
          // Prepend it to the result so users can select an arbitrary input.
          return [{
            place_id: null,
            place_description: term
          }, ...results];
        }

        return results;
      } else {
        return [{
          place_id: null,
          place_description: term
        }];
      }
    }).restartable(),

    fetchPlaceDetails(result) {
      if (!result.place_id) {
        // For allowing custom input. Some users are tracking different places within the
        // same website.
        return Ember.RSVP.Promise.resolve({
          place_cid: null,
          place_title: result.place_description,
          place_description: result.place_description
        });
      }

      return this.fetch.request(`/google_places/${result.place_id}`).then(response => ({
        place_cid: response.cid,
        place_title: response.title,
        place_description: result.place_description
      })).catch(error => this.handleError(error));
    },

    handleError(error) {
      this.notifications.remove(this.get('notification'));
      const notification = this.notifications.error('Something went wrong while fetching Google Place details. Reload the page and try again or contact support', {
        autoClear: true
      });
      this.set('notification', notification);
      throw error;
    },

    toValue(result) {
      if (result.place_cid) {
        return `cid_${result.place_cid}`;
      } else if (result.place_title) {
        return result.place_title;
      } else {
        return null;
      }
    },

    onPlaceSelected: Ember._action(function (selected) {
      if (selected) {
        this.fetchPlaceDetails(selected).then(result => {
          this.onChange(result);
          this.onValueChange(this.toValue(result));
        });
      } else {
        this.onChange(null);
        this.onValueChange(null);
      }
    })
  }));

  _exports.default = _default;
});