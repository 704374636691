define("nightwatch-web/components/tags-batch-actions/submenu-tag-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li {{on 'click' (fn @onClick @tag.name)}} role="button" ...attributes>
    <a tabindex="-1" href="#">{{tag-with-count @tag}}</a>
  </li>
  
  */
  {
    "id": "txQFfELi",
    "block": "[[[11,\"li\"],[24,\"role\",\"button\"],[17,1],[4,[38,0],[\"click\",[28,[37,1],[[30,2],[30,3,[\"name\"]]],null]],null],[12],[1,\"\\n  \"],[10,3],[14,\"tabindex\",\"-1\"],[14,6,\"#\"],[12],[1,[28,[35,2],[[30,3]],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@onClick\",\"@tag\"],false,[\"on\",\"fn\",\"tag-with-count\"]]",
    "moduleName": "nightwatch-web/components/tags-batch-actions/submenu-tag-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});