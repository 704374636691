define("nightwatch-web/templates/plans/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PLgbeKpb",
    "block": "[[[1,[28,[35,0],[\"Cancel Subscription\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@onClose\",\"@alignment\",\"@containerClass\",\"@translucentOverlay\"],[[30,0,[\"goToPlans\"]],\"center\",\"nw-modal nw-modal--max-m\",true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@onClose\"],[[30,0,[\"goToPlans\"]]]],[[\"default\"],[[[[1,\"Cancel Subscription\"]],[]]]]],[1,\"\\n  \"],[8,[39,3],null,[[\"@openSupport\",\"@goToPlans\"],[[30,0,[\"openSupport\"]],[30,0,[\"goToPlans\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"page-title\",\"modal-dialog\",\"modal-header\",\"cancellation-form\"]]",
    "moduleName": "nightwatch-web/templates/plans/cancel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});