define("nightwatch-web/templates/components/sortable-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jTaHe9zp",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "nightwatch-web/templates/components/sortable-objects.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});