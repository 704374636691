define("nightwatch-web/helpers/string-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.stringContains = void 0;

  const stringContains = params => {
    const str = params[0] || '';
    const substr = params[1] || '';
    return str.indexOf(substr) !== -1;
  };

  _exports.stringContains = stringContains;

  var _default = Ember.Helper.helper(stringContains);

  _exports.default = _default;
});