define("nightwatch-web/templates/start", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nr7um1N8",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[10,0],[14,0,\"onboarding-sidebar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"onboarding-sidebar-steps\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"onboarding-sidebar-steps-line\"],[12],[13],[1,\"\\n    \"],[8,[39,1],[[24,0,\"disabled\"]],[[\"@route\"],[\"start.url\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"step-circle\"],[12],[1,\"1\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"step-title\"],[12],[1,\"Website\"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,0,\"disabled\"]],[[\"@route\",\"@model\"],[\"start.keywords\",[30,0,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"step-circle\"],[12],[1,\"2\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"step-title\"],[12],[1,\"Keywords\"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,0,\"disabled\"]],[[\"@route\",\"@model\"],[\"start.competitors\",[30,0,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"step-circle\"],[12],[1,\"3\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"step-title\"],[12],[1,\"Competitors\"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,0,\"disabled\"]],[[\"@route\",\"@model\"],[\"start.integrations\",[30,0,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"step-circle\"],[12],[1,\"4\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"step-title\"],[12],[1,\"Integrations\"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\\n\"],[10,0],[14,0,\"onboarding-main-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"onboarding-main\"],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"nw-navbar\",\"link-to\",\"liquid-outlet\"]]",
    "moduleName": "nightwatch-web/templates/start.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});