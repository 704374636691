define("nightwatch-web/routes/start/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StartIntegrationsRoute extends Ember.Route {
    afterModel(model) {
      this.controllerFor('start').set('model', model);
    }

  }

  _exports.default = StartIntegrationsRoute;
});