define("nightwatch-web/mixins/filter-group-handling", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    dashboardState: Ember.inject.service(),
    filterData: Ember.inject.service(),
    router: Ember.inject.service(),
    metrics: Ember.inject.service(),

    validateView(dynamicView) {
      const name = dynamicView.get('name');
      dynamicView.set('nameError', null);

      if (!!name && name.trim().length > 0) {
        return true;
      } else {
        return dynamicView.set('nameError', 'Name missing.');
      }
    },

    validateFilters(dynamicView, displayErrors = true) {
      let filtersValid = true;
      const filterGroups = dynamicView.get('filterGroups').filter(fg => {
        return !fg.get('markedAsDeleted');
      });
      filterGroups.forEach(filterGroup => {
        const filters = filterGroup.get('filters');

        if (!(filters.get('length') > 0)) {
          filtersValid = false;
        }

        return filters.forEach(filter => {
          const filterValid = this.validateFilter(filter, displayErrors);

          if (!filterValid) {
            return filtersValid = false;
          }
        });
      });
      dynamicView.set('filtersValid', filtersValid);
      return filtersValid;
    },

    resetFilterErrors(filter) {
      filter.set('valueError', null);
      filter.set('conditionError', null);
      filter.set('fieldError', null);
    },

    validateFilter(filter, displayErrors) {
      this.resetFilterErrors(filter);
      let value = filter.get('value') != null ? String(filter.get('value')).trim() : '';
      const condition = filter.get('condition');
      const field = filter.get('field');

      const isNum = val => !isNaN(parseFloat(val)) && isFinite(val);

      const getFieldInfo = field => {
        return this.get('filterData.allFilterFields').findBy('field', field);
      };

      let filterValid = true;

      if (filter.get('isBooleanField')) {
        if (!['true', 'false'].includes(String(filter.get('value')))) {
          filter.set('booleanValue', false);
        }

        return true;
      }

      if (!value || !condition || !field) {
        if (!value) {
          if (!['present', 'absent'].includes(condition)) {
            filterValid = false;

            if (displayErrors) {
              filter.set('valueError', 'Value missing.');
            }
          }
        }

        if (!condition) {
          filterValid = false;

          if (displayErrors) {
            filter.set('conditionError', 'Condition missing.');
          }
        }

        if (!field) {
          filterValid = false;

          if (displayErrors) {
            filter.set('fieldError', 'Field missing.');
          }
        }
      } else {
        const {
          data_type
        } = getFieldInfo(field);

        if (data_type.indexOf('number') !== -1) {
          if (!isNum(value)) {
            filterValid = false;

            if (displayErrors) {
              filter.set('valueError', 'Value should be a number.');
            }
          }
        } else if (data_type.indexOf('date') !== -1) {
          value = (0, _dateFns.format)(new Date(value), 'MM/dd/yyyy');

          if (!(0, _dateFns.format)(new Date(value), 'MM/dd/yyyy')) {
            filterValid = false;

            if (displayErrors) {
              filter.set('valueError', 'Value should be a date.');
            }
          } else {
            filter.set('value', value);
          }
        } else if (data_type === 'exact') {
          if (!['equals', 'not_equals'].includes(filter.get('condition'))) {
            filterValid = false;

            if (displayErrors) {
              filter.set('conditionError', 'Select condition.');
            }
          }
        }
      }

      return filterValid;
    },

    prepareNewFilterGroup: Ember._action(function (dynamicView) {
      const newFilterGroup = this.store.createRecord('filter-group');
      newFilterGroup.set('dynamicView', dynamicView);
      const newFilter = this.store.createRecord('filter');
      newFilter.set('filterGroup', newFilterGroup);
      newFilterGroup.get('filters').pushObject(newFilter);
      const array = dynamicView.get('filterGroups').toArray();
      array.addObjects(dynamicView.get('filterGroups'));
      array.addObject(newFilterGroup);
      dynamicView.set('filterGroups', array);
    }),
    prepareNewFilter: Ember._action(function (filterGroup) {
      const newFilter = this.store.createRecord('filter');
      newFilter.set('filterGroup', filterGroup);
    }),
    removeFilter: Ember._action(function (filter) {
      const filterGroup = filter.get('filterGroup');

      if (filterGroup.get('filters.length') === 1) {
        filter.set('filterGroup.markedAsDeleted', true);
        filterGroup.deleteRecord();
        return;
      }

      filterGroup.get('filters').removeObject(filter);
      filter.deleteRecord();
    }),
    nullifyFilter: Ember._action(function (filter) {
      filter.setProperties({
        field: null,
        condition: null,
        value: null
      });
    }),
    saveViewFilters: Ember._action(function (dynamicView) {
      const isNewDynamicView = dynamicView.get('isNew');

      if (this.validateView(dynamicView) & this.validateFilters(dynamicView)) {
        if (this.url && this.router.currentRouteName.indexOf('url') !== -1) {
          dynamicView.set('url', this.url);
        } else {
          dynamicView.set('urlGroup', this.dashboardState.currentUrlGroup);
        }

        if (isNewDynamicView) {
          // setting filtersSaving: among other things - to prevent showing count in sidebar
          // when view isn't refreshed after filters have been saved - otherwise it shows total keyword count
          dynamicView.set('filtersSaving', true);
          dynamicView.save().then(dynamicView => {
            this.saveFilterGroups(dynamicView, isNewDynamicView);
          });
        } else {
          this.saveFilterGroups(dynamicView, isNewDynamicView);
        }

        this.metrics.trackEvent({
          event: 'Saved View',
          name: dynamicView.get('name')
        });
      }
    }),
    saveFilterGroups: Ember._action(function (dynamicView, isNew) {
      const promises = [];
      dynamicView.set('filtersSaving', true);
      dynamicView.get('filterGroups').forEach(filterGroup => {
        if (filterGroup) {
          // bug when deleting first filter group and then saving
          if (filterGroup.get('markedAsDeleted')) {
            if (filterGroup.get('currentState.stateName') !== 'root.deleted.saved') {
              filterGroup.destroyRecord();
            }
          } else {
            const promise = filterGroup.save().then(filterGroup => {
              // a workaround: ember-data bug https://github.com/emberjs/data/issues/1829
              filterGroup.get('filters').filterBy('isNew').invoke('unloadRecord');
            });
            promises.push(promise);
          }
        }
      });
      promises.push(dynamicView.save());
      const savingStarted = new Date();
      return Ember.RSVP.all(promises).then(() => {
        const savingEnded = new Date();
        const savingTime = savingEnded - savingStarted; // ensure saving animation for at least 1s

        const t = 1000;
        const animateFor = savingTime >= t ? 0 : t - savingTime;
        return Ember.run.later(this, () => {
          if (isNew) {
            let dynamicViews;
            let dynamicViewPath = '';
            const isGlobalView = this.router.currentRouteName.indexOf('url') === -1;

            if (isGlobalView) {
              dynamicViews = dynamicView.get('urlGroup.dynamicViews').toArray();
              dynamicViews.pushObject(dynamicView);
              dynamicView.set('urlGroup.dynamicViews', dynamicViews);
              dynamicViewPath = 'dashboard.dynamic-view.keywords';
            } else {
              dynamicViews = dynamicView.get('url.dynamicViews').toArray();
              dynamicViews.pushObject(dynamicView);
              dynamicView.set('url.dynamicViews', dynamicViews);
              dynamicViewPath = 'dashboard.url.dynamic-view';
            }

            this.router.transitionTo(dynamicViewPath, dynamicView, {
              queryParams: {
                jc: true
              }
            });
          } else {
            this.set('page', 1);
          }

          dynamicView.set('filtersSaving', false);
          dynamicView.set('filtersSaved', true);
          dynamicView.reload();
        }, animateFor);
      });
    })
  });

  _exports.default = _default;
});