define("nightwatch-web/helpers/add-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.addNumber = void 0;

  const addNumber = params => Number.parseInt(params[0], 10) + Number.parseInt(params[1], 10);

  _exports.addNumber = addNumber;

  var _default = Ember.Helper.helper(addNumber);

  _exports.default = _default;
});