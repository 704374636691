define("nightwatch-web/components/no-urls-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="splash-blurred-background"></div>
  <div class="splash-blurred-centered">
    <div class="splash-message">Start tracking your rankings ...</div>
    {{#if @currentUrlGroup}}
      <LinkTo
        @route="start.url"
        @query={{hash groupId=@currentUrlGroup.id}}
        class="btn btn-jumbo"
      >
        <NwIcon @icon="icon-plus-circle" />
        Add a Website
      </LinkTo>
    {{else}}
      <LinkTo @route="start.url" class="btn btn-jumbo">
        <NwIcon @icon="icon-plus-circle" />
        Add a Website
      </LinkTo>
    {{/if}}
  </div>
  */
  {
    "id": "1i+kMEqm",
    "block": "[[[10,0],[14,0,\"splash-blurred-background\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"splash-blurred-centered\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"splash-message\"],[12],[1,\"Start tracking your rankings ...\"],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,1],[[24,0,\"btn btn-jumbo\"]],[[\"@route\",\"@query\"],[\"start.url\",[28,[37,2],null,[[\"groupId\"],[[30,1,[\"id\"]]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@icon\"],[\"icon-plus-circle\"]],null],[1,\"\\n      Add a Website\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],[[24,0,\"btn btn-jumbo\"]],[[\"@route\"],[\"start.url\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@icon\"],[\"icon-plus-circle\"]],null],[1,\"\\n      Add a Website\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"@currentUrlGroup\"],false,[\"if\",\"link-to\",\"hash\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/no-urls-screen.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});