define("nightwatch-web/services/persistence/date-range", ["exports", "nightwatch-web/constants/date-ranges"], function (_exports, _dateRanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DateRangeService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "RANGE_KEY", null);

      _defineProperty(this, "DEFAULT_TYPE", 'day');

      _defineProperty(this, "rangeTypes", (0, _dateRanges.relativeDateRanges)().mapBy('type'));
    }

    parseDateRange() {
      let rangeType = localStorage.getItem(this.RANGE_KEY);

      if (!this.rangeTypes.includes(rangeType)) {
        rangeType = this.DEFAULT_TYPE;
        this.persistDateRange(rangeType);
      }

      const {
        type,
        start,
        end
      } = (0, _dateRanges.relativeDateRanges)().findBy('type', rangeType);
      return {
        type,
        start,
        end
      };
    }

    persistDateRange(type = this.DEFAULT_TYPE) {
      localStorage.setItem(this.RANGE_KEY, type);
    }

  }

  _exports.default = DateRangeService;
});