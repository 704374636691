define("nightwatch-web/utils/series-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    store: Ember.inject.service(),
    graphSeriesTrafficStats: Ember.inject.service('graphs/graph-series-stats'),

    init() {
      this._super(...arguments);

      const {
        keywordId,
        competitorId,
        urlId
      } = this;
      this.setAndFetchModel('keyword', keywordId);
      this.setAndFetchModel('competitor', competitorId, urlId);
    },

    setAndFetchModel(modelType, id, urlId) {
      // Fetching models to add more data into the tooltips and legends, for
      // example mobile/desktop tracking info (keyword), custom name (competitor),...
      if (!['keyword', 'competitor'].includes(modelType)) return;
      if (!id) return; // Is it already in the store? If yes, use it and return.

      const model = this.store.peekRecord(modelType, id);

      if (model) {
        return this.set(`${modelType}Model`, model);
      } // If not, fetch it. But not immediately, thus a delay. This is secondary data, which is best
      // called with a little delay so that all the primary data (series) is requested first
      // and request stalling is prevented.
      // Special case for competitors here, because the back-end doesn't support "show" endpoint


      if (modelType == 'competitor') {
        Ember.run.later(() => {
          const cachedCompetitor = this.store.peekRecord('competitor', String(id));

          if (cachedCompetitor) {
            this.set('competitorModel', cachedCompetitor);
          } else {
            const promise = this.store.query('competitor', {
              url_id: urlId
            });
            promise.then(competitors => {
              const competitor = competitors.findBy('id', String(id));
              this.set('competitorModel', competitor);
            });
          }
        }, Math.random() * 3000); // Space out requests randomly in time so that there is an opportunity to use cached competitors when requests are the same
      } else {
        Ember.run.later(() => {
          this.set(`${modelType}Model`, this.store.findRecord(modelType, id));
        }, 250);
      }
    },

    gaSeriesNames: ['sessions', 'users', 'pageviews'],
    scSeriesNames: ['clicks', 'impressions', 'ctr', 'console'],
    visibilitySeriesNames: ['search_visibility_index', 'click_potential', 'indexed_pages', 'majestic_trust_flow', 'majestic_citation_flow', 'moz_domain_authority', 'moz_page_authority', 'ahrefs_domain_rating', 'ahrefs_rank'],
    lastValue: Ember.computed('fetchedStats', 'series.[]', function () {
      if (this.isSummableSeries) {
        const lastValueFromFetchedStats = this.graphSeriesTrafficStats.intervalChangeData(this, this.fetchedStats)['lastValue'];
        return lastValueFromFetchedStats;
      }

      const series = this.series || [];
      const lastItem = series[series.length - 1] || [];
      return lastItem[1] || 0;
    }),
    firstValue: Ember.computed('series.[]', function () {
      const series = this.series || [];
      const firstItem = series[0] || [];
      return firstItem[1] || 0;
    }),
    intervalValueChange: Ember.computed('fetchedStats', 'firstValue', 'lastValue', function () {
      if (typeof this.lastValue === 'object') {
        // Distribution series
        return null;
      }

      if (this.isSummableSeries) {
        const intervalValueChangeFromFetchedStats = this.graphSeriesTrafficStats.intervalChangeData(this, this.fetchedStats)['intervalValueChange'];
        return intervalValueChangeFromFetchedStats;
      }

      let change = this.lastValue - this.firstValue;

      if (this.isLeftAxisSeries) {
        change = -change;
      }

      return Math.round(change * 10) / 10;
    }),
    keywordDescription: Ember.computed('keywordModel', function () {
      const trackingInfo = this.get('keywordModel.mobile') ? 'Mobile tracking' : 'Desktop tracking';
      const locationInfo = this.get('keywordModel.fullLocation');
      return `${trackingInfo}, ${locationInfo}`;
    }),
    competitorDisplayName: Ember.computed('competitorUrl', 'competitorModel.displayName', function () {
      if (this.competitorModel) {
        return this.get('competitorModel.displayName');
      }

      return this.competitorUrl;
    }),
    generalSeriesNames: ['total_keywords', 'keyword_distribution'],
    columnSeriesNames: ['keyword_distribution'],
    icon: Ember.computed('name', function () {
      if (this.name.indexOf('keyword_distribution') !== -1) {
        return '/assets/icons/distribution-icon.png';
      }

      return null;
    }),
    displayNameMapper: {
      backlinks_total: 'Total Backlinks',
      backlinks_referring_ips: 'Referring IPs',
      backlinks_referring_domains: 'Referring Domains',
      backlinks_referring_subnets: 'Referring Subnets',
      ahrefs_rank: 'URL Rating',
      ahrefs_domain_rating: 'Domain Rating',
      majestic_trust_flow: 'Trust Flow',
      majestic_citation_flow: 'Citation Flow',
      moz_domain_authority: 'Domain Authority',
      moz_page_authority: 'Page Authority'
    },
    seriesId: Ember.computed('urlId', 'keywordId', 'competitorId', 'dynamicViewId', 'name', function () {
      let seriesId = `url_${this.urlId}`;

      if (this.keywordId) {
        seriesId += `_keyword_${this.keywordId}`;
      }

      if (this.competitorId) {
        seriesId += `_competitor_${this.competitorId}`;
      }

      if (this.dynamicViewId) {
        seriesId += `_dynamic_view_${this.dynamicViewId}`;
      }

      if (this.backlinkViewId) {
        seriesId += `_backlink_view_${this.backlinkViewId}`;
      }

      seriesId += `_${this.name}`;
      return seriesId;
    }),
    isGoogleAnalyticsSeries: Ember.computed('name', function () {
      const name = this.name;
      return this.gaSeriesNames.any(n => name.indexOf(n) !== -1);
    }),
    isSummableSeries: Ember.computed('name', function () {
      const scSummableSeriesNames = ['impressions', 'clicks'];
      return this.isGoogleAnalyticsSeries || scSummableSeriesNames.includes(this.name);
    }),
    isSearchConsoleSeries: Ember.computed('name', function () {
      const name = this.name;

      if (name === 'expected_ctr') {
        return false;
      }

      return this.scSeriesNames.any(n => name.indexOf(n) !== -1);
    }),
    isVisibilitySeries: Ember.computed('name', function () {
      const name = this.name;
      return this.visibilitySeriesNames.any(n => name.indexOf(n) !== -1);
    }),
    isGeneralSeries: Ember.computed('name', function () {
      const name = this.name;
      return this.generalSeriesNames.any(n => name.indexOf(n) !== -1);
    }),
    isBacklinksSeries: Ember.computed('name', function () {
      return this.name.indexOf('backlinks_') !== -1;
    }),
    isRightAxisSeries: Ember.computed.or('isGoogleAnalyticsSeries', 'isVisibilitySeries', 'isSearchConsoleSeries', 'isGeneralSeries', 'isBacklinksSeries'),
    isLeftAxisSeries: Ember.computed.not('isRightAxisSeries'),
    yAxis: Ember.computed('isGoogleAnalyticsSeries', function () {
      if (this.name.indexOf('position') !== -1) {
        return 'axis-y-position';
      }

      return this.isRightAxisSeries ? 'axis-y-analytics' : 'axis-y-position';
    }),
    zIndex: Ember.computed('seriesType', function () {
      return this.seriesType === 'line' ? 2 : 1;
    }),
    timestampedSeries: Ember.computed('series.[]', function () {
      const series = this.series || [];
      return series.map(item => {
        const date = Date.parse(item[0]);
        let value = item[1];

        const valueHasDecimals = v => !isNaN(v) && v % 1 !== 0;

        if (valueHasDecimals(value)) {
          value = Math.round(value * 100) / 100; // Round to 2 decimals
        }

        return [date, value];
      });
    }),
    isStackedColumnSeries: Ember.computed('series.[]', function () {
      // Value is an object containing sub-series
      // Example: ["2017-01-03", { top_3: 1, ...}]
      return this.timestampedSeries.any(s => s[1] && typeof s[1] === 'object');
    }),
    isBasicSeries: Ember.computed.not('isStackedColumnSeries'),
    groupingApproximation: Ember.computed('name', function () {
      let approximation = 'average';

      if (this.name.indexOf('position') !== -1) {
        return approximation;
      }

      const shouldSum = this.isSummableSeries;
      return shouldSum ? 'sum' : approximation;
    }),
    displayName: Ember.computed('name', function () {
      let _displayName = this.displayNameMapper[this.name];

      if (!_displayName) {
        _displayName = this.name.replace(/ctr/g, 'CTR');
      }

      return _displayName;
    }),
    seriesType: Ember.computed('name', function () {
      const name = this.name;
      const isColumnSeries = this.columnSeriesNames.any(columnSeriesName => {
        return name.indexOf(columnSeriesName) !== -1;
      });
      return isColumnSeries ? 'column' : 'line';
    })
  });

  _exports.default = _default;
});