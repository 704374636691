define("nightwatch-web/helpers/to-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toInt = toInt;
  _exports.default = void 0;

  function toInt([input]) {
    return Number.parseInt(input, 10);
  }

  var _default = Ember.Helper.helper(toInt);

  _exports.default = _default;
});