define("nightwatch-web/templates/dashboard/url/backlinks/enable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UlKWyo5g",
    "block": "[[[10,0],[14,0,\"splash-blurred-background\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"splash-blurred-cta-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"blurred-cta\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Start tracking backlinks\"],[13],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"gain insights into your link-building efforts\"],[13],[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[\"truncate switch-button \",[52,[30,0,[\"isEnabling\"]],\"processing\"]]]],[16,\"disabled\",[30,0,[\"isEnabling\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"enableBacklinks\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isEnabling\"]],[[[1,\"        Starting ...\\n\"]],[]],[[[1,\"        Start tracking backlinks\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"info\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        By enabling backlinks feature for \"],[1,[28,[35,2],[[30,1,[\"url\"]]],null]],[1,\", we'll start adding and periodically checking backlinks pointing\\n        to your site. New backlinks will be added until your plan backlinks limit is reached.\\n      \"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        Current available backlinks: \"],[1,[30,0,[\"session\",\"user\",\"availableBacklinks\"]]],[1,\"\\n        (\"],[1,[30,0,[\"session\",\"user\",\"backlinksCount\"]]],[1,\"/\"],[1,[30,0,[\"session\",\"user\",\"backlinksLimit\"]]],[1,\" used).\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\"],false,[\"if\",\"on\",\"friendly-url\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/backlinks/enable.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});