define("nightwatch-web/models/url", ["exports", "@ember-data/model", "nightwatch-web/helpers/friendly-url"], function (_exports, _model, _friendlyUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _dec61, _dec62, _dec63, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Url = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)(), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec11 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)(), _dec16 = (0, _model.attr)('date'), _dec17 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec18 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec19 = (0, _model.attr)('string', {
    serialize: false
  }), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec23 = (0, _model.attr)('date', {
    readOnly: true
  }), _dec24 = (0, _model.attr)('date', {
    readOnly: true
  }), _dec25 = (0, _model.attr)('boolean', {
    readOnly: true
  }), _dec26 = (0, _model.attr)('boolean'), _dec27 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec28 = (0, _model.attr)(), _dec29 = (0, _model.attr)('boolean'), _dec30 = (0, _model.attr)('boolean'), _dec31 = (0, _model.attr)('boolean'), _dec32 = (0, _model.attr)('boolean'), _dec33 = (0, _model.attr)('boolean'), _dec34 = (0, _model.attr)('boolean'), _dec35 = (0, _model.attr)(), _dec36 = (0, _model.attr)(), _dec37 = (0, _model.attr)('string'), _dec38 = (0, _model.attr)('boolean'), _dec39 = (0, _model.attr)('boolean'), _dec40 = (0, _model.attr)('number', {
    defaultValue: 3
  }), _dec41 = (0, _model.attr)('number'), _dec42 = (0, _model.attr)('date'), _dec43 = (0, _model.attr)(), _dec44 = (0, _model.belongsTo)('user', {
    async: true
  }), _dec45 = (0, _model.belongsTo)('url-group', {
    async: true
  }), _dec46 = Ember._tracked, _dec47 = Ember._tracked, _dec48 = Ember._tracked, _dec49 = Ember._tracked, _dec50 = Ember._tracked, _dec51 = Ember._tracked, _dec52 = Ember._tracked, _dec53 = Ember._tracked, _dec54 = Ember._tracked, _dec55 = Ember._tracked, _dec56 = Ember._tracked, _dec57 = Ember.computed('backlinksStatus.@each'), _dec58 = Ember.computed('url'), _dec59 = Ember.computed('url'), _dec60 = Ember.computed('custom_name', 'url'), _dec61 = Ember.computed('name'), _dec62 = Ember.computed('isYoutube', 'url_type'), _dec63 = Ember.computed('friendlyUrl'), (_class = class Url extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "discovery", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "url", _descriptor3, this);

      _initializerDefineProperty(this, "url_type", _descriptor4, this);

      _initializerDefineProperty(this, "custom_name", _descriptor5, this);

      _initializerDefineProperty(this, "places_keyword", _descriptor6, this);

      _initializerDefineProperty(this, "places_info", _descriptor7, this);

      _initializerDefineProperty(this, "places_match", _descriptor8, this);

      _initializerDefineProperty(this, "places_image_title", _descriptor9, this);

      _initializerDefineProperty(this, "keyword_count", _descriptor10, this);

      _initializerDefineProperty(this, "report_count", _descriptor11, this);

      _initializerDefineProperty(this, "google_analytics_profile_id", _descriptor12, this);

      _initializerDefineProperty(this, "search_console_url", _descriptor13, this);

      _initializerDefineProperty(this, "youtube_channel", _descriptor14, this);

      _initializerDefineProperty(this, "url_info", _descriptor15, this);

      _initializerDefineProperty(this, "created_at", _descriptor16, this);

      _initializerDefineProperty(this, "google_indexed", _descriptor17, this);

      _initializerDefineProperty(this, "google_indexed_change", _descriptor18, this);

      _initializerDefineProperty(this, "source", _descriptor19, this);

      _initializerDefineProperty(this, "country_code", _descriptor20, this);

      _initializerDefineProperty(this, "language_code", _descriptor21, this);

      _initializerDefineProperty(this, "google_analytics_error", _descriptor22, this);

      _initializerDefineProperty(this, "keyword_suggestions_updated_at", _descriptor23, this);

      _initializerDefineProperty(this, "google_analytics_updated_at", _descriptor24, this);

      _initializerDefineProperty(this, "backlinksEnabled", _descriptor25, this);

      _initializerDefineProperty(this, "backlinksFetchingEnabled", _descriptor26, this);

      _initializerDefineProperty(this, "backlinksCount", _descriptor27, this);

      _initializerDefineProperty(this, "backlinksStatus", _descriptor28, this);

      _initializerDefineProperty(this, "includeLocalPackInMainPosition", _descriptor29, this);

      _initializerDefineProperty(this, "includePlacesImageInMainPosition", _descriptor30, this);

      _initializerDefineProperty(this, "includeFeaturedSnippetInMainPosition", _descriptor31, this);

      _initializerDefineProperty(this, "includeKnowledgePanelInMainPosition", _descriptor32, this);

      _initializerDefineProperty(this, "matchNestedUrls", _descriptor33, this);

      _initializerDefineProperty(this, "matchSubdomains", _descriptor34, this);

      _initializerDefineProperty(this, "seo_metrics", _descriptor35, this);

      _initializerDefineProperty(this, "seo_metrics_changes", _descriptor36, this);

      _initializerDefineProperty(this, "crawling_session_path_ignore_pattern", _descriptor37, this);

      _initializerDefineProperty(this, "crawling_session_url_params_enabled", _descriptor38, this);

      _initializerDefineProperty(this, "crawling_session_follow_nofollow_links", _descriptor39, this);

      _initializerDefineProperty(this, "crawling_session_concurrency_level", _descriptor40, this);

      _initializerDefineProperty(this, "siteAuditInterval", _descriptor41, this);

      _initializerDefineProperty(this, "nextSiteAuditCrawlAt", _descriptor42, this);

      _initializerDefineProperty(this, "backlinksSettings", _descriptor43, this);

      _initializerDefineProperty(this, "user", _descriptor44, this);

      _initializerDefineProperty(this, "urlGroup", _descriptor45, this);

      _initializerDefineProperty(this, "keywords", _descriptor46, this);

      _initializerDefineProperty(this, "competitors", _descriptor47, this);

      _initializerDefineProperty(this, "dynamicViews", _descriptor48, this);

      _initializerDefineProperty(this, "backlinkViews", _descriptor49, this);

      _initializerDefineProperty(this, "siteAuditViews", _descriptor50, this);

      _initializerDefineProperty(this, "graphs", _descriptor51, this);

      _initializerDefineProperty(this, "reports", _descriptor52, this);

      _initializerDefineProperty(this, "notes", _descriptor53, this);

      _initializerDefineProperty(this, "keywordSuggestionCount", _descriptor54, this);

      _initializerDefineProperty(this, "lastCrawlingSession", _descriptor55, this);

      _initializerDefineProperty(this, "stats", _descriptor56, this);
    }

    get backlinksLastRefresh() {
      const lastCheck = this.get('backlinksStatus.last_ahrefs_fill_try');

      if (Ember.isPresent(lastCheck)) {
        return new Date(lastCheck);
      } else {
        return null;
      }
    }

    get domain() {
      // when RankTrackr users who added a "Google Place" switch to Nightwatch,
      // url can be a string like "Johnson's Bakery"
      try {
        const urlObj = new URL(this.url);
        return urlObj.hostname;
      } catch (e) {
        return this.url;
      }
    }

    get friendlyUrl() {
      const url = this.url || '';
      return (0, _friendlyUrl.friendlyUrl)(url);
    }

    get name() {
      var _this$url_info;

      return this.custom_name || this.youtube_channel || ((_this$url_info = this.url_info) === null || _this$url_info === void 0 ? void 0 : _this$url_info.title) || this.friendlyUrl;
    }

    get isYoutube() {
      return this.url.toLowerCase().indexOf('youtube.com') !== -1;
    }

    get hasSeoMetrics() {
      return !this.isYoutube || this.urlType == 'google' || !this.url_type;
    }

    get faviconUrl() {
      return `https://www.google.com/s2/favicons?domain=${this.friendlyUrl}`;
    }

    init() {
      super.init();
      this.keywords = [];
      this.competitors = [];
      this.dynamicViews = [];
      this.backlinkViews = [];
      this.siteAuditViews = [];
      this.graphs = [];
      this.reports = [];
      this.notes = [];
    }

    async loadKeywordSuggestionCount() {
      const data = await this.discovery.fetchKeywordSuggestionsCount(this);
      this.keywordSuggestionCount = data.keyword_suggestion_count;
    }

    async loadNotes(include_global = true) {
      const notes = await this.store.query('note', {
        include_global,
        url_id: this.id
      });
      notes.forEach(note => note.url = this);
      this.notes = notes;
    }

    async loadStats(dateFrom, dateTo) {
      const urlId = this.id;
      const stats = await this.fetch.request('/url_stats', {
        data: {
          url_id: urlId,
          date_from: dateFrom,
          date_to: dateTo
        }
      });
      this.stats = stats;
    }

    async loadLastCrawlingSession() {
      const urlId = this.id;
      const crawlingSessions = await this.store.query('site-audit/crawling_session', {
        search_keyword_url_id: urlId,
        last: true
      });
      this.lastCrawlingSession = crawlingSessions.firstObject;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "discovery", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "url_type", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "custom_name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "places_keyword", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "places_info", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "places_match", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "places_image_title", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "keyword_count", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "report_count", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "google_analytics_profile_id", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "search_console_url", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "youtube_channel", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "url_info", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "google_indexed", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "google_indexed_change", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "source", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "country_code", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "language_code", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "google_analytics_error", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "keyword_suggestions_updated_at", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "google_analytics_updated_at", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "backlinksEnabled", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "backlinksFetchingEnabled", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "backlinksCount", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "backlinksStatus", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "includeLocalPackInMainPosition", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "includePlacesImageInMainPosition", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "includeFeaturedSnippetInMainPosition", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "includeKnowledgePanelInMainPosition", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "matchNestedUrls", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "matchSubdomains", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "seo_metrics", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "seo_metrics_changes", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "crawling_session_path_ignore_pattern", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "crawling_session_url_params_enabled", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "crawling_session_follow_nofollow_links", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "crawling_session_concurrency_level", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "siteAuditInterval", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "nextSiteAuditCrawlAt", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "backlinksSettings", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "urlGroup", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "keywords", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "competitors", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "dynamicViews", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "backlinkViews", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "siteAuditViews", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "graphs", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "reports", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "notes", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = _applyDecoratedDescriptor(_class.prototype, "keywordSuggestionCount", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = _applyDecoratedDescriptor(_class.prototype, "lastCrawlingSession", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = _applyDecoratedDescriptor(_class.prototype, "stats", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backlinksLastRefresh", [_dec57], Object.getOwnPropertyDescriptor(_class.prototype, "backlinksLastRefresh"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "domain", [_dec58], Object.getOwnPropertyDescriptor(_class.prototype, "domain"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "friendlyUrl", [_dec59], Object.getOwnPropertyDescriptor(_class.prototype, "friendlyUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "name", [_dec60], Object.getOwnPropertyDescriptor(_class.prototype, "name"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isYoutube", [_dec61], Object.getOwnPropertyDescriptor(_class.prototype, "isYoutube"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasSeoMetrics", [_dec62], Object.getOwnPropertyDescriptor(_class.prototype, "hasSeoMetrics"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "faviconUrl", [_dec63], Object.getOwnPropertyDescriptor(_class.prototype, "faviconUrl"), _class.prototype)), _class));
  _exports.default = Url;
});