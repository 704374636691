define("nightwatch-web/components/settings/whitelabel-logo-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-whitelabel-logo-list__item">
    {{#unless @whitelabelLogo.default}}
     <button class="nw-button nw-button--plain" {{on "click" @onDeleteWhitelabelLogo}} type="button">
        <NwIcon class="nw-whitelabel-logo-list__item--remove" @icon="svg-times" />
     </button>
    {{/unless}}
    <img class="nw-whitelabel-logo-list__item--image" src={{@whitelabelLogo.logoImgUrl}} alt="Uploaded item {{add-number @idx 1}}">
    {{#if @whitelabelLogo.default}}
      <small>Default</small>
    {{else}}
      <button class="nw-button nw-button--plain nw-whitelabel-logo-list__item--set-default" {{on "click" @onSwitchDefaultLogo}} type="button">Make default</button>
    {{/if}}
  </div>
  {{yield}}
  
  */
  {
    "id": "zgvXM34L",
    "block": "[[[10,0],[14,0,\"nw-whitelabel-logo-list__item\"],[12],[1,\"\\n\"],[41,[51,[30,1,[\"default\"]]],[[[1,\"   \"],[11,\"button\"],[24,0,\"nw-button nw-button--plain\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,2]],null],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"nw-whitelabel-logo-list__item--remove\"]],[[\"@icon\"],[\"svg-times\"]],null],[1,\"\\n   \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"img\"],[14,0,\"nw-whitelabel-logo-list__item--image\"],[15,\"src\",[30,1,[\"logoImgUrl\"]]],[15,\"alt\",[29,[\"Uploaded item \",[28,[37,3],[[30,3],1],null]]]],[12],[13],[1,\"\\n\"],[41,[30,1,[\"default\"]],[[[1,\"    \"],[10,\"small\"],[12],[1,\"Default\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,\"button\"],[24,0,\"nw-button nw-button--plain nw-whitelabel-logo-list__item--set-default\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,4]],null],[12],[1,\"Make default\"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[18,5,null],[1,\"\\n\"]],[\"@whitelabelLogo\",\"@onDeleteWhitelabelLogo\",\"@idx\",\"@onSwitchDefaultLogo\",\"&default\"],false,[\"unless\",\"on\",\"nw-icon\",\"add-number\",\"if\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/settings/whitelabel-logo-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});