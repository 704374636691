define("nightwatch-web/routes/dashboard/dynamic-view/report", ["exports", "nightwatch-web/constants/time-periods", "date-fns"], function (_exports, _timePeriods, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardDynamicViewReportRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      const dynamicView = this.modelFor('dashboard.dynamic-view');
      controller.set('dynamicView', dynamicView);
      controller.set('whitelabelLogos', this.store.findAll('whitelabel-logo'));
    }

    afterModel(model) {
      if (model.get('scheduled')) {
        model.set('end_time', new Date());
        const reportInterval = String(model.get('report_interval'));

        if (reportInterval === _timePeriods.MONTHLY.toString()) {
          model.set('start_time', (0, _dateFns.sub)(new Date(), {
            days: 30
          }));
        } else if (reportInterval === _timePeriods.WEEKLY.toString()) {
          model.set('start_time', (0, _dateFns.sub)(new Date(), {
            days: 7
          }));
        }
      }

      if (!model.get('recipients')) {
        model.set('recipients', ['']);
      }
    }

    renderTemplate() {
      this.render({
        into: 'dashboard'
      });
    }

  }

  _exports.default = DashboardDynamicViewReportRoute;
});