define("nightwatch-web/utils/refresh-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const refreshRoute = (context, routeName) => {
    const route = Ember.getOwner(context).lookup(`route:${routeName}`);
    route.refresh();
  };

  var _default = refreshRoute;
  _exports.default = _default;
});