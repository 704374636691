define("nightwatch-web/components/confirm-resource-deletion", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="confirm-resource-deletion">
    <div class="confirm-resource-deletion__warning">
      <p><strong>Are you absolutely sure?</strong></p>
      <p>Unexpected bad things will happen if you don't read this!</p>
    </div>
  
    {{yield}}
  
    <form class="nw-form confirm-resource-deletion__confirmation" {{on "submit" (prevent-default this.delete)}}>
      <div class="nw-form__row nw-form__row--full">
        <div class="nw-form__group">
          <p>{{@confirmationRules}}:</p>
          <FocusInput @value={{this.providedInput}} class="nw-form__input" />
        </div>
      </div>
  
      <div class="nw-form__row nw-form__row--last nw-form__row--align-right">
        <div class="nw-form__group nw-form__group--row">
          <button class="nw-button" type="button" {{on "click" @onCancelDelete}}>Go back</button>
          <button class="nw-button nw-button--danger offset-left" disabled={{or (not this.canBeDeleted) this.isDeleting}} type="submit">
            {{if this.isDeleting "Deleting…" (concat "I understand, delete this " @resourceName)}}
          </button>
        </div>
      </div>
    </form>
  </div>
  
  */
  {
    "id": "Q1SiH/6r",
    "block": "[[[10,0],[14,0,\"confirm-resource-deletion\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"confirm-resource-deletion__warning\"],[12],[1,\"\\n    \"],[10,2],[12],[10,\"strong\"],[12],[1,\"Are you absolutely sure?\"],[13],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Unexpected bad things will happen if you don't read this!\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[18,4,null],[1,\"\\n\\n  \"],[11,\"form\"],[24,0,\"nw-form confirm-resource-deletion__confirmation\"],[4,[38,1],[\"submit\",[28,[37,2],[[30,0,[\"delete\"]]],null]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"nw-form__row nw-form__row--full\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-form__group\"],[12],[1,\"\\n        \"],[10,2],[12],[1,[30,1]],[1,\":\"],[13],[1,\"\\n        \"],[8,[39,3],[[24,0,\"nw-form__input\"]],[[\"@value\"],[[30,0,[\"providedInput\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"nw-form__row nw-form__row--last nw-form__row--align-right\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-form__group nw-form__group--row\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"nw-button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,2]],null],[12],[1,\"Go back\"],[13],[1,\"\\n        \"],[10,\"button\"],[14,0,\"nw-button nw-button--danger offset-left\"],[15,\"disabled\",[28,[37,4],[[28,[37,5],[[30,0,[\"canBeDeleted\"]]],null],[30,0,[\"isDeleting\"]]],null]],[14,4,\"submit\"],[12],[1,\"\\n          \"],[1,[52,[30,0,[\"isDeleting\"]],\"Deleting…\",[28,[37,7],[\"I understand, delete this \",[30,3]],null]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@confirmationRules\",\"@onCancelDelete\",\"@resourceName\",\"&default\"],false,[\"yield\",\"on\",\"prevent-default\",\"focus-input\",\"or\",\"not\",\"if\",\"concat\"]]",
    "moduleName": "nightwatch-web/components/confirm-resource-deletion.hbs",
    "isStrictMode": false
  });

  function normalize(str) {
    if (str && 'string' === typeof str) {
      return str.toLowerCase().trim();
    }

    return str;
  }

  let ConfirmResourceDeletionComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class ConfirmResourceDeletionComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "providedInput", _descriptor, this);

      _initializerDefineProperty(this, "isDeleting", _descriptor2, this);
    }

    get canBeDeleted() {
      return normalize(this.providedInput) === normalize(this.args.expectedInput);
    }

    delete() {
      const onConfirmDelete = this.args.onConfirmDelete;
      this.isDeleting = true;

      if (this.canBeDeleted) {
        onConfirmDelete(this.args.resource);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "providedInput", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDeleting", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = ConfirmResourceDeletionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ConfirmResourceDeletionComponent);
});