define("nightwatch-web/utils/load-external-scripts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Load remote JS synchronously and return a promise
   * @param  {Array}   scriptSources Array of source URLs
   * @param  {Number}  [timeout=10]  Seconds to wait for all scripts to load before rejecting
   * @return {Promise}
   */
  const loadExternalScripts = async (scriptSources = [], timeout = 10) => {
    timeout = timeout * 1000;
    const existingScripts = Array.from(document.querySelectorAll('script')).map(script => script.getAttribute('src'));
    const uniqueSources = scriptSources.filter(url => !existingScripts.includes(url));
    const start = Date.now();

    const loadScript = url => new Promise((resolve, reject) => {
      let resolved = false;
      const timeoutCheck = setInterval(() => {
        if (resolved) {
          clearInterval(timeoutCheck);
        } else if (Date.now() - start > timeout) {
          reject(new Error('Script load time out.'));
          clearInterval(timeoutCheck);
        }
      }, 1000);
      const script = document.createElement('script');
      script.src = url;
      script.addEventListener('load', () => {
        resolved = true;
        resolve(url);
      });
      document.body.appendChild(script);
    });

    const results = [];

    for (let i = 0; i < uniqueSources.length; i++) {
      const loaded = await loadScript(uniqueSources[i]);
      results.push(loaded);
    }

    return results;
  };

  var _default = loadExternalScripts;
  _exports.default = _default;
});