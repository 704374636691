define("nightwatch-web/components/social-proof", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="social-proof" ...attributes>
    <img src={{this.review.avatar}} class="social-proof__avatar" alt={{this.review.name}} />
    <div class="social-proof__snippet">
      "{{this.review.snippet}}"
    </div>
    <div class="social-proof__annotation">
      <div class="social-proof__name">{{this.review.name}}</div>
      <div class="social-proof__title">{{this.review.title}}</div>
    </div>
    <div class="social-proof__badges">
      <a href={{this.capterraUrl}} target="_blank" rel="noopener noreferrer">
        <img src="/assets/images/social-proof/capterra-easiest-small-biz.png" alt="Capterra Easiest To Do Business With - Small Business - Fall 2020" />
      </a>
      <a href={{this.capterraUrl}} target="_blank" rel="noopener noreferrer">
        <img src="/assets/images/social-proof/capterra-high-performer.png" alt="Capterra High Performer - Fall 2020" />
      </a>
      <a href={{this.capterraUrl}} target="_blank" rel="noopener noreferrer">
        <img src="/assets/images/social-proof/capterra-easiest.png" alt="Capterra Easiest To Do Business With - Fall 2020" />
      </a>
    </div>
    <div class="social-proof__review-link"></div>
  </div>
  
  */
  {
    "id": "csk0tK2T",
    "block": "[[[11,0],[24,0,\"social-proof\"],[17,1],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,0,[\"review\",\"avatar\"]]],[14,0,\"social-proof__avatar\"],[15,\"alt\",[30,0,[\"review\",\"name\"]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"social-proof__snippet\"],[12],[1,\"\\n    \\\"\"],[1,[30,0,[\"review\",\"snippet\"]]],[1,\"\\\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"social-proof__annotation\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"social-proof__name\"],[12],[1,[30,0,[\"review\",\"name\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"social-proof__title\"],[12],[1,[30,0,[\"review\",\"title\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"social-proof__badges\"],[12],[1,\"\\n    \"],[10,3],[15,6,[30,0,[\"capterraUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/social-proof/capterra-easiest-small-biz.png\"],[14,\"alt\",\"Capterra Easiest To Do Business With - Small Business - Fall 2020\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,3],[15,6,[30,0,[\"capterraUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/social-proof/capterra-high-performer.png\"],[14,\"alt\",\"Capterra High Performer - Fall 2020\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,3],[15,6,[30,0,[\"capterraUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/social-proof/capterra-easiest.png\"],[14,\"alt\",\"Capterra Easiest To Do Business With - Fall 2020\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"social-proof__review-link\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[]]",
    "moduleName": "nightwatch-web/components/social-proof.hbs",
    "isStrictMode": false
  });

  const CAPTERRA_URL = 'https://www.capterra.com/p/177363/Nightwatch/';
  const REVIEWS = [{
    avatar: '/assets/images/social-proof/mansoor.png',
    snippet: "Best keyword rank tracking experience I've ever had.",
    name: 'Mansoor A',
    title: 'CEO @ Vibrant Media'
  }];

  const randomReview = () => {
    return REVIEWS[Math.floor(Math.random() * REVIEWS.length)];
  };
  /**
    @class SocialProofComponent
  
    Renders a random Capterra review from REVIEWS
   */


  class SocialProofComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "review", randomReview());

      _defineProperty(this, "capterraUrl", CAPTERRA_URL);
    }

  }

  _exports.default = SocialProofComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SocialProofComponent);
});