define("nightwatch-web/helpers/is-in-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isInCollection = void 0;

  const isInCollection = params => (params[1] || []).mapBy('id').includes(String(params[0].id));

  _exports.isInCollection = isInCollection;

  var _default = Ember.Helper.helper(isInCollection);

  _exports.default = _default;
});