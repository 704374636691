define("nightwatch-web/services/url-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UrlUtilsService = (_dec = Ember.inject.service, (_class = class UrlUtilsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    loadUrlRelationships(url) {
      if (url == null) {
        return;
      }

      const promises = [];
      promises.push(this.store.query('competitor', {
        url_id: url.id
      }).then(competitors => {
        return url.set('competitors', competitors);
      }));
      promises.push(this.store.query('dynamic-view', {
        search_keyword_url_id: url.id
      }).then(dynamicViews => {
        return url.set('dynamicViews', dynamicViews);
      }));
      promises.push(this.refreshGraphs(url));
      promises.push(this.store.query('backlink-view', {
        url_id: url.id
      }).then(backlinkViews => {
        url.set('backlinkViews', backlinkViews);
        return backlinkViews.forEach(backlinkViews => backlinkViews.loadCount());
      })); // Site Audit stuff

      promises.push( // For sidebar "All Pages" count
      url.loadLastCrawlingSession());
      promises.push(this.store.query('site-audit/view', {
        search_keyword_url_id: url.id
      }).then(views => {
        url.set('siteAuditViews', views);
        return views.forEach(view => view.loadCount());
      }));
      promises.push(url.loadNotes());
      return Ember.RSVP.all(promises);
    }

    async refreshGraphs(url) {
      url.graphs = await this.store.query('graph', {
        url_id: url.id
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UrlUtilsService;
});