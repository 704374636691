define("nightwatch-web/modifiers/did-pan", ["exports", "ember-gesture-modifiers/modifiers/did-pan"], function (_exports, _didPan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _didPan.default;
    }
  });
});