define("nightwatch-web/services/graphs/graph-series-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GraphSeriesStatsService extends Ember.Service {
    intervalChangeData(seriesObject, stats = {}) {
      const name = seriesObject.get('name');
      let data = {};

      if (seriesObject.isSummableSeries) {
        const sumKey = `${name}_sum`;
        const valueChangeKey = `${name}_change`;
        data = {
          lastValue: stats[sumKey],
          intervalValueChange: stats[valueChangeKey]
        };
      }

      return data;
    }

  }

  _exports.default = GraphSeriesStatsService;
});