define("nightwatch-web/adapters/pinned-item", ["exports", "nightwatch-web/adapters/application", "nightwatch-web/utils/is-type"], function (_exports, _application, _isType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PinnedItemAdapter extends _application.default {
    handleResponse(status, headers, payload, requestData) {
      if (status >= 400) return super.handleResponse(...arguments);

      if (Array.isArray(payload)) {
        payload = payload.map(pinnedItem => _objectSpread(_objectSpread({}, pinnedItem), {}, {
          url_id: pinnedItem.search_keyword_url_id,
          group_id: pinnedItem.url_group_id
        }));
      } else if ((0, _isType.isObjectType)(payload)) {
        payload = _objectSpread(_objectSpread({}, payload), {}, {
          url_id: payload.search_keyword_url_id,
          group_id: payload.url_group_id
        });
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

  }

  _exports.default = PinnedItemAdapter;
});