define("nightwatch-web/helpers/format-date", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  function formatDate([date, formatStr, fallbackDate]) {
    let dateObject;

    if ((0, _dateFns.isDate)(date) && (0, _dateFns.isValid)(date)) {
      dateObject = date;
    } else if ((0, _dateFns.isDate)(fallbackDate) && (0, _dateFns.isValid)(date)) {
      dateObject = fallbackDate;
    }

    return dateObject ? (0, _dateFns.format)(new Date(dateObject), formatStr) : '';
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});