define("nightwatch-web/components/snapshot-selector/selected-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="ember-power-select-selected-item">
    {{humanize @option.label}}
  </span>
  
  */
  {
    "id": "V+28ecmw",
    "block": "[[[10,1],[14,0,\"ember-power-select-selected-item\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,1,[\"label\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@option\"],false,[\"humanize\"]]",
    "moduleName": "nightwatch-web/components/snapshot-selector/selected-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});