define("nightwatch-web/utils/abbreviate-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = value => {
    // http://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
    if (value == null) {
      return null;
    }

    let newValue = value;

    if (value >= 1000) {
      value = parseInt(value);
      let suffixes = ['', 'k', 'm', 'b', 't'];
      let suffixNum = Math.floor(('' + value).length / 3);
      let shortValue = '';

      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
        let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');

        if (dotLessShortValue.length <= 2) {
          break;
        }
      }

      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1);
      }

      newValue = shortValue + suffixes[suffixNum];
    }

    return newValue;
  };

  _exports.default = _default;
});