define("nightwatch-web/components/billing/customer-portal-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (service "stripe") as |stripe|}}
    <div class="nw-button nw-button--submit" role="button" {{on "click" (perform stripe.redirectToBillingPortal)}} ...attributes>
      {{#if stripe.redirectToBillingPortal.isRunning}}
        Redirecting to Stripe...
      {{else if (has-block)}}
        {{yield}}
      {{/if}}
    </div>
  {{/let}}
  
  */
  {
    "id": "YcxIHSaI",
    "block": "[[[44,[[28,[37,1],[\"stripe\"],null]],[[[1,\"  \"],[11,0],[24,0,\"nw-button nw-button--submit\"],[24,\"role\",\"button\"],[17,2],[4,[38,2],[\"click\",[28,[37,3],[[30,1,[\"redirectToBillingPortal\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,1,[\"redirectToBillingPortal\",\"isRunning\"]],[[[1,\"      Redirecting to Stripe...\\n\"]],[]],[[[41,[48,[30,3]],[[[1,\"      \"],[18,3,null],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]],[\"stripe\",\"&attrs\",\"&default\"],false,[\"let\",\"service\",\"on\",\"perform\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/billing/customer-portal-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});