define("nightwatch-web/controllers/dashboard/url/site-audit", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlSiteAuditController = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('model.url'), _dec3 = Ember.computed.alias('model.crawlingSessions'), _dec4 = Ember.computed.alias('model.crawlingSession'), _dec5 = Ember.computed.alias('model.comparisonCrawlingSession'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed.alias('c'), _dec10 = Ember._action, _dec11 = Ember._action, (_class = class DashboardUrlSiteAuditController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "url", _descriptor2, this);

      _initializerDefineProperty(this, "crawlingSessions", _descriptor3, this);

      _initializerDefineProperty(this, "crawlingSession", _descriptor4, this);

      _initializerDefineProperty(this, "comparisonCrawlingSession", _descriptor5, this);

      _defineProperty(this, "queryParams", ['crawling_session_id', 'comparison_crawling_session_id', 'c']);

      _initializerDefineProperty(this, "crawling_session_id", _descriptor6, this);

      _initializerDefineProperty(this, "comparison_crawling_session_id", _descriptor7, this);

      _initializerDefineProperty(this, "c", _descriptor8, this);

      _initializerDefineProperty(this, "comparisonEnabled", _descriptor9, this);
    }

    get mostRecentCrawlingSession() {
      return this.crawlingSessions.firstObject;
    }

    get secondMostRecentCrawlingSession() {
      var _this$crawlingSession;

      return ((_this$crawlingSession = this.crawlingSessions) !== null && _this$crawlingSession !== void 0 ? _this$crawlingSession : []).toArray()[1];
    }

    get olderCrawlingSessions() {
      var _this$crawlingSession2;

      return ((_this$crawlingSession2 = this.crawlingSessions) !== null && _this$crawlingSession2 !== void 0 ? _this$crawlingSession2 : []).filter(s => {
        return (0, _dateFns.isBefore)(new Date(s.createdAt), new Date(this.crawlingSession.createdAt));
      });
    }

    get availableCrawlingSessionsForCurrentSnapshot() {
      return this.comparisonEnabled ? this.newerCrawlingSessions : this.crawlingSessions;
    }

    get availableCrawlingSessionsForComparisonSnapshot() {
      return this.olderCrawlingSessions;
    }

    get newerCrawlingSessions() {
      var _this$crawlingSession3;

      return ((_this$crawlingSession3 = this.crawlingSessions) !== null && _this$crawlingSession3 !== void 0 ? _this$crawlingSession3 : []).filter(s => {
        return (0, _dateFns.isAfter)(new Date(s.createdAt), new Date(this.comparisonCrawlingSession.createdAt));
      });
    }

    setCrawlingSession(crawlingSession) {
      // This set will refresh the model
      this.crawling_session_id = crawlingSession.id;
    }

    setComparisonCrawlingSession(crawlingSession) {
      // This set will refresh the model
      this.comparison_crawling_session_id = crawlingSession.id;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crawlingSessions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "crawlingSession", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "comparisonCrawlingSession", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "crawling_session_id", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "comparison_crawling_session_id", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "c", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "comparisonEnabled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setCrawlingSession", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setCrawlingSession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setComparisonCrawlingSession", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setComparisonCrawlingSession"), _class.prototype)), _class));
  _exports.default = DashboardUrlSiteAuditController;
});