define("nightwatch-web/services/user-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const LOCAL_STORAGE_KEY = 'nw:anonymous-userdata';
  let UserDataService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class UserDataService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);
    }

    // Stores and retrieve data in localStorage using the local property
    get local() {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    }

    set local(data) {
      if (!data) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        return;
      }

      const existingData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

      const dataToSave = _objectSpread(_objectSpread({}, existingData), data);

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dataToSave));
    }

    store(planIdParam) {
      this.local = this.generate(planIdParam);
    } // Takes locally stored data and saves it to user_info


    async save() {
      var _user$user_info;

      if (!this.local) return;
      const {
        user
      } = this.session;
      const userInfo = (_user$user_info = user === null || user === void 0 ? void 0 : user.user_info) !== null && _user$user_info !== void 0 ? _user$user_info : {};

      const combinedData = _objectSpread(_objectSpread({}, userInfo), this.local);

      this.local = null;
      user.user_info = combinedData;
      await user.save();
      await this.fetch.post('/notify_new_user');
    }

    generate(planIdParam) {
      let data = {}; // Add attempted plan_id if present in queryParams

      const planId = Number.parseInt(planIdParam, 10);
      if (!Number.isNaN(planId)) data.attempted_plan = planId; // Cookie "nw-user-info" matches the script to capture cookies
      // on landing page at www.nightwatch.io (and maybe other subdomains)

      const cookieData = document.cookie.replace(/(?:(?:^|.*;\s*)nw-user-info\s*=\s*([^;]*).*$)|^.*$/, '$1');

      if (cookieData) {
        try {
          const decodedCookieData = JSON.parse(decodeURIComponent(cookieData));
          data = _objectSpread(_objectSpread({}, data), decodedCookieData); // To delete the cookie we need to specify the ".domain.tld" part of hostname

          const domain = window.location.hostname.split('.').slice(-2).join('.');
          document.cookie = `nw-user-info=;max-age=0;domain=.${domain}`;
        } catch {// Should fail silently
        }
      } else {
        // When no cookie, set landing page to app's URL
        data.landing = window.location.origin; // Also attempt to add referrer

        if (document.referrer) {
          data.referrer = document.referrer;
        }
      }

      return data;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserDataService;
});