define("nightwatch-web/components/reports/report-blocks/site-audit/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (and (eq @columnName @selectedColumnName) (eq @direction @selectedDirection)) as |selected|}}
    <div class="report-block__sort {{if selected "report-block__sort--selected"}}" role="button" {{on "click" (fn @selectSort @columnName @direction)}}>
      {{#if (eq @direction "desc")}}
        <NwIcon @icon="icon-chevron-down" />
      {{else if (eq @direction "asc")}}
        <NwIcon @icon="icon-chevron-up" />
      {{/if}}
    </div>
  {{/let}}
  
  */
  {
    "id": "wpjsry2H",
    "block": "[[[44,[[28,[37,1],[[28,[37,2],[[30,1],[30,2]],null],[28,[37,2],[[30,3],[30,4]],null]],null]],[[[1,\"  \"],[11,0],[16,0,[29,[\"report-block__sort \",[52,[30,5],\"report-block__sort--selected\"]]]],[24,\"role\",\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,6],[30,1],[30,3]],null]],null],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,3],\"desc\"],null],[[[1,\"      \"],[8,[39,6],null,[[\"@icon\"],[\"icon-chevron-down\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,3],\"asc\"],null],[[[1,\"      \"],[8,[39,6],null,[[\"@icon\"],[\"icon-chevron-up\"]],null],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[5]]]],[\"@columnName\",\"@selectedColumnName\",\"@direction\",\"@selectedDirection\",\"selected\",\"@selectSort\"],false,[\"let\",\"and\",\"eq\",\"if\",\"on\",\"fn\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/reports/report-blocks/site-audit/sort.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});