define("nightwatch-web/components/reports/report-preview", ["exports", "nightwatch-web/utils/is-testing"], function (_exports, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless this.testing}}
    {{#if this.terminate}}
      <div class="report-preview-loading-indicator">
        <span>
          Report preview terminated due to taking too long to generate.
          We suggest reducing the date range and creating segmented reports using
          <b><a target="_blank" rel="noopener noreferrer" href={{unless this.siteData.hideNightwatchBranding (docs-url 'keywordViewsAndLists')}}>
            keyword views
          </a></b>.
        </span>
      </div>
    {{else if this.previewLoading}}
      <div class="report-preview-loading-indicator">
        <Loading::Spinner />
        {{#if this.warning}}
          <span>
            Generating preview is taking longer than usual...
          </span>
        {{else}}
          Generating preview ...
        {{/if}}
      </div>
    {{/if}}
  
    {{#unless this.terminate}}
      <iframe title="Report preview" {{on "load" this.iframeLoad}} src={{this.previewUrl}} class="report-preview-container {{if this.previewLoading 'very-dimmed'}}"></iframe>
    {{/unless}}
  {{/unless}}
  
  */
  {
    "id": "uU3jy9Ak",
    "block": "[[[41,[51,[30,0,[\"testing\"]]],[[[41,[30,0,[\"terminate\"]],[[[1,\"    \"],[10,0],[14,0,\"report-preview-loading-indicator\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        Report preview terminated due to taking too long to generate.\\n        We suggest reducing the date range and creating segmented reports using\\n        \"],[10,\"b\"],[12],[10,3],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[15,6,[52,[51,[30,0,[\"siteData\",\"hideNightwatchBranding\"]]],[28,[37,2],[\"keywordViewsAndLists\"],null]]],[12],[1,\"\\n          keyword views\\n        \"],[13],[13],[1,\".\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"previewLoading\"]],[[[1,\"    \"],[10,0],[14,0,\"report-preview-loading-indicator\"],[12],[1,\"\\n      \"],[8,[39,3],null,null,null],[1,\"\\n\"],[41,[30,0,[\"warning\"]],[[[1,\"        \"],[10,1],[12],[1,\"\\n          Generating preview is taking longer than usual...\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        Generating preview ...\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[1,\"\\n\"],[41,[51,[30,0,[\"terminate\"]]],[[[1,\"    \"],[11,\"iframe\"],[24,\"title\",\"Report preview\"],[16,\"src\",[30,0,[\"previewUrl\"]]],[16,0,[29,[\"report-preview-container \",[52,[30,0,[\"previewLoading\"]],\"very-dimmed\"]]]],[4,[38,4],[\"load\",[30,0,[\"iframeLoad\"]]],null],[12],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"unless\",\"if\",\"docs-url\",\"loading/spinner\",\"on\"]]",
    "moduleName": "nightwatch-web/components/reports/report-preview.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    siteData: Ember.inject.service(),
    testing: _isTesting.default,

    didReceiveAttrs() {
      if (this.testing) return;

      if (this.previewUrl) {
        this.resetTimerProperties();
        this.startTimer();
        this.set('previewLoading', true);
      }
    },

    resetTimerProperties() {
      this.setProperties({
        timerRunning: false,
        warning: false,
        terminate: false
      });
    },

    stopTimer() {
      this.set('timerRunning', false);
    },

    startTimer() {
      this.set('timerRunning', true);
      const step = 1000;
      const terminateTime = 2 * 60 * step;
      const warningTime = 30 * step;
      let time = 0;

      const _loop = () => {
        if (this.isDestroying || this.isDestroyed) return;
        time = time + step;

        if (time >= warningTime) {
          this.set('warning', true);
        }

        if (time >= terminateTime) {
          this.set('terminate', true);
          this.set('timerRunning', false);
          this.set('previewLoading', false);
          this.set('previewUrl', null);
        }

        if (this.timerRunning) {
          Ember.run.later(() => {
            _loop();
          }, step);
        }
      };

      _loop();
    },

    iframeLoad: Ember._action(function () {
      this.set('previewLoading', false);
      this.stopTimer();
    })
  }));

  _exports.default = _default;
});