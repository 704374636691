define("nightwatch-web/controllers/dashboard/url/graph/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlGraphSettingsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class DashboardUrlGraphSettingsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "urlUtils", _descriptor2, this);
    }

    deleteGraph() {
      if (confirm('Are you sure you want to delete this graph?')) {
        const graph = this.model;
        const url = graph.url;
        graph.destroyRecord().then(() => {
          this.urlUtils.refreshGraphs(url);
          this.router.transitionTo('dashboard.url', url);
          this.notifications.success('Graph deleted.', {
            autoClear: true,
            clearDuration: 2500
          });
        });
      }
    }

    saveGraph() {
      const graph = this.model;
      graph.save().then(() => {
        this.urlUtils.refreshGraphs(graph.url);
        this.notifications.success('Graph saved.', {
          autoClear: true,
          clearDuration: 3000
        });
        this.router.transitionTo('dashboard.url.graph', graph);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "urlUtils", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteGraph", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "deleteGraph"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveGraph", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "saveGraph"), _class.prototype)), _class));
  _exports.default = DashboardUrlGraphSettingsController;
});