define("nightwatch-web/models/site-audit/crawling-session", ["exports", "@ember-data/model", "date-fns"], function (_exports, _model, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CrawlingSession = (_dec = (0, _model.attr)('date'), _dec2 = (0, _model.attr)('date'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)(), _dec9 = (0, _model.attr)(), _dec10 = (0, _model.attr)(), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)('boolean'), _dec13 = (0, _model.attr)('boolean'), _dec14 = (0, _model.attr)('date'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('number'), _dec17 = (0, _model.attr)('number'), _dec18 = Ember.computed.equal('status', 'done'), _dec19 = Ember.computed.equal('status', 'pending'), _dec20 = Ember.computed.equal('status', 'processing'), _dec21 = Ember.computed.or('isPending', 'isProcessing'), _dec22 = Ember.computed('endReason'), _dec23 = Ember.computed('startedAt'), (_class = class CrawlingSession extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "startedAt", _descriptor, this);

      _initializerDefineProperty(this, "createdAt", _descriptor2, this);

      _initializerDefineProperty(this, "searchKeywordUrlId", _descriptor3, this);

      _initializerDefineProperty(this, "endedAt", _descriptor4, this);

      _initializerDefineProperty(this, "internalUrlsCount", _descriptor5, this);

      _initializerDefineProperty(this, "status", _descriptor6, this);

      _initializerDefineProperty(this, "endReason", _descriptor7, this);

      _initializerDefineProperty(this, "ips", _descriptor8, this);

      _initializerDefineProperty(this, "dns_servers", _descriptor9, this);

      _initializerDefineProperty(this, "aliases", _descriptor10, this);

      _initializerDefineProperty(this, "sitemap", _descriptor11, this);

      _initializerDefineProperty(this, "robots", _descriptor12, this);

      _initializerDefineProperty(this, "sslValid", _descriptor13, this);

      _initializerDefineProperty(this, "sslValidUntil", _descriptor14, this);

      _initializerDefineProperty(this, "pagesCount", _descriptor15, this);

      _initializerDefineProperty(this, "version", _descriptor16, this);

      _initializerDefineProperty(this, "nextCrawlInterval", _descriptor17, this);

      _initializerDefineProperty(this, "isDone", _descriptor18, this);

      _initializerDefineProperty(this, "isPending", _descriptor19, this);

      _initializerDefineProperty(this, "isProcessing", _descriptor20, this);

      _initializerDefineProperty(this, "isPendingOrProcessing", _descriptor21, this);
    }

    get forcefullyTerminated() {
      return this.endReason && this.endReason !== 'no_links';
    }

    get startedAtDateString() {
      return this.startedAt ? (0, _dateFns.format)(new Date(this.startedAt), 'MM-dd-yyyy') : (0, _dateFns.format)(new Date(), 'MM-dd-yyyy');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "startedAt", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchKeywordUrlId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "endedAt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "internalUrlsCount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "endReason", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ips", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dns_servers", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "aliases", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "sitemap", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "robots", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sslValid", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "sslValidUntil", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "pagesCount", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "nextCrawlInterval", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "isDone", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "isProcessing", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "isPendingOrProcessing", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "forcefullyTerminated", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "forcefullyTerminated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startedAtDateString", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "startedAtDateString"), _class.prototype)), _class));
  _exports.default = CrawlingSession;
});