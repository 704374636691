define("nightwatch-web/components/site-audit/crawling-session-end-reason", ["exports", "@glimmer/component", "nightwatch-web/constants/site-audit"], function (_exports, _component, _siteAudit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.endReason.message}}
    <NwHelpTooltip @icon={{this.endReason.icon}} class={{this.endReason.iconClasses}}>
      Crawling finished: {{this.endReason.message}}
    </NwHelpTooltip>
  {{/if}}
  
  */
  {
    "id": "Mvrx2vQw",
    "block": "[[[41,[30,0,[\"endReason\",\"message\"]],[[[1,\"  \"],[8,[39,1],[[16,0,[30,0,[\"endReason\",\"iconClasses\"]]]],[[\"@icon\"],[[30,0,[\"endReason\",\"icon\"]]]],[[\"default\"],[[[[1,\"\\n    Crawling finished: \"],[1,[30,0,[\"endReason\",\"message\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"nw-help-tooltip\"]]",
    "moduleName": "nightwatch-web/components/site-audit/crawling-session-end-reason.hbs",
    "isStrictMode": false
  });

  class CrawlingSessionEndReasonComponent extends _component.default {
    get endReason() {
      return _siteAudit.crawlingSessionEndReasonExplanations[this.args.reason];
    }

  }

  _exports.default = CrawlingSessionEndReasonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CrawlingSessionEndReasonComponent);
});