define("nightwatch-web/helpers/nice-date", ["exports", "nightwatch-web/helpers/format-date"], function (_exports, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.niceDate = void 0;

  const niceDate = ([date]) => (0, _formatDate.formatDate)([new Date(date), 'MMM dd, yyyy']);

  _exports.niceDate = niceDate;

  var _default = Ember.Helper.helper(niceDate);

  _exports.default = _default;
});