define("nightwatch-web/components/focus-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    didInsertElement() {
      this.element.focus();
    }

  });

  _exports.default = _default;
});