define("nightwatch-web/helpers/format-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAmount = formatAmount;
  _exports.default = void 0;

  function formatAmount([number]) {
    const parsed = Number.parseFloat(number);

    if (isNaN(parsed)) {
      return '';
    }

    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    return parsed.toLocaleString('en-US', options);
  }

  var _default = Ember.Helper.helper(formatAmount);

  _exports.default = _default;
});