define("nightwatch-web/routes/changelog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChangelogRoute extends Ember.Route {
    model() {
      return this.store.findAll('changelog');
    }

  }

  _exports.default = ChangelogRoute;
});