define("nightwatch-web/services/graphs/nw-graph-series", ["exports", "nightwatch-web/utils/humanize", "nightwatch-web/utils/titleize", "nightwatch-web/utils/graphs/fill-gaps", "nightwatch-web/utils/graphs/grouping"], function (_exports, _humanize, _titleize, _fillGaps, _grouping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
    Responsible for series data for the *new* Chart.js graphs
   */
  let NwGraphSeriesService = (_dec = Ember.inject.service, (_class = class NwGraphSeriesService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "graphColorManager", _descriptor, this);
    }

    averagePositionSeries(data, timeUnit) {
      data = (0, _grouping.groupData)((0, _fillGaps.default)(data), timeUnit, _grouping.groupMethods.average);
      return [{
        data: data.map(([date, value]) => ({
          x: date,
          y: value
        })),
        label: 'Average Position',
        scaleId: 'nwPosition',
        type: 'nwLine',
        borderColor: '#89d63a'
      }];
    }

    searchVisibilitySeries(seriesName, data, timeUnit) {
      data = (0, _grouping.groupData)((0, _fillGaps.default)(data), timeUnit, _grouping.groupMethods.average);
      return [{
        backgroundColor: 'rgb(39, 177, 78)',
        data: data.map(([date, value]) => ({
          x: date,
          y: value
        })),
        label: (0, _titleize.default)((0, _humanize.default)(seriesName.replace('_series', ''))),
        scaleId: 'nwValue',
        type: 'nwBar'
      }];
    }

    distributionSeries(data, timeUnit) {
      var _data, _data$;

      data = (0, _grouping.groupData)((0, _fillGaps.default)(data), timeUnit, _grouping.groupMethods.average); // for new urls without processed keywords, the response is [[null, {}]]

      if (((_data = data) === null || _data === void 0 ? void 0 : (_data$ = _data[0]) === null || _data$ === void 0 ? void 0 : _data$[0]) == null) data = [];
      return [{
        backgroundColor: this.graphColorManager.colorForSeries('top_3'),
        data: data.map(([date, value]) => ({
          x: date,
          y: value.top_3
        })),
        label: 'Top 3',
        scaleId: 'nwValue',
        type: 'nwBar'
      }, {
        backgroundColor: this.graphColorManager.colorForSeries('top_10'),
        data: data.map(([date, value]) => ({
          x: date,
          y: value.top_10
        })),
        label: 'Top 10',
        scaleId: 'nwValue',
        type: 'nwBar'
      }, {
        backgroundColor: this.graphColorManager.colorForSeries('top_100'),
        data: data.map(([date, value]) => ({
          x: date,
          y: value.top_100
        })),
        label: 'Top 100',
        scaleId: 'nwValue',
        type: 'nwBar'
      }, {
        backgroundColor: this.graphColorManager.colorForSeries('no_rank'),
        data: data.map(([date, value]) => ({
          x: date,
          y: value.no_rank
        })),
        label: 'No Rank',
        scaleId: 'nwValue',
        type: 'nwBar'
      }];
    }

    singleDistributionSeries(keywordStats) {
      return [{
        label: 'Top 3',
        data: keywordStats === null || keywordStats === void 0 ? void 0 : keywordStats.top3_count,
        backgroundColor: this.graphColorManager.colorForSeries('top_3')
      }, {
        label: 'Top 10',
        data: keywordStats === null || keywordStats === void 0 ? void 0 : keywordStats.top10_count,
        backgroundColor: this.graphColorManager.colorForSeries('top_10')
      }, {
        label: 'Top 100',
        data: keywordStats === null || keywordStats === void 0 ? void 0 : keywordStats.top100_count,
        backgroundColor: this.graphColorManager.colorForSeries('top_100')
      }, {
        label: 'No Rank',
        data: keywordStats === null || keywordStats === void 0 ? void 0 : keywordStats.no_rank_count,
        backgroundColor: this.graphColorManager.colorForSeries('no_rank')
      }];
    }

    upDownSeries(data, timeUnit) {
      var _data2, _data2$;

      // New urls without processed keywords the response is [[null, {}]]
      const firstDate = (_data2 = data) === null || _data2 === void 0 ? void 0 : (_data2$ = _data2[0]) === null || _data2$ === void 0 ? void 0 : _data2$[0];
      data = firstDate === null ? [] : data;
      data = (0, _grouping.groupData)((0, _fillGaps.default)(data), timeUnit, _grouping.groupMethods.sum);
      return [{
        backgroundColor: '#89d63a',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.went_up
        })),
        label: 'Up',
        scaleId: 'nwValue',
        type: 'nwBar'
      }, {
        backgroundColor: '#d8232e',
        data: data.map(([date, value]) => ({
          x: date,
          y: -value.went_down
        })),
        label: 'Down',
        scaleId: 'nwValue',
        type: 'nwBar'
      }];
    } // Backlinks Series Functions
    // TODO: Remove after backlinks sunset


    backlinkSingleDistributionSeries(data) {
      var _data$get, _data$get2, _data$get3, _data$get4;

      return [{
        label: 'High',
        data: (_data$get = data === null || data === void 0 ? void 0 : data.get('high_quality')) !== null && _data$get !== void 0 ? _data$get : [],
        backgroundColor: this.graphColorManager.colorForSeries('top_3')
      }, {
        label: 'Medium',
        data: (_data$get2 = data === null || data === void 0 ? void 0 : data.get('medium_quality')) !== null && _data$get2 !== void 0 ? _data$get2 : [],
        backgroundColor: this.graphColorManager.colorForSeries('top_10')
      }, {
        label: 'Low',
        data: (_data$get3 = data === null || data === void 0 ? void 0 : data.get('low_quality')) !== null && _data$get3 !== void 0 ? _data$get3 : [],
        backgroundColor: this.graphColorManager.colorForSeries('top_100')
      }, {
        label: 'Suspicious',
        data: (_data$get4 = data === null || data === void 0 ? void 0 : data.get('suspicious_quality')) !== null && _data$get4 !== void 0 ? _data$get4 : [],
        backgroundColor: this.graphColorManager.colorForSeries('no_rank')
      }];
    }

    backlinkSeries(data, timeUnit) {
      data = (0, _grouping.groupData)((0, _fillGaps.default)(data), timeUnit, _grouping.groupMethods.average);
      return [{
        data: data.map(([date, value]) => ({
          x: date,
          y: value
        })),
        label: 'Active Backlinks',
        scaleId: 'nwValue',
        type: 'nwLine',
        borderColor: '#89d63a'
      }];
    }

    backlinkDiversitySeries(seriesName, data, timeUnit) {
      data = (0, _grouping.groupData)((0, _fillGaps.default)(data), timeUnit, _grouping.groupMethods.average);
      return [{
        backgroundColor: 'rgb(39, 177, 78)',
        data: data.map(([date, value]) => ({
          x: date,
          y: value
        })),
        label: (0, _titleize.default)((0, _humanize.default)(seriesName.replace('_series', ''))),
        scaleId: 'nwValue',
        type: 'nwBar'
      }];
    }

    backlinkQualitySeries(data, timeUnit) {
      var _data3, _data3$, _data$get5, _data4, _data$get6, _data5, _data$get7, _data6, _data$get8, _data7;

      const firstDate = (_data3 = data) === null || _data3 === void 0 ? void 0 : (_data3$ = _data3[0]) === null || _data3$ === void 0 ? void 0 : _data3$[0];
      data = firstDate === null ? [] : data;
      const high = (_data$get5 = (_data4 = data) === null || _data4 === void 0 ? void 0 : _data4.get('high_quality_series')) !== null && _data$get5 !== void 0 ? _data$get5 : [];
      const med = (_data$get6 = (_data5 = data) === null || _data5 === void 0 ? void 0 : _data5.get('medium_quality_series')) !== null && _data$get6 !== void 0 ? _data$get6 : [];
      const low = (_data$get7 = (_data6 = data) === null || _data6 === void 0 ? void 0 : _data6.get('low_quality_series')) !== null && _data$get7 !== void 0 ? _data$get7 : [];
      const sus = (_data$get8 = (_data7 = data) === null || _data7 === void 0 ? void 0 : _data7.get('suspicious_quality_series')) !== null && _data$get8 !== void 0 ? _data$get8 : [];
      data = high.map((e, index) => {
        var _high$index, _med$index, _low$index, _sus$index;

        return [e[0], {
          high: (_high$index = high[index]) === null || _high$index === void 0 ? void 0 : _high$index[1],
          medium: (_med$index = med[index]) === null || _med$index === void 0 ? void 0 : _med$index[1],
          low: (_low$index = low[index]) === null || _low$index === void 0 ? void 0 : _low$index[1],
          suspicious: (_sus$index = sus[index]) === null || _sus$index === void 0 ? void 0 : _sus$index[1]
        }];
      });
      data = (0, _grouping.groupData)((0, _fillGaps.default)(data), timeUnit, _grouping.groupMethods.sum);
      return [{
        label: 'High',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.high
        })),
        backgroundColor: this.graphColorManager.colorForSeries('top_3'),
        scaleId: 'nwValue',
        type: 'nwBar'
      }, {
        label: 'Medium',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.medium
        })),
        backgroundColor: this.graphColorManager.colorForSeries('top_10'),
        scaleId: 'nwValue',
        type: 'nwBar'
      }, {
        label: 'Low',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.low
        })),
        backgroundColor: this.graphColorManager.colorForSeries('top_100'),
        scaleId: 'nwValue',
        type: 'nwBar'
      }, {
        label: 'Suspicious',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.suspicious
        })),
        backgroundColor: this.graphColorManager.colorForSeries('no_rank'),
        scaleId: 'nwValue',
        type: 'nwBar'
      }];
    }

    backlinksNewLostSeries(data, timeUnit) {
      var _data8, _data8$, _data$get9, _data9, _data$get10, _data10;

      const firstDate = (_data8 = data) === null || _data8 === void 0 ? void 0 : (_data8$ = _data8[0]) === null || _data8$ === void 0 ? void 0 : _data8$[0];
      data = firstDate === null ? [] : data;
      const newSeries = (_data$get9 = (_data9 = data) === null || _data9 === void 0 ? void 0 : _data9.get('new_series')) !== null && _data$get9 !== void 0 ? _data$get9 : [];
      const lostSeries = (_data$get10 = (_data10 = data) === null || _data10 === void 0 ? void 0 : _data10.get('lost_series')) !== null && _data$get10 !== void 0 ? _data$get10 : [];
      data = newSeries === null || newSeries === void 0 ? void 0 : newSeries.map((e, index) => [e[0], {
        newSeries: newSeries[index][1],
        lostSeries: lostSeries[index][1]
      }]);
      data = (0, _grouping.groupData)((0, _fillGaps.default)(data), timeUnit, _grouping.groupMethods.sum);
      return [{
        backgroundColor: '#89d63a',
        data: data.map(([date, value]) => ({
          x: date,
          y: value.newSeries
        })),
        label: 'New',
        scaleId: 'nwValue',
        type: 'nwBar'
      }, {
        backgroundColor: '#d8232e',
        data: data.map(([date, value]) => ({
          x: date,
          y: -value.lostSeries
        })),
        label: 'Lost',
        scaleId: 'nwValue',
        type: 'nwBar'
      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "graphColorManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NwGraphSeriesService;
});