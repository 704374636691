define("nightwatch-web/services/overview/view-overview-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ViewOverviewStateService extends Ember.Service {
    init() {
      super.init(...arguments);
      const searchVisibilityCategory = this.parseFromLocalStorage('nw:overview:search-visibility-category', 'search_visibility_series');
      const showExtendedOverview = this.parseFromLocalStorage('nw:overview:show-extended-overview', true);
      this.set('searchVisibilityCategory', searchVisibilityCategory);
      this.set('showExtendedOverview', showExtendedOverview);
    }

    parseFromLocalStorage(property, defaultValue) {
      const item = localStorage.getItem(property);

      if (!item) {
        return defaultValue;
      } else if (typeof defaultValue === 'boolean') {
        return String(item).toLowerCase() === 'true';
      } else {
        return item;
      }
    }

    setSearchVisibilityCategory(category) {
      localStorage.setItem('nw:overview:search-visibility-category', category);
      this.set('searchVisibilityCategory', category);
    }

    toggleShowExtendedOverview() {
      const newShowSetting = !this.showExtendedOverview;
      localStorage.setItem('nw:overview:show-extended-overview', newShowSetting);
      this.set('showExtendedOverview', newShowSetting);
    }

  }

  _exports.default = ViewOverviewStateService;
});