define("nightwatch-web/models/filter-group", ["exports", "@ember-decorators/object", "@ember-data/model"], function (_exports, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FilterGroup = (_dec = (0, _model.belongsTo)('dynamic-view'), _dec2 = (0, _model.hasMany)('filter', {
    embedded: 'always',
    async: false
  }), _dec3 = Ember.computed.filter('filters', filter => !filter.get('markedAsDeleted')), _dec4 = (0, _object.observes)('filters.@each.value', 'filters.@each.field', 'filters.@each.condition'), (_class = class FilterGroup extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dynamicView", _descriptor, this);

      _initializerDefineProperty(this, "filters", _descriptor2, this);

      _initializerDefineProperty(this, "visibleFilters", _descriptor3, this);
    }

    filtersChanged() {
      Ember.run.once(this, 'writeChangedAt');
    }

    writeChangedAt() {
      this.set('filtersChangedAt', new Date());
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dynamicView", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "visibleFilters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filtersChanged", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "filtersChanged"), _class.prototype)), _class));
  _exports.default = FilterGroup;
});