define("nightwatch-web/routes/dashboard/url/keywords/add/manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlKeywordsAddManualRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('url', this.modelFor('dashboard.url'));
    }

  }

  _exports.default = DashboardUrlKeywordsAddManualRoute;
});