define("nightwatch-web/controllers/confirm-account", ["exports", "nightwatch-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ConfirmAccountController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('token'), _dec4 = Ember._action, (_class = class ConfirmAccountController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _defineProperty(this, "queryParams", ['token']);

      _defineProperty(this, "t", null);
    }

    get tokenPresent() {
      return this.token && Ember.isPresent(this.token);
    }

    resendLink() {
      if (this.emailSent) {
        return;
      }

      this.fetch.post(`${_environment.default.serverURL}send_confirmation_email`).then(() => {
        this.set('emailSent', true);
      }).catch(() => {
        this.notifications.error('Error while sending the e-mail. Please contact support.', {
          autoClear: true,
          clearDuration: 10000
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "tokenPresent", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "tokenPresent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resendLink", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "resendLink"), _class.prototype)), _class));
  _exports.default = ConfirmAccountController;
});