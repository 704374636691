define("nightwatch-web/services/persistence/keyword-list-sorting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let KeywordListSortingService = (_dec = Ember.inject.service, (_class = class KeywordListSortingService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "userSettings", _descriptor, this);
    }

    persistSorting(params) {
      const {
        urlId,
        viewId,
        sortField,
        sortDirection
      } = params;
      this.userSettings.saveSetting({
        searchKeywordUrlId: urlId,
        dynamicViewId: viewId,
        settingsData: {
          keywords: {
            sortField: sortField,
            sortDirection: sortDirection
          }
        }
      });
    }

    parseFromLocalStorage(params) {
      const {
        urlId,
        viewId
      } = params;
      let resourceSortKey = null; // Legacy

      if (urlId) {
        resourceSortKey = `nw:url:${urlId}:keywordSorting`;
      } else if (viewId) {
        resourceSortKey = `nw:url:${viewId}:keywordSorting`;
      }

      let item = localStorage.getItem(resourceSortKey);

      if (!item) {
        item = localStorage.getItem('nw:lastKeywordSorting');
      }

      try {
        const sortParams = JSON.parse(item);
        const {
          sortField,
          sortDirection
        } = sortParams;
        return {
          sort: sortField,
          direction: sortDirection
        };
      } catch (e) {
        return {};
      }
    }

    getSorting(params) {
      const searchKeywordUrlId = params.urlId;
      const dynamicViewId = params.viewId;
      let defaultSort = 'position';
      let defaultSortDirection = 'asc';
      let sort, sortDirection;

      try {
        const setting = this.userSettings.getSetting({
          searchKeywordUrlId,
          dynamicViewId
        });
        const settingsData = setting.get('settingsData');
        sort = settingsData.keywords.sortField;
        sortDirection = settingsData.keywords.sortDirection;
      } catch (e) {
        // There's no persisted setting available. Let's try to get it from localStorage
        const paramsFromLocalStorage = this.parseFromLocalStorage(params);
        sort = paramsFromLocalStorage.sort;
        sortDirection = paramsFromLocalStorage.sortDirection;
      }

      return {
        sort: sort || defaultSort,
        direction: sortDirection || defaultSortDirection
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = KeywordListSortingService;
});