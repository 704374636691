define("nightwatch-web/helpers/relative-date-limited", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relativeDateLimited = relativeDateLimited;
  _exports.default = void 0;

  function relativeDateLimited([dateValue, periodDays]) {
    const daysDiff = Math.abs((0, _dateFns.differenceInDays)(new Date(dateValue || new Date()), new Date()));
    !dateValue ? dateValue = new Date() : dateValue = new Date(dateValue);

    if (daysDiff <= periodDays) {
      return (0, _dateFns.formatDistanceStrict)(dateValue, new Date(), {
        addSuffix: true
      });
    } else {
      return (0, _dateFns.format)(dateValue, 'MMM dd, yyyy');
    }
  }

  var _default = Ember.Helper.helper(relativeDateLimited);

  _exports.default = _default;
});