define("nightwatch-web/controllers/plan", ["exports", "nightwatch-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlanController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class PlanController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);
    }

    // paymentAmount = 1 is the default used by FB when no value submitted
    trackConversion(paymentAmount = 1) {
      if (window.ga) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'Conversions',
          eventAction: 'subscribe',
          eventLabel: '',
          eventValue: paymentAmount
        });
      }

      if (window.gtag) {
        const conversionId = 'inJgCNDogI8BEP3p7Z0D';
        window.gtag('event', 'conversion', {
          send_to: `${_environment.default.gtagId}/${conversionId}`,
          value: paymentAmount,
          currency: 'USD',
          transaction_id: ''
        });
      }

      if (window.qp) {
        window.qp('track', 'Purchase');
      }

      if (window.fbq) {
        window.fbq('track', 'Purchase', {
          value: paymentAmount,
          currency: 'USD'
        });
      }
    }

    trackSubscription(paymentAmount) {
      const plan = this.get('model.plan');
      this.metrics.trackEvent({
        event: 'Subscribed',
        paymentAmount,
        planId: plan.id,
        planName: plan.name,
        planPrice: plan.price,
        currency: 'USD'
      });
    }

    afterPaymentSuccess(isConversion = false, paymentAmount) {
      window.scroll(0, 0);
      this.trackSubscription(paymentAmount);
      if (isConversion) this.trackConversion(paymentAmount);
      this.router.transitionTo('plan-success', this.get('model.plan'));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterPaymentSuccess", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "afterPaymentSuccess"), _class.prototype)), _class));
  _exports.default = PlanController;
});