define("nightwatch-web/components/site-audit/timer", ["exports", "nightwatch-web/utils/is-testing"], function (_exports, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{duration this.crawlingSession.startedAt this.currentTime 'seconds' true}}
  
  */
  {
    "id": "UDC9dpUK",
    "block": "[[[1,[28,[35,0],[[30,0,[\"crawlingSession\",\"startedAt\"]],[30,0,[\"currentTime\"]],\"seconds\",true],null]],[1,\"\\n\"]],[],false,[\"duration\"]]",
    "moduleName": "nightwatch-web/components/site-audit/timer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      if (_isTesting.default) return;
      this.set('startTime', this.get('crawlingSession.startedAt'));
      this.set('currentTime', new Date());
      this.startTimer();
    },

    startTimer() {
      this._super(...arguments);

      const _tick = () => {
        if (!this.startTime) {
          return;
        }

        this.set('currentTime', new Date());
        Ember.run.later(() => {
          _tick();
        }, 1000);
      };

      _tick();
    },

    willDestroyElement() {
      this.stopTimer();
    },

    stopTimer() {
      this.set('startTime', null);
    }

  }));

  _exports.default = _default;
});