define("nightwatch-web/controllers/dashboard/url/graph", ["exports", "nightwatch-web/mixins/graph-controller"], function (_exports, _graphController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlGraphController extends Ember.Controller.extend(_graphController.default) {}

  _exports.default = DashboardUrlGraphController;
});