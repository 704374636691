define("nightwatch-web/components/site-audit/health", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="row">
    <div class="col-md-3 col-sm-6 site-audit-health__header">
      <div class="site-audit-details__section-title">Health</div>
    </div>
    <div
      data-test-health-proportion
      class="col-md-3 col-sm-6 site-audit-health__proportion">
      <b>{{this.safePoints}}</b> / <b>{{this.maxPoints}}</b>
    </div>
    <div class="col-md-6 col-sm-12 site-audit-health__progress">
      {{#if this.maxPoints}}
        <ProgressBar class="progress-bar-error" @scaleClass="progress-bar-ok" @maxValue={{this.maxPoints}} @value={{this.points}} @showScale={{false}} />
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "7Sca397N",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-md-3 col-sm-6 site-audit-health__header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"site-audit-details__section-title\"],[12],[1,\"Health\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-md-3 col-sm-6 site-audit-health__proportion\"],[12],[1,\"\\n    \"],[10,\"b\"],[12],[1,[30,0,[\"safePoints\"]]],[13],[1,\" / \"],[10,\"b\"],[12],[1,[30,0,[\"maxPoints\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-md-6 col-sm-12 site-audit-health__progress\"],[12],[1,\"\\n\"],[41,[30,0,[\"maxPoints\"]],[[[1,\"      \"],[8,[39,1],[[24,0,\"progress-bar-error\"]],[[\"@scaleClass\",\"@maxValue\",\"@value\",\"@showScale\"],[\"progress-bar-ok\",[30,0,[\"maxPoints\"]],[30,0,[\"points\"]],false]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"progress-bar\"]]",
    "moduleName": "nightwatch-web/components/site-audit/health.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['site-audit-health'],
    safePoints: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.gt)('maxPoints', 0), 'points', 0)
  }));

  _exports.default = _default;
});