define("nightwatch-web/helpers/abbreviate-number", ["exports", "nightwatch-web/utils/abbreviate-number"], function (_exports, _abbreviateNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abbreviate = abbreviate;
  _exports.default = void 0;

  function abbreviate(params) {
    return (0, _abbreviateNumber.default)(params[0]);
  }

  var _default = Ember.Helper.helper(abbreviate);

  _exports.default = _default;
});