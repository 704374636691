define("nightwatch-web/helpers/ensure-protocol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ensureProtocol = ensureProtocol;
  _exports.default = void 0;

  function ensureProtocol([url]) {
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
      return `http://${url}`;
    }

    return url;
  }

  var _default = Ember.Helper.helper(ensureProtocol);

  _exports.default = _default;
});