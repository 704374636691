define("nightwatch-web/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "InT5ZMCX",
    "block": "[[[1,[28,[35,0],[\"Page Not Found\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"Page not found.\"]],[]]]]],[1,\"\\n\"]],[],false,[\"page-title\",\"nw-navbar\",\"error-page\"]]",
    "moduleName": "nightwatch-web/templates/404.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});