define("nightwatch-web/helpers/duration", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.duration = duration;
  _exports.default = void 0;

  function duration([start = new Date(), end = new Date(), formatString, shortFormSeconds = false]) {
    const duration = (0, _dateFns.intervalToDuration)({
      start,
      end
    });
    if (shortFormSeconds) return `${duration.seconds}s`;
    const format = formatString ? formatString.split(',') : ['hours', 'minutes', 'seconds'];
    return (0, _dateFns.formatDuration)(duration, {
      format
    });
  }

  var _default = Ember.Helper.helper(duration);

  _exports.default = _default;
});