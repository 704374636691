define("nightwatch-web/constants/default-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    defaultBacklinksStats: {
      all_series: [['2017-09-08', 5], ['2017-09-09', 5], ['2017-09-10', 15], ['2017-09-11', 24], ['2017-09-12', 36], ['2017-09-13', 51], ['2017-09-14', 63], ['2017-09-15', 76], ['2017-09-16', 84], ['2017-09-17', 102], ['2017-09-18', 118], ['2017-09-19', 134], ['2017-09-20', 146], ['2017-09-21', 160], ['2017-09-22', 177], ['2017-09-23', 190], ['2017-09-24', 199], ['2017-09-25', 208], ['2017-09-26', 218], ['2017-09-27', 224], ['2017-09-28', 234], ['2017-09-29', 241], ['2017-09-30', 244], ['2017-10-01', 247], ['2017-10-02', 250], ['2017-10-03', 258], ['2017-10-04', 260], ['2017-10-05', 263], ['2017-10-06', 272], ['2017-10-07', 276], ['2017-10-08', 284], ['2017-10-09', 290], ['2017-10-10', 292], ['2017-10-11', 293]],
      total_series: [['2017-09-08', 22], ['2017-09-09', 28], ['2017-09-10', 66], ['2017-09-11', 83], ['2017-09-12', 106], ['2017-09-13', 146], ['2017-09-14', 168], ['2017-09-15', 196], ['2017-09-16', 224], ['2017-09-17', 257], ['2017-09-18', 288], ['2017-09-19', 318], ['2017-09-20', 340], ['2017-09-21', 358], ['2017-09-22', 391], ['2017-09-23', 423], ['2017-09-24', 457], ['2017-09-25', 490], ['2017-09-26', 509], ['2017-09-27', 523], ['2017-09-28', 552], ['2017-09-29', 574], ['2017-09-30', 592], ['2017-10-01', 598], ['2017-10-02', 617], ['2017-10-03', 629], ['2017-10-04', 636], ['2017-10-05', 641], ['2017-10-06', 654], ['2017-10-07', 658], ['2017-10-08', 668], ['2017-10-09', 675], ['2017-10-10', 678], ['2017-10-11', 680]],
      nofollow_series: [['2017-09-08', 0], ['2017-09-09', 0], ['2017-09-10', 4], ['2017-09-11', 5], ['2017-09-12', 5], ['2017-09-13', 5], ['2017-09-14', 6], ['2017-09-15', 8], ['2017-09-16', 8], ['2017-09-17', 17], ['2017-09-18', 21], ['2017-09-19', 21], ['2017-09-20', 22], ['2017-09-21', 23], ['2017-09-22', 25], ['2017-09-23', 26], ['2017-09-24', 26], ['2017-09-25', 26], ['2017-09-26', 26], ['2017-09-27', 26], ['2017-09-28', 26], ['2017-09-29', 26], ['2017-09-30', 26], ['2017-10-01', 26], ['2017-10-02', 26], ['2017-10-03', 26], ['2017-10-04', 26], ['2017-10-05', 26], ['2017-10-06', 26], ['2017-10-07', 26], ['2017-10-08', 27], ['2017-10-09', 27], ['2017-10-10', 27], ['2017-10-11', 28]],
      follow_series: [['2017-09-08', 5], ['2017-09-09', 5], ['2017-09-10', 11], ['2017-09-11', 19], ['2017-09-12', 31], ['2017-09-13', 46], ['2017-09-14', 57], ['2017-09-15', 68], ['2017-09-16', 76], ['2017-09-17', 85], ['2017-09-18', 97], ['2017-09-19', 113], ['2017-09-20', 124], ['2017-09-21', 137], ['2017-09-22', 152], ['2017-09-23', 164], ['2017-09-24', 173], ['2017-09-25', 182], ['2017-09-26', 192], ['2017-09-27', 198], ['2017-09-28', 208], ['2017-09-29', 215], ['2017-09-30', 218], ['2017-10-01', 221], ['2017-10-02', 224], ['2017-10-03', 232], ['2017-10-04', 234], ['2017-10-05', 237], ['2017-10-06', 246], ['2017-10-07', 250], ['2017-10-08', 257], ['2017-10-09', 263], ['2017-10-10', 265], ['2017-10-11', 265]],
      referring_ips_series: [['2017-09-08', 3], ['2017-09-09', 3], ['2017-09-10', 7], ['2017-09-11', 15], ['2017-09-12', 25], ['2017-09-13', 39], ['2017-09-14', 51], ['2017-09-15', 61], ['2017-09-16', 67], ['2017-09-17', 77], ['2017-09-18', 90], ['2017-09-19', 102], ['2017-09-20', 110], ['2017-09-21', 118], ['2017-09-22', 133], ['2017-09-23', 143], ['2017-09-24', 151], ['2017-09-25', 159], ['2017-09-26', 168], ['2017-09-27', 172], ['2017-09-28', 180], ['2017-09-29', 185], ['2017-09-30', 188], ['2017-10-01', 191], ['2017-10-02', 194], ['2017-10-03', 199], ['2017-10-04', 201], ['2017-10-05', 203], ['2017-10-06', 211], ['2017-10-07', 214], ['2017-10-08', 220], ['2017-10-09', 225], ['2017-10-10', 227], ['2017-10-11', 228]],
      referring_domains_series: [['2017-09-08', 3], ['2017-09-09', 3], ['2017-09-10', 7], ['2017-09-11', 15], ['2017-09-12', 26], ['2017-09-13', 40], ['2017-09-14', 52], ['2017-09-15', 62], ['2017-09-16', 68], ['2017-09-17', 78], ['2017-09-18', 92], ['2017-09-19', 104], ['2017-09-20', 112], ['2017-09-21', 119], ['2017-09-22', 134], ['2017-09-23', 144], ['2017-09-24', 152], ['2017-09-25', 160], ['2017-09-26', 169], ['2017-09-27', 174], ['2017-09-28', 183], ['2017-09-29', 190], ['2017-09-30', 193], ['2017-10-01', 196], ['2017-10-02', 199], ['2017-10-03', 205], ['2017-10-04', 207], ['2017-10-05', 209], ['2017-10-06', 218], ['2017-10-07', 221], ['2017-10-08', 227], ['2017-10-09', 232], ['2017-10-10', 234], ['2017-10-11', 235]],
      referring_subnets_series: [['2017-09-08', 3], ['2017-09-09', 3], ['2017-09-10', 7], ['2017-09-11', 15], ['2017-09-12', 25], ['2017-09-13', 38], ['2017-09-14', 50], ['2017-09-15', 60], ['2017-09-16', 66], ['2017-09-17', 76], ['2017-09-18', 89], ['2017-09-19', 101], ['2017-09-20', 109], ['2017-09-21', 116], ['2017-09-22', 131], ['2017-09-23', 141], ['2017-09-24', 149], ['2017-09-25', 157], ['2017-09-26', 165], ['2017-09-27', 169], ['2017-09-28', 176], ['2017-09-29', 181], ['2017-09-30', 184], ['2017-10-01', 187], ['2017-10-02', 190], ['2017-10-03', 195], ['2017-10-04', 197], ['2017-10-05', 199], ['2017-10-06', 207], ['2017-10-07', 210], ['2017-10-08', 216], ['2017-10-09', 221], ['2017-10-10', 223], ['2017-10-11', 224]],
      lost_series: [['2017-09-08', 0], ['2017-09-09', 0], ['2017-09-10', 0], ['2017-09-11', 0], ['2017-09-12', 0], ['2017-09-13', 0], ['2017-09-14', 0], ['2017-09-15', 0], ['2017-09-16', 0], ['2017-09-17', 0], ['2017-09-18', 0], ['2017-09-19', 0], ['2017-09-20', 0], ['2017-09-21', 0], ['2017-09-22', 0], ['2017-09-23', 0], ['2017-09-24', 0], ['2017-09-25', 0], ['2017-09-26', 0], ['2017-09-27', 0], ['2017-09-28', 0], ['2017-09-29', 0], ['2017-09-30', 0], ['2017-10-01', 0], ['2017-10-02', 0], ['2017-10-03', 0], ['2017-10-04', 0], ['2017-10-05', 0], ['2017-10-06', 0], ['2017-10-07', 0], ['2017-10-08', 0], ['2017-10-09', 0], ['2017-10-10', 0], ['2017-10-11', 0]],
      new_series: [['2017-09-08', 5], ['2017-09-09', 0], ['2017-09-10', 10], ['2017-09-11', 9], ['2017-09-12', 12], ['2017-09-13', 15], ['2017-09-14', 12], ['2017-09-15', 13], ['2017-09-16', 8], ['2017-09-17', 18], ['2017-09-18', 16], ['2017-09-19', 16], ['2017-09-20', 12], ['2017-09-21', 14], ['2017-09-22', 17], ['2017-09-23', 13], ['2017-09-24', 9], ['2017-09-25', 9], ['2017-09-26', 10], ['2017-09-27', 6], ['2017-09-28', 10], ['2017-09-29', 7], ['2017-09-30', 3], ['2017-10-01', 3], ['2017-10-02', 3], ['2017-10-03', 8], ['2017-10-04', 2], ['2017-10-05', 3], ['2017-10-06', 9], ['2017-10-07', 4], ['2017-10-08', 8], ['2017-10-09', 6], ['2017-10-10', 2], ['2017-10-11', 1]],
      low_quality_series: [['2017-09-08', 5], ['2017-09-09', 5], ['2017-09-10', 15], ['2017-09-11', 24], ['2017-09-12', 34], ['2017-09-13', 45], ['2017-09-14', 55], ['2017-09-15', 67], ['2017-09-16', 75], ['2017-09-17', 91], ['2017-09-18', 103], ['2017-09-19', 116], ['2017-09-20', 127], ['2017-09-21', 140], ['2017-09-22', 154], ['2017-09-23', 167], ['2017-09-24', 176], ['2017-09-25', 184], ['2017-09-26', 194], ['2017-09-27', 200], ['2017-09-28', 208], ['2017-09-29', 215], ['2017-09-30', 218], ['2017-10-01', 221], ['2017-10-02', 224], ['2017-10-03', 230], ['2017-10-04', 231], ['2017-10-05', 234], ['2017-10-06', 243], ['2017-10-07', 247], ['2017-10-08', 255], ['2017-10-09', 261], ['2017-10-10', 263], ['2017-10-11', 264]],
      medium_quality_series: [['2017-09-08', 0], ['2017-09-09', 0], ['2017-09-10', 0], ['2017-09-11', 0], ['2017-09-12', 1], ['2017-09-13', 2], ['2017-09-14', 2], ['2017-09-15', 2], ['2017-09-16', 2], ['2017-09-17', 3], ['2017-09-18', 4], ['2017-09-19', 4], ['2017-09-20', 4], ['2017-09-21', 4], ['2017-09-22', 4], ['2017-09-23', 4], ['2017-09-24', 4], ['2017-09-25', 4], ['2017-09-26', 4], ['2017-09-27', 4], ['2017-09-28', 4], ['2017-09-29', 4], ['2017-09-30', 4], ['2017-10-01', 4], ['2017-10-02', 4], ['2017-10-03', 4], ['2017-10-04', 4], ['2017-10-05', 4], ['2017-10-06', 4], ['2017-10-07', 4], ['2017-10-08', 4], ['2017-10-09', 4], ['2017-10-10', 4], ['2017-10-11', 4]],
      high_quality_series: [['2017-09-08', 0], ['2017-09-09', 0], ['2017-09-10', 0], ['2017-09-11', 0], ['2017-09-12', 0], ['2017-09-13', 0], ['2017-09-14', 0], ['2017-09-15', 0], ['2017-09-16', 0], ['2017-09-17', 0], ['2017-09-18', 0], ['2017-09-19', 0], ['2017-09-20', 0], ['2017-09-21', 0], ['2017-09-22', 0], ['2017-09-23', 0], ['2017-09-24', 0], ['2017-09-25', 0], ['2017-09-26', 0], ['2017-09-27', 0], ['2017-09-28', 0], ['2017-09-29', 0], ['2017-09-30', 0], ['2017-10-01', 0], ['2017-10-02', 0], ['2017-10-03', 0], ['2017-10-04', 0], ['2017-10-05', 0], ['2017-10-06', 0], ['2017-10-07', 0], ['2017-10-08', 0], ['2017-10-09', 0], ['2017-10-10', 0], ['2017-10-11', 0]],
      suspicious_quality_series: [['2017-09-08', 0], ['2017-09-09', 0], ['2017-09-10', 0], ['2017-09-11', 0], ['2017-09-12', 1], ['2017-09-13', 4], ['2017-09-14', 6], ['2017-09-15', 7], ['2017-09-16', 7], ['2017-09-17', 8], ['2017-09-18', 11], ['2017-09-19', 14], ['2017-09-20', 15], ['2017-09-21', 16], ['2017-09-22', 19], ['2017-09-23', 19], ['2017-09-24', 19], ['2017-09-25', 20], ['2017-09-26', 20], ['2017-09-27', 20], ['2017-09-28', 22], ['2017-09-29', 22], ['2017-09-30', 22], ['2017-10-01', 22], ['2017-10-02', 22], ['2017-10-03', 24], ['2017-10-04', 25], ['2017-10-05', 25], ['2017-10-06', 25], ['2017-10-07', 25], ['2017-10-08', 25], ['2017-10-09', 25], ['2017-10-10', 25], ['2017-10-11', 25]],
      referring_domains_change: 232,
      referring_ips_change: 225,
      referring_subnets_change: 221,
      all_change: 288,
      suspicious_quality_change: 25,
      low_quality_change: 259,
      medium_quality_change: 4,
      high_quality_change: 0,
      no_change: 387,
      new: 293,
      lost: 0,
      referring_domains: 235,
      referring_ips: 228,
      referring_subnets: 224,
      all: 293,
      total: 680,
      follow: 265,
      nofollow: 28,
      suspicious_quality: 25,
      low_quality: 264,
      medium_quality: 4,
      high_quality: 0
    },
    defaultTrafficStats: {
      sessions_series: [['2017-10-04', 18], ['2017-10-05', 8], ['2017-10-06', 12], ['2017-10-07', 7], ['2017-10-08', 10], ['2017-10-09', 8], ['2017-10-10', 9], ['2017-10-11', 14], ['2017-10-12', 16], ['2017-10-13', 18], ['2017-10-14', 4], ['2017-10-15', 5], ['2017-10-16', 17], ['2017-10-17', 22], ['2017-10-18', 16], ['2017-10-19', 7], ['2017-10-20', 12], ['2017-10-21', 9], ['2017-10-22', 8], ['2017-10-23', 17], ['2017-10-24', 14], ['2017-10-25', 14], ['2017-10-26', 16], ['2017-10-27', 13], ['2017-10-28', 8], ['2017-10-29', 11], ['2017-10-30', 22], ['2017-10-31', 28], ['2017-11-01', 29], ['2017-11-02', 26], ['2017-11-03', 19], ['2017-11-04', 8], ['2017-11-05', 29], ['2017-11-06', 4]],
      new_users_series: [['2017-10-04', 15], ['2017-10-05', 6], ['2017-10-06', 12], ['2017-10-07', 5], ['2017-10-08', 8], ['2017-10-09', 8], ['2017-10-10', 9], ['2017-10-11', 12], ['2017-10-12', 16], ['2017-10-13', 15], ['2017-10-14', 4], ['2017-10-15', 5], ['2017-10-16', 17], ['2017-10-17', 17], ['2017-10-18', 12], ['2017-10-19', 6], ['2017-10-20', 11], ['2017-10-21', 8], ['2017-10-22', 7], ['2017-10-23', 14], ['2017-10-24', 12], ['2017-10-25', 12], ['2017-10-26', 14], ['2017-10-27', 12], ['2017-10-28', 8], ['2017-10-29', 7], ['2017-10-30', 16], ['2017-10-31', 21], ['2017-11-01', 21], ['2017-11-02', 17], ['2017-11-03', 17], ['2017-11-04', 2], ['2017-11-05', 20], ['2017-11-06', 26]],
      returning_users_series: [['2017-10-04', 1], ['2017-10-05', 0], ['2017-10-06', 0], ['2017-10-07', 0], ['2017-10-08', 1], ['2017-10-09', 0], ['2017-10-10', 0], ['2017-10-11', 2], ['2017-10-12', 0], ['2017-10-13', 3], ['2017-10-14', 0], ['2017-10-15', 0], ['2017-10-16', 0], ['2017-10-17', 2], ['2017-10-18', 2], ['2017-10-19', 1], ['2017-10-20', 1], ['2017-10-21', 1], ['2017-10-22', 1], ['2017-10-23', 2], ['2017-10-24', 1], ['2017-10-25', 1], ['2017-10-26', 2], ['2017-10-27', 1], ['2017-10-28', 0], ['2017-10-29', 3], ['2017-10-30', 4], ['2017-10-31', 3], ['2017-11-01', 5], ['2017-11-02', 6], ['2017-11-03', 2], ['2017-11-04', 3], ['2017-11-05', 3], ['2017-11-06', 1]],
      users_series: [['2017-10-04', 16], ['2017-10-05', 6], ['2017-10-06', 12], ['2017-10-07', 5], ['2017-10-08', 9], ['2017-10-09', 8], ['2017-10-10', 9], ['2017-10-11', 14], ['2017-10-12', 16], ['2017-10-13', 18], ['2017-10-14', 4], ['2017-10-15', 5], ['2017-10-16', 17], ['2017-10-17', 19], ['2017-10-18', 14], ['2017-10-19', 7], ['2017-10-20', 12], ['2017-10-21', 9], ['2017-10-22', 8], ['2017-10-23', 16], ['2017-10-24', 13], ['2017-10-25', 13], ['2017-10-26', 16], ['2017-10-27', 13], ['2017-10-28', 8], ['2017-10-29', 10], ['2017-10-30', 20], ['2017-10-31', 24], ['2017-11-01', 26], ['2017-11-02', 23], ['2017-11-03', 19], ['2017-11-04', 5], ['2017-11-05', 23], ['2017-11-06', 4]],
      pageviews_series: [['2017-10-04', 18], ['2017-10-05', 10], ['2017-10-06', 12], ['2017-10-07', 7], ['2017-10-08', 12], ['2017-10-09', 8], ['2017-10-10', 9], ['2017-10-11', 14], ['2017-10-12', 16], ['2017-10-13', 19], ['2017-10-14', 4], ['2017-10-15', 5], ['2017-10-16', 19], ['2017-10-17', 23], ['2017-10-18', 17], ['2017-10-19', 10], ['2017-10-20', 15], ['2017-10-21', 12], ['2017-10-22', 8], ['2017-10-23', 18], ['2017-10-24', 17], ['2017-10-25', 15], ['2017-10-26', 17], ['2017-10-27', 14], ['2017-10-28', 11], ['2017-10-29', 135], ['2017-10-30', 68], ['2017-10-31', 106], ['2017-11-01', 112], ['2017-11-02', 61], ['2017-11-03', 21], ['2017-11-04', 34], ['2017-11-05', 203], ['2017-11-06', 4]],
      bounces_series: [['2017-10-04', 18], ['2017-10-05', 6], ['2017-10-06', 12], ['2017-10-07', 7], ['2017-10-08', 8], ['2017-10-09', 8], ['2017-10-10', 9], ['2017-10-11', 14], ['2017-10-12', 16], ['2017-10-13', 17], ['2017-10-14', 4], ['2017-10-15', 5], ['2017-10-16', 15], ['2017-10-17', 21], ['2017-10-18', 15], ['2017-10-19', 5], ['2017-10-20', 10], ['2017-10-21', 7], ['2017-10-22', 8], ['2017-10-23', 16], ['2017-10-24', 13], ['2017-10-25', 13], ['2017-10-26', 15], ['2017-10-27', 12], ['2017-10-28', 7], ['2017-10-29', 6], ['2017-10-30', 16], ['2017-10-31', 21], ['2017-11-01', 22], ['2017-11-02', 18], ['2017-11-03', 17], ['2017-11-04', 5], ['2017-11-05', 17], ['2017-11-06', 4]],
      direct_series: [['2017-10-04', 1], ['2017-10-05', 1], ['2017-10-06', 0], ['2017-10-07', 2], ['2017-10-08', 0], ['2017-10-09', 0], ['2017-10-10', 0], ['2017-10-11', 2], ['2017-10-12', 4], ['2017-10-13', 4], ['2017-10-14', 0], ['2017-10-15', 1], ['2017-10-16', 1], ['2017-10-17', 0], ['2017-10-18', 1], ['2017-10-19', 2], ['2017-10-20', 2], ['2017-10-21', 0], ['2017-10-22', 1], ['2017-10-23', 1], ['2017-10-24', 1], ['2017-10-25', 0], ['2017-10-26', 1], ['2017-10-27', 0], ['2017-10-28', 1], ['2017-10-29', 1], ['2017-10-30', 2], ['2017-10-31', 4], ['2017-11-01', 4], ['2017-11-02', 4], ['2017-11-03', 1], ['2017-11-04', 5], ['2017-11-05', 18], ['2017-11-06', 2]],
      organic_series: [['2017-10-04', 17], ['2017-10-05', 7], ['2017-10-06', 12], ['2017-10-07', 5], ['2017-10-08', 9], ['2017-10-09', 8], ['2017-10-10', 9], ['2017-10-11', 11], ['2017-10-12', 12], ['2017-10-13', 14], ['2017-10-14', 3], ['2017-10-15', 4], ['2017-10-16', 14], ['2017-10-17', 22], ['2017-10-18', 15], ['2017-10-19', 4], ['2017-10-20', 10], ['2017-10-21', 5], ['2017-10-22', 6], ['2017-10-23', 16], ['2017-10-24', 12], ['2017-10-25', 13], ['2017-10-26', 14], ['2017-10-27', 12], ['2017-10-28', 7], ['2017-10-29', 7], ['2017-10-30', 13], ['2017-10-31', 18], ['2017-11-01', 19], ['2017-11-02', 18], ['2017-11-03', 15], ['2017-11-04', 3], ['2017-11-05', 8], ['2017-11-06', 2]],
      social_series: [['2017-10-04', 0], ['2017-10-05', 0], ['2017-10-06', 0], ['2017-10-07', 0], ['2017-10-08', 0], ['2017-10-09', 0], ['2017-10-10', 0], ['2017-10-11', 0], ['2017-10-12', 0], ['2017-10-13', 0], ['2017-10-14', 1], ['2017-10-15', 0], ['2017-10-16', 2], ['2017-10-17', 0], ['2017-10-18', 0], ['2017-10-19', 0], ['2017-10-20', 0], ['2017-10-21', 2], ['2017-10-22', 0], ['2017-10-23', 0], ['2017-10-24', 1], ['2017-10-25', 0], ['2017-10-26', 1], ['2017-10-27', 0], ['2017-10-28', 0], ['2017-10-29', 0], ['2017-10-30', 3], ['2017-10-31', 1], ['2017-11-01', 2], ['2017-11-02', 2], ['2017-11-03', 1], ['2017-11-04', 0], ['2017-11-05', 1], ['2017-11-06', 0]],
      referral_series: [['2017-10-04', 0], ['2017-10-05', 0], ['2017-10-06', 0], ['2017-10-07', 0], ['2017-10-08', 0], ['2017-10-09', 0], ['2017-10-10', 0], ['2017-10-11', 0], ['2017-10-12', 0], ['2017-10-13', 0], ['2017-10-14', 0], ['2017-10-15', 0], ['2017-10-16', 0], ['2017-10-17', 0], ['2017-10-18', 0], ['2017-10-19', 0], ['2017-10-20', 0], ['2017-10-21', 1], ['2017-10-22', 1], ['2017-10-23', 0], ['2017-10-24', 0], ['2017-10-25', 1], ['2017-10-26', 0], ['2017-10-27', 1], ['2017-10-28', 0], ['2017-10-29', 0], ['2017-10-30', 0], ['2017-10-31', 0], ['2017-11-01', 0], ['2017-11-02', 1], ['2017-11-03', 1], ['2017-11-04', 0], ['2017-11-05', 2], ['2017-11-06', 0]],
      ads_series: [['2017-10-04', 0], ['2017-10-05', 0], ['2017-10-06', 0], ['2017-10-07', 0], ['2017-10-08', 0], ['2017-10-09', 0], ['2017-10-10', 0], ['2017-10-11', 0], ['2017-10-12', 0], ['2017-10-13', 0], ['2017-10-14', 0], ['2017-10-15', 0], ['2017-10-16', 0], ['2017-10-17', 0], ['2017-10-18', 0], ['2017-10-19', 0], ['2017-10-20', 0], ['2017-10-21', 0], ['2017-10-22', 0], ['2017-10-23', 0], ['2017-10-24', 0], ['2017-10-25', 0], ['2017-10-26', 0], ['2017-10-27', 0], ['2017-10-28', 0], ['2017-10-29', 0], ['2017-10-30', 0], ['2017-10-31', 0], ['2017-11-01', 0], ['2017-11-02', 0], ['2017-11-03', 0], ['2017-11-04', 0], ['2017-11-05', 0], ['2017-11-06', 0]],
      other_series: [['2017-10-04', 0], ['2017-10-05', 0], ['2017-10-06', 0], ['2017-10-07', 0], ['2017-10-08', 0], ['2017-10-09', 0], ['2017-10-10', 0], ['2017-10-11', 0], ['2017-10-12', 0], ['2017-10-13', 0], ['2017-10-14', 0], ['2017-10-15', 0], ['2017-10-16', 0], ['2017-10-17', 0], ['2017-10-18', 0], ['2017-10-19', 0], ['2017-10-20', 0], ['2017-10-21', 0], ['2017-10-22', 0], ['2017-10-23', 0], ['2017-10-24', 0], ['2017-10-25', 0], ['2017-10-26', 0], ['2017-10-27', 0], ['2017-10-28', 0], ['2017-10-29', 0], ['2017-10-30', 0], ['2017-10-31', 0], ['2017-11-01', 0], ['2017-11-02', 0], ['2017-11-03', 0], ['2017-11-04', 0], ['2017-11-05', 0], ['2017-11-06', 0]],
      current_day_sessions: 4,
      current_day_pageviews: 4,
      current_day_users: 4,
      current_day_bounces: 4,
      sessions_sum: 29,
      pageviews_sum: 203,
      users_sum: 23,
      bounces_sum: 17,
      direct_sum: 18,
      organic_sum: 8,
      social_sum: 1,
      referral_sum: 2,
      ads_sum: 0,
      other_sum: 0,
      sessions_change: 21,
      pageviews_change: 169,
      users_change: 18,
      bounces_change: 12,
      direct_change: 13,
      organic_change: 5,
      referral_change: 2,
      social_change: 1,
      ads_change: 0,
      other_change: 0,
      new_users: 20,
      returning_users: 3,
      last_series_date_is_current: true
    }
  };
  _exports.default = _default;
});