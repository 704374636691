define("nightwatch-web/components/site-audit/page-details-referrers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="site-audit-details-title">
    <div
      class="site-audit-details__section-title double-offset-top"
      data-test-referrers-title
    >
      Referrers
      {{#unless @isDataLoading}}({{@brokenReferrers.length}}){{/unless}}
    </div>
  </div>
  {{#if @isDataLoading}}
    <Loading::Spinner />
  {{else}}
    <div class="site-audit-details-table" data-test-referrers-table>
      {{#if @brokenReferrers.length}}
        <div class="site-audit-details-header">
          <div class="column-num">#</div>
          <div class="column-src">url</div>
          <div class="column-status">status</div>
        </div>
        <div class="site-audit-details-content">
          {{#each @brokenReferrers as |brokenReferrer index|}}
            <div class="site-audit-details-row" data-test-referrer-row>
              <div class="column-num">
                {{add-number index 1}}
              </div>
              <div class="column-src">
                <div class="truncate-left">
                  &lrm;{{brokenReferrer.url}}&lrm;
                </div>
              </div>
              <div class="column-status">
                {{#if brokenReferrer.response_code}}
                  <SiteAudit::StatusBadge
                    @responseCode={{brokenReferrer.response_code}}
                  />
                {{/if}}
              </div>
            </div>
          {{/each}}
        </div>
      {{else}}
        There are no referrers pointing to this page.
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "iQsOcNDg",
    "block": "[[[10,0],[14,0,\"site-audit-details-title\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"site-audit-details__section-title double-offset-top\"],[12],[1,\"\\n    Referrers\\n    \"],[41,[51,[30,1]],[[[1,\"(\"],[1,[30,2,[\"length\"]]],[1,\")\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"site-audit-details-table\"],[12],[1,\"\\n\"],[41,[30,2,[\"length\"]],[[[1,\"      \"],[10,0],[14,0,\"site-audit-details-header\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"column-num\"],[12],[1,\"#\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"column-src\"],[12],[1,\"url\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"column-status\"],[12],[1,\"status\"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"site-audit-details-content\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,2]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"site-audit-details-row\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"column-num\"],[12],[1,\"\\n              \"],[1,[28,[35,5],[[30,4],1],null]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"column-src\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"truncate-left\"],[12],[1,\"\\n                ‎\"],[1,[30,3,[\"url\"]]],[1,\"‎\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"column-status\"],[12],[1,\"\\n\"],[41,[30,3,[\"response_code\"]],[[[1,\"                \"],[8,[39,6],null,[[\"@responseCode\"],[[30,3,[\"response_code\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      There are no referrers pointing to this page.\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"@isDataLoading\",\"@brokenReferrers\",\"brokenReferrer\",\"index\"],false,[\"unless\",\"if\",\"loading/spinner\",\"each\",\"-track-array\",\"add-number\",\"site-audit/status-badge\"]]",
    "moduleName": "nightwatch-web/components/site-audit/page-details-referrers.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});