define("nightwatch-web/components/backlinks/backlinks-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    {{#if @showProgress}}
      <span class="progress-message">
        <NwHelpTooltip @placement="left">
          We are preparing all the backlinks for your website. It may take a few
          hours, depending on the count of backlinks your website has.
        </NwHelpTooltip>
        {{#if @limitReached}}
          Fetched backlinks ({{abbreviate-number @url.backlinksCount}}
          /
          {{abbreviate-number @url.backlinksStatus.backlinks_total}}):
        {{else}}
          Fetching backlinks ({{abbreviate-number @url.backlinksCount}}
          /
          {{abbreviate-number @url.backlinksStatus.backlinks_total}}) ...
        {{/if}}
      </span>
  
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped active"
          role="progressbar"
          {{style width=(concat @percent "%")}}
        >
        </div>
      </div>
    {{else if @url.backlinksLastRefresh}}
      Last update:
      {{relative-date @url.backlinksLastRefresh}}
    {{/if}}
  </div>
  */
  {
    "id": "85CzNTIe",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,1],[14,0,\"progress-message\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@placement\"],[\"left\"]],[[\"default\"],[[[[1,\"\\n        We are preparing all the backlinks for your website. It may take a few\\n        hours, depending on the count of backlinks your website has.\\n      \"]],[]]]]],[1,\"\\n\"],[41,[30,3],[[[1,\"        Fetched backlinks (\"],[1,[28,[35,2],[[30,4,[\"backlinksCount\"]]],null]],[1,\"\\n        /\\n        \"],[1,[28,[35,2],[[30,4,[\"backlinksStatus\",\"backlinks_total\"]]],null]],[1,\"):\\n\"]],[]],[[[1,\"        Fetching backlinks (\"],[1,[28,[35,2],[[30,4,[\"backlinksCount\"]]],null]],[1,\"\\n        /\\n        \"],[1,[28,[35,2],[[30,4,[\"backlinksStatus\",\"backlinks_total\"]]],null]],[1,\") ...\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"progress\"],[12],[1,\"\\n      \"],[11,0],[24,0,\"progress-bar progress-bar-striped active\"],[24,\"role\",\"progressbar\"],[4,[38,3],null,[[\"width\"],[[28,[37,4],[[30,5],\"%\"],null]]]],[12],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,4,[\"backlinksLastRefresh\"]],[[[1,\"    Last update:\\n    \"],[1,[28,[35,5],[[30,4,[\"backlinksLastRefresh\"]]],null]],[1,\"\\n  \"]],[]],null]],[]]],[13]],[\"&attrs\",\"@showProgress\",\"@limitReached\",\"@url\",\"@percent\"],false,[\"if\",\"nw-help-tooltip\",\"abbreviate-number\",\"style\",\"concat\",\"relative-date\"]]",
    "moduleName": "nightwatch-web/components/backlinks/backlinks-progress.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});