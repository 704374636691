define("nightwatch-web/components/settings/whitelabel-logo-list", ["exports", "@glimmer/component", "ember-concurrency", "nightwatch-web/config/environment"], function (_exports, _component, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-whitelabel-logo-list">
    <FileUpload @name="whitelabel-logos" @accept="image/*" @onfileadd={{perform this.uploadFile}} as |queue|>
      <span role="button" tabindex="0" class="nw-button nw-button--logo-upload">
        <NwIcon class="nw-button__icon" @icon="svg-upload" /> {{#if queue.files.length}}Uploading…{{queue.progress}}%{{else}}Upload logo{{/if}}
      </span>
    </FileUpload>
  
    <div class="nw-whitelabel-logo-list__images">
      {{#each @whitelabelLogos as |whitelabelLogo idx|}}
        <Settings::WhitelabelLogoItem @whitelabelLogo={{whitelabelLogo}} @idx={{idx}} @onSwitchDefaultLogo={{fn this.switchDefaultLogo whitelabelLogo}} @onDeleteWhitelabelLogo={{fn this.deleteWhitelabelLogo whitelabelLogo}} />
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "UL9/B//x",
    "block": "[[[10,0],[14,0,\"nw-whitelabel-logo-list\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@accept\",\"@onfileadd\"],[\"whitelabel-logos\",\"image/*\",[28,[37,1],[[30,0,[\"uploadFile\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,\"role\",\"button\"],[14,\"tabindex\",\"0\"],[14,0,\"nw-button nw-button--logo-upload\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"nw-button__icon\"]],[[\"@icon\"],[\"svg-upload\"]],null],[1,\" \"],[41,[30,1,[\"files\",\"length\"]],[[[1,\"Uploading…\"],[1,[30,1,[\"progress\"]]],[1,\"%\"]],[]],[[[1,\"Upload logo\"]],[]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"nw-whitelabel-logo-list__images\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,2]],null]],null],null,[[[1,\"      \"],[8,[39,6],null,[[\"@whitelabelLogo\",\"@idx\",\"@onSwitchDefaultLogo\",\"@onDeleteWhitelabelLogo\"],[[30,3],[30,4],[28,[37,7],[[30,0,[\"switchDefaultLogo\"]],[30,3]],null],[28,[37,7],[[30,0,[\"deleteWhitelabelLogo\"]],[30,3]],null]]],null],[1,\"\\n\"]],[3,4]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"queue\",\"@whitelabelLogos\",\"whitelabelLogo\",\"idx\"],false,[\"file-upload\",\"perform\",\"nw-icon\",\"if\",\"each\",\"-track-array\",\"settings/whitelabel-logo-item\",\"fn\"]]",
    "moduleName": "nightwatch-web/components/settings/whitelabel-logo-list.hbs",
    "isStrictMode": false
  });

  let WhitelabelLogoListComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)({
    restartable: true,
    maxConcurrency: 3
  }), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class WhitelabelLogoListComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);
    }

    *uploadFile(file) {
      try {
        yield file.upload(`${_environment.default.apiBaseURL}whitelabel_logos`, {
          fileKey: 'whitelabel_logo[logo]',
          data: {
            access_token: this.session.token
          }
        });
        this.metrics.trackEvent({
          event: 'Added Logo'
        });
        this.args.onFileUploaded();
      } catch (error) {
        this.notifications.error(`File upload failed: ${error.message}`, {
          autoClear: true,
          clearDuration: 10000
        });
      }
    }

    switchDefaultLogo(whitelabelLogo) {
      this.args.whitelabelLogos.setEach('default', false);
      whitelabelLogo.default = true;
      this.args.onSwitchDefaultLogo(whitelabelLogo);
    }

    deleteWhitelabelLogo(whitelabelLogo) {
      if (this.args.whitelabelLogos.length === 0) {
        this.notifications.error('You need to have at least one logo for your white-label.', {
          autoClear: true,
          clearDuration: 10000
        });
        return;
      }

      whitelabelLogo.destroyRecord();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchDefaultLogo", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "switchDefaultLogo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteWhitelabelLogo", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "deleteWhitelabelLogo"), _class.prototype)), _class));
  _exports.default = WhitelabelLogoListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, WhitelabelLogoListComponent);
});