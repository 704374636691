define("nightwatch-web/helpers/string-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.stringEq = void 0;

  const stringEq = params => String(params[0]) === String(params[1]);

  _exports.stringEq = stringEq;

  var _default = Ember.Helper.helper(stringEq);

  _exports.default = _default;
});