define("nightwatch-web/utils/filters/filter-object", ["exports", "nightwatch-web/constants/filters/filter-types", "lodash-es/flatten"], function (_exports, _filterTypes, _flatten) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    isFirstAndNullified: Ember.computed('name', 'operator', 'value', 'filterGroup.filters.[]', 'filterGroup.filterConfig.filterGroups.[]', function () {
      const isFirstAndOnlyGroup = this.get('filterGroup.filterConfig.filterGroups.length') === 1;
      const isFirstAndOnlyFilter = this.get('filterGroup.filters.length') === 1;
      return isFirstAndOnlyGroup && isFirstAndOnlyFilter && Ember.isBlank(this.name) && Ember.isBlank(this.operator) && Ember.isBlank(this.value);
    }),
    isLast: Ember.computed('filterGroup.filters.[]', function () {
      return this.get('filterGroup.filters.lastObject.id') === this.id;
    }),
    currentFilter: Ember.computed('availableFilters.[]', 'name', function () {
      return this.availableFilters.findBy('name', this.name);
    }),
    availableFilterNames: Ember.computed('availableFilters.[]', function () {
      return this.availableFilters.mapBy('name');
    }),
    filterDisplayName: Ember.computed('name', function () {
      const filter = this.currentFilter;

      if (filter) {
        return this.currentFilter.displayName;
      }
    }),
    isPresenceOperator: Ember.computed('operator', function () {
      return ['is_present', 'is_blank'].includes(this.operator);
    }),
    disableInput: Ember.computed('hasPredefinedValues', 'operator', function () {
      return this.hasPredefinedValues || this.isPresenceOperator;
    }),
    hasPredefinedValues: Ember.computed('currentFilter', function () {
      const filter = this.currentFilter;

      if (filter) {
        return this.currentFilter.type === 'values';
      }
    }),
    isDateType: Ember.computed('currentFilter', function () {
      const filter = this.currentFilter;

      if (filter) {
        return this.get('currentFilter.type').indexOf('date') !== -1;
      }
    }),
    dateValue: Ember.computed('value', function () {
      if (this.value) {
        return new Date(this.value);
      }
    }),
    predefinedValues: Ember.computed('currentFilter', function () {
      const filter = this.currentFilter;

      if (filter) {
        return this.currentFilter.availableValues;
      }
    }),
    isValid: Ember.computed('errors.[]', function () {
      return this.get('errors.length') === 0;
    }),
    isEmpty: Ember.computed('name', 'operator', 'value', function () {
      if (this.hasPredefinedValues) {
        return Ember.isBlank(this.value);
      } else if (this.isPresenceOperator) {
        return false;
      } else {
        return Ember.isBlank(this.operator) || Ember.isBlank(this.value);
      }
    }),
    errors: Ember.computed('currentFilter', 'value', function () {
      const filter = this.currentFilter;
      const errors = [];

      if (filter) {
        const filterType = this.currentFilter.type;
        const value = this.value;

        if (filterType === 'number' && Ember.isPresent(value) && isNaN(value)) {
          errors.push('Enter a number');
        }
      }

      return errors;
    }),
    availableOperators: Ember.computed('name', function () {
      if (!this.currentFilter) {
        return [];
      }

      const filterType = this.currentFilter.type;
      let filterTypes = [];

      if (filterType.constructor === Array) {
        filterTypes = filterType;
      } else {
        filterTypes.push(filterType);
      }

      let operators = [];
      filterTypes.forEach(t => {
        operators.push(_filterTypes.default[t]);
      });
      return (0, _flatten.default)(operators);
    }),
    availableOperatorNames: Ember.computed('availableOperators.[]', function () {
      return this.availableOperators.mapBy('name');
    }),

    nullify() {
      this.setProperties({
        name: null,
        operator: null,
        value: null
      });
    }

  });

  _exports.default = _default;
});