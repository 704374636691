define("nightwatch-web/routes/dashboard/url/graphs/new", ["exports", "nightwatch-web/mixins/graph-route"], function (_exports, _graphRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlGraphsNewRoute extends Ember.Route.extend(_graphRoute.default) {
    activate() {
      window.scrollTo(0, 0);
    }

    model(params = {}) {
      const urlModel = this.modelFor('dashboard.url');
      const {
        id,
        url
      } = urlModel;
      const urlId = id;
      const {
        serpPreviewKeywordId
      } = params;
      const series = [{
        urlId,
        url,
        keywordId: serpPreviewKeywordId,
        color: '#32c45c',
        active: true,
        name: serpPreviewKeywordId ? 'position' : 'average_position'
      }];
      const grouping = 'day';
      return this.store.createRecord('graph', {
        url_id: urlId,
        url: urlModel,
        graph_data: {
          grouping,
          series
        },
        relative_date_range: 'last_30_days'
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      const serpPreviewKeywordId = controller.serpPreviewKeywordId;

      if (serpPreviewKeywordId) {
        controller.toggleSerpPreviewInitially(serpPreviewKeywordId);
      }
    }

    resetController() {
      this.controller.set('serpPreviewKeywordId', null);
    }

  }

  _exports.default = DashboardUrlGraphsNewRoute;
});