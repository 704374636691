define("nightwatch-web/controllers/dashboard/url/settings/backlinks", ["exports", "ember-cp-validations", "nightwatch-web/utils/is-testing"], function (_exports, _emberCpValidations, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.backlinksSettings.minUrlRating': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0,
      lt: 100
    })
  });
  let DashboardUrlSettingsBacklinksController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('model'), _dec4 = Ember.computed('url.backlinksSettings.ignored_domains.[]'), _dec5 = Ember.computed('ignoreDomainsOverLimit'), _dec6 = Ember.computed('url.backlinksSettings.ignored_domains.@each'), _dec7 = Ember._action, _dec8 = Ember._action, (_class = class DashboardUrlSettingsBacklinksController extends Ember.Controller.extend(Validations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _defineProperty(this, "maxIgnoreDomains", 120);

      _defineProperty(this, "queryParams", ['ignored_domains']);

      _defineProperty(this, "ignored_domains", []);

      _initializerDefineProperty(this, "url", _descriptor3, this);
    }

    get ignoreDomainsOverLimit() {
      return this.get('url.backlinksSettings.ignored_domains.length') > this.maxIgnoreDomains;
    }

    get ignoreDomainsError() {
      return this.ignoreDomainsOverLimit ? `Limit is ${this.maxIgnoreDomains} domains.` : '';
    }

    get contentForIgnoreDomains() {
      return (this.get('url.backlinksSettings.ignored_domains') || []).join('\n');
    }

    set contentForIgnoreDomains(value) {
      const domains = value.split('\n').uniq().compact();
      this.set('url.backlinksSettings.ignored_domains', domains);
    }

    saveUrl(url) {
      if (this.ignoreDomainsError) {
        this.notifications.error('Please delete some of the ignore domains to fit the limit.', {
          autoClear: true,
          clearDuration: 10000
        });
        return;
      }

      const promise = url.save();
      const didAddIgnoredDomains = this.get('ignored_domains.length') > 0;
      promise.then(() => {
        if (didAddIgnoredDomains) {
          this.notifications.success('Settings saved sucessfully. Domains were added to the ignore list. If you want to remove existing ones, delete them manually.', {
            autoClear: true,
            clearDuration: _isTesting.default ? 0 : 15000
          });
          this.router.transitionTo('dashboard.url.backlinks.index', url);
        } else {
          this.notifications.success('Settings saved sucessfully.', {
            autoClear: true
          });
        }
      }, () => {
        this.notifications.error('Error while saving settings.', {
          autoClear: true,
          clearDuration: 10000
        });
      });
    }

    toggleBacklinksFetchingEnabled() {
      this.toggleProperty('url.backlinksFetchingEnabled');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "ignoreDomainsOverLimit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreDomainsOverLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ignoreDomainsError", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreDomainsError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contentForIgnoreDomains", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "contentForIgnoreDomains"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveUrl", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "saveUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBacklinksFetchingEnabled", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBacklinksFetchingEnabled"), _class.prototype)), _class));
  _exports.default = DashboardUrlSettingsBacklinksController;
});