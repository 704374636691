define("nightwatch-web/serializers/application", ["exports", "ember-inflector", "active-model-adapter"], function (_exports, _emberInflector, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    isNewSerializerAPI: true,

    normalizeSingleResponse(store, primaryType, payload, recordId) {
      const {
        modelName
      } = primaryType;
      let newPayload = {};

      if (payload[modelName] && typeof payload[modelName] === 'object') {
        newPayload = payload;
      } else {
        newPayload[modelName] = payload;
      }

      return this._super(store, primaryType, newPayload, recordId);
    },

    normalizeArrayResponse(store, primaryType, payload) {
      const pluralTypeKey = (0, _emberInflector.pluralize)(primaryType.modelName);
      let newPayload = {};

      if (payload[pluralTypeKey] && typeof payload[pluralTypeKey] === 'object') {
        newPayload = payload;
      } else {
        newPayload[pluralTypeKey] = Array.isArray(payload) ? payload : [payload];
      }

      return this._super(store, primaryType, newPayload);
    }

  });

  _exports.default = _default;
});