define("nightwatch-web/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qIQU0ka6",
    "block": "[[[1,[28,[35,0],[\"Error\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"There was an error while processing your request.\"]],[]]]]],[1,\"\\n\"]],[],false,[\"page-title\",\"error-page\"]]",
    "moduleName": "nightwatch-web/templates/error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});