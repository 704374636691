define("nightwatch-web/helpers/is-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isIn = isIn;
  _exports.default = void 0;

  function isIn([obj, col, prop]) {
    return !!col.findBy(prop, obj.get(prop));
  }

  var _default = Ember.Helper.helper(isIn);

  _exports.default = _default;
});