define("nightwatch-web/components/keyword-list/position-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @positionType 'places')}}
    <span class="inline-block">
      <i class='fa fa-map-marker icon-keyword-row cursor-pointer'>
        <NwTooltip>Google Places</NwTooltip>
      </i>
    </span>
  {{else if (eq @positionType 'places_image')}}
    <span class="inline-block">
      <i class='fa fa-picture-o icon-keyword-row cursor-pointer'>
        <NwTooltip>Google Carousel</NwTooltip>
      </i>
    </span>
  {{else if (eq @positionType 'local_pack')}}
    <span class="inline-block">
      <i class='fa fa-list icon-keyword-row cursor-pointer icon-keyword-row-small'>
        <NwTooltip>Google Local Pack</NwTooltip>
      </i>
    </span>
  {{else if (eq @positionType 'featured_snippet')}}
    <span class="inline-block">
      <i class='fa fa-quote-right icon-keyword-row cursor-pointer icon-keyword-row-small'>
        <NwTooltip>Google Featured Snippet</NwTooltip>
      </i>
    </span>
  {{else if (eq @positionType 'knowledge_panel')}}
    <span class="inline-block">
      <NwTooltip>Google Knowledge Panel</NwTooltip>
      <NwIcon @icon="icon-knowledge-panel" class="icon-keyword-row cursor-pointer icon-keyword-row-small" />
    </span>
  {{/if}}
  
  */
  {
    "id": "pnOsDwZ4",
    "block": "[[[41,[28,[37,1],[[30,1],\"places\"],null],[[[1,\"  \"],[10,1],[14,0,\"inline-block\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-map-marker icon-keyword-row cursor-pointer\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"Google Places\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"places_image\"],null],[[[1,\"  \"],[10,1],[14,0,\"inline-block\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-picture-o icon-keyword-row cursor-pointer\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"Google Carousel\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"local_pack\"],null],[[[1,\"  \"],[10,1],[14,0,\"inline-block\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-list icon-keyword-row cursor-pointer icon-keyword-row-small\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"Google Local Pack\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"featured_snippet\"],null],[[[1,\"  \"],[10,1],[14,0,\"inline-block\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-quote-right icon-keyword-row cursor-pointer icon-keyword-row-small\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"Google Featured Snippet\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"knowledge_panel\"],null],[[[1,\"  \"],[10,1],[14,0,\"inline-block\"],[12],[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"Google Knowledge Panel\"]],[]]]]],[1,\"\\n    \"],[8,[39,3],[[24,0,\"icon-keyword-row cursor-pointer icon-keyword-row-small\"]],[[\"@icon\"],[\"icon-knowledge-panel\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[]]]],[]]]],[\"@positionType\"],false,[\"if\",\"eq\",\"nw-tooltip\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/keyword-list/position-icon.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});