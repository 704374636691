define("nightwatch-web/serializers/keyword", ["exports", "nightwatch-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const transformAttributes = attrs => {
    return _objectSpread(_objectSpread({}, attrs), {}, {
      url_id: attrs.search_keyword_url_id,
      tags: attrs.tags.mapBy('name'),
      url_value: attrs.url,
      url: null,
      // For cases where API returns 'google_mobile' we should treat this as 'google'
      engine: attrs.engine === 'google_mobile' ? 'google' : attrs.engine
    });
  };

  class KeywordSerializer extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isNewSerializerAPI", true);
    }

    normalizeArrayResponse(store, primaryType, payload) {
      payload.keywords = payload.keywords.map(k => transformAttributes(k));
      return super.normalizeArrayResponse(store, primaryType, payload);
    }

    normalizeSingleResponse(store, primaryType, payload, recordId) {
      payload.keyword = transformAttributes(payload.keyword);
      return super.normalizeSingleResponse(store, primaryType, payload, recordId);
    }

  }

  _exports.default = KeywordSerializer;
});