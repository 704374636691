define("nightwatch-web/components/last-position-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @r}}
    {{#if (gte @r.length 2)}}
      {{#if (eq @r.firstObject "appeared")}}
        <i class="icon-up"></i>
        <span class="up">&infin;</span>
      {{else if (eq @r.firstObject "disappeared")}}
        <i class="icon-down"></i>
        <span class="down">&infin;</span>
      {{else}}
        <UpDownStagnantIcon @value={{@r.firstObject}} class="inline-block" />
        <span class="{{up-down-stagnant @r.firstObject}}">
          {{abs @r.firstObject}}
        </span>
      {{/if}}
      &nbsp;
      {{relative-date (parse-iso-date (item-at-index @r 1))}}
    {{else if (eq @r.lastObject false)}}
      not in results
    {{else}}
      found
    {{/if}}
  {{else}}
    no change
  {{/if}}
  */
  {
    "id": "tYOfeBa6",
    "block": "[[[41,[30,1],[[[41,[28,[37,1],[[30,1,[\"length\"]],2],null],[[[41,[28,[37,2],[[30,1,[\"firstObject\"]],\"appeared\"],null],[[[1,\"      \"],[10,\"i\"],[14,0,\"icon-up\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"up\"],[12],[1,\"∞\"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,1,[\"firstObject\"]],\"disappeared\"],null],[[[1,\"      \"],[10,\"i\"],[14,0,\"icon-down\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"down\"],[12],[1,\"∞\"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,3],[[24,0,\"inline-block\"]],[[\"@value\"],[[30,1,[\"firstObject\"]]]],null],[1,\"\\n      \"],[10,1],[15,0,[29,[[28,[37,4],[[30,1,[\"firstObject\"]]],null]]]],[12],[1,\"\\n        \"],[1,[28,[35,5],[[30,1,[\"firstObject\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]],[1,\"     \\n    \"],[1,[28,[35,6],[[28,[37,7],[[28,[37,8],[[30,1],1],null]],null]],null]],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,1,[\"lastObject\"]],false],null],[[[1,\"    not in results\\n\"]],[]],[[[1,\"    found\\n  \"]],[]]]],[]]]],[]],[[[1,\"  no change\\n\"]],[]]]],[\"@r\"],false,[\"if\",\"gte\",\"eq\",\"up-down-stagnant-icon\",\"up-down-stagnant\",\"abs\",\"relative-date\",\"parse-iso-date\",\"item-at-index\"]]",
    "moduleName": "nightwatch-web/components/last-position-change.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});