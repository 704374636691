define("nightwatch-web/services/keyword-columns-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class KeywordColumnsDataService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "availableColumns", [{
        name: 'keyword-position',
        displayName: 'Rank',
        sortProperty: 'position'
      }, {
        name: 'keyword-position-organic',
        displayName: 'Organic rank',
        sortProperty: 'position_organic'
      }, {
        name: 'keyword-position-places-image',
        displayName: 'Image carousel rank',
        sortProperty: 'position_places_image'
      }, {
        name: 'keyword-position-local-pack',
        displayName: 'Local pack rank',
        sortProperty: 'position_local_pack'
      }, {
        name: 'keyword-position-previous',
        displayName: 'Previous Rank',
        sortProperty: 'previous_position'
      }, {
        name: 'keyword-position-best',
        displayName: 'Best Rank',
        sortProperty: 'best_position'
      }, {
        name: 'keyword-created-at',
        displayName: 'Added On',
        sortProperty: 'created_at'
      }, {
        name: 'keyword-last-update',
        displayName: 'Last Update',
        sortProperty: 'last_processed_at'
      }, {
        name: 'keyword-last-position-change',
        displayName: 'Last Change',
        sortProperty: 'position_changed_by'
      }, {
        name: 'keyword-mini-graph',
        displayName: 'Evolution',
        sortProperty: 'position_changed_during_last_day'
      }, {
        name: 'keyword-search-engine',
        displayName: 'Search Engine',
        sortProperty: 'engine'
      }, {
        name: 'keyword-location',
        displayName: 'Location',
        sortProperty: 'google_gl'
      }, {
        name: 'keyword-language',
        displayName: 'Language',
        sortProperty: 'google_hl'
      }, {
        name: 'keyword-dwm-change',
        displayName: 'D/W/M Change',
        sortProperty: 'position_changed_during_last_day'
      }, {
        name: 'position-changed-during-last-day',
        displayName: 'Daily change',
        sortProperty: 'position_changed_during_last_day'
      }, {
        name: 'position-changed-during-last-week',
        displayName: 'Weekly change',
        sortProperty: 'position_changed_during_last_week'
      }, {
        name: 'position-changed-during-last-month',
        displayName: 'Monthly change',
        sortProperty: 'position_changed_during_last_month'
      }, {
        name: 'keyword-results-count',
        displayName: 'Results #',
        sortProperty: 'results_count'
      }, {
        name: 'keyword-sv-local',
        headerName: 'SV (local)',
        displayName: 'Search Volume (local)',
        sortProperty: 'adwords_local_search_volume'
      }, {
        name: 'keyword-sv-global',
        headerName: 'SV (global)',
        displayName: 'Search Volume (global)',
        sortProperty: 'adwords_global_search_volume'
      }, {
        name: 'keyword-cpc-local',
        headerName: 'CPC (local)',
        displayName: 'Average Cost per Click (local)',
        sortProperty: 'adwords_local_average_cpc'
      }, {
        name: 'keyword-cpc-global',
        headerName: 'CPC (global)',
        displayName: 'Average Cost per Click (global)',
        sortProperty: 'adwords_global_average_cpc'
      }, {
        name: 'keyword-url',
        displayName: 'URL',
        sortProperty: 'url'
      }, {
        name: 'keyword-clicks',
        displayName: 'Daily Clicks',
        sortProperty: 'clicks'
      }, {
        name: 'keyword-impressions',
        displayName: 'Daily Impressions',
        sortProperty: 'impressions'
      }, {
        name: 'keyword-ctr',
        displayName: 'Daily CTR',
        sortProperty: 'ctr'
      }, {
        name: 'keyword-search-console-position',
        displayName: 'Search Console Rank',
        sortProperty: 'search_console_position'
      }, {
        name: 'serp-opportunities',
        displayName: 'Opportunities',
        sortProperty: 'available_serp_features'
      }]);

      _defineProperty(this, "availableReportColumns", [{
        name: 'keyword-position',
        displayName: 'Rank',
        sortProperty: 'position'
      }, {
        name: 'keyword-position-organic',
        displayName: 'Organic rank',
        sortProperty: 'position_organic'
      }, {
        name: 'keyword-position-places-image',
        displayName: 'Image carousel rank',
        sortProperty: 'position_places_image'
      }, {
        name: 'keyword-position-local-pack',
        displayName: 'Local pack rank',
        sortProperty: 'position_local_pack'
      }, {
        name: 'keyword-position-previous',
        displayName: 'Previous Rank',
        sortProperty: 'previous_position'
      }, {
        name: 'keyword-position-max',
        displayName: 'Best Rank',
        sortProperty: 'best_position'
      }, {
        name: 'keyword-created-at',
        displayName: 'Added On',
        sortProperty: 'created_at'
      }, {
        name: 'keyword-last-update',
        displayName: 'Last Update',
        sortProperty: 'last_processed_at'
      }, {
        name: 'keyword-last-position-change',
        displayName: 'Last Change',
        sortProperty: 'position_changed_by'
      }, {
        name: 'keyword-mini-graph',
        displayName: 'Evolution',
        sortProperty: 'position_changed_during_last_day'
      }, {
        name: 'keyword-search-engine',
        displayName: 'Search Engine',
        sortProperty: 'engine'
      }, {
        name: 'keyword-location',
        displayName: 'Location',
        sortProperty: 'google_gl'
      }, {
        name: 'keyword-language',
        displayName: 'Language',
        sortProperty: 'google_hl'
      }, {
        name: 'keyword-dwm-change',
        displayName: 'D/W/M Change',
        sortProperty: 'position_changed_during_last_day'
      }, {
        name: 'position-changed-during-last-day',
        displayName: 'Daily change',
        sortProperty: 'position_changed_during_last_day'
      }, {
        name: 'position-changed-during-last-week',
        displayName: 'Weekly change',
        sortProperty: 'position_changed_during_last_week'
      }, {
        name: 'position-changed-during-last-month',
        displayName: 'Monthly change',
        sortProperty: 'position_changed_during_last_month'
      }, {
        name: 'keyword-results-count',
        displayName: 'Results #',
        sortProperty: 'results_count'
      }, {
        name: 'keyword-sv-local',
        headerName: 'SV (local)',
        displayName: 'Search Volume (local)',
        sortProperty: 'adwords_local_search_volume'
      }, {
        name: 'keyword-sv-global',
        headerName: 'SV (global)',
        displayName: 'Search Volume (global)',
        sortProperty: 'adwords_global_search_volume'
      }, {
        name: 'keyword-cpc-local',
        headerName: 'CPC (local)',
        displayName: 'Average Cost per Click (local)',
        sortProperty: 'adwords_local_average_cpc'
      }, {
        name: 'keyword-cpc-global',
        headerName: 'CPC (global)',
        displayName: 'Average Cost per Click (global)',
        sortProperty: 'adwords_global_average_cpc'
      }, {
        name: 'keyword-url',
        displayName: 'URL',
        sortProperty: 'url'
      }, {
        name: 'keyword-clicks',
        displayName: 'Daily Clicks',
        sortProperty: 'clicks'
      }, {
        name: 'keyword-impressions',
        displayName: 'Daily Impressions',
        sortProperty: 'impressions'
      }, {
        name: 'keyword-ctr',
        displayName: 'Daily CTR',
        sortProperty: 'ctr'
      }, {
        name: 'keyword-search-console-position',
        displayName: 'Search Console Rank',
        sortProperty: 'search_console_position'
      }, {
        name: 'keyword-start-position',
        displayName: 'Start Rank',
        sortProperty: 'start_position'
      }, {
        name: 'keyword-end-position',
        displayName: 'End Rank',
        sortProperty: 'end_position'
      }, {
        name: 'keyword-position-change',
        displayName: 'Rank Change',
        sortProperty: 'position_change'
      }]);

      _defineProperty(this, "availableCompetitorColumns", [{
        name: 'keyword-position',
        displayName: 'Rank'
      }, {
        name: 'keyword-position-organic',
        displayName: 'Organic rank',
        sortProperty: 'position_organic'
      }, {
        name: 'keyword-position-places-image',
        displayName: 'Image carousel rank',
        sortProperty: 'position_places_image'
      }, {
        name: 'keyword-position-local-pack',
        displayName: 'Local pack rank',
        sortProperty: 'position_local_pack'
      }, {
        name: 'keyword-last-position-change',
        displayName: 'Last Change'
      }, {
        name: 'keyword-dwm-change',
        displayName: 'D/W/M Change'
      }]);
    }

  }

  var _default = KeywordColumnsDataService;
  _exports.default = _default;
});