define("nightwatch-web/components/graph/grouping-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="btn-group {{if @isLoading "dimmed"}}" ...attributes>
    {{#each @groupings as |groupingOption|}}
      <button class="btn btn-secondary-4 btn-grouping {{if (string-eq @graph.grouping groupingOption) "active"}}" {{on "click" (fn @changeGrouping groupingOption)}} type="button">
        {{capitalize groupingOption}}
      </button>
    {{/each}}
  </div>
  
  */
  {
    "id": "D1omX6gA",
    "block": "[[[11,0],[16,0,[29,[\"btn-group \",[52,[30,1],\"dimmed\"]]]],[17,2],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],null,[[[1,\"    \"],[11,\"button\"],[16,0,[29,[\"btn btn-secondary-4 btn-grouping \",[52,[28,[37,3],[[30,5,[\"grouping\"]],[30,4]],null],\"active\"]]]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,6],[30,4]],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,6],[[30,4]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[4]],null],[13],[1,\"\\n\"]],[\"@isLoading\",\"&attrs\",\"@groupings\",\"groupingOption\",\"@graph\",\"@changeGrouping\"],false,[\"if\",\"each\",\"-track-array\",\"string-eq\",\"on\",\"fn\",\"capitalize\"]]",
    "moduleName": "nightwatch-web/components/graph/grouping-chooser.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});