define("nightwatch-web/components/loading/small-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="spinner-container">
    <div class="splash-spinner--small splash-spinner--chart">
      <div class="rect rect1"></div>
      <div class="rect rect2"></div>
      <div class="rect rect3"></div>
      <div class="rect rect4"></div>
      <div class="rect rect5"></div>
    </div>
  </div>
  
  */
  {
    "id": "yzS4iJjv",
    "block": "[[[10,0],[14,0,\"spinner-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"splash-spinner--small splash-spinner--chart\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"rect rect1\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"rect rect2\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"rect rect3\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"rect rect4\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"rect rect5\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "nightwatch-web/components/loading/small-horizontal.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});