define("nightwatch-web/templates/dashboard/dynamic-view/reports/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "53g8Iqa3",
    "block": "[[[1,[28,[35,0],[[30,1,[\"dynamicView\",\"name\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Create Report\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"main-area-navbar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main-area-container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.dynamic-view\",[30,1,[\"dynamicView\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"dynamicView\",\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.dynamic-view.reports\",[30,1,[\"dynamicView\"]]]],[[\"default\"],[[[[1,\"\\n        Reports\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@dynamicView\",\"@report\",\"@logos\",\"@onReportSave\"],[[30,1,[\"dynamicView\"]],[30,1,[\"report\"]],[30,1,[\"whitelabelLogos\"]],[30,0,[\"onReportSave\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"link-to\",\"nw-icon\",\"reports/report-form\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/dynamic-view/reports/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});