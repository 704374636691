define("nightwatch-web/utils/universal-graph-config", ["exports", "jquery", "highcharts/highstock"], function (_exports, _jquery, _highstock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AXIS = _exports.MAX_DENSITY_FOR_MARKERS = _exports.SERIES_WITH_SERP_PREVIEW_CLASS = _exports.EMPTY_SEGMENTS_ID = _exports.EMPTY_SEGMENTS_TITLE = _exports.EMPTY_SEGMENTS_BAND_COLOR = _exports.EMPTY_SEGMENT_LINE_PADDING_RATE = _exports.MAX_DATA_VALUE_OFFSET = _exports.TICK_AMOUNT = _exports.CHART_REFLOW_INTERVAL = _exports.DEFAULT_GROUPING = _exports.GROUPING = _exports.COLORS = void 0;
  const COLORS = {
    GRID_LINE_DARK: '#2a2a2a',
    GRID_LINE_LIGHT: '#c3c3c3',
    X_AXIS_LINE: '#a7a7a7',
    NAVIGATOR_OUTLINE: '#4d4d4d'
  };
  _exports.COLORS = COLORS;
  const GROUPING = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month'
  };
  _exports.GROUPING = GROUPING;
  const DEFAULT_GROUPING = GROUPING.DAY;
  _exports.DEFAULT_GROUPING = DEFAULT_GROUPING;
  const CHART_REFLOW_INTERVAL = 550;
  _exports.CHART_REFLOW_INTERVAL = CHART_REFLOW_INTERVAL;
  const TICK_AMOUNT = 5;
  _exports.TICK_AMOUNT = TICK_AMOUNT;
  const MAX_DATA_VALUE_OFFSET = 1.2;
  _exports.MAX_DATA_VALUE_OFFSET = MAX_DATA_VALUE_OFFSET;
  const EMPTY_SEGMENT_LINE_PADDING_RATE = 0.2;
  _exports.EMPTY_SEGMENT_LINE_PADDING_RATE = EMPTY_SEGMENT_LINE_PADDING_RATE;
  const EMPTY_SEGMENTS_BAND_COLOR = 'rgba(255,152,152,0.4)';
  _exports.EMPTY_SEGMENTS_BAND_COLOR = EMPTY_SEGMENTS_BAND_COLOR;
  const EMPTY_SEGMENTS_TITLE = 'Not in search engine results';
  _exports.EMPTY_SEGMENTS_TITLE = EMPTY_SEGMENTS_TITLE;
  const EMPTY_SEGMENTS_ID = 'empty-segments-series';
  _exports.EMPTY_SEGMENTS_ID = EMPTY_SEGMENTS_ID;
  const SERIES_WITH_SERP_PREVIEW_CLASS = 'series-with-serp-preview';
  _exports.SERIES_WITH_SERP_PREVIEW_CLASS = SERIES_WITH_SERP_PREVIEW_CLASS;
  const MAX_DENSITY_FOR_MARKERS = 0.1; // points per px

  _exports.MAX_DENSITY_FOR_MARKERS = MAX_DENSITY_FOR_MARKERS;
  const AXIS = {
    POSITION: 'axis-y-position',
    ANALYTICS: 'axis-y-analytics',
    ANNOTATION: 'axis-y-annotation',
    TIMELINE: 'axis-x-timeline'
  };
  _exports.AXIS = AXIS;
  var _default = {
    rangeSelector: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      },
      chartOptions: {
        navigator: {
          enabled: false
        },
        legend: {
          enabled: true
        }
      }
    },
    scrollbar: {
      enabled: false
    },
    navigator: {
      outlineColor: COLORS.NAVIGATOR_OUTLINE,
      series: {
        type: 'line'
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      },
      line: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      },
      series: {
        animation: false,
        dataGrouping: {
          forced: false,
          units: [[DEFAULT_GROUPING, [1]]]
        },
        marker: {
          states: {
            select: {
              radius: 6,
              fillColor: '#ffffff',
              lineColor: '#70C83F',
              lineWidth: 5
            }
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    yAxis: [// For Ranks (inverted)
    {
      id: AXIS.POSITION,
      title: {
        text: 'Position'
      },
      reversed: true,
      gridLineColor: COLORS.GRID_LINE_DARK,
      gridLineWidth: 1,
      labels: {
        x: -10,
        y: 4
      },
      opposite: false,
      maxPadding: 0,
      minPadding: 0,
      startOnTick: true,
      endOnTick: false,
      allowDecimals: false,
      showFirstLabel: true,
      showLastLabel: false,
      min: 1,
      showEmpty: false
    }, {
      id: AXIS.ANALYTICS,
      title: {
        text: 'Analytics Data'
      },
      gridLineColor: COLORS.GRID_LINE_DARK,
      gridLineWidth: 1,
      labels: {
        x: 10,
        y: 4
      }
    }, // For annotations
    {
      id: AXIS.ANNOTATION,
      visible: false
    }],
    xAxis: {
      id: AXIS.TIMELINE,
      type: 'datetime',
      lineWidth: 1,
      lineColor: COLORS.X_AXIS_LINE
    },
    chart: {
      spacingTop: 20,
      backgroundColor: null
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        let date, text, y;
        const {
          points,
          x
        } = this;
        date = _highstock.default.dateFormat('%A, %b %d, %Y', new Date(x));
        text = `<b>${date}</b>`;
        let userNotes = [];

        const colorLiteral = color => {
          if (typeof color == 'string') return color; // If it's not string, it's a gradient.

          return color.stops[0][1];
        };

        _jquery.default.each(this.points, function (i, point) {
          if (this.point.userNote) {
            // Don't show it.
            userNotes.push(this.point.userNote);
          } else if (point.series.options.seriesId === EMPTY_SEGMENTS_ID) {
            const {
              seriesColor,
              parentSeriesId
            } = point.series.options;

            if (!points.isAny('series.options.seriesId', parentSeriesId)) {
              text += `<br><span style="color:${colorLiteral(seriesColor)}">\u25CF</span> ${point.series.name}: <b>${EMPTY_SEGMENTS_TITLE}</b>`;
            }
          } else {
            y = point.y % 1 === 0 ? point.y : point.y.toFixed(2);
            text += `<br><span style="color:${colorLiteral(point.color)}">\u25CF</span> ${point.series.name}: <b>${y}</b>`;
          }
        });

        if (userNotes.length > 0) {
          text += '<br><br> <b>Note</b> <br>';
          userNotes.forEach(note => {
            text += `${note}`;
          });
        }

        return text;
      },
      shared: true,
      split: false,
      borderWidth: 0,
      padding: 0,
      backgroundColor: 'transparent'
    }
  };
  _exports.default = _default;
});