define("nightwatch-web/components/site-audit/page-details-referrer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['site-audit-details-stats']
  });

  _exports.default = _default;
});