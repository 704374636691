define("nightwatch-web/services/graphs/graph-data-transforms", ["exports", "nightwatch-web/utils/series-object", "highcharts/highstock"], function (_exports, _seriesObject, _highstock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GraphDataTransformsService = (_dec = Ember.inject.service, (_class = class GraphDataTransformsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "graphColorManager", _descriptor, this);
    }

    serializeSelectedSeries(selectedSeries) {
      return selectedSeries.map(series => {
        return series.getProperties(['name', 'urlId', 'keywordId', 'competitorId', 'active', 'url', 'dynamicViewId', 'dynamicViewName', 'keyword', 'backlinkViewId', 'backlinkViewName', 'competitorUrl', 'urlGroupName', 'urlGroupId', 'color']);
      });
    }

    gradientify(color, percent, chartHeight) {
      const linearGradient = [0, 0, 0, chartHeight];

      const color1 = _highstock.default.Color(color).setOpacity(1).get('rgba');

      const color2 = _highstock.default.Color(color).setOpacity(percent).get('rgba');

      const stops = [[0, color1], [1, color2]];
      return {
        linearGradient,
        stops
      };
    }

    emberizeSeries(series) {
      if (!series) return [];
      return series.map(s => {
        return _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
          active: true,
          color: s.color,
          urlId: s.urlId,
          url: s.url,
          keywordId: s.keywordId,
          keyword: s.keyword,
          competitorId: s.competitorId,
          competitorUrl: s.competitorUrl,
          name: s.name,
          dynamicViewId: s.dynamicViewId,
          dynamicViewName: s.dynamicViewName,
          backlinkViewName: s.backlinkViewName,
          urlGroupId: s.urlGroupId,
          urlGroupName: s.urlGroupName,
          backlinkViewId: s.backlinkViewId
        });
      });
    }

    extractStackedColumnSeries(stackedSeriesObj) {
      // Break down stacked column object to multiple individual series
      if (!stackedSeriesObj.get('series.firstObject')[1]) {
        return [];
      }

      const keys = Object.keys(stackedSeriesObj.get('series.firstObject')[1]).reverse();
      const parentProps = stackedSeriesObj.getProperties(['urlId', 'url', 'name', 'active']);
      return keys.map(key => {
        const childSeriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), parentProps);

        const newName = childSeriesObj.get('name') + '_' + key;
        childSeriesObj.setProperties({
          name: newName,
          color: this.graphColorManager.colorForSeries(key)
        });
        const childSeries = stackedSeriesObj.get('series').map(s => [s[0], s[1][key]]);
        childSeriesObj.set('series', childSeries);
        return childSeriesObj;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "graphColorManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GraphDataTransformsService;
});