define("nightwatch-web/services/graphs/graph-series", ["exports", "nightwatch-web/utils/humanize", "nightwatch-web/utils/titleize", "lodash-es/min", "lodash-es/max"], function (_exports, _humanize, _titleize, _min, _max) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GraphSeriesService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class GraphSeriesService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "graphColorManager", _descriptor, this);

      _initializerDefineProperty(this, "theme", _descriptor2, this);

      _defineProperty(this, "seriesNameMapper", {
        average_positions: 'Average Position',
        click_potential_series: 'Click Potential',
        search_visibility_series: 'Search Visibility',
        keyword_up_down_series: 'Keywords Up/Down',
        keyword_distribution_series: 'Keyword Distribution',
        indexed_pages_series: 'Indexed Pages'
      });
    }

    averagePositionSeries(data, size) {
      data = data || [];
      let positions = data.slice(-size).map(item => {
        return {
          x: Date.parse(item[0]),
          y: item[1]
        };
      });
      let stops = [[0, '#32c45c'], [1, 'black']];

      if (this.theme.isBright) {
        stops = [[0, '#2DB353'], [1, '#2DB353']];
      }

      return [{
        gapSize: 1,
        color: {
          linearGradient: [0, 0, 0, 100],
          stops: stops
        },
        data: positions,
        name: 'Average Position'
      }];
    }

    searchVisibilitySeries(seriesName, data, size) {
      data = data || [];
      const minValue = (0, _min.default)(data.map(item => item[1]));
      const maxValue = (0, _max.default)(data.map(item => item[1]));
      let series = data.slice(-size).map(item => {
        const value = item[1];
        const opacity = this.calculateOpacity(minValue, maxValue, value);
        return {
          x: Date.parse(item[0]),
          y: value,
          color: `rgba(39, 177, 78, ${opacity})`
        };
      });
      let betterName = (0, _titleize.default)((0, _humanize.default)(seriesName.replace('_series', '')));
      let pointWidth = null;

      if (data.length <= 4) {
        pointWidth = 11;
      }

      return [{
        pointWidth: pointWidth,
        data: series,
        name: betterName
      }];
    }

    distributionSeries(data, size) {
      data = data || []; // for new urls without processed keywords, the response is [[null, {}]]

      if (data[0] && data[0][0] == null) {
        data = [];
      }

      let top3 = [],
          top10 = [],
          top100 = [],
          noRank = [];
      data.slice(-size).forEach(item => {
        const date = Date.parse(item[0]);
        top3.push({
          x: date,
          y: item[1].top_3
        });
        top10.push({
          x: date,
          y: item[1].top_10
        });
        top100.push({
          x: date,
          y: item[1].top_100
        });
        noRank.push({
          x: date,
          y: item[1].no_rank
        });
      });
      let pointWidth = null;

      if (data.length <= 4) {
        pointWidth = 11;
      }

      return [{
        pointWidth: pointWidth,
        data: noRank,
        name: 'No Rank'
      }, {
        pointWidth: pointWidth,
        data: top100,
        name: 'Top 100'
      }, {
        pointWidth: pointWidth,
        data: top10,
        name: 'Top 10'
      }, {
        pointWidth: pointWidth,
        data: top3,
        name: 'Top 3'
      }];
    }

    upDownSeries(data, size) {
      data = data || []; // for new urls without processed keywords, the response is [[null, {}]]

      if (data[0] && data[0][0] == null) {
        data = [];
      }

      let wentUp = [];
      let wentDown = [];
      const maxUp = (0, _max.default)(data.map(item => item[1].went_up));
      const minUp = (0, _min.default)(data.map(item => item[1].went_up));
      const maxDown = (0, _max.default)(data.map(item => item[1].went_down));
      const minDown = (0, _min.default)(data.map(item => item[1].went_down));
      data.slice(-size).forEach(item => {
        const date = Date.parse(item[0]);
        const wentUpValue = item[1].went_up;
        const wentDownValue = item[1].went_down;
        const wentUpOpacity = this.calculateOpacity(minUp, maxUp, wentUpValue, 0.5);
        const wentDownOpacity = this.calculateOpacity(minDown, maxDown, wentDownValue, 0.5);
        wentUp.push({
          x: date,
          y: wentUpValue,
          color: `rgba(39, 177, 78, ${wentUpOpacity})`
        });
        wentDown.push({
          x: date,
          y: -wentDownValue,
          color: `rgba(179, 12, 23, ${wentDownOpacity})`
        });
      });
      let pointWidth = null;

      if (data.length <= 4) {
        pointWidth = 11;
      }

      return [{
        pointWidth: pointWidth,
        data: wentUp,
        name: 'Up'
      }, {
        pointWidth: pointWidth,
        data: wentDown,
        name: 'Down'
      }];
    }

    graphOptions(seriesName) {
      const searchVisibilitySeries = ['indexed_pages_series', 'search_visibility_series', 'click_potential_series'];

      if (seriesName === 'average_positions') {
        return {
          reversed: true,
          showXAxis: true,
          enableLabels: true,
          padExtremes: true,
          startOnZero: false,
          type: 'line',
          stacked: false
        };
      } else if (searchVisibilitySeries.indexOf(seriesName) !== -1) {
        return {
          startOnZero: true,
          showXAxis: true
        };
      } else if (seriesName === 'keyword_up_down_series') {
        return {
          stacked: true
        };
      } else if (seriesName === 'keyword_distribution_series') {
        return {
          stacked: true,
          startOnZero: true,
          showXAxis: true,
          colors: this.distributionColors()
        };
      }
    }

    distributionColors() {
      const noRankColor = this.graphColorManager.colorForSeries('no_rank');
      return [noRankColor, '#004979', '#1883ca', '#48aaed'];
    }

    calculateOpacity(minValue, maxValue, value, intensity = 0.7) {
      const minOpacity = intensity;
      const maxOpacity = 1;
      const opacity = (maxOpacity - minOpacity) / (maxValue - minValue) * (value - maxValue) + maxOpacity;
      return !isNaN(opacity) ? opacity : maxOpacity;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "graphColorManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GraphSeriesService;
});