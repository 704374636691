define("nightwatch-web/components/favicon-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <img src={{@src}} class="url-favicon" alt="" role="presentation">
  
  */
  {
    "id": "g9Pgaf71",
    "block": "[[[10,\"img\"],[15,\"src\",[30,1]],[14,0,\"url-favicon\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"\\n\"]],[\"@src\"],false,[]]",
    "moduleName": "nightwatch-web/components/favicon-image.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});