define("nightwatch-web/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ei8+/X0w",
    "block": "[[[1,[28,[35,0],[[30,0,[\"siteData\",\"siteTitle\"]]],null]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[8,[39,3],null,[[\"@position\"],[\"top\"]],null],[1,\"\\n\"],[10,0],[14,1,\"splash-overlay\"],[12],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"component\",\"-outlet\",\"notification-container\"]]",
    "moduleName": "nightwatch-web/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});