define("nightwatch-web/routes/dashboard/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardIndexRoute extends Ember.Route {
    activate() {
      return this.controllerFor('dashboard').setSelectedUrl(null);
    }

  }

  _exports.default = DashboardIndexRoute;
});