define("nightwatch-web/components/nw-share-panel", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-share-panel">
    <button class="nw-fb-icon" {{on "click" (fn this.openFbLink @url)}} type="button">
      <i class="fa fa-facebook-square"></i>
    </button>
    <button class="nw-fb-icon" {{on "click" (fn this.openTwLink @url @shareText)}} type="button">
      <i class="fa fa-twitter-square"></i>
    </button>
  </div>
  
  */
  {
    "id": "jI4Ab4kW",
    "block": "[[[10,0],[14,0,\"nw-share-panel\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"nw-fb-icon\"],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"openFbLink\"]],[30,1]],null]],null],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-facebook-square\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"nw-fb-icon\"],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"openTwLink\"]],[30,1],[30,2]],null]],null],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-twitter-square\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@url\",\"@shareText\"],false,[\"on\",\"fn\"]]",
    "moduleName": "nightwatch-web/components/nw-share-panel.hbs",
    "isStrictMode": false
  });

  let NwSharePanelComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class NwSharePanelComponent extends _component.default {
    openFbLink(url) {
      let link = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
      this.openSharePopup(link, 'fb');
    }

    openTwLink(url, shareText) {
      let link = `https://twitter.com/intent/tweet?url=${url}&text=${shareText}`;
      this.openSharePopup(link);
    }

    openSharePopup(url, platform) {
      var _newWindow;

      let newWindow; // Facebook opens a fixed width popup, otherwise you get stretched mobile UI in a new tab

      if (platform === 'fb') {
        newWindow = window.open(url, 'Facebook', 'location=no,toolbar=no,menubar=no,scrollbars=no,status=no, width=600, height=600');
      } else {
        newWindow = window.open(url);
      }

      (_newWindow = newWindow) === null || _newWindow === void 0 ? void 0 : _newWindow.focus();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "openFbLink", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "openFbLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openTwLink", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "openTwLink"), _class.prototype)), _class));
  _exports.default = NwSharePanelComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NwSharePanelComponent);
});