define("nightwatch-web/templates/settings/api-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sCqOyN/T",
    "block": "[[[1,[28,[35,0],[\"API Access\"],null]],[1,\"\\n\\n\"],[10,\"h2\"],[14,0,\"nw-settings__subtitle\"],[12],[1,\"API access\"],[13],[1,\"\\n\\n\"],[10,2],[12],[1,\"Integrate Nightwatch into other services by generating an access token.\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"nw-form-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"api-access-input\"],[14,\"hidden\",\"\"],[12],[1,\"API access\"],[13],[1,\"\\n  \"],[11,\"input\"],[24,1,\"api-access-input\"],[24,0,\"nw-form__input nw-form__input--api-access\"],[24,\"readonly\",\"\"],[16,2,[30,0,[\"apiAccessToken\"]]],[24,4,\"text\"],[4,[38,1],[\"click\",[30,0,[\"selectContents\"]]],null],[12],[13],[1,\"\\n\"],[41,[30,0,[\"apiAccessToken\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"nw-button nw-button--danger\"],[16,\"disabled\",[30,0,[\"isSubmitting\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"revoke\"]]],null],[12],[1,\"\\n      \"],[1,[52,[30,0,[\"isSubmitting\"]],\"Revoking…\",\"Revoke\"]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,\"button\"],[24,0,\"nw-button nw-button--submit\"],[16,\"disabled\",[30,0,[\"isSubmitting\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"generate\"]]],null],[12],[1,\"\\n      \"],[1,[52,[30,0,[\"isSubmitting\"]],\"Generating…\",\"Generate\"]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"on\",\"if\"]]",
    "moduleName": "nightwatch-web/templates/settings/api-access.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});