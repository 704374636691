define("nightwatch-web/templates/dashboard/url/backlinks/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UhJeQOy8",
    "block": "[[[1,[28,[35,0],[\"Add Links\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"main-area-row bottom-border clearfix\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Add Links\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,2],null,[[\"@url\",\"@onBacklinksCreated\"],[[30,1],[30,0,[\"onBacklinksCreated\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\\n\"]],[\"@model\"],false,[\"page-title\",\"nw-page-content\",\"backlinks/backlink-form\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/backlinks/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});