define("nightwatch-web/components/contextual-panel/serp-preview/live/knowledge-panel-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-serps__kp-result-title">{{this.result.title}}</div>
  <div class="nw-serps__kp-result-btns">
    <a href="{{this.result.url}}" target="_blank" rel="noopener noreferrer" class="nw-serps__kp-result-btn">Website</a>
    <a href="https://maps.google.com/?cid={{this.result.cid}}" target="_blank" rel="noopener noreferrer" class="nw-serps__kp-result-btn">Directions</a>
    <span class="nw-serps__kp-result-btn">Save</span>
  </div>
  <div class="nw-serps__kp-result-url">
    <a href={{this.result.url}} target="_blank" rel="noopener noreferrer" class="nw-serps__kp-result-link">{{this.result.url}}</a>
  </div>
  <div class="nw-serps__kp-result-icon">
    <NwIcon @icon="icon-knowledge-panel" />
    <NwTooltip @placement="left">Knowledge Panel</NwTooltip>
  </div>
  
  */
  {
    "id": "AFAZ6p4c",
    "block": "[[[10,0],[14,0,\"nw-serps__kp-result-title\"],[12],[1,[30,0,[\"result\",\"title\"]]],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__kp-result-btns\"],[12],[1,\"\\n  \"],[10,3],[15,6,[29,[[30,0,[\"result\",\"url\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"nw-serps__kp-result-btn\"],[12],[1,\"Website\"],[13],[1,\"\\n  \"],[10,3],[15,6,[29,[\"https://maps.google.com/?cid=\",[30,0,[\"result\",\"cid\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"nw-serps__kp-result-btn\"],[12],[1,\"Directions\"],[13],[1,\"\\n  \"],[10,1],[14,0,\"nw-serps__kp-result-btn\"],[12],[1,\"Save\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__kp-result-url\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"result\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"nw-serps__kp-result-link\"],[12],[1,[30,0,[\"result\",\"url\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__kp-result-icon\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\"],[\"icon-knowledge-panel\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@placement\"],[\"left\"]],[[\"default\"],[[[[1,\"Knowledge Panel\"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"nw-icon\",\"nw-tooltip\"]]",
    "moduleName": "nightwatch-web/components/contextual-panel/serp-preview/live/knowledge-panel-result.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['nw-serps__kp-result'],
    classNameBindings: ['result.isMatching:nw-serps__result--highlighted'],
    result: null
  }));

  _exports.default = _default;
});