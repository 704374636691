define("nightwatch-web/services/theme", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const THEMES = {
    BRIGHT: 'bright',
    DARK: 'dark'
  };

  const detectThemePreference = () => {
    var _window;

    return (_window = window) !== null && _window !== void 0 && _window.matchMedia('(prefers-color-scheme: dark)').matches ? THEMES.DARK : THEMES.BRIGHT;
  };

  let ThemeService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), (_class = class ThemeService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);

      _initializerDefineProperty(this, "_current", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      this.current = detectThemePreference();
    }

    get current() {
      return this._current;
    }

    set current(value) {
      this._current = value !== null && value !== void 0 ? value : THEMES.DARK;
      this.update();
    }

    get isDark() {
      return this.current === THEMES.DARK;
    }

    get isBright() {
      return this.current === THEMES.BRIGHT;
    }

    afterLoadUser(user) {
      var _user$settings;

      if (!(user !== null && user !== void 0 && user.id)) return;
      this.current = user === null || user === void 0 ? void 0 : (_user$settings = user.settings) === null || _user$settings === void 0 ? void 0 : _user$settings.theme;
    }

    *switch() {
      var _this$session$user$se, _this$session$user;

      const theme = this.current === THEMES.DARK ? THEMES.BRIGHT : THEMES.DARK;
      this.current = theme;
      this.session.user.settings = _objectSpread(_objectSpread({}, (_this$session$user$se = (_this$session$user = this.session.user) === null || _this$session$user === void 0 ? void 0 : _this$session$user.settings) !== null && _this$session$user$se !== void 0 ? _this$session$user$se : {}), {}, {
        theme
      });
      this.metrics.trackEvent({
        event: 'Switched Theme',
        theme
      });
      yield (0, _emberConcurrency.timeout)(1500);
      yield this.session.user.save();
    }

    update() {
      const previous = this.current === THEMES.DARK ? THEMES.BRIGHT : THEMES.DARK;
      const body = document.querySelector('body');
      body.classList.remove(previous);
      body.classList.add(this.current);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_current", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "switch", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "switch"), _class.prototype)), _class));
  _exports.default = ThemeService;
});