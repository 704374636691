define("nightwatch-web/components/site-audit/page-cell-simple-content", ["exports", "ember-awesome-macros", "ember-awesome-macros/raw"], function (_exports, _emberAwesomeMacros, _raw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TruncateWithTooltip data-test-truncate-with-tooltip @message={{this.valueOrSlashDisplay}}>
    {{this.valueOrSlashDisplay}}
  </TruncateWithTooltip>
  
  */
  {
    "id": "/RSHZINV",
    "block": "[[[8,[39,0],null,[[\"@message\"],[[30,0,[\"valueOrSlashDisplay\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,0,[\"valueOrSlashDisplay\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"truncate-with-tooltip\"]]",
    "moduleName": "nightwatch-web/components/site-audit/page-cell-simple-content.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    page: null,
    field: null,
    tagName: '',
    valueOrSlashDisplay: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.isEmpty)((0, _emberAwesomeMacros.getBy)('page', 'field')), (0, _raw.default)('/'), (0, _emberAwesomeMacros.getBy)('page', 'field'))
  }));

  _exports.default = _default;
});