define("nightwatch-web/templates/maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CMWKn6Sc",
    "block": "[[[1,[28,[35,0],[\"Maintenance\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"splash-blurred-background\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"splash-blurred-cta-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"blurred-cta\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"We are currently performing system upgrades.\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"info\"],[12],[1,\"\\n      We'll be back shortly.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[\"page-title\"]]",
    "moduleName": "nightwatch-web/templates/maintenance.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});