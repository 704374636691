define("nightwatch-web/mixins/graph-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and
  } = Ember.computed;

  var _default = Ember.Mixin.create({
    queryParams: ['serpPreviewKeywordId'],
    keywordResultsSeries: null,
    serpPreviewKeywordModel: null,
    serpPreviewTimestamp: null,

    get previewPoint() {
      const {
        chartObject,
        keywordResultsSeries,
        serpPreviewTimestamp
      } = this;

      if (!chartObject || !keywordResultsSeries) {
        return null;
      }

      const isHcSeries = keywordResultsSeries[0] && keywordResultsSeries[0].onMouseOut; // Here, series can be either from Higchcharts (coming from  toggleSerpPreviewInitially,
      // where graph automatically opens SERP preview - new route) or normal series from the seriesContainer (graph route)

      if (isHcSeries) {
        return keywordResultsSeries.find(point => point.x === serpPreviewTimestamp);
      } else {
        const keywordResultsSeriesId = keywordResultsSeries.get('seriesId');
        const series = chartObject.series.filter(s => s.options.seriesId === keywordResultsSeriesId || s.options.parentSeriesId === keywordResultsSeriesId) || [];
        return [].concat(...series.mapBy('data')).find(point => point ? point.x === serpPreviewTimestamp && !point.isNull : false);
      }
    },

    isSerpPreviewOpened: and('serpPreviewKeywordModel', 'serpPreviewTimestamp'),

    selectPreviewPoint(state) {
      const point = this.previewPoint;

      if (point) {
        point.select(state);
      }
    },

    waitForKeywordPositionSeries(keywordId) {
      const controller = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        const timeout = 60 * 1000; // 1min

        const period = 100;
        let waitedFor = 0;

        const _check = () => {
          const chartObject = controller.chartObject;
          let keywordPositionSeries = [];

          if (chartObject && chartObject.series) {
            keywordPositionSeries = chartObject.series.find(s => {
              return (s.options.seriesId || '').includes(`keyword_${keywordId}_position`);
            });
          }

          if (keywordPositionSeries && keywordPositionSeries.data && keywordPositionSeries.data.length > 0) {
            resolve(keywordPositionSeries);
          } else {
            Ember.run.later(() => {
              waitedFor += period;

              if (waitedFor > timeout) {
                reject();
              } else {
                _check();
              }
            }, 150);
          }
        };

        _check();
      });
    },

    toggleSerpPreviewInitially(keywordId) {
      const keywordPromise = this.store.findRecord('keyword', keywordId);
      const keywordSeriesPromise = this.waitForKeywordPositionSeries(keywordId);
      const waitForSerpPreviewData = Ember.RSVP.all([keywordPromise, keywordSeriesPromise]);
      waitForSerpPreviewData.then(([keyword, keywordSeries]) => {
        const point = keywordSeries.data[keywordSeries.data.length - 1];

        if (point && point.x) {
          const timestamp = point.x;
          this.toggleSerpPreview(keyword, timestamp, keywordSeries.data.concat());
        }
      });
    },

    updatePreview: Ember._action(function (newPreviewKeyword, newserpPreviewTimestamp, newkeywordResultsSeries) {
      const {
        serpPreviewKeywordModel,
        serpPreviewTimestamp
      } = this;
      const isSamePoint = serpPreviewKeywordModel && newPreviewKeyword && newPreviewKeyword.get('id') === serpPreviewKeywordModel.get('id') && newserpPreviewTimestamp === serpPreviewTimestamp;
      this.setProperties({
        serpPreviewKeywordModel: isSamePoint ? null : newPreviewKeyword,
        serpPreviewTimestamp: isSamePoint ? null : newserpPreviewTimestamp,
        keywordResultsSeries: isSamePoint ? null : newkeywordResultsSeries
      });
    }),
    toggleSerpPreview: Ember._action(function (serpPreviewKeywordModel, serpPreviewTimestamp, keywordResultsSeries) {
      this.selectPreviewPoint(false);
      this.updatePreview(serpPreviewKeywordModel, serpPreviewTimestamp, keywordResultsSeries);
      this.selectPreviewPoint(true);
    })
  });

  _exports.default = _default;
});