define("nightwatch-web/components/nw-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let
    (if @v1 "nw-power-select" "nw-power-select nw-power-select--v2")
    (if @v1 "nw-power-select-dropdown" "nw-power-select-dropdown nw-power-select-dropdown--v2")
    as |wrapperClass dropdownClass|
  }}
    <div class={{wrapperClass}} ...attributes>
      <PowerSelect
        @afterOptionsComponent={{@afterOptionsComponent}}
        @closeOnSelect={{@closeOnSelect}}
        @dropdownClass={{concat dropdownClass " " @dropdownClass}}
        @extra={{@extra}}
        @initiallyOpened={{@initiallyOpened}}
        @noMatchesMessage={{@noMatchesMessage}}
        @onChange={{@onChange}}
        @onClose={{@onClose}}
        @onOpen={{@onOpen}}
        @options={{@options}}
        @placeholder={{@placeholder}}
        @renderInPlace={{@renderInPlace}}
        @search={{@search}}
        @searchEnabled={{@searchEnabled}}
        @searchField={{@searchField}}
        @selected={{@selected}}
        @selectedItemComponent={{@selectedItemComponent}} as |option|>
        {{yield option}}
      </PowerSelect>
    </div>
  {{/let}}
  
  */
  {
    "id": "QhKIpmKF",
    "block": "[[[44,[[52,[30,1],\"nw-power-select\",\"nw-power-select nw-power-select--v2\"],[52,[30,1],\"nw-power-select-dropdown\",\"nw-power-select-dropdown nw-power-select-dropdown--v2\"]],[[[1,\"  \"],[11,0],[16,0,[30,2]],[17,4],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@afterOptionsComponent\",\"@closeOnSelect\",\"@dropdownClass\",\"@extra\",\"@initiallyOpened\",\"@noMatchesMessage\",\"@onChange\",\"@onClose\",\"@onOpen\",\"@options\",\"@placeholder\",\"@renderInPlace\",\"@search\",\"@searchEnabled\",\"@searchField\",\"@selected\",\"@selectedItemComponent\"],[[30,5],[30,6],[28,[37,3],[[30,3],\" \",[30,7]],null],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14],[30,15],[30,16],[30,17],[30,18],[30,19],[30,20],[30,21]]],[[\"default\"],[[[[1,\"\\n      \"],[18,23,[[30,22]]],[1,\"\\n    \"]],[22]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2,3]]]],[\"@v1\",\"wrapperClass\",\"dropdownClass\",\"&attrs\",\"@afterOptionsComponent\",\"@closeOnSelect\",\"@dropdownClass\",\"@extra\",\"@initiallyOpened\",\"@noMatchesMessage\",\"@onChange\",\"@onClose\",\"@onOpen\",\"@options\",\"@placeholder\",\"@renderInPlace\",\"@search\",\"@searchEnabled\",\"@searchField\",\"@selected\",\"@selectedItemComponent\",\"option\",\"&default\"],false,[\"let\",\"if\",\"power-select\",\"concat\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/nw-select.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});