define("nightwatch-web/helpers/dwm-change-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dwmChangeValue = dwmChangeValue;
  _exports.default = void 0;

  function dwmChangeValue(params) {
    const changeValue = params[0];
    let displayValue;

    if (changeValue >= 10000 || changeValue <= -10000) {
      displayValue = '∞';
    } else {
      displayValue = Math.abs(changeValue);
    }

    return displayValue;
  }

  var _default = Ember.Helper.helper(dwmChangeValue);

  _exports.default = _default;
});