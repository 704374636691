define("nightwatch-web/components/stat-change", ["exports", "ember-awesome-macros", "ember-awesome-macros/raw"], function (_exports, _emberAwesomeMacros, _raw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <i
    data-test-stat-change-icon
    class="icon-{{this.directionClass}} {{if this.needInvertedClass 'color-inverted'}}">
  </i>
  <div data-test-stat-change-value class="change-number {{this.directionClass}}">
    {{#if (has-block)}}
      {{yield this.valueOrZero}}
    {{else}}
      {{this.absValue}}
    {{/if}}
  </div>
  
  */
  {
    "id": "9F/+EA6B",
    "block": "[[[10,\"i\"],[15,0,[29,[\"icon-\",[30,0,[\"directionClass\"]],\" \",[52,[30,0,[\"needInvertedClass\"]],\"color-inverted\"]]]],[12],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"change-number \",[30,0,[\"directionClass\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[30,0,[\"valueOrZero\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"absValue\"]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/stat-change.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['stat-change'],
    valueOrZero: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.isEmpty)('value'), (0, _raw.default)(0), 'value'),

    /**
     * Returns css class for an icon
     * * returns "up" if value > 0
     * * returns "down" if value < 0
     * * returns "stagnant" if value == 0 / undefined / null
     * @type {('up'|'down'|'stagnant')}
     */
    directionClass: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.gt)('valueOrZero', (0, _raw.default)(0)), (0, _raw.default)('up'), (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.lt)('valueOrZero', (0, _raw.default)(0)), (0, _raw.default)('down'), (0, _raw.default)('stagnant'))),
    absValue: _emberAwesomeMacros.math.abs('valueOrZero'),
    needInvertedClass: (0, _emberAwesomeMacros.and)('inverted', (0, _emberAwesomeMacros.notEqual)('valueOrZero', (0, _raw.default)(0)))
  }));

  _exports.default = _default;
});