define("nightwatch-web/initializers/user-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  const initialize = application => {
    application.inject('controller', 'userSettings', 'service:userSettings');
    application.inject('route', 'userSettings', 'service:userSettings');
    application.inject('component', 'userSettings', 'service:userSettings');
  };

  _exports.initialize = initialize;
  var _default = {
    name: 'user-settings',
    initialize
  };
  _exports.default = _default;
});