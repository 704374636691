define("nightwatch-web/components/nw-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (or @id (uuid)) as |id|}}
    <div class="nw-checkbox {{if @hideLabel "nw-checkbox--no-label"}}" ...attributes>
      <input
        type="checkbox"
        id={{id}}
        class="nw-checkbox__input"
        checked={{@checked}}
        {{on "change" (fn (optional @onChange) (not @checked))}}
      />
      <label for={{id}} class="nw-checkbox__label">
        {{yield}}
        <NwIcon class="nw-checkbox__mark" @icon="svg-checkmark" />
      </label>
    </div>
  {{/let}}
  
  */
  {
    "id": "Ssy9Sau0",
    "block": "[[[44,[[28,[37,1],[[30,1],[28,[37,2],null,null]],null]],[[[1,\"  \"],[11,0],[16,0,[29,[\"nw-checkbox \",[52,[30,3],\"nw-checkbox--no-label\"]]]],[17,4],[12],[1,\"\\n    \"],[11,\"input\"],[16,1,[30,2]],[24,0,\"nw-checkbox__input\"],[16,\"checked\",[30,5]],[24,4,\"checkbox\"],[4,[38,4],[\"change\",[28,[37,5],[[28,[37,6],[[30,6]],null],[28,[37,7],[[30,5]],null]],null]],null],[12],[13],[1,\"\\n    \"],[10,\"label\"],[15,\"for\",[30,2]],[14,0,\"nw-checkbox__label\"],[12],[1,\"\\n      \"],[18,7,null],[1,\"\\n      \"],[8,[39,9],[[24,0,\"nw-checkbox__mark\"]],[[\"@icon\"],[\"svg-checkmark\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]]]],[\"@id\",\"id\",\"@hideLabel\",\"&attrs\",\"@checked\",\"@onChange\",\"&default\"],false,[\"let\",\"or\",\"uuid\",\"if\",\"on\",\"fn\",\"optional\",\"not\",\"yield\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/nw-checkbox.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});