define("nightwatch-web/templates/dashboard/url/settings/site-audit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c2uIk7Fs",
    "block": "[[[1,[28,[35,0],[[30,1,[\"friendlyUrl\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Site Audit Settings\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@url\",\"@user\",\"@startCrawling\",\"@saveAndStartCrawling\",\"@startingCrawlOperation\",\"@saveUrl\"],[[30,1],[30,0,[\"session\",\"user\"]],[30,0,[\"startCrawling\"]],[30,0,[\"saveAndStartCrawling\"]],[30,0,[\"startingCrawlOperation\"]],[30,0,[\"saveUrl\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"url-site-audit-settings\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/settings/site-audit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});