define("nightwatch-web/controllers/dashboard/url/backlinks/index", ["exports", "nightwatch-web/mixins/backlink-view-controller", "lodash-es/throttle", "nightwatch-web/utils/refresh-route", "nightwatch-web/utils/is-testing"], function (_exports, _backlinkViewController, _throttle, _refreshRoute, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlBacklinksIndexController = (_dec = Ember.inject.service, _dec2 = Ember.computed.not('initialFetchingDone'), _dec3 = Ember.computed('url.backlinksStatus.@each'), _dec4 = Ember.computed('initialFetchingDone', 'url.backlinksStatus.@each'), _dec5 = Ember.computed('session.user.backlinksRemaining'), (_class = class DashboardUrlBacklinksIndexController extends Ember.Controller.extend(_backlinkViewController.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "isLoading", false);

      _initializerDefineProperty(this, "showProgress", _descriptor2, this);
    }

    get initialFetchingDone() {
      return Ember.isPresent(this.get('url.backlinksStatus.ahrefs_initial_fetching_done_at'));
    }

    get progressPercent() {
      const count = this.get('url.backlinksCount');
      const total = this.get('url.backlinksStatus.backlinks_total');

      if (count === 0) {
        return 100;
      }

      const percent = 100 * (count / total);

      if (percent == null || percent < 3) {
        return 3;
      } else {
        return percent;
      }
    }

    get limitReached() {
      return this.get('session.user.backlinksRemaining') <= 0;
    }

    handleProgress() {
      if (this.isPeriodicallyRefreshing) {
        return;
      }

      const _refresh = () => {
        (0, _refreshRoute.default)(this, 'dashboard.url.backlinks.index');
        this.fetchStats();
        const {
          user
        } = this.session;
        user.reload().then(() => {
          this.backlinksNotifications.notifyLimitReached(user);
        });
      };

      const throttledRefresh = (0, _throttle.default)(_refresh, 10000);

      const _check = url => {
        if (_isTesting.default) return;

        if (!this.progressCheckingEnabled) {
          return;
        }

        if (this.initialFetchingDone || this.limitReached) {
          this.set('isPeriodicallyRefreshing', false);
          return;
        }

        url.reload().then(url => {
          const newBacklinksCount = url.get('backlinksCount');

          if (newBacklinksCount > this.currentBacklinksTmp) {
            throttledRefresh();
          }

          this.set('currentBacklinksTmp', newBacklinksCount);
          Ember.run.later(() => {
            _check(url);
          }, 2000);
        });
      };

      const url = this.url;
      this.set('currentBacklinksTmp', url.get('backlinksCount'));
      this.set('isPeriodicallyRefreshing', true);

      _check(url);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showProgress", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initialFetchingDone", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "initialFetchingDone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "progressPercent", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "progressPercent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "limitReached", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "limitReached"), _class.prototype)), _class));
  _exports.default = DashboardUrlBacklinksIndexController;
});