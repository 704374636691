define("nightwatch-web/templates/dashboard/dynamic-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZYghPq5i",
    "block": "[[[1,[28,[35,0],[[30,1,[\"name\"]]],null]],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/dynamic-view.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});