define("nightwatch-web/routes/application", ["exports", "nightwatch-web/utils/is-testing", "nightwatch-web/config/environment", "nprogress", "nightwatch-web/utils/generate-user-metrics"], function (_exports, _isTesting, _environment, _nprogress, _generateUserMetrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class ApplicationRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "faye", _descriptor2, this);

      _initializerDefineProperty(this, "fetch", _descriptor3, this);

      _initializerDefineProperty(this, "intercom", _descriptor4, this);

      _initializerDefineProperty(this, "mixpanel", _descriptor5, this);

      _initializerDefineProperty(this, "metrics", _descriptor6, this);

      _initializerDefineProperty(this, "errorReporter", _descriptor7, this);

      _initializerDefineProperty(this, "updateVersionChecker", _descriptor8, this);

      _initializerDefineProperty(this, "userData", _descriptor9, this);

      _initializerDefineProperty(this, "session", _descriptor10, this);

      _initializerDefineProperty(this, "siteData", _descriptor11, this);
    }

    beforeModel(transition) {
      if (transition.targetName.includes('maintenance')) {
        return;
      }

      if (transition.to.queryParams.t) {
        this.session.ingestQueryParamToken(transition.to.queryParams.t);
      }

      if (!this.session.isAuthenticated) {
        return this.siteData.load();
      } // user promise must be returned here so we ensure user is loaded before dashboard activates


      return this.loadUser(transition);
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      if (_isTesting.default) return;
      this.updateVersionChecker.get('checkNewVersion').perform();
    }

    async loadUser(transition) {
      let user;

      try {
        user = await this.session.loadUser.perform();
      } catch (error) {
        this.session.invalidate();
        this.handleUnauthenticated(transition);
        return;
      }

      this.redirectUser(user, transition);
      Ember.run.scheduleOnce('afterRender', () => this.setUpServices(user));
    }

    async handleUnauthenticated(transition) {
      await this.siteData.load();

      if (transition.targetName !== 'login') {
        this.router.transitionTo('login');
      }
    }

    redirectUser(user, transition) {
      if (!user.get('confirmed')) {
        if (transition.targetName !== 'confirm-account') {
          this.router.transitionTo('confirm-account');
        }
      } else {
        if (['dashboard', 'dashboard.index', 'login'].includes(transition.targetName)) {
          this.router.transitionTo('dashboard.overview');
        }
      }
    }

    setUpServices(user) {
      const userMetrics = (0, _generateUserMetrics.default)(user, this.siteData);
      const {
        userId,
        email
      } = userMetrics;
      this.setupGA(userId);
      this.setupGTAG(userId);
      this.mixpanel.afterLogin(userMetrics);
      this.intercom.afterLogin(userMetrics);
      this.faye.handlePushUpdates({
        onKeywordPositionUpdate: () => Ember.run.debounce(this, 'refreshUrlOverviewWithFallback', 850)
      });
      this.userData.save();
      this.errorReporter.set('person', {
        id: userId,
        email
      });
      this.session.trackLogin.perform();
    }

    setupGA(userId) {
      if (this.session.isAdminViewingUser) return;
      this.metrics.identify('GoogleAnalytics', {
        distinctId: userId
      });
    }

    setupGTAG(userId) {
      if (this.session.isAdminViewingUser || !window.gtag) return;

      const {
        config
      } = _environment.default.metricsAdapters.findBy('name', 'GoogleAnalytics');

      window.gtag('config', config.id, {
        custom_map: {
          dimension2: 'user_id'
        }
      });
      window.gtag('event', 'user_id_dimension', {
        user_id: userId
      });
    }

    refreshUrlOverview() {
      const keywordsIndexController = this.controller.get('dashboardUrlKeywordsIndex');

      if (keywordsIndexController && keywordsIndexController.fetchKeywordStats) {
        keywordsIndexController.fetchKeywordStats();
        keywordsIndexController.showKeywordNotifications();
      }
    }

    refreshUrlOverviewWithFallback() {
      if (_isTesting.default) return;
      this.refreshUrlOverview(); // Refresh again to give some time for the Query API to adjust
      // FIXME: The Query API should notify us when the data is ready

      Ember.run.debounce(this, 'refreshUrlOverview', 5000);
    }

    loading(transition) {
      _nprogress.default.start();

      transition.promise.finally(() => {
        _nprogress.default.done();
      });
      return true;
    }

    error(error) {
      var _error$errors, _error$errors$firstOb;

      console.error(error);
      const status = error === null || error === void 0 ? void 0 : (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : (_error$errors$firstOb = _error$errors.firstObject) === null || _error$errors$firstOb === void 0 ? void 0 : _error$errors$firstOb.status;

      if (status === '503') {
        window.location = '/maintenance';
        return;
      } else if (status === '401') {
        this.session.invalidate();
        window.location = '/';
        return;
      }

      this.errorReporter.error(error);
      this.router.transitionTo('error');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "faye", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intercom", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "mixpanel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "errorReporter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "updateVersionChecker", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "userData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "siteData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});