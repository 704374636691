define("nightwatch-web/components/graph/nw-chart", ["exports", "@glimmer/component", "chart.js"], function (_exports, _component, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <canvas
    ...attributes
    {{in-viewport onEnter=this.didEnterViewport}}
    {{did-update this.updateChart @chartOptions}}
    {{will-destroy this.destroyChart}}
  ></canvas>
  
  */
  {
    "id": "J+DbWnIk",
    "block": "[[[11,\"canvas\"],[17,1],[4,[38,0],null,[[\"onEnter\"],[[30,0,[\"didEnterViewport\"]]]]],[4,[38,1],[[30,0,[\"updateChart\"]],[30,2]],null],[4,[38,2],[[30,0,[\"destroyChart\"]]],null],[12],[13],[1,\"\\n\"]],[\"&attrs\",\"@chartOptions\"],false,[\"in-viewport\",\"did-update\",\"will-destroy\"]]",
    "moduleName": "nightwatch-web/components/graph/nw-chart.hbs",
    "isStrictMode": false
  });

  /**
    @class NwChartComponent
  
    Renders Chart.js charts
  
    @argument chartOptions {Object} Chart.js data and options
   **/
  let NwChartComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class NwChartComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "hasEnteredViewport", false);

      _defineProperty(this, "chart", null);
    }

    // Can be accessed from the Ember Inspector for debugging
    didEnterViewport(element) {
      if (this.hasEnteredViewport) return;
      this.hasEnteredViewport = true;
      this.renderGraph(element);
    }

    updateChart() {
      if (!this.chart) return;
      Ember.run.scheduleOnce('afterRender', () => {
        const {
          data,
          options
        } = this.args.chartOptions;
        this.chart.data = data;
        this.chart.options = options;
        this.chart.update();
      });
    }

    renderGraph(element) {
      if (!element) return;
      Ember.run.scheduleOnce('afterRender', () => {
        this.chart = new _chart.Chart(element, this.args.chartOptions);
      });
    }

    destroyChart() {
      var _this$chart, _this$chart$destroy;

      (_this$chart = this.chart) === null || _this$chart === void 0 ? void 0 : (_this$chart$destroy = _this$chart.destroy) === null || _this$chart$destroy === void 0 ? void 0 : _this$chart$destroy.call(_this$chart);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didEnterViewport", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didEnterViewport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateChart", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderGraph", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "renderGraph"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyChart", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "destroyChart"), _class.prototype)), _class));
  _exports.default = NwChartComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NwChartComponent);
});