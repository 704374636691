define("nightwatch-web/utils/filters/filter-group-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    isLast: Ember.computed('filterConfig.filterGroups.[]', function () {
      return this.get('filterConfig.filterGroups.lastObject.id') === this.id;
    })
  });

  _exports.default = _default;
});