define("nightwatch-web/helpers/subtract-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subtractNumber = subtractNumber;
  _exports.default = void 0;

  function subtractNumber([a, b]) {
    return parseInt(a) - parseInt(b);
  }

  var _default = Ember.Helper.helper(subtractNumber);

  _exports.default = _default;
});