define("nightwatch-web/components/user-notifications/user-notifications-pagination", ["exports", "nightwatch-web/components/keyword-list-pagination"], function (_exports, _keywordListPagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.paginationShown}}
    <ul class="nw-pagination__items">
      {{#if this.previousPage}}
        <li class="nw-pagination__item">
          <LinkTo @route="notifications" @query={{hash page=this.previousPage}} class="nw-pagination__link nw-pagination__link--caret">
            <NwIcon @icon="icon-caret-left" alt="Previous" />
          </LinkTo>
        </li>
      {{/if}}
      {{#each this.pagesList as |page|}}
        <li class="nw-pagination__item">
          {{#if page}}
            <LinkTo @route="notifications" @query={{hash page=page}} class="nw-pagination__link">{{page}}</LinkTo>
          {{else}}
            <span class="nw-pagination__link nw-pagination__link--ellipsis">…</span>
          {{/if}}
        </li>
      {{/each}}
      {{#if this.nextPage}}
        <li class="nw-pagination__item">
          <LinkTo @route="notifications" @query={{hash page=this.nextPage}} class="nw-pagination__link nw-pagination__link--caret">
            <NwIcon @icon="icon-caret-right" alt="Next" />
          </LinkTo>
        </li>
      {{/if}}
    </ul>
  {{/if}}
  
  */
  {
    "id": "jk0nN7GV",
    "block": "[[[41,[30,0,[\"paginationShown\"]],[[[1,\"  \"],[10,\"ul\"],[14,0,\"nw-pagination__items\"],[12],[1,\"\\n\"],[41,[30,0,[\"previousPage\"]],[[[1,\"      \"],[10,\"li\"],[14,0,\"nw-pagination__item\"],[12],[1,\"\\n        \"],[8,[39,1],[[24,0,\"nw-pagination__link nw-pagination__link--caret\"]],[[\"@route\",\"@query\"],[\"notifications\",[28,[37,2],null,[[\"page\"],[[30,0,[\"previousPage\"]]]]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,3],[[24,\"alt\",\"Previous\"]],[[\"@icon\"],[\"icon-caret-left\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"pagesList\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"nw-pagination__item\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"          \"],[8,[39,1],[[24,0,\"nw-pagination__link\"]],[[\"@route\",\"@query\"],[\"notifications\",[28,[37,2],null,[[\"page\"],[[30,1]]]]]],[[\"default\"],[[[[1,[30,1]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[14,0,\"nw-pagination__link nw-pagination__link--ellipsis\"],[12],[1,\"…\"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[1]],null],[41,[30,0,[\"nextPage\"]],[[[1,\"      \"],[10,\"li\"],[14,0,\"nw-pagination__item\"],[12],[1,\"\\n        \"],[8,[39,1],[[24,0,\"nw-pagination__link nw-pagination__link--caret\"]],[[\"@route\",\"@query\"],[\"notifications\",[28,[37,2],null,[[\"page\"],[[30,0,[\"nextPage\"]]]]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,3],[[24,\"alt\",\"Next\"]],[[\"@icon\"],[\"icon-caret-right\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"page\"],false,[\"if\",\"link-to\",\"hash\",\"nw-icon\",\"each\",\"-track-array\"]]",
    "moduleName": "nightwatch-web/components/user-notifications/user-notifications-pagination.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _keywordListPagination.default.extend());

  _exports.default = _default;
});