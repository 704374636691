define("nightwatch-web/router", ["exports", "nightwatch-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Router = (_dec = Ember.inject.service, (_class = class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);

      _initializerDefineProperty(this, "metrics", _descriptor, this);
    }

    init() {
      super.init(...arguments);
      this.on('routeDidChange', () => {
        Ember.run.scheduleOnce('afterRender', this, () => {
          const page = this.url;
          const title = this.currentRouteName || 'unknown';
          this.metrics.trackPage('GoogleAnalytics', {
            page,
            title
          });
          this.metrics.trackPage('Intercom', {
            page,
            title
          });
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Router;
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('start', function () {
      this.route('url');
      this.route('keywords', {
        path: ':url_id/keywords'
      });
      this.route('competitors', {
        path: ':url_id/competitors'
      });
      this.route('integrations', {
        path: ':url_id/integrations'
      });
    });
    this.route('maintenance');
    this.route('plans', function () {
      this.route('cancel');
    });
    this.route('update-payment');
    this.route('plan', {
      path: 'plans/:plan_id'
    });
    this.route('plan-success', {
      path: 'plans/:plan_id/success'
    });
    this.route('login');
    this.route('signup');
    this.route('password-reset');
    this.route('invoices');
    this.route('settings', function () {
      this.route('profile');
      this.route('subusers', {
        path: 'users'
      }, function () {
        this.route('subuser', {
          path: '/:subuser_id'
        });
        this.route('new');
      });
      this.route('api-access');
      this.route('billing');
      this.route('whitelabel');
      this.route('integrations');
      this.route('privacy');
      this.route('notes');
    });
    this.route('dashboard', {
      path: '/'
    }, function () {
      this.route('overview');
      this.route('url', {
        path: 'urls/:url_id'
      }, function () {
        this.route('dashboard');
        this.route('keyword', {
          path: 'keywords/:keyword_id'
        });
        this.route('keywords', function () {
          this.route('new');
          this.route('add', function () {
            this.route('manual');
            this.route('discover');
          });
        });
        this.route('dynamic-view', {
          path: 'views/:dynamic_view_id'
        }, function () {
          this.route('settings');
        });
        this.route('dynamic-views', {
          path: 'views/'
        }, function () {
          this.route('new');
        });
        this.route('settings', function () {
          this.route('configuration', function () {
            this.route('delete');
          });
          this.route('competitors');
          this.route('site-audit');
          this.route('backlinks');
        });
        this.route('report', {
          path: 'reports/:report_id'
        });
        this.route('reports', function () {
          this.route('new');
        });
        this.route('notes');
        this.route('graphs', function () {
          this.route('new');
        });
        this.route('graph', {
          path: 'graphs/:graph_id'
        }, function () {
          this.route('settings');
        });
        this.route('backlink-view', {
          path: 'backlink-views/:backlink_view_id'
        });
        this.route('backlink-view-settings', {
          path: 'backlink-views/:backlink_view_id/settings'
        });
        this.route('backlink-view-new', {
          path: 'backlink-views/new'
        });
        this.route('backlinks', function () {
          this.route('disabled');
          this.route('enable');
          this.route('new');
        });
        this.route('site-audit-disabled');
        this.route('site-audit', function () {
          this.route('all', {
            path: '/'
          }, function () {
            this.route('checks', function () {
              this.route('show', {
                path: '/:audit_check_id'
              });
            });
          });
          this.route('view', {
            path: 'views/:view_id'
          }, function () {
            this.route('show', {
              path: '/'
            });
            this.route('edit');
            this.route('checks', function () {
              this.route('show', {
                path: '/:audit_check_id'
              });
            });
            this.route('settings');
          });
          this.route('views', function () {
            this.route('new');
          });
        });
      });
      this.route('dynamic-view', {
        path: 'views/:dynamic_view_id'
      }, function () {
        this.route('keywords');
        this.route('settings');
        this.route('report', {
          path: 'reports/:report_id'
        });
        this.route('reports', function () {
          this.route('new');
        });
      });
      this.route('dynamic-views', {
        path: 'views/'
      }, function () {
        this.route('new');
      });
      this.route('reports', function () {
        this.route('new');
      });
    });
    this.route('error');
    this.route('confirm-account');
    this.route('changelog');
    this.route('notifications'); // KEEP THIS AT THE END !!!

    this.route('404', {
      path: '/*path'
    });
  });
});