define("nightwatch-web/helpers/abs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.abs = void 0;

  const abs = params => {
    if (params[0] === null || params[0] === undefined) return null;
    return Math.abs(params[0]);
  };

  _exports.abs = abs;

  var _default = Ember.Helper.helper(abs);

  _exports.default = _default;
});