define("nightwatch-web/components/contextual-panel/serp-preview/live/organic-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-serps__organic-result-title">
    {{#if (and this.showTextChange this.result.titleDiff this.result.title)}}
      <DiffWords
        class="nw-serps__organic-result-link"
        target="_blank"
        rel="noopener noreferrer"
        href={{this.result.url}}
        @tagName="a"
        @oldText={{this.result.titleDiff}}
        @newText={{this.result.title}} />
    {{else}}
      <a href={{this.result.url}} target="_blank" rel="noopener noreferrer" class="nw-serps__organic-result-link">{{this.result.title}}</a>
    {{/if}}
  </div>
  <div class="nw-serps__organic-result-href">{{this.result.url}}</div>
  {{#if (and this.showTextChange this.result.descriptionDiff this.result.description)}}
    <DiffWords
      class="nw-serps__organic-result-description"
      @oldText={{this.result.descriptionDiff}}
      @newText={{this.result.description}} />
  {{else}}
    <div class="nw-serps__organic-result-description">{{this.result.description}}</div>
  {{/if}}
  <div class="nw-serps__organic-result-stats">
    <b class="nw-serps__organic-result-rank">{{this.result.rank}}.</b>
    {{#if this.result.rankDiff}}
      <span class="nw-serps__organic-result-rankdiff nw-serps__organic-result-rankdiff--{{this.result.rankDiff.change}}">{{this.result.rankDiff.amount}}</span>
    {{/if}}
  </div>
  
  */
  {
    "id": "J6ZEEIrI",
    "block": "[[[10,0],[14,0,\"nw-serps__organic-result-title\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"showTextChange\"]],[30,0,[\"result\",\"titleDiff\"]],[30,0,[\"result\",\"title\"]]],null],[[[1,\"    \"],[8,[39,2],[[24,0,\"nw-serps__organic-result-link\"],[24,\"target\",\"_blank\"],[24,\"rel\",\"noopener noreferrer\"],[16,6,[30,0,[\"result\",\"url\"]]]],[[\"@tagName\",\"@oldText\",\"@newText\"],[\"a\",[30,0,[\"result\",\"titleDiff\"]],[30,0,[\"result\",\"title\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,3],[15,6,[30,0,[\"result\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"nw-serps__organic-result-link\"],[12],[1,[30,0,[\"result\",\"title\"]]],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__organic-result-href\"],[12],[1,[30,0,[\"result\",\"url\"]]],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"showTextChange\"]],[30,0,[\"result\",\"descriptionDiff\"]],[30,0,[\"result\",\"description\"]]],null],[[[1,\"  \"],[8,[39,2],[[24,0,\"nw-serps__organic-result-description\"]],[[\"@oldText\",\"@newText\"],[[30,0,[\"result\",\"descriptionDiff\"]],[30,0,[\"result\",\"description\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"nw-serps__organic-result-description\"],[12],[1,[30,0,[\"result\",\"description\"]]],[13],[1,\"\\n\"]],[]]],[10,0],[14,0,\"nw-serps__organic-result-stats\"],[12],[1,\"\\n  \"],[10,\"b\"],[14,0,\"nw-serps__organic-result-rank\"],[12],[1,[30,0,[\"result\",\"rank\"]]],[1,\".\"],[13],[1,\"\\n\"],[41,[30,0,[\"result\",\"rankDiff\"]],[[[1,\"    \"],[10,1],[15,0,[29,[\"nw-serps__organic-result-rankdiff nw-serps__organic-result-rankdiff--\",[30,0,[\"result\",\"rankDiff\",\"change\"]]]]],[12],[1,[30,0,[\"result\",\"rankDiff\",\"amount\"]]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"if\",\"and\",\"diff-words\"]]",
    "moduleName": "nightwatch-web/components/contextual-panel/serp-preview/live/organic-result.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['nw-serps__organic-result'],
    classNameBindings: ['result.isMatching:nw-serps__result--highlighted'],
    result: null
  }));

  _exports.default = _default;
});