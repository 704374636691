define("nightwatch-web/serializers/note", ["exports", "nightwatch-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    isNewSerializerAPI: true,
    serializeIntoHash: function (data, type, record) {
      this._super(...arguments);

      if (record.belongsTo('url')) {
        data.url_id = record.belongsTo('url').id;
        data.note.search_keyword_url_id = data.url_id;
      }
    }
  });

  _exports.default = _default;
});