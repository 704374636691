define("nightwatch-web/components/plans/widget", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="plans_widget">
    <div class="triple-offset-top font--l">
      <Plans::PeriodToggler @periods={{this.planPeriods}} @activePeriod={{this.activePeriod}} />
    </div>
  
    <div class="triple-offset-top triple-offset-bottom">
      <Plans::LayoutCards
        @plans={{this.periodPlans}}
        @activePlanPeriod={{this.activePeriod}}
        @user={{@user}}
        @activePlan={{this.activePlan}}
      >
        <:cancel as |planId|>
          {{yield planId to="cancel"}}
        </:cancel>
        <:subscribe as |planId|>
          {{yield planId to="subscribe"}}
        </:subscribe>
      </Plans::LayoutCards>
    </div>
  </div>
  
  */
  {
    "id": "RgLvtEnf",
    "block": "[[[10,0],[14,0,\"plans_widget\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"triple-offset-top font--l\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@periods\",\"@activePeriod\"],[[30,0,[\"planPeriods\"]],[30,0,[\"activePeriod\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"triple-offset-top triple-offset-bottom\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@plans\",\"@activePlanPeriod\",\"@user\",\"@activePlan\"],[[30,0,[\"periodPlans\"]],[30,0,[\"activePeriod\"]],[30,1],[30,0,[\"activePlan\"]]]],[[\"cancel\",\"subscribe\"],[[[[1,\"\\n        \"],[18,4,[[30,2]]],[1,\"\\n      \"]],[2]],[[[1,\"\\n        \"],[18,5,[[30,3]]],[1,\"\\n      \"]],[3]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@user\",\"planId\",\"planId\",\"&cancel\",\"&subscribe\"],false,[\"plans/period-toggler\",\"plans/layout-cards\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/plans/widget.hbs",
    "isStrictMode": false
  });

  let PlansWidget = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.computed('activePeriod'), (_class = class PlansWidget extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "activePeriod", _descriptor, this);

      _initializerDefineProperty(this, "activePlan", _descriptor2, this);

      _defineProperty(this, "planPeriods", [{
        name: 'monthly',
        label: 'Monthly'
      }, {
        name: 'yearly',
        label: 'Annually',
        tag: '(Saves up to 40%)'
      }]);
    }

    get periodPlans() {
      return this.args.plans.filterBy('periodName', this.activePeriod);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activePeriod", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'yearly';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activePlan", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "periodPlans", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "periodPlans"), _class.prototype)), _class));
  _exports.default = PlansWidget;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlansWidget);
});