define("nightwatch-web/components/snapshot-selector/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (get @extra "showCalendar")}}
    <PowerCalendar
      class="nw-snapshot-selector__calendar"
      @center={{get @extra "center"}}
      @onCenterChange={{get @extra "onCenterChange"}}
      @selected={{get @extra "selectedDate"}}
      @onSelect={{get @extra "onDateSelected"}}
      @navComponent="power-calendar/nav-with-year-buttons" as |calendar|>
      <calendar.Nav />
      <calendar.Days as |day|>
        {{#let (array-contains-date (get @extra "snapshotDates") day.date) as |hasSnapshot|}}
          <span class="nw-snapshot-selector__calendar-day {{if hasSnapshot "nw-snapshot-selector__calendar-day--with-snapshot"}}">
            {{day.number}}
          </span>
        {{/let}}
      </calendar.Days>
    </PowerCalendar>
  {{/if}}
  
  */
  {
    "id": "124erV8U",
    "block": "[[[41,[28,[37,1],[[30,1],\"showCalendar\"],null],[[[1,\"  \"],[8,[39,2],[[24,0,\"nw-snapshot-selector__calendar\"]],[[\"@center\",\"@onCenterChange\",\"@selected\",\"@onSelect\",\"@navComponent\"],[[28,[37,1],[[30,1],\"center\"],null],[28,[37,1],[[30,1],\"onCenterChange\"],null],[28,[37,1],[[30,1],\"selectedDate\"],null],[28,[37,1],[[30,1],\"onDateSelected\"],null],\"power-calendar/nav-with-year-buttons\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"Nav\"]],null,null,null],[1,\"\\n    \"],[8,[30,2,[\"Days\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,4],[[28,[37,1],[[30,1],\"snapshotDates\"],null],[30,3,[\"date\"]]],null]],[[[1,\"        \"],[10,1],[15,0,[29,[\"nw-snapshot-selector__calendar-day \",[52,[30,4],\"nw-snapshot-selector__calendar-day--with-snapshot\"]]]],[12],[1,\"\\n          \"],[1,[30,3,[\"number\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]]],[1,\"    \"]],[3]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"@extra\",\"calendar\",\"day\",\"hasSnapshot\"],false,[\"if\",\"get\",\"power-calendar\",\"let\",\"array-contains-date\"]]",
    "moduleName": "nightwatch-web/components/snapshot-selector/calendar.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});