define("nightwatch-web/components/contextual-panel/serp-preview/live/local-pack-main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-serps__local-pack-map">
    <i class="nw-serps__local-pack-mapbtn fa fa-expand"></i>
  </div>
  <div class="nw-serps__local-pack-filters">
    <span>Rating <NwIcon @icon="icon-caret-down" /></span>
    <span>Price <NwIcon @icon="icon-caret-down" /></span>
    <span>Hours <NwIcon @icon="icon-caret-down" /></span>
  </div>
  {{#each this.results as |result| }}
    <ContextualPanel::SerpPreview::Live::LocalPackResult @result={{result}} />
  {{/each}}
  <div class="nw-serps__local-pack-more">
    <i class="fa fa-list-ul"></i> More places
  </div>
  
  */
  {
    "id": "Oes1qO/H",
    "block": "[[[10,0],[14,0,\"nw-serps__local-pack-map\"],[12],[1,\"\\n  \"],[10,\"i\"],[14,0,\"nw-serps__local-pack-mapbtn fa fa-expand\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__local-pack-filters\"],[12],[1,\"\\n  \"],[10,1],[12],[1,\"Rating \"],[8,[39,0],null,[[\"@icon\"],[\"icon-caret-down\"]],null],[13],[1,\"\\n  \"],[10,1],[12],[1,\"Price \"],[8,[39,0],null,[[\"@icon\"],[\"icon-caret-down\"]],null],[13],[1,\"\\n  \"],[10,1],[12],[1,\"Hours \"],[8,[39,0],null,[[\"@icon\"],[\"icon-caret-down\"]],null],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"  \"],[8,[39,3],null,[[\"@result\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[10,0],[14,0,\"nw-serps__local-pack-more\"],[12],[1,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-list-ul\"],[12],[13],[1,\" More places\\n\"],[13],[1,\"\\n\"]],[\"result\"],false,[\"nw-icon\",\"each\",\"-track-array\",\"contextual-panel/serp-preview/live/local-pack-result\"]]",
    "moduleName": "nightwatch-web/components/contextual-panel/serp-preview/live/local-pack-main.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['nw-serps__local-pack']
  }));

  _exports.default = _default;
});