define("nightwatch-web/routes/dashboard/url/site-audit/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlSiteAuditViewRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('persistence/site-audit-settings'), (_class = class DashboardUrlSiteAuditViewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "userSettings", _descriptor2, this);

      _initializerDefineProperty(this, "siteAuditSettings", _descriptor3, this);
    }

    activate() {
      window.scrollTo(0, 0);
    }

    async beforeModel(transition) {
      const url = this.modelFor('dashboard.url');
      const siteAuditViewId = transition.to.parent.params.view_id;
      await this.userSettings.loadData({
        siteAuditViewId
      });
      const {
        sort,
        direction,
        limit
      } = this.siteAuditSettings.getSettings({
        siteAuditViewId
      });
      const queryParamsWithoutParentParams = Object.keys(transition.to.queryParams).filter(p => p != 'crawling_session_id');
      const shouldOverwriteQueryParams = Ember.isBlank(queryParamsWithoutParentParams); //&& isPresent(transition.params["dashboard.url.site-audit.view"]);

      if (shouldOverwriteQueryParams) {
        const newQueryParams = Object.assign({}, transition.to.queryParams);

        if (Ember.isBlank(transition.to.queryParams.sort) && Ember.isPresent(sort)) {
          newQueryParams.sort = sort;
          newQueryParams.direction = direction || 'desc';
        }

        if (Ember.isBlank(transition.to.queryParams.limit) && Ember.isPresent(limit)) {
          newQueryParams.limit = limit;
        }

        this.router.transitionTo('dashboard.url.site-audit.view.show', url, siteAuditViewId, {
          queryParams: newQueryParams
        });
      }
    }

    model(params) {
      const url = this.modelFor('dashboard.url');
      return this.store.findRecord('site-audit/view', params.view_id).then(view => {
        view.set('url', url);
        view.loadCount();
        return view;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteAuditSettings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DashboardUrlSiteAuditViewRoute;
});