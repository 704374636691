define("nightwatch-web/routes/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = class DashboardRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "dashboardNotifications", _descriptor3, this);

      _initializerDefineProperty(this, "dashboardState", _descriptor4, this);

      _initializerDefineProperty(this, "metrics", _descriptor5, this);
    }

    get user() {
      return this.session.user;
    }

    showDashboardNotifications() {
      this.dashboardNotifications.showSubscriptionNotifications(this.user);
      this.dashboardNotifications.showNotice();
    }

    checkConfirmed() {
      const {
        user
      } = this;

      if (user.id && !user.confirmed) {
        this.router.transitionTo('confirm-account');
      }
    }

    handleOnboarding() {
      if (JSON.parse(localStorage.getItem('nw:needs-onboarding'))) {
        if (this.get('session.user.urlsUsed') > 0) {
          localStorage.removeItem('nw:needs-onboarding');
        } else {
          this.router.transitionTo('start.url');
        }
      }
    }

    beforeModel() {
      this.session.requireAuthentication();
      this.handleOnboarding();
      this.session.requireActive();
      this.checkConfirmed();
      this.showDashboardNotifications();
    }

    async setupController(controller, model) {
      var _ref, _ref2, _controller$get, _urlGroups$firstObjec;

      super.setupController(controller, model);
      this.metrics.trackEvent({
        event: 'Open Dashboard'
      });
      const urlGroupsPromise = this.store.query('url-group', {
        static: true
      });
      controller.set('urlGroups', urlGroupsPromise);
      const urlGroups = await urlGroupsPromise;
      const urlGroupIdToActivate = (_ref = (_ref2 = (_controller$get = controller.get('selectedUrl.urlGroup.id')) !== null && _controller$get !== void 0 ? _controller$get : controller.get('selectedDynamicView.urlGroup.id')) !== null && _ref2 !== void 0 ? _ref2 : this.getMostRecentlySelectedGroupId(urlGroups)) !== null && _ref !== void 0 ? _ref : (_urlGroups$firstObjec = urlGroups.firstObject) === null || _urlGroups$firstObjec === void 0 ? void 0 : _urlGroups$firstObjec.id;
      if (!urlGroupIdToActivate) return;
      const urlGroup = urlGroups.findBy('id', urlGroupIdToActivate);
      controller.activateUrlGroup(urlGroup);
    }

    getMostRecentlySelectedGroupId(urlGroups) {
      const groupId = this.dashboardState.mostRecentlySelectedUrlGroupId;
      const urlGroup = urlGroups.findBy('id', String(groupId));
      return urlGroup === null || urlGroup === void 0 ? void 0 : urlGroup.id;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dashboardNotifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dashboardState", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DashboardRoute;
});