define("nightwatch-web/components/error-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (service 'site-data') (service 'intercom') as |siteData intercom|}}
    <div class="nw-centered">
      <div class="error-page">
        <LinkTo @route="dashboard.overview">
          <img src={{siteData.siteLogoUrl}} alt={{siteData.siteTitle}} class="logo">
        </LinkTo>
  
        <h3>{{yield}}</h3>
  
        <LinkTo @route="dashboard.overview" class="btn btn-primary btn-small">
          Return to dashboard
        </LinkTo>
  
        {{#if intercom.enabled}}
          <p>
            Having problems?
          </p>
  
          <button class="btn btn-secondary-1 btn-small" {{on "click" intercom.showNewMessage}} type="button">
            Contact us
          </button>
        {{/if}}
      </div>
    </div>
  {{/let}}
  
  */
  {
    "id": "mzCNYesh",
    "block": "[[[44,[[28,[37,1],[\"site-data\"],null],[28,[37,1],[\"intercom\"],null]],[[[1,\"  \"],[10,0],[14,0,\"nw-centered\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"error-page\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@route\"],[\"dashboard.overview\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,1,[\"siteLogoUrl\"]]],[15,\"alt\",[30,1,[\"siteTitle\"]]],[14,0,\"logo\"],[12],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[10,\"h3\"],[12],[18,3,null],[13],[1,\"\\n\\n      \"],[8,[39,2],[[24,0,\"btn btn-primary btn-small\"]],[[\"@route\"],[\"dashboard.overview\"]],[[\"default\"],[[[[1,\"\\n        Return to dashboard\\n      \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2,[\"enabled\"]],[[[1,\"        \"],[10,2],[12],[1,\"\\n          Having problems?\\n        \"],[13],[1,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn btn-secondary-1 btn-small\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,2,[\"showNewMessage\"]]],null],[12],[1,\"\\n          Contact us\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]]]],[\"siteData\",\"intercom\",\"&default\"],false,[\"let\",\"service\",\"link-to\",\"yield\",\"if\",\"on\"]]",
    "moduleName": "nightwatch-web/components/error-page.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});