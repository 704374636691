define("nightwatch-web/mixins/backlink-view-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    fetch: Ember.inject.service(),
    backlinksSettings: Ember.inject.service('persistence/backlinks-settings'),
    backlinksNotifications: Ember.inject.service('notifications/backlinks-notifications'),
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      filters: {
        refreshModel: true
      },
      one_link_per_domain: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      }
    },

    async beforeModel(transition) {
      if (!transition.to.params.backlink_view_id) {
        return true; // "new" route
      }

      if (this.controller && this.controller.get('emptyFilters')) {
        return true;
      }

      if (transition.to.queryParams && transition.to.queryParams.filters) {
        return true;
      }

      const backlinkViewId = transition.to.params.backlink_view_id;
      await this.userSettings.loadData({
        backlinkViewId
      });
      const {
        sort,
        direction,
        limit
      } = this.backlinksSettings.getSettings({
        backlinkViewId
      });
      return this.store.findRecord('backlink-view', backlinkViewId).then(backlinkView => {
        const oneLinkPerDomain = backlinkView.get('filterConfig.one_link_per_domain');
        const encodedFilters = encodeURI(JSON.stringify({
          filterGroups: backlinkView.get('filterConfig.filter_groups')
        }));
        let queryParams = {
          one_link_per_domain: oneLinkPerDomain,
          filters: encodedFilters
        }; // Take sort and limit from persisted settings

        if (sort) {
          queryParams.sort = sort;
          queryParams.direction = direction;
        }

        if (limit) {
          queryParams.limit = limit;
        }

        this.router.transitionTo('dashboard.url.backlink-view', backlinkViewId, {
          queryParams: queryParams
        });
      });
    },

    model(params) {
      const url = this.modelFor('dashboard.url');
      let backlinkViewPromise;

      if (params.backlink_view_id) {
        const cachedBacklinkView = this.store.peekAll('backlink-view').findBy('id', params.backlink_view_id);

        if (cachedBacklinkView) {
          backlinkViewPromise = Ember.RSVP.Promise.resolve(cachedBacklinkView);
        } else {
          backlinkViewPromise = this.store.findRecord('backlink-view', params.backlink_view_id);
        }
      } else {
        backlinkViewPromise = Ember.RSVP.Promise.resolve(null);
      }

      return backlinkViewPromise.then(backlinkView => {
        const backlinks = this.store.query('backlink', {
          url_id: url.id,
          limit: params.limit || 50,
          sort: params.sort || 'url_rating',
          direction: params.direction || 'desc',
          page: params.page || 1,
          search: params.search,
          filters: params.filters,
          one_link_per_domain: params.one_link_per_domain
        });
        return Ember.RSVP.hash({
          url: url,
          backlinks: backlinks,
          backlinkView: backlinkView
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      const filtersHidden = JSON.parse(localStorage.getItem('nw:backlinks:filters-shown')) === false;
      const filtersPresent = Ember.isPresent(controller.filters);
      controller.set('filtersShown', !filtersHidden || filtersPresent);

      if (!controller.backlinkView) {
        const url = model.url;
        const backlinkView = this.store.createRecord('backlink-view', {
          url: url
        });
        controller.set('backlinkView', backlinkView);
      } // Did backlinkView change in this controller?


      const newBacklinkViewId = model.backlinkView.id;
      const previousBacklinkViewId = controller.cachedBacklinkViewId;

      if (newBacklinkViewId !== previousBacklinkViewId) {
        controller.setProperties({
          cachedBacklinkViewId: newBacklinkViewId,
          page: 1
        });
        controller.fetchStats();
      }
    },

    deactivate() {
      this.backlinksNotifications.clearLimitNotification();
    },

    resetController(controller, isExiting) {
      controller.setProperties({
        backlinkView: null,
        emptyFilters: false,
        filters: null
      });

      if (isExiting) {
        controller.setProperties({
          stats: Ember.Object.create({}),
          cachedBacklinkViewId: null
        });
      }
    },

    actions: {
      willTransition() {
        this.controller.get('backlinkView').rollbackAttributes();
      }

    }
  });

  _exports.default = _default;
});