define("nightwatch-web/serializers/competitor", ["exports", "nightwatch-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    addHttpPrefix(url) {
      if (url && url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        return 'http://' + url;
      } else {
        return url;
      }
    },

    serializeIntoHash(data, type, record, options) {
      const payload = this.serialize(record, options);
      payload.competitor_id = record.id;
      payload.url = this.addHttpPrefix(record.attr('competitor_url'));
      data['competitor'] = payload;
      return data;
    }

  });

  _exports.default = _default;
});