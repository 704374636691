define("nightwatch-web/helpers/favicon-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faviconUrl = faviconUrl;
  _exports.default = void 0;

  function faviconUrl(params) {
    const url = params[0];
    return `https://www.google.com/s2/favicons?domain=${url}`;
  }

  var _default = Ember.Helper.helper(faviconUrl);

  _exports.default = _default;
});