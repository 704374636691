define("nightwatch-web/templates/dashboard/url/backlinks/wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ocmcGm6R",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"main-area-row bottom-border clearfix\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Loading\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n    Please wait while we load backlinks for \"],[1,[28,[35,1],[[30,1,[\"url\"]]],null]],[1,\"...\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@messages\"],[[30,0,[\"loadingMessages\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"nw-page-content\",\"friendly-url\",\"overlay/loader-overlay\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/backlinks/wait.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});