define("nightwatch-web/components/diff-words", ["exports", "diff"], function (_exports, _diff) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.output}}
  
  */
  {
    "id": "+9/Qwl+L",
    "block": "[[[1,[30,0,[\"output\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "nightwatch-web/components/diff-words.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['diff'],
    oldText: '',
    newText: '',
    output: Ember.computed('oldText', 'newText', function () {
      const {
        oldText = '',
        newText = ''
      } = this;
      if (oldText === newText) return oldText;

      const diffData = _diff.default.diffWords(oldText, newText);

      if (diffData.length < 2) return oldText;
      const oldLine = diffData.filter(w => !w.added && !w.removed || w.removed).map(w => w.removed ? `<del>${w.value.trim()}</del>` : w.value.trim()).join(' ');
      const newLine = diffData.filter(w => !w.added && !w.removed || w.added).map(w => w.added ? `<ins>${w.value.trim()}</ins>` : w.value.trim()).join(' ');
      return Ember.String.htmlSafe(`<div class="diff__line diff__line--old">${oldLine}</div><div class="diff__line diff__line--new">${newLine}</div>`);
    })
  }));

  _exports.default = _default;
});