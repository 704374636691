define("nightwatch-web/utils/append-to-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const appendToUrl = (url, append) => {
    return url.includes('?') ? `${url}&${append}` : `${url}?${append}`;
  };

  var _default = appendToUrl;
  _exports.default = _default;
});