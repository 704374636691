define("nightwatch-web/services/report-data", ["exports", "nightwatch-web/constants/time-periods"], function (_exports, _timePeriods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const hourDisplay = hours => {
    const suffix = hours >= 12 ? 'PM' : 'AM';
    hours = hours > 12 ? hours - 12 : hours;
    hours = hours === '00' ? 12 : hours;
    return `${hours}:00 ${suffix}`;
  };

  const ordinalSuffix = i => {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) return i + 'st';
    if (j === 2 && k !== 12) return i + 'nd';
    if (j === 3 && k !== 13) return i + 'rd';
    return i + 'th';
  };

  let ReportDataService = (_dec = Ember.computed, _dec2 = Ember.computed, (_class = class ReportDataService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "scheduleIntervals", [{
        id: _timePeriods.DAILY,
        text: 'Daily'
      }, {
        id: _timePeriods.WEEKLY,
        text: 'Weekly'
      }, {
        id: _timePeriods.MONTHLY,
        text: 'Monthly'
      }]);

      _defineProperty(this, "daysInWeek", [{
        id: 0,
        text: 'On Sunday'
      }, {
        id: 1,
        text: 'On Monday'
      }, {
        id: 2,
        text: 'On Tuesday'
      }, {
        id: 3,
        text: 'On Wednesday'
      }, {
        id: 4,
        text: 'On Thursday'
      }, {
        id: 5,
        text: 'On Friday'
      }, {
        id: 6,
        text: 'On Saturday'
      }]);
    }

    get hoursInDay() {
      return [...Array(24).keys()].map(h => ({
        id: h,
        text: 'at ' + hourDisplay(h)
      }));
    }

    get daysInMonth() {
      return [...Array(31).keys()].map(d => ++d).map(d => ({
        id: d,
        text: 'on ' + ordinalSuffix(d)
      }));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "hoursInDay", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "hoursInDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "daysInMonth", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "daysInMonth"), _class.prototype)), _class));
  _exports.default = ReportDataService;
});