define("nightwatch-web/components/overview-legend-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="legend-item" {{on 'click' (fn (optional @clickLegendItem) @data.label)}} role="button">
    <div class="pull-left">
      <div
        data-test-donut-legend-color
        class="square"
        {{style  background=@data.backgroundColor}}>
      </div>
      <span data-test-donut-legend-name>{{@data.label}}</span>
    </div>
    <div class="distribution-change pull-right">
      {{#if @comparisonEnabled}}
        <StatChange
          data-test-legend-changes-stat
          class='top-count-change pull-right'
          @value={{@data.change}} />
      {{/if}}
      <span data-test-donut-legend-count class="top-count-value pull-right">
        {{abbreviate-number @data.data}}
      </span>
    </div>
  </div>
  
  */
  {
    "id": "o5ypLOrQ",
    "block": "[[[11,0],[24,0,\"legend-item\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[28,[37,2],[[30,1]],null],[30,2,[\"label\"]]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"pull-left\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"square\"],[4,[38,3],null,[[\"background\"],[[30,2,[\"backgroundColor\"]]]]],[12],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"distribution-change pull-right\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[8,[39,5],[[24,0,\"top-count-change pull-right\"]],[[\"@value\"],[[30,2,[\"change\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,1],[14,0,\"top-count-value pull-right\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[[30,2,[\"data\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@clickLegendItem\",\"@data\",\"@comparisonEnabled\"],false,[\"on\",\"fn\",\"optional\",\"style\",\"if\",\"stat-change\",\"abbreviate-number\"]]",
    "moduleName": "nightwatch-web/components/overview-legend-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});