define("nightwatch-web/components/power-calendar/days-en", ["exports", "ember-power-calendar/components/power-calendar/days", "ember-power-calendar/templates/components/power-calendar/days"], function (_exports, _days, _days2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DaysEnComponent extends _days.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "layout", _days2.default);

      _defineProperty(this, "startOfWeek", '0');
    }

    get weekdaysShort() {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }

  }

  _exports.default = DaysEnComponent;
});