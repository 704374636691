define("nightwatch-web/templates/confirm-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wwXwSND2",
    "block": "[[[1,[28,[35,0],[\"Confirm Account\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[10,0],[14,0,\"splash-blurred-background\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"splash-blurred-cta-wrapper confirmation-prompt\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"blurred-cta\"],[12],[1,\"\\n\"],[41,[30,0,[\"isConfirming\"]],[[[1,\"      \"],[10,\"h1\"],[12],[1,\"Hold on…\"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h1\"],[12],[1,\"Welcome to \"],[1,[30,0,[\"siteData\",\"siteTitle\"]]],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"info\"],[12],[1,\"\\n\"],[41,[30,0,[\"errorMessage\"]],[[[1,\"          \"],[10,2],[12],[10,\"b\"],[12],[1,[30,0,[\"errorMessage\"]]],[13],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,2],[12],[1,\"Before you can start using \"],[1,[30,0,[\"siteData\",\"siteTitle\"]]],[1,\", please confirm your e-mail address by clicking on the link we sent you.\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,2],[12],[11,3],[24,6,\"#\"],[24,5,\"margin-top: 40px;\"],[24,0,\"link\"],[4,[38,3],[\"click\",[28,[37,4],[[28,[37,5],[[30,0,[\"resendButtonShown\"]]],null],true],null]],null],[12],[1,\"Didn't receive the e-mail?\"],[13],[13],[1,\"\\n\\n\"],[6,[39,6],[[30,0,[\"resendButtonShown\"]]],null,[[\"default\"],[[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"resendLink\"]]],null],[12],[1,\"\\n          \"],[1,[52,[30,0,[\"emailSent\"]],\"E-mail sent!\",\"Resend confirmation e-mail\"]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"nw-navbar\",\"if\",\"on\",\"fn\",\"mut\",\"liquid-if\"]]",
    "moduleName": "nightwatch-web/templates/confirm-account.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});