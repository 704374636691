define("nightwatch-web/components/backlinks/csv-export", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a download href={{@csvExportUrl}} class="btn btn-secondary-2"
    {{on-faye-message "push:backlinksDownload" this.onStatusUpdate}}
  >
    <NwTooltip>Download filtered backlinks in CSV format.</NwTooltip>
    <span class="btn-label-default">
      {{#if this.isGenerating}}
        <Loading::Spinner />
        Generating ...
      {{else}}
        <NwIcon @icon="icon-download" />
        Export
      {{/if}}
    </span>
  </a>
  
  */
  {
    "id": "VqzQYZVv",
    "block": "[[[11,3],[24,\"download\",\"\"],[16,6,[30,1]],[24,0,\"btn btn-secondary-2\"],[4,[38,0],[\"push:backlinksDownload\",[30,0,[\"onStatusUpdate\"]]],null],[12],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"Download filtered backlinks in CSV format.\"]],[]]]]],[1,\"\\n  \"],[10,1],[14,0,\"btn-label-default\"],[12],[1,\"\\n\"],[41,[30,0,[\"isGenerating\"]],[[[1,\"      \"],[8,[39,3],null,null,null],[1,\"\\n      Generating ...\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,[[\"@icon\"],[\"icon-download\"]],null],[1,\"\\n      Export\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@csvExportUrl\"],false,[\"on-faye-message\",\"nw-tooltip\",\"if\",\"loading/spinner\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/backlinks/csv-export.hbs",
    "isStrictMode": false
  });

  let CsvExportComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class CsvExportComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isGenerating", _descriptor, this);
    }

    onStatusUpdate({
      status
    }) {
      this.isGenerating = status === 'generate_start';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isGenerating", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onStatusUpdate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onStatusUpdate"), _class.prototype)), _class));
  _exports.default = CsvExportComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CsvExportComponent);
});