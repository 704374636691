define("nightwatch-web/utils/graphs/nw-chart-controllers", ["exports", "chart.js"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NwBarController = _exports.NwLineController = void 0;

  const drawTooltip = context => {
    var _context$chart$toolti, _context$chart$toolti2;

    if (!((_context$chart$toolti = context.chart.tooltip) !== null && _context$chart$toolti !== void 0 && (_context$chart$toolti2 = _context$chart$toolti._active) !== null && _context$chart$toolti2 !== void 0 && _context$chart$toolti2.length)) return;
    const [activePoint] = context.chart.tooltip._active,
          ctx = context.chart.ctx,
          x = activePoint.element.x,
          topY = context.chart.scales.y.top,
          bottomY = context.chart.scales.y.bottom; // Render line on canvas

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, bottomY);
    ctx.lineTo(x, topY);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#b4b4b4';
    ctx.stroke();
    ctx.restore();
  };

  class NwLineController extends _chart.LineController {
    draw() {
      super.draw();
      drawTooltip(this);
    }

  }

  _exports.NwLineController = NwLineController;

  class NwBarController extends _chart.BarController {
    draw() {
      super.draw();
      drawTooltip(this);
    }

  }

  _exports.NwBarController = NwBarController;
  NwLineController.id = 'nwLine';
  NwBarController.id = 'nwBar';
});