define("nightwatch-web/helpers/full-month-date", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fullMonthDate = fullMonthDate;
  _exports.default = void 0;

  function fullMonthDate([inputDate]) {
    return (0, _dateFns.format)(new Date(inputDate), 'MMMM dd, yyyy');
  }

  var _default = Ember.Helper.helper(fullMonthDate);

  _exports.default = _default;
});