define("nightwatch-web/controllers/dashboard/url/keywords/add/discover", ["exports", "lodash-es/isEmpty", "lodash-es/xor", "nightwatch-web/utils/refresh-route"], function (_exports, _isEmpty, _xor, _refreshRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlKeywordsAddDiscoverController = (_dec = Ember.inject.service, _dec2 = Ember.inject.controller('dashboard.url.keywords.add'), _dec3 = Ember.computed.alias('model'), _dec4 = Ember.computed.alias('addKeywordsController.addedKeywordSuggestions'), _dec5 = Ember.computed.alias('with_tracked'), _dec6 = Ember.computed('direction'), _dec7 = Ember.computed('selectedKeywordSuggestions.[]'), _dec8 = Ember.computed('selectedKeywordSuggestions.[]'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class DashboardUrlKeywordsAddDiscoverController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "addKeywordsController", _descriptor2, this);

      _defineProperty(this, "queryParams", ['sort', 'direction', 'page', 'with_tracked', 'with_ignored', 'all_tracked', 'status']);

      _defineProperty(this, "sort", 'created_at');

      _defineProperty(this, "direction", 'desc');

      _defineProperty(this, "page", '1');

      _defineProperty(this, "with_tracked", false);

      _defineProperty(this, "with_ignored", false);

      _defineProperty(this, "all_tracked", false);

      _defineProperty(this, "selectedKeywordSuggestions", Ember.A());

      _initializerDefineProperty(this, "keywordSuggestions", _descriptor3, this);

      _initializerDefineProperty(this, "addedKeywordSuggestions", _descriptor4, this);

      _initializerDefineProperty(this, "showTrackedColumn", _descriptor5, this);
    }

    get oppositeDirection() {
      return this.direction === 'asc' ? 'desc' : 'asc';
    }

    get allKeywordSuggestionsSelected() {
      if (!this.keywordSuggestions) {
        return false;
      }

      return (0, _isEmpty.default)((0, _xor.default)(this.selectedKeywordSuggestions.mapBy('id'), this.keywordSuggestions.mapBy('id')));
    }

    get someKeywordSuggestionsSelected() {
      return this.get('selectedKeywordSuggestions.length') > 0;
    }

    _ignoreKeywordSuggestions(keywordSuggestions) {
      const ids = keywordSuggestions.mapBy('id');
      const url = this.get('addKeywordsController.url');
      return this.fetch.post('/keyword_suggestions/ignore', {
        data: {
          url_id: url.id,
          ids: ids,
          ignore: true
        }
      }).catch(() => {
        this.notifications.error('There was an error while ignoring keyword suggestions.', {
          autoClear: true,
          clearDuration: 5000
        });
      });
    }

    ignoreKeywordSuggestion(keywordSuggestion, ignore = true) {
      const promise = this._ignoreKeywordSuggestions([keywordSuggestion], ignore);

      promise.then(() => {
        const url = this.get('addKeywordsController.url');
        url.loadKeywordSuggestionCount();
        this.notifications.success('Keyword suggestion successfully ignored.', {
          autoClear: true,
          clearDuration: 5000
        });
        (0, _refreshRoute.default)(this, 'dashboard.url.keywords.add.discover');
      });
    }

    ignoreSelectedKeywords(ignore) {
      const selectedKeywords = this.selectedKeywordSuggestions;

      const promise = this._ignoreKeywordSuggestions(selectedKeywords, ignore);

      promise.then(() => {
        this.notifications.success('Keyword suggestions successfully ignored.', {
          autoClear: true,
          clearDuration: 5000
        });
        (0, _refreshRoute.default)(this, 'dashboard.url.keywords.add.discover');
      });
    }

    addSelectedKeywords() {
      this.selectedKeywordSuggestions.forEach(ks => {
        this.addKeywordsController.addKeywordSuggestion(ks);
      });
      this.notifications.success('Keywords copied to Add Keywords tab.', {
        autoClear: true,
        clearDuration: 5000
      });
    }

    addKeywordSuggestion(keywordSuggestion) {
      const shouldNotify = this.get('addedKeywordSuggestions.length') === 0;
      this.addKeywordsController.addKeywordSuggestion(keywordSuggestion);

      if (shouldNotify) {
        this.notifications.success('Keyword copied to Add Keywords tab.', {
          autoClear: true,
          clearDuration: 5000
        });
      }
    }

    removeKeywordSuggestion(keywordSuggestion) {
      this.addKeywordsController.removeKeywordSuggestion(keywordSuggestion);
    }

    toggleWithTrackedAndUnignored() {
      this.toggleProperty('with_tracked');
      this.toggleProperty('with_ignored');
    }

    onSelectAllToggleChange(selectAll) {
      if (selectAll) {
        this.set('selectedKeywordSuggestions', this.keywordSuggestions.toArray().concat());
      } else {
        this.set('selectedKeywordSuggestions', Ember.A());
      }
    }

    toggleKeywordSuggestionSelected(keywordSuggestion) {
      let selectedKeywordSuggestions = this.selectedKeywordSuggestions.concat();
      const addedSuggestion = selectedKeywordSuggestions.findBy('id', keywordSuggestion.id);

      if (addedSuggestion) {
        selectedKeywordSuggestions.removeObject(addedSuggestion);
      } else {
        selectedKeywordSuggestions.pushObject(keywordSuggestion);
      }

      this.set('selectedKeywordSuggestions', selectedKeywordSuggestions);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "addKeywordsController", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "keywordSuggestions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "addedKeywordSuggestions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showTrackedColumn", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "oppositeDirection", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "oppositeDirection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allKeywordSuggestionsSelected", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "allKeywordSuggestionsSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "someKeywordSuggestionsSelected", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "someKeywordSuggestionsSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ignoreKeywordSuggestion", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreKeywordSuggestion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ignoreSelectedKeywords", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreSelectedKeywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addSelectedKeywords", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "addSelectedKeywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addKeywordSuggestion", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "addKeywordSuggestion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeKeywordSuggestion", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "removeKeywordSuggestion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleWithTrackedAndUnignored", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleWithTrackedAndUnignored"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectAllToggleChange", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectAllToggleChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleKeywordSuggestionSelected", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "toggleKeywordSuggestionSelected"), _class.prototype)), _class));
  _exports.default = DashboardUrlKeywordsAddDiscoverController;
});