define("nightwatch-web/controllers/settings/whitelabel", ["exports", "nightwatch-web/utils/refresh-route", "ember-concurrency"], function (_exports, _refreshRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SettingsWhitelabelController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.computed('model.whitelabelDomain.{isNew,enabled}'), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class SettingsWhitelabelController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "domainError", _descriptor, this);

      _initializerDefineProperty(this, "nameError", _descriptor2, this);

      _initializerDefineProperty(this, "logoError", _descriptor3, this);
    }

    validateWhitelabelDomain() {
      this.clearErrors();
      let valid = true;

      if (this.model.whitelabelDomain.enabled) {
        if (!this.model.whitelabelDomain.domain) {
          this.domainError = 'Domain missing';
          valid = false;
        }

        if (!this.model.whitelabelDomain.title) {
          this.nameError = 'Name missing';
          valid = false;
        }

        if (!this.model.whitelabelDomain.whitelabelLogoId) {
          valid = false;
          this.logoError = 'You have to set a default logo';
        }
      }

      return valid;
    }

    clearErrors() {
      this.domainError = null;
      this.nameError = null;
      this.logoError = null;
    }

    get shouldSaveWhitelabelDomain() {
      if (this.model.whitelabelDomain.isNew) return this.model.whitelabelDomain.enabled;
      return true;
    }

    switchDefaultLogo(whitelabelLogo) {
      this.model.whitelabelDomain.whitelabelLogoId = whitelabelLogo.id;
    }

    refreshRoute() {
      (0, _refreshRoute.default)(this, 'settings.whitelabel');
    }

    *save() {
      if (!this.validateWhitelabelDomain()) return;

      try {
        if (this.shouldSaveWhitelabelDomain) yield this.model.whitelabelDomain.save();
      } catch (error) {
        this.notifications.error('Settings update failed.');
        throw error;
      }

      this.notifications.success('Settings sucessfully updated.', {
        autoClear: true
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "domainError", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "nameError", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "logoError", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "shouldSaveWhitelabelDomain", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "shouldSaveWhitelabelDomain"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchDefaultLogo", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "switchDefaultLogo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshRoute", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "refreshRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = SettingsWhitelabelController;
});