define("nightwatch-web/controllers/start/url", ["exports", "nightwatch-web/utils/extract-tld"], function (_exports, _extractTld) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StartUrlController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed.or('model.url.isSaving', 'saving'), _dec13 = Ember.computed('model.urlGroups.[]', 'groupId'), _dec14 = Ember.computed.map('siteData.languages', function (l) {
    return {
      id: l.get('firstObject'),
      text: l.get('lastObject')
    };
  }), _dec15 = Ember.computed.map('siteData.countries', function (c) {
    return {
      id: c.get('firstObject'),
      text: c.get('lastObject')
    };
  }), _dec16 = Ember._action, _dec17 = Ember._action, (_class = class StartUrlController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);

      _initializerDefineProperty(this, "fetch", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _defineProperty(this, "queryParams", ['groupId']);

      _defineProperty(this, "groupId", null);

      _initializerDefineProperty(this, "multiple", _descriptor5, this);

      _initializerDefineProperty(this, "urls", _descriptor6, this);

      _initializerDefineProperty(this, "saving", _descriptor7, this);

      _initializerDefineProperty(this, "errorsForMultiple", _descriptor8, this);

      _initializerDefineProperty(this, "urlsAdded", _descriptor9, this);

      _initializerDefineProperty(this, "countryCode", _descriptor10, this);

      _initializerDefineProperty(this, "languageCode", _descriptor11, this);

      _initializerDefineProperty(this, "isSaving", _descriptor12, this);

      _initializerDefineProperty(this, "languages", _descriptor13, this);

      _initializerDefineProperty(this, "countries", _descriptor14, this);
    }

    get urlGroup() {
      const groupId = this.groupId;

      if (groupId) {
        return this.get('model.urlGroups').findBy('id', this.groupId);
      } else {
        return this.get('model.urlGroups.firstObject');
      }
    }

    handleSingle() {
      const url = this.get('model.url');
      const urlGroup = this.urlGroup;
      url.set('urlGroup', urlGroup);
      url.set('country_code', this.countryCode);
      url.set('language_code', this.languageCode);
      url.save().then(url => {
        var _this$session$user;

        if (!urlGroup) return;
        const urls = urlGroup.get('urls').toArray();
        urls.pushObject(url);
        urlGroup.set('urls', urls);
        (_this$session$user = this.session.user) === null || _this$session$user === void 0 ? void 0 : _this$session$user.reload();
        localStorage.removeItem('nw:needs-onboarding');
        this.trackAddedUrls([url]);
        this.router.transitionTo('start.keywords', url);
      });
    }

    handleMultiple() {
      const textareaUrls = this.urls.trim(); // string from textarea

      if (Ember.isBlank(textareaUrls)) {
        this.urlsError = 'Enter URLs';
        return;
      }

      this.urlsError = null;
      this.errorsForMultiple = null;
      const groupId = this.groupId;
      const urlGroup = this.urlGroup;
      const data = {
        group_id: groupId,
        urls: textareaUrls,
        country_code: this.countryCode,
        language_code: this.languageCode
      };
      this.saving = true;
      this.fetch.post('/urls/batch_create', {
        data
      }).then(urls => {
        var _this$session$user2;

        this.saving = false;
        const urlsAdded = urls.filter(url => url.id && Object.keys(url.errors).length === 0);
        const urlsWithErrors = urls.filter(url => url.errors && Object.keys(url.errors).length !== 0);
        this.store.query('url', {
          group_id: groupId
        }).then(urls => {
          urlGroup.set('urls', urls);
        });
        (_this$session$user2 = this.session.user) === null || _this$session$user2 === void 0 ? void 0 : _this$session$user2.reload();
        this.trackAddedUrls(urlsAdded);

        if (!urlsWithErrors.length) {
          this.router.transitionTo('dashboard.overview');
        } else {
          const urlErrors = [];
          urlsWithErrors.forEach(url => {
            const errorInfo = {
              url: url.url,
              errors: []
            };
            Object.keys(url.errors).forEach(key => {
              const errorKey = key === 'base' ? '' : key;
              errorInfo.errors.push(`${errorKey} ${url.errors[key].join(', ')}`);
            });
            urlErrors.push(errorInfo);
          });
          this.errorsForMultiple = urlErrors;
          this.urlsAdded = urlsAdded;
        }
      });
    }

    trackAddedUrls(urls) {
      this.metrics.trackEvent({
        event: 'Added URLs',
        urls: urls.mapBy('url').join(', ')
      });
    }

    fillLocalizationFromDomain() {
      const url = this.get('model.url.url');
      const tld = (0, _extractTld.default)(url);

      if (Ember.isBlank(tld)) {
        this.countryCode = null;
        this.languageCode = null;
        return;
      }

      if (['com', 'net', 'org'].indexOf(tld.toLowerCase()) > -1) {
        this.countryCode = 'us';
        this.languageCode = 'en';
        return;
      }

      const countryCode = this.get('siteData.countries').find(countryData => countryData[0] === tld.toLowerCase());

      if (countryCode) {
        this.countryCode = countryCode[0];
        const countryLanguage = this.get('siteData.countryLanguages').find(countryLanguageData => {
          const code = countryLanguageData[0].toLowerCase();
          return code === countryCode[0];
        });

        if (countryLanguage) {
          this.languageCode = countryLanguage[1];
        } else {
          this.languageCode = null;
        }
      }
    }

    onUrlInput() {
      this.fillLocalizationFromDomain();
    }

    save() {
      if (this.multiple) {
        this.handleMultiple();
      } else {
        this.handleSingle();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "multiple", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "urls", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "saving", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "errorsForMultiple", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "urlsAdded", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "countryCode", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "languageCode", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isSaving", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "urlGroup", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "urlGroup"), _class.prototype), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "languages", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "countries", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUrlInput", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onUrlInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = StartUrlController;
});