define("nightwatch-web/components/nw-help-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (service "site-data") as |siteData|}}
    {{#let (and @doc (not siteData.hideNightwatchBranding)) as |linkToDoc|}}
      <span class="nw-help-tooltip" ...attributes>
        {{#if linkToDoc}}
          <a href={{docs-url @doc}} target="_blank" rel="noopener noreferrer">
            <NwIcon @icon={{or @icon "icon-info"}} class="nw-help-tooltip__icon" />
          </a>
        {{else}}
          <NwIcon @icon={{or @icon "icon-info"}} class="nw-help-tooltip__icon" />
        {{/if}}
  
        <NwTooltip @placement={{@placement}}>
          {{yield}}
          {{#if linkToDoc}}
            <NwIcon @icon="icon-double-right" class="nw-help-tooltip__link-icon" />
          {{/if}}
        </NwTooltip>
      </span>
    {{/let}}
  {{/let}}
  
  */
  {
    "id": "EqqcuLR5",
    "block": "[[[44,[[28,[37,1],[\"site-data\"],null]],[[[44,[[28,[37,2],[[30,2],[28,[37,3],[[30,1,[\"hideNightwatchBranding\"]]],null]],null]],[[[1,\"    \"],[11,1],[24,0,\"nw-help-tooltip\"],[17,4],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,3],[15,6,[28,[37,5],[[30,2]],null]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n          \"],[8,[39,6],[[24,0,\"nw-help-tooltip__icon\"]],[[\"@icon\"],[[28,[37,7],[[30,5],\"icon-info\"],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,6],[[24,0,\"nw-help-tooltip__icon\"]],[[\"@icon\"],[[28,[37,7],[[30,5],\"icon-info\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"\\n      \"],[8,[39,8],null,[[\"@placement\"],[[30,6]]],[[\"default\"],[[[[1,\"\\n        \"],[18,7,null],[1,\"\\n\"],[41,[30,3],[[[1,\"          \"],[8,[39,6],[[24,0,\"nw-help-tooltip__link-icon\"]],[[\"@icon\"],[\"icon-double-right\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[3]]]],[1]]]],[\"siteData\",\"@doc\",\"linkToDoc\",\"&attrs\",\"@icon\",\"@placement\",\"&default\"],false,[\"let\",\"service\",\"and\",\"not\",\"if\",\"docs-url\",\"nw-icon\",\"or\",\"nw-tooltip\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/nw-help-tooltip.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});