define("nightwatch-web/components/graph/mini-graph", ["exports", "@glimmer/component", "nightwatch-web/utils/svg-ui-elements"], function (_exports, _component, _svgUiElements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-mg" ...attributes
    {{in-viewport onEnter=this.didEnterViewport}}
    {{did-update this.renderGraph @series @seriesLastDate}}
  >
  </div>
  
  */
  {
    "id": "qrLcUJyY",
    "block": "[[[11,0],[24,0,\"nw-mg\"],[17,1],[4,[38,0],null,[[\"onEnter\"],[[30,0,[\"didEnterViewport\"]]]]],[4,[38,1],[[30,0,[\"renderGraph\"]],[30,2],[30,3]],null],[12],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@series\",\"@seriesLastDate\"],false,[\"in-viewport\",\"did-update\"]]",
    "moduleName": "nightwatch-web/components/graph/mini-graph.hbs",
    "isStrictMode": false
  });

  /**
    @class MiniGraphComponent
  
    Renders mini graph of keyword positions over time
  
    @argument series {Array[Number]} Array of keyword positions to graph
    @argument seriesLastDate {Date}
   */
  let MiniGraphComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class MiniGraphComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "hasEnteredViewport", false);
    }

    didEnterViewport(element) {
      if (this.hasEnteredViewport) return;
      this.hasEnteredViewport = true;
      this.renderGraph(element);
    }

    renderGraph(element) {
      if (!element) return;
      Ember.run.schedule('afterRender', () => {
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }

        (0, _svgUiElements.miniGraph)(element, this.args.series, this.args.seriesLastDate);
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didEnterViewport", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didEnterViewport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderGraph", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "renderGraph"), _class.prototype)), _class));
  _exports.default = MiniGraphComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MiniGraphComponent);
});