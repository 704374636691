define("nightwatch-web/helpers/report-column-competitor-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.round = round;
  _exports.default = void 0;

  function round([column, competitors]) {
    const competitorId = column.split('-').reverse()[0];
    const competitor = competitors.findBy('id', competitorId);
    if (competitor) return competitor.get('friendlyUrl');else {
      return 'Competitor deleted. Please switch column.';
    }
  }

  var _default = Ember.Helper.helper(round);

  _exports.default = _default;
});