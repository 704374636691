define("nightwatch-web/components/graph/universal-graph-small", ["exports", "nightwatch-web/components/graph/universal-graph", "nightwatch-web/utils/small-universal-graph-config", "highcharts/highstock", "highcharts/modules/exporting"], function (_exports, _universalGraph, _smallUniversalGraphConfig, _highstock, _exporting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id="universal-graph-{{this.graphId}}" class="pinned-item-graph {{if this.isLoading 'dimmed'}}"></div>
  
  */
  {
    "id": "qots3rRA",
    "block": "[[[10,0],[15,1,[29,[\"universal-graph-\",[30,0,[\"graphId\"]]]]],[15,0,[29,[\"pinned-item-graph \",[52,[30,0,[\"isLoading\"]],\"dimmed\"]]]],[12],[13],[1,\"\\n\"]],[],false,[\"if\"]]",
    "moduleName": "nightwatch-web/components/graph/universal-graph-small.hbs",
    "isStrictMode": false
  });

  (0, _exporting.default)(_highstock.default);

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _universalGraph.default.extend({
    useLineGradient: true,
    isSmallGraph: true,

    createChart() {
      const chartObject = _highstock.default.stockChart(`universal-graph-${this.graphId}`, _smallUniversalGraphConfig.default);

      this.setProperties({
        chartObject
      });
    },

    didInsertElement() {
      this._super(...arguments); // url.content because it's an async model on a pinned item. :/
      // Delay this a bit (schedule these requests so they are in the last places) so we don't send too many requests when there is a lot of pinned items


      Ember.run.later(() => {
        this.reloadNotes(this.get('url.content'));
      }, 500);
    }

  }));

  _exports.default = _default;
});