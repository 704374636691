define("nightwatch-web/models/keyword", ["exports", "@ember-data/model", "nightwatch-web/constants/keyword-data"], function (_exports, _model, _keywordData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const engineIcon = _keywordData.allSearchEngines.reduce((acc, {
    id,
    icon
  }) => {
    acc[id] = icon;
    return acc;
  }, {});

  let Keyword = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('date'), _dec11 = (0, _model.attr)('date'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('boolean'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('number'), _dec21 = (0, _model.attr)(), _dec22 = (0, _model.attr)('date'), _dec23 = (0, _model.attr)('number'), _dec24 = (0, _model.attr)('number'), _dec25 = (0, _model.attr)('number'), _dec26 = (0, _model.attr)('number'), _dec27 = (0, _model.attr)('number'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.attr)('string'), _dec30 = (0, _model.attr)('string'), _dec31 = (0, _model.attr)('string'), _dec32 = (0, _model.attr)(), _dec33 = (0, _model.attr)('string'), _dec34 = (0, _model.attr)('string'), _dec35 = (0, _model.attr)(), _dec36 = (0, _model.attr)(), _dec37 = (0, _model.attr)('string'), _dec38 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec39 = (0, _model.attr)(), _dec40 = (0, _model.attr)(), _dec41 = (0, _model.belongsTo)('url'), (_class = class Keyword extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "query", _descriptor, this);

      _initializerDefineProperty(this, "position", _descriptor2, this);

      _initializerDefineProperty(this, "previous_position", _descriptor3, this);

      _initializerDefineProperty(this, "best_position", _descriptor4, this);

      _initializerDefineProperty(this, "position_organic", _descriptor5, this);

      _initializerDefineProperty(this, "position_places_image", _descriptor6, this);

      _initializerDefineProperty(this, "position_local_pack", _descriptor7, this);

      _initializerDefineProperty(this, "position_featured_snippet", _descriptor8, this);

      _initializerDefineProperty(this, "position_knowledge_panel", _descriptor9, this);

      _initializerDefineProperty(this, "created_at", _descriptor10, this);

      _initializerDefineProperty(this, "last_processed_at", _descriptor11, this);

      _initializerDefineProperty(this, "google_hl", _descriptor12, this);

      _initializerDefineProperty(this, "google_gl", _descriptor13, this);

      _initializerDefineProperty(this, "engine", _descriptor14, this);

      _initializerDefineProperty(this, "result_url", _descriptor15, this);

      _initializerDefineProperty(this, "mobile", _descriptor16, this);

      _initializerDefineProperty(this, "position_change", _descriptor17, this);

      _initializerDefineProperty(this, "last_day_change", _descriptor18, this);

      _initializerDefineProperty(this, "last_week_change", _descriptor19, this);

      _initializerDefineProperty(this, "last_month_change", _descriptor20, this);

      _initializerDefineProperty(this, "historic_positions", _descriptor21, this);

      _initializerDefineProperty(this, "last_historic_position_on", _descriptor22, this);

      _initializerDefineProperty(this, "clicks", _descriptor23, this);

      _initializerDefineProperty(this, "impressions", _descriptor24, this);

      _initializerDefineProperty(this, "ctr", _descriptor25, this);

      _initializerDefineProperty(this, "search_console_position", _descriptor26, this);

      _initializerDefineProperty(this, "results_count", _descriptor27, this);

      _initializerDefineProperty(this, "adwords_global_average_cpc", _descriptor28, this);

      _initializerDefineProperty(this, "adwords_global_search_volume", _descriptor29, this);

      _initializerDefineProperty(this, "adwords_local_average_cpc", _descriptor30, this);

      _initializerDefineProperty(this, "adwords_local_search_volume", _descriptor31, this);

      _initializerDefineProperty(this, "last_position_change", _descriptor32, this);

      _initializerDefineProperty(this, "local_search", _descriptor33, this);

      _initializerDefineProperty(this, "uri", _descriptor34, this);

      _initializerDefineProperty(this, "positionInfo", _descriptor35, this);

      _initializerDefineProperty(this, "tags", _descriptor36, this);

      _initializerDefineProperty(this, "position_type", _descriptor37, this);

      _initializerDefineProperty(this, "url_value", _descriptor38, this);

      _initializerDefineProperty(this, "competitor_results", _descriptor39, this);

      _initializerDefineProperty(this, "available_serp_features", _descriptor40, this);

      _initializerDefineProperty(this, "url", _descriptor41, this);
    }

    get mobileReady() {
      var _this$positionInfo;

      return (_this$positionInfo = this.positionInfo) === null || _this$positionInfo === void 0 ? void 0 : _this$positionInfo.mobile_ready;
    }

    get fullLocation() {
      const country = this.google_gl || '';
      const city = this.local_search || '';
      let location = `${country.toUpperCase()}`;

      if (city) {
        location += `, ${city}`;
      } // New York,New York,United States to New York, New York, United States


      return location.split(',').map(l => l.trim()).join(', ');
    }

    get fullLocationShortened() {
      const country = (this.google_gl || '').toUpperCase();
      const city = this.local_search;
      const location = city ? city : country; // New York,New York,United States to New York, New York, United States

      return location.split(',').map(l => l.trim()).join(', ');
    }

    get engineImgPath() {
      return engineIcon[this.engine];
    }

    get isGoogle() {
      return this.engine === 'google';
    }

    get isProcessing() {
      return !this.last_processed_at;
    }

    get miniGraphSeries() {
      return (this.historic_positions || []).slice(0, 10).reverse();
    }

    get rankingUrl() {
      return `${this.url_value}${this.uri}`;
    } // No-op for bug where `reload()` is called on a deleted instance.


    reload() {
      if (this.isDeleted) return Ember.RSVP.resolve(this);
      return super.reload();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "query", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "previous_position", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "best_position", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "position_organic", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "position_places_image", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "position_local_pack", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "position_featured_snippet", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "position_knowledge_panel", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "last_processed_at", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "google_hl", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "google_gl", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "engine", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "result_url", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "mobile", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "position_change", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "last_day_change", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "last_week_change", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "last_month_change", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "historic_positions", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "last_historic_position_on", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "clicks", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "impressions", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "ctr", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "search_console_position", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "results_count", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "adwords_global_average_cpc", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "adwords_global_search_volume", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "adwords_local_average_cpc", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "adwords_local_search_volume", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "last_position_change", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "local_search", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "uri", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "positionInfo", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "position_type", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "url_value", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "competitor_results", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "available_serp_features", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Keyword;
});