define("nightwatch-web/components/nw-sidebar/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-logo">
    <LinkTo @route="dashboard.overview"><NwLogo /></LinkTo>
  </div>
  
  */
  {
    "id": "i7bU50R/",
    "block": "[[[10,0],[14,0,\"nw-logo\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\"],[\"dashboard.overview\"]],[[\"default\"],[[[[8,[39,1],null,null,null]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"nw-logo\"]]",
    "moduleName": "nightwatch-web/components/nw-sidebar/logo.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});