define("nightwatch-web/modifiers/copy-on-click", ["exports", "ember-modifier", "clipboard"], function (_exports, _emberModifier, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const className = 'btn-copy-on-click';

  const copyOnClick = (element, [clipboardContent], {
    onSuccess,
    onError
  }) => {
    element.classList.add(className);
    const clipboard = new _clipboard.default(`.${className}`, {
      text: () => clipboardContent
    });

    if (onSuccess) {
      clipboard.on('success', event => onSuccess(event));
    }

    if (onError) {
      clipboard.on('error', event => onError(event));
    }

    return () => clipboard.destroy();
  };

  var _default = (0, _emberModifier.modifier)(copyOnClick);

  _exports.default = _default;
});