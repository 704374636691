define("nightwatch-web/components/nw-sidebar/toggle-section-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span role="button" {{on "click" (stop-propagation (prevent-default @toggleSection))}}>
    <NwIcon class="nw-menu-item__toggle-icon" @icon="icon-caret-down" />
  </span>
  
  */
  {
    "id": "hxuQVXQm",
    "block": "[[[11,1],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[28,[37,2],[[30,1]],null]],null]],null],[12],[1,\"\\n  \"],[8,[39,3],[[24,0,\"nw-menu-item__toggle-icon\"]],[[\"@icon\"],[\"icon-caret-down\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@toggleSection\"],false,[\"on\",\"stop-propagation\",\"prevent-default\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/nw-sidebar/toggle-section-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});