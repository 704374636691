define("nightwatch-web/utils/signup-questionnaire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createQuestionnaire = void 0;
  const questionnaire = [{
    key: 'signup_company_description',
    question: 'What best describes your company?',
    options: ['Small business', 'Agency', 'Enterprise', 'E-Commerce', 'Non-profit'] // excludeCustomOption: true // NOTE: If you don't want the option to select "Other"

  }, {
    key: 'signup_employee_count',
    question: 'Number of employees in your company?',
    options: ['Just me', '2-10', '11-20', '21-50', '51-100', '101-1000', 'more than 1000'],
    excludeCustomOption: true
  }, {
    key: 'signup_referral',
    question: 'How did you find out about Nightwatch?',
    options: null
  }];

  const createQuestionnaire = (customOptionLabel = 'Other') => ({
    questions: questionnaire.map(({
      key,
      question,
      options,
      excludeCustomOption = false
    }) => ({
      key,
      question,
      options: options && !excludeCustomOption ? [...options, customOptionLabel] : options,
      predefinedAnswer: !options ? customOptionLabel : '',
      customAnswer: ''
    })),
    customOptionLabel
  });

  _exports.createQuestionnaire = createQuestionnaire;
});