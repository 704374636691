define("nightwatch-web/templates/dashboard/url/keywords/add/manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vmxK71KX",
    "block": "[[[1,[28,[35,0],[\"Add Keywords\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@url\",\"@onKeywordsAdd\",\"@goToUrl\",\"@addedKeywordSuggestions\"],[[30,0,[\"url\"]],[30,0,[\"onKeywordsAdd\"]],[30,0,[\"goToUrl\"]],[30,0,[\"addedKeywordSuggestions\"]]]],null],[1,\"\\n\"]],[],false,[\"page-title\",\"edit-keyword-form\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/keywords/add/manual.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});