define("nightwatch-web/adapters/competitor", ["exports", "nightwatch-web/adapters/application", "nightwatch-web/utils/is-type"], function (_exports, _application, _isType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const sanitizeDomain = url => url ? url.replace('http://', '').replace('https://', '') : null;

  class CompetitorAdapter extends _application.default {
    buildURL(_modelName, id, snapshot, requestType, query) {
      var _snapshot$belongsTo;

      const defaultUrl = super.buildURL(...arguments);
      const urlId = query && query.url_id ? query.url_id : snapshot && snapshot.belongsTo ? (_snapshot$belongsTo = snapshot.belongsTo('url')) === null || _snapshot$belongsTo === void 0 ? void 0 : _snapshot$belongsTo.id : undefined;

      switch (requestType) {
        case 'deleteRecord':
        case 'updateRecord':
          return defaultUrl.replace(/\/competitors\/?\d*/, `/urls/${urlId}/competitors/${id}`);

        default:
          return defaultUrl.replace(/\/competitors/, `/urls/${urlId}/competitors`);
      }
    }

    handleResponse(status, headers, payload, requestData) {
      if (status >= 400) return super.handleResponse(...arguments);

      if (Array.isArray(payload)) {
        payload = payload.map(competitor => {
          const competitorUrl = competitor.url;
          delete competitor.url;
          return _objectSpread(_objectSpread({}, competitor), {}, {
            url_id: competitor.search_keyword_url_id,
            competitor_url: sanitizeDomain(competitorUrl)
          });
        });
      } else if ((0, _isType.isObjectType)(payload)) {
        payload = _objectSpread(_objectSpread({}, payload), {}, {
          url_id: payload.search_keyword_url_id,
          competitor_url: sanitizeDomain(payload.url)
        });
        delete payload.url;
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

  }

  _exports.default = CompetitorAdapter;
});