define("nightwatch-web/helpers/item-at-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.itemAtIndex = void 0;

  const itemAtIndex = params => params[0][params[1]];

  _exports.itemAtIndex = itemAtIndex;

  var _default = Ember.Helper.helper(itemAtIndex);

  _exports.default = _default;
});