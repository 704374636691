define("nightwatch-web/components/waiting-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.value null)}}
    <NwTooltip>{{this.tooltip}}</NwTooltip>
    <NwIcon @icon="icon-clock" />
  {{else}}
    {{#if this.round}}
      {{round this.value}}
    {{else}}
      {{this.value}}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "OCmOmICg",
    "block": "[[[41,[28,[37,1],[[30,0,[\"value\"]],null],null],[[[1,\"  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,[30,0,[\"tooltip\"]]]],[]]]]],[1,\"\\n  \"],[8,[39,3],null,[[\"@icon\"],[\"icon-clock\"]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"round\"]],[[[1,\"    \"],[1,[28,[35,4],[[30,0,[\"value\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"value\"]]],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"eq\",\"nw-tooltip\",\"nw-icon\",\"round\"]]",
    "moduleName": "nightwatch-web/components/waiting-value.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tooltip: Ember.computed('units', function () {
      let units = 'hours';

      if (this.units == 'days') {
        units = 'days';
      }

      return `Retrieving data (can take up to a couple of ${units})`;
    })
  }));

  _exports.default = _default;
});