define("nightwatch-web/adapters/keyword", ["exports", "nightwatch-web/adapters/application", "nightwatch-web/utils/is-type"], function (_exports, _application, _isType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class KeywordAdapter extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "coalesceFindRequests", false);
    }

    buildURL(_modelName, _id, snapshot, requestType, query) {
      var _snapshot$belongsTo, _snapshot$belongsTo2;

      const defaultUrl = super.buildURL(...arguments);
      const urlId = query && query.url_id ? query.url_id : snapshot && snapshot.belongsTo ? (_snapshot$belongsTo = snapshot.belongsTo('url')) === null || _snapshot$belongsTo === void 0 ? void 0 : _snapshot$belongsTo.id : undefined;

      switch (requestType) {
        case 'findRecord':
          return defaultUrl;

        case 'deleteRecord':
        case 'updateRecord':
          return defaultUrl.replace(/\/keywords/, `/urls/${snapshot === null || snapshot === void 0 ? void 0 : (_snapshot$belongsTo2 = snapshot.belongsTo('url')) === null || _snapshot$belongsTo2 === void 0 ? void 0 : _snapshot$belongsTo2.id}/keywords`);

        default:
          return defaultUrl.replace(/\/keywords/, `/urls/${urlId}/keywords`);
      }
    }

    handleResponse(status, headers, payload, requestData) {
      if (status >= 400) return super.handleResponse(...arguments);
      const meta = {
        total: headers['X-Total-Count'] || headers['x-total-count'],
        pages: headers['X-Page-Count'] || headers['x-page-count']
      };

      if (Array.isArray(payload)) {
        payload = {
          keywords: payload,
          meta
        };
      } else if ((0, _isType.isObjectType)(payload)) {
        payload = {
          keyword: payload,
          meta
        };
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

  }

  _exports.default = KeywordAdapter;
});