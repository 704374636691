define("nightwatch-web/utils/svg-ui-elements", ["exports", "date-fns", "nightwatch-web/utils/shift-to-utc"], function (_exports, _dateFns, _shiftToUtc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.miniGraphTooltips = _exports.miniGraph = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Build an array of objects describing single point rendering properties.
   * @param  {Array}  [series=[]]   Series of plot points.
   * @param  {Number} [min=0]       Min plot point value.
   * @param  {Number} [max=100]     Max plot point value.
   * @param  {Number} [yMax=100]    Height of the area for plot points (local SVG coords).
   * @param  {Number} [xStep=20]    Horizontal distance between plot points (local SVG coords).
   * @param  {Number} [xAdjust=0]   Left offset for first plot point (local SVG coords).
   * @param  {Number} [yAdjust=0]   Top offset for plot points (local SVG coords).
   * @return {[Array]}
   */
  const plotPoints = (series = [], min = 0, max = 100, yMax = 100, xStep = 20, xAdjust = 0, yAdjust = 0) => series.reduce((acc, value, idx) => {
    // Extract x and y from previous iteration as prevX and prevY
    const {
      x: prevX,
      y: prevY,
      value: prevValue
    } = acc[idx]; // Set x and y for current iteration

    const x = prevX + xStep;
    const y = value < 0 // Is unranked?
    ? yMax + yAdjust + 4 // Put point into no rank zone
    : min === max // Is graph flat?
    ? Math.round(yMax / 2) + yAdjust // Put point in the middle of canvas
    : Math.round((value - min) * yMax / (max - min)) + yAdjust; // Construct point object

    const point = {
      x,
      y,
      value,
      growth: 'same'
    }; // In the first iteration there is no previous point so continue to the next iteration

    if (idx === 0) {
      acc.push(point);
      return acc;
    } // Determine the change between previous and current point


    if (value < 0 && prevValue > -1) {
      point.growth = 'down'; // Drop into red zone
    } else if (prevValue < 0 && value > -1) {
      point.growth = 'up'; // Rise out of red zone
    } else {
      // The usual ups and downs
      if (value < prevValue) point.growth = 'up';
      if (value > prevValue) point.growth = 'down';
    } // Construct final point object and go to the next iteration


    acc.push(_objectSpread(_objectSpread({}, point), {}, {
      prevX,
      prevY
    }));
    return acc;
  }, // Start reduce with a blank that applies the left offset
  [{
    x: xAdjust - xStep
  }]) // Remove the first blank we initiated reduce with
  .slice(1);
  /**
   * Create SVG paths to connect points in the graph.
   * @param  {Array}    [series=[]]   An array of plot point objects
   * @return {[Array]}
   */


  const plotSegments = (series = []) => series.map(({
    x,
    y,
    prevX,
    prevY,
    growth
  }) => ({
    growth,
    path: `M${prevX},${prevY} ${x},${y}`
  })).reverse(); // Reverse order for proper visual layering

  /**
   * Helper function to construct an SVG tree
   * @param  {String}       tag         SVG element tag
   * @param  {Object}       [attrs={}]  Element attributes
   * @param  {Node|String}  children    Child node(s); SVG element(s) or text
   * @return {[Node]}                   SVG node
   */


  const svg = (tag, attrs = {}, ...children) => {
    const el = document.createElementNS('http://www.w3.org/2000/svg', tag);
    Object.keys(attrs).forEach(attr => {
      el.setAttributeNS(null, attr, attrs[attr]);
    });
    children.forEach(c => {
      if (!c.toString()) return; // Skip empty strings

      if (typeof c !== 'object') {
        c = document.createTextNode(c.toString());
      }

      el.appendChild(c);
    });
    return el;
  }; // Construct mini graph SVG tree


  const renderMiniGraph = (segments = [], min = 0, max = 100, redZone = true) => svg('svg', {
    viewBox: '0 0 120 40'
  }, svg('text', {
    class: 'nw-mg__tx',
    x: 20,
    y: 13
  }, min), svg('text', {
    class: 'nw-mg__tx',
    x: 20,
    y: 34
  }, max), svg('rect', {
    class: 'nw-mg__axis',
    width: 90,
    height: 1,
    x: 25,
    y: 9
  }), svg('rect', {
    class: 'nw-mg__axis',
    width: 90,
    height: 1,
    x: 25,
    y: 30
  }), redZone ? svg('rect', {
    class: 'nw-mg__red-zone',
    width: 90,
    height: 6,
    x: 25,
    y: 31
  }) : '', svg('g', {}, ...segments.map(s => svg('path', {
    class: `nw-mg__plot nw-mg__plot--${s.growth}`,
    d: s.path
  })))); // Construct mini graph SVG tree where all points have the same value


  const renderFlatMiniGraph = (segments = [], val = 0, redZone = true) => svg('svg', {
    viewBox: '0 0 120 40'
  }, svg('text', {
    class: 'nw-mg__tx',
    x: 20,
    y: 24
  }, val < 0 ? '/' : val), svg('rect', {
    class: 'nw-mg__axis',
    width: 90,
    height: 1,
    x: 25,
    y: 20
  }), redZone ? svg('rect', {
    class: 'nw-mg__red-zone',
    width: 90,
    height: 6,
    x: 25,
    y: 31
  }) : '', svg('g', {}, ...segments.map(s => svg('path', {
    class: `nw-mg__plot nw-mg__plot--${s.growth}`,
    d: s.path
  })))); // Construct mini graph SVG tree with a single point


  const renderPointMiniGraph = ({
    x,
    y,
    value
  }) => svg('svg', {
    viewBox: '0 0 120 40'
  }, svg('text', {
    class: 'nw-mg__tx',
    x: 20,
    y: 24
  }, value < 0 ? '/' : value), svg('rect', {
    class: 'nw-mg__axis',
    width: 90,
    height: 1,
    x: 25,
    y: 20
  }), value < 0 ? svg('rect', {
    class: 'nw-mg__red-zone',
    width: 90,
    height: 6,
    x: 25,
    y: 17
  }) : '', svg('circle', {
    class: `nw-mg__dot nw-mg__dot--${value < 0 ? 'same' : 'up'}`,
    cx: x,
    cy: y,
    r: 3.5
  }));
  /**
   * Contruct a mini graph from a series array and append it to provided Node
   * @param  {Node}   node                          DOM element to append the graph to
   * @param  {Array}  [series=[]]                   Array of graph points
   * @param  {Date}   [seriesLastDate=new Date()]   Date of the last point in the series
   */


  const miniGraph = (node, series = [], seriesLastDate = new Date()) => {
    // Exit if empty series
    if (!node || !series.length) return; // Replace nulls with numbers

    const numSeries = series.map(p => Number.isNaN(Number.parseInt(p, 10)) ? -1 : p); // Subset only positive integers from series

    const subSeries = numSeries.filter(p => p > -1); // Calculate limits which will have to be rescaled to plot canvas coordinates

    const min = subSeries.length ? Math.min(...subSeries) : -1;
    const max = Math.max(...numSeries, min);
    const xStep = 10; // Distance between points on the graph

    const yMax = 20; // Plottable canvas height

    const xAdjust = (10 - series.length) * xStep + 25; // First point left offset

    const yAdjust = 10; // Graph points top offset
    // Turn series into an array of objects describing each point

    const points = plotPoints(numSeries, min, max, yMax, xStep, xAdjust, yAdjust); // If there's only 1 point, change its y coordinate to middle of canvas

    if (points.length === 1) {
      points[0].y = Math.round(yMax / 2) + yAdjust;
    } // Construct SVG graph


    const graph = points.length > 1 ? min === max ? renderFlatMiniGraph(plotSegments(points.slice(1)), min, subSeries.length < numSeries.length) : renderMiniGraph(plotSegments(points.slice(1)), min, max, subSeries.length < numSeries.length) : renderPointMiniGraph(points[0]); // Skip tooltips for browsers without Element.dataset (*caugh* SeaMonkey *caugh*)

    if (graph.dataset) {
      // Add a class to be used as a hook for tooltips
      graph.classList.add('js--minigraph'); // Store series values on the SVG element for tooltips

      graph.dataset.points = JSON.stringify(points);
      graph.dataset.seriesLastDate = seriesLastDate.getTime().toString();
    } // Attach SVG graph to DOM


    node.appendChild(graph);
  };
  /*
   * Construct an SVG tree of anchor points to append to the main graph.
   * These will create event targets (circles) over the points in the graphs
   * which are just line end markers (non targetable decorations)
   */


  _exports.miniGraph = miniGraph;

  const miniGraphTooltipAnchors = (points = []) => svg('g', {}, ...points.map(p => svg('circle', {
    class: 'nw-mg__tip-anchor js--minigraph-tip',
    cx: p.x,
    cy: p.y,
    r: 4,
    'data-tooltip': [p.value, p.date, p.growth].join(':')
  })));
  /**
   * Extend minigraphs with anchors for showing tooltips
   * @param  {[Node]} node Minigraph SVG element
   */


  const miniGraphTooltips = node => {
    // Exit if already initialized
    if (!node || node.dataset.tooltips) return; // Mark as initialized

    node.dataset.tooltips = true; // Get series data from graph element

    const points = JSON.parse(node.dataset.points); // Exit if no points

    if (!points.length) return; // Create a date for the last point in series

    const seriesLastDate = (0, _shiftToUtc.default)((0, _dateFns.parse)(node.dataset.seriesLastDate, 'T', new Date())); // Add dates to points from last to first

    let pointDate;
    const pointsWithDate = points.reverse().map(point => {
      pointDate = pointDate ? (0, _dateFns.sub)(pointDate, {
        days: 1
      }) : seriesLastDate;
      return _objectSpread(_objectSpread({}, point), {}, {
        date: (0, _dateFns.format)(pointDate, 'EEEE, MMM dd, yyyy')
      });
    }).reverse(); // Append tooltip anchors to graph

    node.appendChild(miniGraphTooltipAnchors(pointsWithDate));
  };

  _exports.miniGraphTooltips = miniGraphTooltips;
});