define("nightwatch-web/components/nw-sidebar/section", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-sidebar__section
    {{if @disableOpen "nw-sidebar__section--disable-open"}}
    {{if this.isOpen "nw-sidebar__section--open"}}"
    {{did-update this.initiallyOpenChanged @initiallyOpen}}
  >
    {{yield this.toggle (not @disableOpen)}}
  </div>
  
  */
  {
    "id": "OFgq7bDk",
    "block": "[[[11,0],[16,0,[29,[\"nw-sidebar__section\\n  \",[52,[30,1],\"nw-sidebar__section--disable-open\"],\"\\n  \",[52,[30,0,[\"isOpen\"]],\"nw-sidebar__section--open\"]]]],[4,[38,1],[[30,0,[\"initiallyOpenChanged\"]],[30,2]],null],[12],[1,\"\\n  \"],[18,3,[[30,0,[\"toggle\"]],[28,[37,3],[[30,1]],null]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@disableOpen\",\"@initiallyOpen\",\"&default\"],false,[\"if\",\"did-update\",\"yield\",\"not\"]]",
    "moduleName": "nightwatch-web/components/nw-sidebar/section.hbs",
    "isStrictMode": false
  });

  let SectionComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class SectionComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isOpen", _descriptor, this);
    }

    initiallyOpenChanged() {
      const {
        initiallyOpen
      } = this.args;
      if (!initiallyOpen) return;
      this.isOpen = initiallyOpen;
    }

    toggle() {
      this.isOpen = !this.isOpen;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.initiallyOpen || false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initiallyOpenChanged", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initiallyOpenChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = SectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SectionComponent);
});