define("nightwatch-web/controllers/dashboard/url/site-audit/views/new", ["exports", "nightwatch-web/utils/site-audit-properties"], function (_exports, _siteAuditProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlSiteAuditViewsNewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('model.view'), _dec4 = Ember.computed.alias('model.pages'), _dec5 = Ember.computed.alias('model.url'), _dec6 = Ember.computed('filters'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class DashboardUrlSiteAuditViewsNewController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "view", _descriptor3, this);

      _initializerDefineProperty(this, "pages", _descriptor4, this);

      _initializerDefineProperty(this, "url", _descriptor5, this);

      _defineProperty(this, "queryParams", ['page', 'sort', 'direction', 'params', 'search', 'filters']);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 50);

      _defineProperty(this, "sort", 'created_at');

      _defineProperty(this, "direction", 'desc');

      _defineProperty(this, "search", null);

      _defineProperty(this, "isLoading", false);

      _defineProperty(this, "filters", null);

      _defineProperty(this, "availableFilters", _siteAuditProperties.AVAILABLE_FILTERS);
    }

    get filtersObj() {
      if (!this.filters) {
        return {};
      }

      try {
        return JSON.parse(decodeURIComponent(this.filters));
      } catch (err) {
        return {};
      }
    }

    setFilters(filters) {
      if (filters && filters.filterGroups && filters.filterGroups.length === 1 && filters.filterGroups[0].filters[0].name == null) {
        this.setProperties({
          filters: null,
          page: 1,
          emptyFilters: true
        });
      } else {
        this.setProperties({
          page: 1
        });
        const urlSafeFilters = encodeURI(JSON.stringify(filters));
        this.setProperties({
          filters: urlSafeFilters,
          emptyFilters: false
        });
      }
    }

    setFilterConfig(filterConfig) {
      this.set('filterConfig', filterConfig);
    }

    saveResource(siteAuditView) {
      siteAuditView.set('filterConfig', {
        filter_groups: this.get('filtersObj.filterGroups')
      });
      const promise = siteAuditView.save();
      promise.then(siteAuditView => {
        this.notifications.success('Site audit view saved successfully', {
          autoClear: true
        });
        this.router.transitionTo('dashboard.url.site-audit.view.show', siteAuditView.id); // Add new view so it shows up in the sidebar

        const urlSiteAuditViews = this.get('url.siteAuditViews').toArray();
        urlSiteAuditViews.pushObject(siteAuditView);
        this.set('url.siteAuditViews', urlSiteAuditViews.toArray());
        siteAuditView.loadCount();
      }, () => {
        this.notifications.error('There was an error while saving the site audit view', {
          autoClear: true
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "view", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pages", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filtersObj", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "filtersObj"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFilters", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFilterConfig", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setFilterConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveResource", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "saveResource"), _class.prototype)), _class));
  _exports.default = DashboardUrlSiteAuditViewsNewController;
});