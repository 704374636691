define("nightwatch-web/components/plans/period-toggler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="toggles toggles--horizontal toggles--center toggles--radio toggles--default">
    {{#each @periods as |period|}}
      <RadioButton @classNames="toggles__item" @checkedClass="toggles__item--active" @value={{period.name}} @groupValue={{@activePeriod}}>
        <span class="text--normal">{{period.label}}</span>
        {{#if period.tag}}
          <span class="plan__period-toggler-tag">{{period.tag}}</span>
        {{/if}}
      </RadioButton>
    {{/each}}
  </div>
  
  */
  {
    "id": "ibb9zNcj",
    "block": "[[[10,0],[14,0,\"toggles toggles--horizontal toggles--center toggles--radio toggles--default\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@classNames\",\"@checkedClass\",\"@value\",\"@groupValue\"],[\"toggles__item\",\"toggles__item--active\",[30,2,[\"name\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"text--normal\"],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n\"],[41,[30,2,[\"tag\"]],[[[1,\"        \"],[10,1],[14,0,\"plan__period-toggler-tag\"],[12],[1,[30,2,[\"tag\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@periods\",\"period\",\"@activePeriod\"],false,[\"each\",\"-track-array\",\"radio-button\",\"if\"]]",
    "moduleName": "nightwatch-web/components/plans/period-toggler.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});