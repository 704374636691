define("nightwatch-web/serializers/site-audit/crawling-session", ["exports", "nightwatch-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIntoHash: function (data, type, record, options) {
      this._super(data, type, record, options);

      data['crawling_session'] = data['site_audit/crawling_session'];
    }
  });

  _exports.default = _default;
});