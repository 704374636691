define("nightwatch-web/services/note-utils", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NoteUtilsService extends Ember.Service {
    notesInRange(notes, dateFrom, dateTo) {
      return notes.filter(note => {
        const betweenDate = note.date;
        return betweenDate > (0, _dateFns.startOfDay)((0, _dateFns.parse)(dateFrom, 'yyyy-MM-dd', new Date())) && betweenDate < (0, _dateFns.endOfDay)((0, _dateFns.parse)(dateTo, 'yyyy-MM-dd', new Date()));
      });
    }

    transformForGraph(notes, dateFrom, dateTo) {
      if (!notes) return;
      return this.notesInRange(notes, dateFrom, dateTo).map(note => {
        return {
          /*
            Imports UTC 00:00 note.date into user timezone.
            Otherwise the x value doesn't line up with graph data.
          */
          x: Date.parse((0, _dateFns.format)(note.date, 'yyyy-MM-dd')),
          y: 0,
          userNote: note.text,
          zIndex: 3
        };
      }).sort((a, b) => a.x - b.x);
    }

  }

  _exports.default = NoteUtilsService;
});