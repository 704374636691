define("nightwatch-web/components/nw-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg class="nw-icon" fill="currentColor" ...attributes>
    <use xlink:href="#{{@icon}}" />
  </svg>
  
  */
  {
    "id": "3sJBRsMA",
    "block": "[[[11,\"svg\"],[24,0,\"nw-icon\"],[24,\"fill\",\"currentColor\"],[17,1],[12],[1,\"\\n  \"],[10,\"use\"],[15,\"xlink:href\",[29,[\"#\",[30,2]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@icon\"],false,[]]",
    "moduleName": "nightwatch-web/components/nw-icon.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});