define("nightwatch-web/components/nw-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (service "site-data") as |siteData|}}
    <img src={{siteData.siteLogoUrl}} alt={{siteData.siteTitle}} class={{if siteData.isWhiteLabel "nw-logo--whitelabel"}} ...attributes>
  {{/let}}
  
  */
  {
    "id": "IsDwE99v",
    "block": "[[[44,[[28,[37,1],[\"site-data\"],null]],[[[1,\"  \"],[11,\"img\"],[16,\"src\",[30,1,[\"siteLogoUrl\"]]],[16,\"alt\",[30,1,[\"siteTitle\"]]],[16,0,[52,[30,1,[\"isWhiteLabel\"]],\"nw-logo--whitelabel\"]],[17,2],[12],[13],[1,\"\\n\"]],[1]]]],[\"siteData\",\"&attrs\"],false,[\"let\",\"service\",\"if\"]]",
    "moduleName": "nightwatch-web/components/nw-logo.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});