define("nightwatch-web/helpers/to-upper-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.toUpperCase = void 0;

  const toUpperCase = params => (params[0] || '').toUpperCase();

  _exports.toUpperCase = toUpperCase;

  var _default = Ember.Helper.helper(toUpperCase);

  _exports.default = _default;
});