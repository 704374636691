define("nightwatch-web/utils/flatten-power-select-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Flatten multidimensional `power-select` options for easier processing
   */
  const flattenPowerSelectOptions = options => {
    const flatOptions = [];
    options.forEach(option => {
      if (Array.isArray(option.options)) {
        flatOptions.push(...flattenPowerSelectOptions(option.options));
      } else {
        flatOptions.push(option);
      }
    });
    return flatOptions;
  };

  var _default = flattenPowerSelectOptions;
  _exports.default = _default;
});