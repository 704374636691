define("nightwatch-web/controllers/start/integrations", ["exports", "nightwatch-web/utils/is-testing", "nightwatch-web/utils/dom/fade-element"], function (_exports, _isTesting, _fadeElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StartIntegrationsController = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('model'), _dec3 = Ember.computed('url'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class StartIntegrationsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "url", _descriptor2, this);

      _defineProperty(this, "onboarding", true);
    }

    get loadingMessages() {
      return [{
        msg: `Starting Nightvision for ${this.get('url.friendlyUrl')}…`,
        duration: _isTesting.default ? 0 : 800
      }];
    }

    save(event) {
      const startTrackingButtonEl = event.currentTarget;
      this.set('isSaving', true);
      this.url.save().finally(async () => {
        await (0, _fadeElement.fadeOut)(startTrackingButtonEl, _isTesting.default ? 0 : 800);
        this.set('isSaving', false);
        this.set('progressOverlayShown', true);
      });
    }

    onProgressFinish() {
      Ember.run(() => {
        Ember.run.later(() => {
          this.set('progressOverlayShown', false);
        }, _isTesting.default ? 0 : 300);
        this.router.transitionTo('dashboard.url.keywords', this.url);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadingMessages", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadingMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onProgressFinish", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onProgressFinish"), _class.prototype)), _class));
  _exports.default = StartIntegrationsController;
});