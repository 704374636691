define("nightwatch-web/controllers/dashboard/url/keywords/index", ["exports", "nightwatch-web/mixins/dynamic-view-handling", "nightwatch-web/mixins/keywords-sort-handling", "nightwatch-web/mixins/filters-toggling", "nightwatch-web/mixins/filter-group-handling", "nightwatch-web/mixins/selectable-keywords", "lodash-es/isEmpty", "date-fns"], function (_exports, _dynamicViewHandling, _keywordsSortHandling, _filtersToggling, _filterGroupHandling, _selectableKeywords, _isEmpty, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlKeywordsIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('persistence/keyword-list'), _dec8 = Ember.computed.alias('model'), _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class DashboardUrlKeywordsIndexController extends Ember.Controller.extend(_dynamicViewHandling.default, _keywordsSortHandling.default, _filtersToggling.default, _filterGroupHandling.default, _selectableKeywords.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);

      _initializerDefineProperty(this, "dashboardNotifications", _descriptor3, this);

      _initializerDefineProperty(this, "fetch", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "keywordStats", _descriptor6, this);

      _initializerDefineProperty(this, "keywordListPersistence", _descriptor7, this);

      _initializerDefineProperty(this, "dynamicView", _descriptor8, this);

      _initializerDefineProperty(this, "url", _descriptor9, this);

      _initializerDefineProperty(this, "searchQuery", _descriptor10, this);

      _initializerDefineProperty(this, "selectedDateRange", _descriptor11, this);

      _initializerDefineProperty(this, "initialKeywordLoadingDone", _descriptor12, this);

      _initializerDefineProperty(this, "selectedKeywordIds", _descriptor13, this);
    }

    init() {
      super.init(...arguments);
      const {
        type,
        start,
        end
      } = this.keywordListPersistence.parseDateRange();
      this.selectedDateRange = {
        type,
        start,
        end
      };
    }

    get statsDateFrom() {
      return (0, _dateFns.format)(new Date(this.selectedDateRange.start), 'yyyy-MM-dd');
    }

    get statsDateTo() {
      return (0, _dateFns.format)(new Date(this.selectedDateRange.end), 'yyyy-MM-dd');
    }

    enteredRoute(dynamicView, url) {
      this.url = url;
      this.setPerPageLimit();
      this.searchQuery = null;
      this.prepareNewFilterGroup(dynamicView);
      this.initialKeywordLoadingDone = false;
      this.selectedKeywordIds = [];
      this.fetchKeywords(); // Don't allow `sortingChanged` observer to trigger before controller is setup
      // (otherwise we get double keyword fetch)

      this.allowSortObserver = true;
      this.fetchKeywordStats();
      this.dashboardNotifications.showUrlNotifications(url);
    }

    fetchKeywordStats() {
      const {
        url,
        statsDateFrom,
        statsDateTo,
        dynamicView
      } = this;
      this.keywordStats.load({
        url,
        statsDateFrom,
        statsDateTo,
        dynamicView
      });
    }

    fetchKeywords(filters = {}) {
      this.set('keywordsLoading', true);
      const isSearch = Ember.isPresent(this.searchQuery);
      let params = {
        url_id: this.url.id,
        page: this.page,
        sort: this.sort,
        direction: this.direction,
        limit: this.limit,
        search: this.searchQuery,
        date_from: this.statsDateFrom,
        date_to: this.statsDateTo
      };

      if ((0, _isEmpty.default)(filters) && this.dynamicView.filtersValid) {
        const appliedFilters = this.serializeFilters();

        if (appliedFilters.length) {
          filters = Ember.assign(filters, {
            filter_groups: JSON.stringify(appliedFilters)
          });
        }
      }

      params = Ember.assign(params, filters);
      const keywordsPromise = this.store.query('keyword', params);
      return keywordsPromise.then(keywords => {
        this.set('keywords', keywords);
        this.set('initialKeywordLoadingDone', true);
        this.set('keywordsLoading', false);
        const showKeywordNotifications = !isSearch && (0, _isEmpty.default)(filters);

        if (showKeywordNotifications) {
          this.showKeywordNotifications();
        }
      });
    }

    showKeywordNotifications() {
      const {
        url,
        keywords
      } = this;
      if (!url || !keywords) return;
      this.dashboardNotifications.showKeywordNotifications({
        keywords,
        user: this.session.user,
        url
      });
    }

    deleteSelectedKeywords() {
      const keywordsForDeletion = this.selectedKeywords;
      const keywordCount = keywordsForDeletion.length;
      const keywordsDisplay = keywordCount === 1 ? 'keyword' : 'keywords';

      if (confirm(`Are you sure you want to delete ${keywordCount} ${keywordsDisplay}? All associated data will be lost.`)) {
        const keywordList = keywordsForDeletion.mapBy('query').join(', ');
        const keywordIds = keywordsForDeletion.mapBy('id');
        const data = {
          keyword_ids: keywordIds
        };
        keywordsForDeletion.forEach(keyword => {
          this.store.unloadRecord(keyword);
        });
        this.decrementProperty('keywords.meta.total', keywordCount);
        this.session.user.keywordsUsed -= 1;
        this.decrementProperty('url.keyword_count', keywordCount);
        return this.fetch.post(`/urls/${this.url.id}/keywords/batch_destroy`, {
          data
        }).then(() => {
          this.fetchKeywordStats();
          this.metrics.trackEvent({
            event: 'Removed Keywords',
            keywords: keywordList
          });
        });
      }
    }

    exportSelectedKeywords() {
      this.router.transitionTo('dashboard.url.reports.new', this.url, {
        queryParams: {
          keyword_ids: this.selectedKeywords.mapBy('id')
        }
      });
    }

    deleteKeyword(keyword) {
      if (confirm('Are you sure you want to delete this keyword?')) {
        this.decrementProperty('keywords.meta.total');
        this.session.user.keywordsUsed -= 1;
        this.decrementProperty('url.keyword_count');
        return keyword.destroyRecord().then(() => {
          this.fetchKeywordStats();
        });
      }
    }

    exportKeyword(keyword) {
      this.router.transitionTo('dashboard.url.reports.new', keyword.get('url'), {
        queryParams: {
          keyword_ids: [keyword.id]
        }
      });
    }

    onSearchChange(query) {
      if (String(this.page) !== '1') {
        this.set('page', 1);
      }

      this.set('searchQuery', query);
      Ember.run.debounce(this, 'fetchKeywords', 150);
    }

    setDateRange({
      start,
      end,
      type
    }) {
      this.keywordListPersistence.persistDateRange(type);
      this.selectedDateRange = {
        type,
        start,
        end
      };
      this.fetchKeywordStats();
      this.fetchKeywords();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dashboardNotifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "keywordStats", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "keywordListPersistence", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dynamicView", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "searchQuery", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedDateRange", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initialKeywordLoadingDone", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedKeywordIds", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteSelectedKeywords", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "deleteSelectedKeywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportSelectedKeywords", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "exportSelectedKeywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteKeyword", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "deleteKeyword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportKeyword", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "exportKeyword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSearchChange", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onSearchChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDateRange", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "setDateRange"), _class.prototype)), _class));
  _exports.default = DashboardUrlKeywordsIndexController;
});