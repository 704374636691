define("nightwatch-web/routes/dashboard/url/graph", ["exports", "nightwatch-web/mixins/graph-route"], function (_exports, _graphRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlGraphRoute extends Ember.Route.extend(_graphRoute.default) {
    activate() {
      window.scrollTo(0, 0);
    }

    model(params) {
      return this.store.findRecord('graph', params.graph_id, {
        reload: true
      });
    }

  }

  _exports.default = DashboardUrlGraphRoute;
});