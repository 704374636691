define("nightwatch-web/components/notes/note-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-table__row nw-table__row--bordered">
    <div class="nw-table__col nw-table__col--first">{{nice-date @note.date}}</div>
    <div class="nw-table__col">{{@note.text}}</div>
    <div class="nw-table__col nw-table__col--last {{if @readOnly 'nw-table__col--label' 'nw-table__col--actions'}}">
      {{#if @readOnly}}
        <span class="nw-badge">Account note</span>
      {{else}}
        <LimitedAccess>
          <button {{on "click" @onDelete}} class="nw-button nw-button--plain nw-table__col--action-button" type="button">
            <NwIcon class="nw-table__col--action-icon" @icon="svg-trash" />
          </button>
        </LimitedAccess>
        <button {{on "click" @onEdit}} class="nw-button nw-button--plain nw-table__col--action-button" type="button">
          <NwIcon class="nw-table__col--action-icon" @icon="svg-pencil" />
        </button>
      {{/if}}
    </div>
    {{#if @readOnly}}
      <AttachTooltip @class="nw-tooltip--v2">
        This is an account-wide note that applies to all URLs.
      </AttachTooltip>
    {{/if}}
  </div>
  
  */
  {
    "id": "XgL6+W1u",
    "block": "[[[10,0],[14,0,\"nw-table__row nw-table__row--bordered\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nw-table__col nw-table__col--first\"],[12],[1,[28,[35,0],[[30,1,[\"date\"]]],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"nw-table__col\"],[12],[1,[30,1,[\"text\"]]],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"nw-table__col nw-table__col--last \",[52,[30,2],\"nw-table__col--label\",\"nw-table__col--actions\"]]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,1],[14,0,\"nw-badge\"],[12],[1,\"Account note\"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"nw-button nw-button--plain nw-table__col--action-button\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,3]],null],[12],[1,\"\\n          \"],[8,[39,4],[[24,0,\"nw-table__col--action-icon\"]],[[\"@icon\"],[\"svg-trash\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[11,\"button\"],[24,0,\"nw-button nw-button--plain nw-table__col--action-button\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,4]],null],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"nw-table__col--action-icon\"]],[[\"@icon\"],[\"svg-pencil\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,5],null,[[\"@class\"],[\"nw-tooltip--v2\"]],[[\"default\"],[[[[1,\"\\n      This is an account-wide note that applies to all URLs.\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@note\",\"@readOnly\",\"@onDelete\",\"@onEdit\"],false,[\"nice-date\",\"if\",\"limited-access\",\"on\",\"nw-icon\",\"attach-tooltip\"]]",
    "moduleName": "nightwatch-web/components/notes/note-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});