define("nightwatch-web/components/filters/filter-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @filterGroup.filters as |filter|}}
    <div data-test-filter-item class="row or-row">
      <Filters::FilterItem @filter={{filter}} @onSelectedFilterChange={{@onSelectedFilterChange}} @onSelectedOperatorChange={{@onSelectedOperatorChange}} @onValueChange={{@onValueChange}} />
      <div class="col-sm-1">
        {{#if filter.isLast}}
          <button class="btn btn-secondary or-btn {{if @isAddingDisabled 'disabled'}}" disabled={{@isAddingDisabled}} {{on "click" (fn @addNewFilter @filterGroup)}} type="button">
            <NwIcon @icon="icon-plus-circle" />
            or
          </button>
        {{else}}
          or
        {{/if}}
      </div>
      <div class="col-sm-1 filter-remove-icon-area">
        {{#if filter.isFirstAndNullified}}
          <span class="dimmed disable-click">
            <NwIcon @icon="icon-minus-circle" />
          </span>
        {{else}}
          <span data-test-filter-remove role="button" {{on "click" (fn @removeFilter @filterGroup filter)}}>
            <NwIcon @icon="icon-minus-circle" />
          </span>
        {{/if}}
      </div>
    </div>
  {{/each}}
  
  */
  {
    "id": "eBsctB5X",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1,[\"filters\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"row or-row\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@filter\",\"@onSelectedFilterChange\",\"@onSelectedOperatorChange\",\"@onValueChange\"],[[30,2],[30,3],[30,4],[30,5]]],null],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-1\"],[12],[1,\"\\n\"],[41,[30,2,[\"isLast\"]],[[[1,\"        \"],[11,\"button\"],[16,0,[29,[\"btn btn-secondary or-btn \",[52,[30,6],\"disabled\"]]]],[16,\"disabled\",[30,6]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,7],[30,1]],null]],null],[12],[1,\"\\n          \"],[8,[39,6],null,[[\"@icon\"],[\"icon-plus-circle\"]],null],[1,\"\\n          or\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        or\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-1 filter-remove-icon-area\"],[12],[1,\"\\n\"],[41,[30,2,[\"isFirstAndNullified\"]],[[[1,\"        \"],[10,1],[14,0,\"dimmed disable-click\"],[12],[1,\"\\n          \"],[8,[39,6],null,[[\"@icon\"],[\"icon-minus-circle\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,1],[24,\"role\",\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,8],[30,1],[30,2]],null]],null],[12],[1,\"\\n          \"],[8,[39,6],null,[[\"@icon\"],[\"icon-minus-circle\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]],null]],[\"@filterGroup\",\"filter\",\"@onSelectedFilterChange\",\"@onSelectedOperatorChange\",\"@onValueChange\",\"@isAddingDisabled\",\"@addNewFilter\",\"@removeFilter\"],false,[\"each\",\"-track-array\",\"filters/filter-item\",\"if\",\"on\",\"fn\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/filters/filter-group.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});