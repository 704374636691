define("nightwatch-web/templates/dashboard/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Odcpu/iq",
    "block": "[[[1,[28,[35,0],[\"Dashboard\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@pinnedItems\",\"@ensureSelectedGroup\",\"@selectedDateRange\",\"@setDateRange\"],[[30,0,[\"pinnedItems\"]],[30,0,[\"dashboard\",\"ensureSelectedGroup\"]],[30,0,[\"selectedDateRange\"]],[30,0,[\"setDateRange\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"page-title\",\"nw-page-content\",\"pinned-items-area\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/overview.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});