define("nightwatch-web/components/graph/series-chooser-dropdown", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BasicDropdown
    @onClose={{fn (mut this.dropdownShown) false}}
    @horizontalPosition={{this.horizontalPosition}} as |dropdown|>
    <dropdown.Trigger class="inline-block">
      <button
        class="btn btn-secondary-3 btn-secondary-2-with-caret {{if this.dropdownShown 'active'}} {{if this.isLoading 'disabled'}}"
        {{on 'click' (fn (mut this.dropdownShown) true)}}
        type="button"
        id="seriesChooser"
      >
        {{#if (or this.loadingInitially this.isLoading)}}
          Loading data ...
        {{else}}
          Add dimension
          <NwIcon @icon="icon-chevron-down" />
        {{/if}}
      </button>
    </dropdown.Trigger>
    <dropdown.Content class="nw-dropdown">
      <ul aria-labelledby="seriesChooser">
        <li>
          <Graph::GraphSeriesSelectorContainer
            @showBacklinks={{@showBacklinks}}
            @currentSeriesContainer={{@currentSeriesContainer}}
            @selectedSeries={{@selectedSeries}}
            @keywordSearch={{@keywordSearch}}
            @onKeywordSearchChange={{@onKeywordSearchChange}}
            @graph={{@graph}}
            @toggleKeyword={{@toggleKeyword}}
            @toggleWebsiteCompetitor={{@toggleWebsiteCompetitor}}
            @toggleDynamicView={{@toggleDynamicView}}
            @toggleBacklinkView={{@toggleBacklinkView}}
            @toggleSelectedSeries={{@toggleSelectedSeries}}
            @onTabSwitch={{@onTabSwitch}}
          />
        </li>
      </ul>
    </dropdown.Content>
  </BasicDropdown>
  
  */
  {
    "id": "yg1KowFE",
    "block": "[[[8,[39,0],null,[[\"@onClose\",\"@horizontalPosition\"],[[28,[37,1],[[28,[37,2],[[30,0,[\"dropdownShown\"]]],null],false],null],[30,0,[\"horizontalPosition\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Trigger\"]],[[24,0,\"inline-block\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[\"btn btn-secondary-3 btn-secondary-2-with-caret \",[52,[30,0,[\"dropdownShown\"]],\"active\"],\" \",[52,[30,0,[\"isLoading\"]],\"disabled\"]]]],[24,1,\"seriesChooser\"],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,1],[[28,[37,2],[[30,0,[\"dropdownShown\"]]],null],true],null]],null],[12],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"loadingInitially\"]],[30,0,[\"isLoading\"]]],null],[[[1,\"        Loading data ...\\n\"]],[]],[[[1,\"        Add dimension\\n        \"],[8,[39,6],null,[[\"@icon\"],[\"icon-chevron-down\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"Content\"]],[[24,0,\"nw-dropdown\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"ul\"],[14,\"aria-labelledby\",\"seriesChooser\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@showBacklinks\",\"@currentSeriesContainer\",\"@selectedSeries\",\"@keywordSearch\",\"@onKeywordSearchChange\",\"@graph\",\"@toggleKeyword\",\"@toggleWebsiteCompetitor\",\"@toggleDynamicView\",\"@toggleBacklinkView\",\"@toggleSelectedSeries\",\"@onTabSwitch\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"dropdown\",\"@showBacklinks\",\"@currentSeriesContainer\",\"@selectedSeries\",\"@keywordSearch\",\"@onKeywordSearchChange\",\"@graph\",\"@toggleKeyword\",\"@toggleWebsiteCompetitor\",\"@toggleDynamicView\",\"@toggleBacklinkView\",\"@toggleSelectedSeries\",\"@onTabSwitch\"],false,[\"basic-dropdown\",\"fn\",\"mut\",\"if\",\"on\",\"or\",\"nw-icon\",\"graph/graph-series-selector-container\"]]",
    "moduleName": "nightwatch-web/components/graph/series-chooser-dropdown.hbs",
    "isStrictMode": false
  });

  let SeriesChooserDropdown = (_dec = Ember._tracked, (_class = class SeriesChooserDropdown extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dropdownShown", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dropdownShown", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = SeriesChooserDropdown;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SeriesChooserDropdown);
});