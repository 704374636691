define("nightwatch-web/services/filter-data", ["exports", "nightwatch-web/constants/keyword-data"], function (_exports, _keywordData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const searchEngineOptions = _keywordData.allSearchEngines.reduce((options, engine) => {
    options.pushObject({
      value: engine.id,
      display: engine.text
    });
    return options;
  }, []);

  class FilterDataService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "stringConditions", [{
        condition: 'contains',
        display_name: 'contains'
      }, {
        condition: 'starts_with',
        display_name: 'starts with'
      }, {
        condition: 'ends_with',
        display_name: 'ends with'
      }, {
        condition: 'equals',
        display_name: 'equals'
      }, {
        condition: 'not_contains',
        display_name: "doesn't contain"
      }, {
        condition: 'not_starts_with',
        display_name: "doesn't start with"
      }, {
        condition: 'not_ends_with',
        display_name: "doesn't end with"
      }, {
        condition: 'not_equals',
        display_name: "doesn't equal"
      }]);

      _defineProperty(this, "numberAndDateConditions", [{
        condition: 'less',
        display_name: '<'
      }, {
        condition: 'more',
        display_name: '>'
      }, {
        condition: 'less_or_equal',
        display_name: '<='
      }, {
        condition: 'more_or_equal',
        display_name: '>='
      }, {
        condition: 'equals',
        display_name: '='
      }]);

      _defineProperty(this, "presentAbsentConditions", [{
        condition: 'present',
        display_name: 'is present'
      }, {
        condition: 'absent',
        display_name: 'is absent'
      }]);

      _defineProperty(this, "exactConditions", [{
        condition: 'equals',
        display_name: 'is'
      }, {
        condition: 'not_equals',
        display_name: 'is not'
      }]);

      _defineProperty(this, "allFilterFields", [{
        field: 'query',
        data_type: 'string',
        display_name: 'Keyword'
      }, {
        field: 'url',
        data_type: 'string',
        display_name: 'URL'
      }, {
        field: 'position_changed_at',
        data_type: 'date',
        display_name: 'Last position change'
      }, {
        field: 'location',
        data_type: 'string',
        display_name: 'Location'
      }, {
        field: 'language',
        data_type: 'string',
        display_name: 'Language'
      }, {
        field: 'position',
        data_type: 'number_with_presence',
        display_name: 'Position'
      }, {
        field: 'previous_position',
        data_type: 'number',
        display_name: 'Previous position'
      }, {
        field: 'best_position',
        data_type: 'number',
        display_name: 'Best position'
      }, {
        field: 'position_organic',
        data_type: 'number_with_presence',
        display_name: 'Position organic'
      }, {
        field: 'position_local_pack',
        data_type: 'number_with_presence',
        display_name: 'Position in local pack'
      }, {
        field: 'position_places_image',
        data_type: 'number_with_presence',
        display_name: 'Position in image carousel'
      }, {
        field: 'position_featured_snippet',
        data_type: 'number_with_presence',
        display_name: 'Position for featured snippet'
      }, {
        field: 'position_knowledge_panel',
        data_type: 'number_with_presence',
        display_name: 'Position for knowledge panel'
      }, {
        field: 'search_volume_global',
        data_type: 'number_with_presence',
        display_name: 'Search volume global'
      }, {
        field: 'search_volume_local',
        data_type: 'number_with_presence',
        display_name: 'Search volume local'
      }, {
        field: 'average_cpc_global',
        data_type: 'number_with_presence',
        display_name: 'Average CPC global'
      }, {
        field: 'average_cpc_local',
        data_type: 'number_with_presence',
        display_name: 'Average CPC local'
      }, {
        field: 'day_change',
        data_type: 'number',
        display_name: 'Daily change'
      }, {
        field: 'week_change',
        data_type: 'number',
        display_name: 'Weekly change'
      }, {
        field: 'month_change',
        data_type: 'number',
        display_name: 'Monthly change'
      }, {
        field: 'keyword_created_at',
        data_type: 'date',
        display_name: 'Keyword date added'
      }, {
        field: 'keyword_last_processed_at',
        data_type: 'date',
        display_name: 'Keyword last updated'
      }, {
        field: 'result_url',
        data_type: 'string_with_presence',
        display_name: 'Ranking Full URL'
      }, {
        field: 'keyword_tag',
        data_type: 'exact',
        display_name: 'Keyword tag'
      }, {
        field: 'engine',
        data_type: 'select',
        display_name: 'Search engine',
        options: searchEngineOptions // All options should have schema: { value, display }

      }, {
        field: 'mobile',
        data_type: 'boolean',
        display_name: 'Mobile'
      }]);
    }

    getFilterInfoByField(field) {
      return this.allFilterFields.findBy('field', field);
    }

    isBooleanField(field) {
      const filterInfo = this.getFilterInfoByField(field) || {};
      return filterInfo.data_type ? filterInfo.data_type === 'boolean' : false;
    }

    filterHasValues(filter) {
      const {
        condition,
        field,
        value
      } = Ember.getProperties(filter, 'condition', 'field', 'value');
      const isBooleanField = this.isBooleanField(field);

      if (['present', 'absent'].includes(condition) || isBooleanField) {
        return true;
      }

      return [condition, field, value].every(Ember.isPresent);
    }

    filterIsBlank(filter) {
      const {
        condition,
        field,
        value
      } = Ember.getProperties(filter, 'condition', 'field', 'value');
      return [condition, field, value].every(Ember.isBlank);
    }

    filterIsDirty(filter) {
      const {
        condition,
        field,
        value
      } = Ember.getProperties(filter, 'condition', 'field', 'value');
      return [condition, field, value].some(Ember.isPresent);
    }

    removeEmptyFiltersFromGroups(filterGroupsPayload) {
      // Remove empty filters
      filterGroupsPayload.forEach(filterGroup => {
        filterGroup.filters = Ember.get(filterGroup, 'filters').filter(filter => this.filterHasValues(filter));
      }); // Remove groups with empty filters

      const notEmptyGroups = filterGroupsPayload.filter(filterGroup => Ember.get(filterGroup, 'filters.length'));
      return notEmptyGroups;
    }

  }

  _exports.default = FilterDataService;
});