define("nightwatch-web/mixins/site-audit/override-columns", ["exports", "ember-awesome-macros", "ember-awesome-macros/array"], function (_exports, _emberAwesomeMacros, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    overrideColumnsWithSelectedFilters: true,
    pinnedColumns: (0, _emberAwesomeMacros.conditional)('overrideColumnsWithSelectedFilters', (0, _array.uniq)((0, _array.compact)((0, _array.reduce)('filtersObj.filterGroups', (arr, obj) => arr.concat(...(Ember.get(obj, 'filters') || []).mapBy('name')), []))), (0, _emberAwesomeMacros.raw)([]))
  });

  _exports.default = _default;
});