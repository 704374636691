define("nightwatch-web/helpers/divide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.divide = divide;
  _exports.default = void 0;

  function divide([value, factor = 1000]) {
    if (value === null) {
      return null;
    } else if (value === 0) {
      return 0;
    } else {
      return parseFloat(value) / factor;
    }
  }

  var _default = Ember.Helper.helper(divide);

  _exports.default = _default;
});