define("nightwatch-web/components/contextual-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
      <div class="contextual-panel {{if @isOpened "contextual-panel--opened"}}">
      <div class="contextual-panel-content bright-theme-scope">
        {{#if (has-block) }}
          {{yield}}
        {{else if @component}}
          {{component (concat "contextual-panel/" @component) model=@model onClose=@onClose}}
        {{/if}}
      </div>
    </div>
  
  */
  {
    "id": "BI3g64+X",
    "block": "[[[1,\"  \"],[10,0],[15,0,[29,[\"contextual-panel \",[52,[30,1],\"contextual-panel--opened\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"contextual-panel-content bright-theme-scope\"],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"        \"],[18,5,null],[1,\"\\n\"]],[]],[[[41,[30,2],[[[1,\"        \"],[46,[28,[37,4],[\"contextual-panel/\",[30,2]],null],null,[[\"model\",\"onClose\"],[[30,3],[30,4]]],null],[1,\"\\n      \"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@isOpened\",\"@component\",\"@model\",\"@onClose\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"component\",\"concat\"]]",
    "moduleName": "nightwatch-web/components/contextual-panel.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});