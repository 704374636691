define("nightwatch-web/routes/dashboard/url/site-audit/view/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DashboardUrlSiteAuditViewShowRoute = (_dec = Ember._action, (_class = class DashboardUrlSiteAuditViewShowRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        direction: {
          refreshModel: true
        },
        search: {
          refreshModel: true
        },
        filters: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        }
      });
    }

    activate() {
      window.scrollTo(0, 0);
    }

    model(params) {
      const modelForSiteAudit = this.modelFor('dashboard.url.site-audit');
      const view = this.modelFor('dashboard.url.site-audit.view');
      const {
        url,
        crawlingSession,
        comparisonCrawlingSession,
        comparisonEnabled
      } = modelForSiteAudit;
      const encodedFilters = encodeURI(JSON.stringify(view.get('filterConfig')));
      let filters = encodedFilters;

      if (params.filters) {
        filters = params.filters;
      }

      const pagesPromise = this.store.query('site-audit/page', {
        crawling_session_id: crawlingSession.id,
        search_keyword_url_id: url.id,
        limit: params.limit || 50,
        sort: params.sort || 'created_at',
        direction: params.direction || 'desc',
        page: params.page || 1,
        search: params.search,
        filters: filters
      });
      return Ember.RSVP.hash({
        view: view,
        crawlingSession,
        comparisonCrawlingSession,
        url,
        comparisonEnabled,
        pages: pagesPromise
      });
    }

    shouldReloadStats(model) {
      // if is refreshing or if crawling session did change
      const crawlingSessionId = Ember.get(model, 'crawlingSession.id');
      const crawlingSessionStatus = Ember.get(model, 'crawlingSession.status');
      const comparisonCrawlingSessionId = Ember.get(model, 'comparisonCrawlingSession.id');
      const viewId = Ember.get(model, 'view.id');
      const {
        currentCrawlingSessionId,
        currentCrawlingSessionStatus,
        currentComparisonCrawlingSessionId,
        currentViewId
      } = this;
      if (this.isRefreshing) return true;
      const shouldReload = crawlingSessionId != currentCrawlingSessionId || comparisonCrawlingSessionId != currentComparisonCrawlingSessionId || viewId != currentViewId || crawlingSessionStatus != currentCrawlingSessionStatus;
      return shouldReload;
    }

    resetController() {
      this.controllerFor('dashboard.url.site-audit').set('currentView', null);
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        crawlingSession: model.crawlingSession,
        comparisonCrawlingSession: model.comparisonCrawlingSession,
        comparisonEnabled: model.comparisonEnabled
      }); // Set initial filters from saved view

      if (!controller.filters) {
        controller.set('filters', encodeURIComponent(JSON.stringify(model.view.get('filterConfigWithUnderscore'))));
      }

      if (this.shouldReloadStats(model)) {
        controller.loadStats();
      } // For navbar in site audit breadcrumbs


      this.controllerFor('dashboard.url.site-audit').set('currentView', model.view);
      this.setProperties({
        currentViewId: Ember.get(model, 'view.id'),
        currentCrawlingSessionId: Ember.get(model, 'crawlingSession.id'),
        currentCrawlingSessionStatus: Ember.get(model, 'crawlingSession.status'),
        currentComparisonCrawlingSessionId: Ember.get(model, 'comparisonCrawlingSession.id')
      });
    }

    async loading(transition) {
      const controller = this.controllerFor('dashboard.url.site-audit.view.show');
      controller.set('isLoading', true);

      try {
        await transition.promise;
      } finally {
        controller.set('isLoading', false);
      }

      return true;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = DashboardUrlSiteAuditViewShowRoute;
});