define("nightwatch-web/helpers/relative-date", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relativeDate = relativeDate;
  _exports.default = void 0;

  function relativeDate([date, addSuffix = true]) {
    return (0, _dateFns.isDate)(date) ? (0, _dateFns.formatDistanceToNow)(date, {
      addSuffix
    }) : '/';
  }

  var _default = Ember.Helper.helper(relativeDate);

  _exports.default = _default;
});