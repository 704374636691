define("nightwatch-web/initializers/browser-support", ["exports", "nightwatch-web/utils/html-ui-elements"], function (_exports, _htmlUiElements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  // Tests runner
  const supports = tests => {
    for (let i = 0; i < tests.length; i++) {
      if (!tests[i]()) return false;
    }

    return true;
  };
  /*
   * Tests
   * - return true if pass, false if fail
   */
  // Test if querySelectorAll exists and returns a forEach-able NodeList


  const forEachOnNodeList = () => {
    const el = (0, _htmlUiElements.html)('div');
    if (!el.querySelectorAll) return false;
    if (!el.querySelectorAll('[test]').forEach) return false;
    return true;
  };

  const stringIncludes = () => {
    if (!String.prototype.includes) return false;
    return true;
  };

  const arrayFrom = () => {
    if (!Array.from) return false;
    return true;
  };

  const initialize = application => {
    if (!supports([forEachOnNodeList, stringIncludes, arrayFrom])) {
      application.deferReadiness(); // Stops booting

      const warning = (0, _htmlUiElements.html)('div', {
        class: 'unsupported-browser'
      }, (0, _htmlUiElements.html)('h1', {
        class: 'unsupported-browser__heading'
      }, 'Please update or change your browser'), (0, _htmlUiElements.html)('p', {
        class: 'unsupported-browser__description'
      }, "You're using a browser that is missing essential programming features. We're not particularly strict about blocking outdated browsers but when missing essential functionality, we don't have a choice."), (0, _htmlUiElements.html)('p', {
        class: 'unsupported-browser__description'
      }, 'This application is being extensively tested on recent versions of Firefox, Safari and Chromium based browsers. Other browsers might work.'), (0, _htmlUiElements.html)('p', {
        class: 'unsupported-browser__description'
      }, (0, _htmlUiElements.html)('a', {
        href: 'mailto:info@nightwatch.io'
      }, 'Need help? Get in touch.')));
      document.body.className = 'dusk';
      document.body.appendChild(warning);
    }
  };

  _exports.initialize = initialize;
  var _default = {
    name: 'browser-support',
    before: ['inject-notifications', 'input-attributes', 'site-data', 'user-settings'],
    initialize
  };
  _exports.default = _default;
});