define("nightwatch-web/templates/dashboard/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "84hXN7DP",
    "block": "[[[1,[28,[35,0],[\"Reports\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"main-area-row bottom-border clearfix\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"pull-left\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,\"Reports \"],[10,1],[14,0,\"items-count\"],[12],[1,\"(\"],[1,[30,0,[\"reports\",\"length\"]]],[1,\")\"],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"pull-right\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-primary btn-add-report\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"toggleReportModal\"]]],null],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@icon\"],[\"icon-plus-circle\"]],null],[1,\" Add Report\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,4],null,[[\"@reports\",\"@sort\",\"@direction\",\"@onDelete\"],[[30,0,[\"reports\"]],[30,0,[\"sort\"]],[30,0,[\"direction\"]],[30,0,[\"delete\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"reportModalShown\"]],[[[1,\"  \"],[8,[39,6],null,[[\"@onClose\",\"@alignment\",\"@containerClass\",\"@translucentOverlay\"],[[30,0,[\"toggleReportModal\"]],\"center\",\"nw-modal nw-modal--min-l\",true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,[[\"@onClose\"],[[30,0,[\"toggleReportModal\"]]]],[[\"default\"],[[[[1,\"Add Report\"]],[]]]]],[1,\"\\n    \"],[8,[39,8],null,[[\"@goToNewReport\"],[[30,0,[\"goToNewReport\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"page-title\",\"nw-page-content\",\"on\",\"nw-icon\",\"dashboard-report-list\",\"if\",\"modal-dialog\",\"modal-header\",\"report-candidates\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/reports.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});