define("nightwatch-web/services/notifications", ["exports", "ember-cli-notifications/services/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NotificationsService extends _notifications.default {
    remove(...notifications) {
      notifications.forEach(n => this.removeNotification(n));
    }

  }

  _exports.default = NotificationsService;
});