define("nightwatch-web/components/graph/universal-graph", ["exports", "ember-uuid", "nightwatch-web/utils/base64-images", "nightwatch-web/utils/universal-graph-config", "lodash-es/zip", "lodash-es/isEmpty", "lodash-es/difference", "highcharts/highstock", "highcharts/modules/exporting"], function (_exports, _emberUuid, _base64Images, _universalGraphConfig, _zip, _isEmpty, _difference, _highstock, _exporting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id="universal-graph-{{this.graphId}}"></div>
  
  */
  {
    "id": "qEeLHX1m",
    "block": "[[[10,0],[15,1,[29,[\"universal-graph-\",[30,0,[\"graphId\"]]]]],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "nightwatch-web/components/graph/universal-graph.hbs",
    "isStrictMode": false
  });

  (0, _exporting.default)(_highstock.default);
  const {
    not,
    equal,
    filter,
    filterBy,
    map
  } = Ember.computed;

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    noteUtils: Ember.inject.service(),
    store: Ember.inject.service(),
    theme: Ember.inject.service(),
    graphUtils: Ember.inject.service('graphs/graph-utils'),
    graphDataTransforms: Ember.inject.service('graphs/graph-data-transforms'),
    isLoading: false,
    currentDrawnSeries: null,
    currentDrawnDateFrom: null,
    currentDrawnDateTo: null,
    currentDrawnGrouping: null,
    useLineGradient: false,
    graphSeriesObserverTimer: null,

    onSelectKeyword() {},

    onUpdateChart() {},

    setChartObject() {},

    nonLoadingSeries: filter('graphSeries', function (series) {
      return !series.get('isLoading');
    }),
    seriesForChart: map('nonLoadingSeries', function (series) {
      return this.graphUtils.transformSeriesForChart(series);
    }),

    get maxDataValue() {
      // max value of all series data
      return this.seriesForChart.filterBy('yAxis', 'axis-y-position').mapBy('data').reduce((max, seriesData = []) => {
        // walk through all points of all series and find a point with max value
        return Math.max(max, seriesData.reduce((max, point = []) => {
          return Math.max(max, point[1] || 0);
        }, 0));
      }, 0);
    },

    get maxGraphValue() {
      // absolute max value of the graph
      const maxDataValue = this.maxDataValue || 1;
      const tickStep = Math.ceil(maxDataValue * _universalGraphConfig.MAX_DATA_VALUE_OFFSET / _universalGraphConfig.TICK_AMOUNT);
      return tickStep * _universalGraphConfig.TICK_AMOUNT;
    },

    get emptyLineValue() {
      const {
        maxGraphValue,
        emptyLinePaddingSize
      } = this;
      return maxGraphValue - emptyLinePaddingSize;
    },

    get emptyLinePaddingSize() {
      // padding between empty segment line and X axis
      const {
        maxGraphValue,
        maxDataValue
      } = this;
      const rate = maxDataValue === 0 ? 0.05 : _universalGraphConfig.EMPTY_SEGMENT_LINE_PADDING_RATE;
      const tickSize = maxDataValue / _universalGraphConfig.TICK_AMOUNT;
      const maxRate = tickSize / 3 / 2; // third-tick size for top and bottom padding

      return (maxGraphValue - maxDataValue) * Math.min(rate, maxRate);
    },

    get graphDensity() {
      const chartObject = this.chartObject;
      const maxSeriesPointsAmount = this.graphSeries.map(s => s.get('series.length')).compact().sort((a, b) => b - a)[0] || 0; // descending sort

      const plotWidth = chartObject.plotSizeX;
      return maxSeriesPointsAmount / plotWidth;
    },

    keywordSeries: filterBy('nonLoadingSeries', 'keywordId'),
    seriesWithEmptySegments: filter('keywordSeries', function (series) {
      if (!series) return []; // series that have empty values

      return (series.get('series') || Ember.A()).any(([date, position]) => date && !position);
    }),
    graphSeriesObserver: Ember.observer('graphSeries.[]', function () {
      const timer = Ember.run.scheduleOnce('afterRender', () => {
        // empty segments redraw is relying on the chart object to have some data (processedXData,...),
        // that's why we wait for the graph to have some and then call drawing empty segments
        this.redrawEmptySegments();
      });
      this.set('graphSeriesObserverTimer', timer);
    }),

    get emptySegmentSeries() {
      // Highcharts series objects for empty segments
      const {
        chartObject,
        emptyLineValue
      } = this;
      return this.seriesWithEmptySegments.map(seriesWithEmptySegments => {
        const {
          yAxis,
          seriesType,
          zIndex,
          isLoading,
          color
        } = seriesWithEmptySegments;
        const seriesId = _universalGraphConfig.EMPTY_SEGMENTS_ID;
        const parentSeriesId = seriesWithEmptySegments.get('seriesId');
        const parentSeries = chartObject.series.findBy('options.seriesId', parentSeriesId); // to properly connect with parent series regardless grouping, empty segments series uses processed series data (after grouping application)

        const series = (0, _zip.default)(parentSeries.processedXData, parentSeries.processedYData);
        const name = this.graphUtils.buildSeriesGraphTitle(seriesWithEmptySegments); // inherit color from parent series

        let emptySegmentSeriesColor = color;

        if (this.useLineGradient) {
          emptySegmentSeriesColor = this.graphDataTransforms.gradientify(color, 0.3, 85);
        }

        const type = seriesType;
        const className = _universalGraphConfig.SERIES_WITH_SERP_PREVIEW_CLASS;
        const cursor = 'pointer'; // disable grouping because it uses already processed data from parent series

        const dataGrouping = {
          enabled: false
        }; // normalize series data

        const emptySegmentPoints = series.map(([timestamp, position], index) => {
          const prevPoint = series[index - 1];
          const nextPoint = series[index + 1];

          if (!position) {
            // max value in case of empty data
            return [timestamp, emptyLineValue];
          } else if (prevPoint && !prevPoint[1] || nextPoint && !nextPoint[1]) {
            // certain value on connection points
            return [timestamp, position];
          } else {
            // no line when data exists on parent series
            return [timestamp, null];
          }
        });
        return {
          seriesId,
          parentSeriesId,
          linkedTo: parentSeriesId,
          name,
          data: emptySegmentPoints,
          yAxis,
          color: emptySegmentSeriesColor,
          seriesColor: color,
          type,
          className,
          cursor,
          zIndex: zIndex - 1,
          isLoading,
          dataGrouping
        };
      });
    },

    isDefaultGrouping: equal('grouping', _universalGraphConfig.DEFAULT_GROUPING),
    forceGrouping: not('isDefaultGrouping'),
    gridLineColor: Ember.computed('theme.isBright', function () {
      return this.get('theme.isBright') ? _universalGraphConfig.COLORS.GRID_LINE_LIGHT : _universalGraphConfig.COLORS.GRID_LINE_DARK;
    }),

    init() {
      this._super(...arguments);

      this.setProperties({
        graphSeries: [],
        graphId: (0, _emberUuid.v4)(),
        grouping: _universalGraphConfig.DEFAULT_GROUPING,
        currentDrawnSeries: []
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      const {
        graphSeries,
        isLoading
      } = this;
      const isRendered = !(0, _isEmpty.default)(this.chartObject);

      if (isRendered && Ember.isArray(graphSeries) && !isLoading) {
        this.updateChart();
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.createChart();
      this.attachChartResizeListener();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.detachChartResizeListener();
      this.destroyChart();
      if (this.graphSeriesObserverTimer) Ember.run.cancel(this.graphSeriesObserverTimer);
    },

    createChart() {
      const chartObject = _highstock.default.stockChart(`universal-graph-${this.graphId}`, _universalGraphConfig.default);

      this.setChartObject(chartObject);
      this.setProperties({
        chartObject
      });
      this.attachEventListeners();
    },

    destroyChart() {
      const chart = this.chartObject;

      if (chart) {
        chart.destroy();
      }

      this.set('chartObject', null);
    },

    updateChart() {
      const {
        chartObject,
        onUpdateChart
      } = this;
      if (!chartObject) return;
      this.updateGrouping();
      this.updateColors();
      this.redrawChart();
      this.updateGraphColors();
      this.updateGraphTitles();
      this.updateCurrentValues();
      this.updateNotes();
      this.updateMarkers();
      chartObject.redraw();
      onUpdateChart(chartObject);
    },

    redrawChart() {
      const {
        dateFrom,
        dateTo,
        grouping,
        currentDrawnDateTo,
        currentDrawnDateFrom,
        currentDrawnGrouping
      } = this;

      if (currentDrawnDateFrom !== dateFrom || currentDrawnDateTo !== dateTo || currentDrawnGrouping !== grouping) {
        this.fullRedraw();
      } else {
        this.incrementalRedraw();
      }
    },

    fullRedraw() {
      const {
        chartObject,
        seriesForChart
      } = this;

      while (chartObject.series.length) {
        chartObject.series[0].remove(false);
      }

      seriesForChart.forEach(item => chartObject.addSeries(item, false));
    },

    incrementalRedraw() {
      const {
        chartObject,
        seriesForChart,
        currentDrawnSeries
      } = this;
      const series = seriesForChart;
      const seriesIds = series.mapBy('seriesId');
      const currentDrawnSeriesIds = currentDrawnSeries.mapBy('seriesId'); // Dynamically remove/add series to keep nice animations

      const seriesIdsAdded = (0, _difference.default)(seriesIds, currentDrawnSeriesIds);
      const seriesIdsRemoved = (0, _difference.default)(currentDrawnSeriesIds, seriesIds);
      seriesIdsAdded.forEach(seriesId => {
        chartObject.addSeries(series.findBy('seriesId', seriesId), false);
      });
      seriesIdsRemoved.forEach(seriesId => {
        const relObj = chartObject.series.findBy('options.seriesId', seriesId);
        if (relObj) relObj.remove(false);
      });
    },

    redrawEmptySegments() {
      const {
        chartObject,
        emptySegmentSeries,
        maxGraphValue,
        emptyLinePaddingSize,
        emptyLineValue
      } = this;
      const positionAxis = chartObject.get(_universalGraphConfig.AXIS.POSITION);

      if (!emptySegmentSeries.length) {
        positionAxis.plotLinesAndBands.forEach(band => positionAxis.removePlotBand(band.id));
        chartObject.series.filterBy('options.seriesId', _universalGraphConfig.EMPTY_SEGMENTS_ID).forEach(series => series === null || series === void 0 ? void 0 : series.remove(false));
        return;
      }

      chartObject.series.filterBy('options.seriesId', _universalGraphConfig.EMPTY_SEGMENTS_ID).forEach(series => series === null || series === void 0 ? void 0 : series.remove(false));
      emptySegmentSeries.forEach(item => chartObject.addSeries(item, false));
      const tickInterval = maxGraphValue / _universalGraphConfig.TICK_AMOUNT; // graph ticks positions, starting from 2nd one

      const tickPositions = [...Array(_universalGraphConfig.TICK_AMOUNT)].map((item, index) => ++index * tickInterval);
      positionAxis.update({
        max: maxGraphValue,
        tickPositions: [1, ...tickPositions] // first tick is always `1`

      });
      positionAxis.plotLinesAndBands.forEach(band => positionAxis.removePlotBand(band.id));

      if (emptySegmentSeries.length) {
        positionAxis.addPlotBand({
          from: emptyLineValue - emptyLinePaddingSize,
          to: maxGraphValue,
          color: _universalGraphConfig.EMPTY_SEGMENTS_BAND_COLOR
        });
      }
    },

    updateMarkers() {
      const {
        chartObject,
        graphDensity
      } = this;
      if (!graphDensity || isNaN(graphDensity)) return;
      chartObject.series.filterBy('options.seriesId').filter(s => s.userOptions.group !== 'nav').forEach(s => s.update({
        marker: {
          enabled: graphDensity < _universalGraphConfig.MAX_DENSITY_FOR_MARKERS
        }
      }, false));
    },

    updateGraphColors() {
      const {
        chartObject,
        graphSeries
      } = this;
      graphSeries.filter(s => !s.get('icon')) // Don't modify series colors which have the icon
      .forEach(s => {
        const {
          seriesId,
          color
        } = s;
        let adjustedColor = color;

        if (this.useLineGradient) {
          adjustedColor = this.graphDataTransforms.gradientify(color, 0.3, 85);
        }

        if (!chartObject) return;
        const hcSeries = chartObject.series.findBy('options.seriesId', seriesId);

        if (hcSeries) {
          hcSeries.options.color = adjustedColor;
          hcSeries.update(hcSeries.options, false);
        }
      });
    },

    updateGraphTitles() {
      const graphSeries = this.graphSeries || [];
      graphSeries.forEach(seriesObj => {
        const keywordModel = seriesObj.get('keywordModel');

        if (keywordModel && keywordModel.then) {
          keywordModel.then(() => this.updateSeriesTitle(seriesObj));
        } else {
          this.updateSeriesTitle(seriesObj, false);
        }
      });
    },

    updateSeriesTitle(series, redraw = true) {
      const chartObject = this.chartObject;
      const hcSeries = chartObject.series.findBy('options.seriesId', series.get('seriesId'));

      if (hcSeries) {
        hcSeries.options.name = this.graphUtils.buildSeriesGraphTitle(series);
        hcSeries.update(hcSeries.options, redraw);
      }
    },

    updateGrouping() {
      const {
        chartObject,
        grouping,
        forceGrouping
      } = this;
      const units = [[grouping, [1]]];
      const forced = forceGrouping;
      const dataGrouping = {
        units,
        forced
      };
      const series = {
        dataGrouping
      };
      const plotOptions = {
        series
      };
      chartObject.update({
        plotOptions
      }, false);
    },

    updateColors() {
      const {
        chartObject,
        gridLineColor
      } = this;
      const yAxis = chartObject.options.yAxis || [];
      yAxis.forEach(item => {
        if (item.gridLineColor) {
          item.gridLineColor = gridLineColor;
        }
      });
      chartObject.update({
        yAxis
      }, false);
    },

    updateCurrentValues() {
      const {
        seriesForChart,
        dateFrom,
        dateTo,
        grouping
      } = this;
      this.setProperties({
        currentDrawnSeries: seriesForChart,
        currentDrawnDateFrom: dateFrom,
        currentDrawnDateTo: dateTo,
        currentDrawnGrouping: grouping
      });
    },

    updateNotes() {
      const {
        chartObject,
        noteUtils,
        url,
        dateFrom,
        dateTo
      } = this;
      if (!chartObject) return;
      const notes = url.get('notes');
      const notesForGraph = noteUtils.transformForGraph(notes, dateFrom, dateTo);
      const symbol = _base64Images.NOTE_SYMBOL;
      let marker = {
        symbol
      };

      if (this.isSmallGraph) {
        const notesIconSize = 11;
        marker['width'] = notesIconSize;
        marker['height'] = notesIconSize;
      }

      this.waitForSomeSeriesToLoad(this.graphSeries).then(() => {
        if (!chartObject || !chartObject.series) return;
        const noteSeries = chartObject.series.findBy('name', 'Notes');
        if (noteSeries) noteSeries.remove(true);
        chartObject.addSeries({
          name: 'Notes',
          cursor: 'pointer',
          data: notesForGraph,
          lineWidth: 0,
          zIndex: 3,
          marker: marker,
          yAxis: 'axis-y-annotation' // Annotation axis

        });
      }).catch(() => {// Zzz
      });
    },

    attachChartResizeListener() {
      this.__reflowInterval = setInterval(this.reflowChart.bind(this), _universalGraphConfig.CHART_REFLOW_INTERVAL);
    },

    detachChartResizeListener() {
      clearInterval(this.__reflowInterval);
    },

    reflowChart() {
      const chart = this.chartObject;

      if (chart) {
        chart.reflow();
      }
    },

    attachEventListeners() {
      this.chartObject.update({
        plotOptions: {
          series: {
            point: {
              events: {
                click: this.handleGraphNodeClick.bind(this),
                mouseOver: this.handleGraphNodeMouseOver.bind(this)
              }
            }
          }
        }
      }, false);
    },

    handleGraphNodeClick({
      target
    }) {
      const {
        chartObject,
        graphSeries,
        onSelectKeyword
      } = this;
      const point = chartObject.hoverPoints.find(hoverPoint => {
        const targetSeriesElement = target.parentElement; // When series have no markers (just a line), hoverPoint will have graphic undefined
        // TODO: enable marker when clicking on line.

        if (hoverPoint.graphic) {
          const hoverPointSeriesElement = hoverPoint.graphic.element.parentElement;
          return hoverPointSeriesElement === targetSeriesElement;
        } else {
          return hoverPoint;
        }
      });
      if (!point) return;
      let {
        seriesId
      } = point.series.userOptions;

      if (seriesId === _universalGraphConfig.EMPTY_SEGMENTS_ID) {
        seriesId = point.series.userOptions.parentSeriesId;
      }

      const series = graphSeries.findBy('seriesId', seriesId);
      const keywordModel = series && series.get('keywordModel');

      if (keywordModel) {
        onSelectKeyword(keywordModel, point.x, series);
      }
    },

    handleGraphNodeMouseOver({
      target
    }) {
      if (this.__currentHoverTimestamp !== target.x) {
        this.__currentHoverTimestamp = target.x;
      }
    },

    waitForSomeSeriesToLoad(series) {
      const waitFor = 500;
      const timeout = 30 * 1000; // 30 sec

      let currentTime = 0;
      return new Promise((resolve, reject) => {
        if (series.length === 0) return resolve();

        const _check = () => {
          if (this._state !== 'inDOM') return reject('graph no longer in DOM');
          currentTime += waitFor;
          Ember.run.later(() => {
            const someSeriesLoaded = series.mapBy('isLoading').some(loading => !loading);

            if (currentTime > timeout) {
              reject('Notes waiting for series to load timeout');
            } else if (!someSeriesLoaded) {
              _check();
            } else {
              resolve();
            }
          }, waitFor);
        };

        _check();
      });
    },

    reloadNotes: Ember._action(function (url = null) {
      const urlToLoadNotes = url || this.url;
      if (!urlToLoadNotes || !urlToLoadNotes.loadNotes) return;
      urlToLoadNotes.loadNotes().then(() => this.updateNotes());
    })
  }));

  _exports.default = _default;
});