define("nightwatch-web/components/site-audit/page-table-header", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route={{@linkPath}} @model={{@sortLinkResource}} @query={{hash page=1 sort=this.column.sortField direction=this.oppositeDirection}} class="nw-table__sorter">
    <span class="nw-table__sorter-label" role="button" {{on "click" (fn this.persistSorting this.column.sortField this.oppositeDirection)}}>{{if (eq this.column.name 'load_time') 'Load Time' this.column.displayName}}</span>
    {{#if (eq this.sort this.column.sortField)}}
      <SortDirection @direction={{this.direction}} />
    {{/if}}
  </LinkTo>
  {{#unless this.column.pinned}}
    <Backlinks::BacklinksColumnSwitcher
      data-test-column-switcher
      @metricsTableName="Site Audit Pages"
      @availableColumns={{this.availableColumns}}
      @selectedColumns={{this.selectedColumns}}
      @selectedColumn={{this.column.name}}
      @index={{this.index}}
      @columnDisplayNames={{this.columnDisplayNames}}
      @dropdownDisplayNames={{this.dropdownDisplayNames}}
      @switchColumn={{this.switchColumn}}
    />
  {{/unless}}
  
  */
  {
    "id": "43sUyLM7",
    "block": "[[[8,[39,0],[[24,0,\"nw-table__sorter\"]],[[\"@route\",\"@model\",\"@query\"],[[30,1],[30,2],[28,[37,1],null,[[\"page\",\"sort\",\"direction\"],[1,[30,0,[\"column\",\"sortField\"]],[30,0,[\"oppositeDirection\"]]]]]]],[[\"default\"],[[[[1,\"\\n  \"],[11,1],[24,0,\"nw-table__sorter-label\"],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"persistSorting\"]],[30,0,[\"column\",\"sortField\"]],[30,0,[\"oppositeDirection\"]]],null]],null],[12],[1,[52,[28,[37,5],[[30,0,[\"column\",\"name\"]],\"load_time\"],null],\"Load Time\",[30,0,[\"column\",\"displayName\"]]]],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"sort\"]],[30,0,[\"column\",\"sortField\"]]],null],[[[1,\"    \"],[8,[39,6],null,[[\"@direction\"],[[30,0,[\"direction\"]]]],null],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"],[41,[51,[30,0,[\"column\",\"pinned\"]]],[[[1,\"  \"],[8,[39,8],null,[[\"@metricsTableName\",\"@availableColumns\",\"@selectedColumns\",\"@selectedColumn\",\"@index\",\"@columnDisplayNames\",\"@dropdownDisplayNames\",\"@switchColumn\"],[\"Site Audit Pages\",[30,0,[\"availableColumns\"]],[30,0,[\"selectedColumns\"]],[30,0,[\"column\",\"name\"]],[30,0,[\"index\"]],[30,0,[\"columnDisplayNames\"]],[30,0,[\"dropdownDisplayNames\"]],[30,0,[\"switchColumn\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@linkPath\",\"@sortLinkResource\"],false,[\"link-to\",\"hash\",\"on\",\"fn\",\"if\",\"eq\",\"sort-direction\",\"unless\",\"backlinks/backlinks-column-switcher\"]]",
    "moduleName": "nightwatch-web/components/site-audit/page-table-header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'div',
    classNames: ['nw-table__col'],
    classNameBindings: ['columnCssClass'],
    columnCssClass: (0, _emberAwesomeMacros.computed)(_emberAwesomeMacros.string.dasherize('column.name'), n => `nw-table__col--${n}`)
  }));

  _exports.default = _default;
});