define("nightwatch-web/components/nw-sidebar/url-selector-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @showHideUrlSelector}}
    <button
      type="button"
      class="nw-resource-selector"
      {{on "click" (fn @showHideUrlSelector true)}}>
      <div class="nw-resource-selector__toggle">
        <div class="nw-resource-selector__opener-title">
          {{#if @currentUrl}}
            <span class="nw-resource-selector__url-group-name truncate">
              {{@currentUrl.urlGroup.name}}
            </span>
            <NwIcon @icon="icon-chevron-right" />
            <span class="nw-resource-selector__url-group-item-name truncate">
              {{@currentUrl.name}}
            </span>
          {{else if @currentDynamicView}}
            <span class="nw-resource-selector__url-group-name truncate">
              {{@currentDynamicView.urlGroup.name}}
            </span>
            <NwIcon @icon="icon-chevron-right" />
            <span class="nw-resource-selector__url-group-item-name truncate">
              {{@currentDynamicView.name}}
            </span>
          {{/if}}
          <NwIcon class="{{if @showUrlSelector "nw-resource-selector__toggled-icon"}}" @icon="icon-caret-down" />
        </div>
      </div>
    </button>
  
    <ClickOutside @onClickOutside={{fn @showHideUrlSelector false}} @exceptSelector=".nw-resource-selector">
      <div id="resource_selector" class="nw-resource-selector__menu" role="button">
      </div>
    </ClickOutside>
  {{/if}}
  
  */
  {
    "id": "9eCNKdR4",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"button\"],[24,0,\"nw-resource-selector\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,1],true],null]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"nw-resource-selector__toggle\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-resource-selector__opener-title\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"          \"],[10,1],[14,0,\"nw-resource-selector__url-group-name truncate\"],[12],[1,\"\\n            \"],[1,[30,2,[\"urlGroup\",\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[39,3],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\"\\n          \"],[10,1],[14,0,\"nw-resource-selector__url-group-item-name truncate\"],[12],[1,\"\\n            \"],[1,[30,2,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"          \"],[10,1],[14,0,\"nw-resource-selector__url-group-name truncate\"],[12],[1,\"\\n            \"],[1,[30,3,[\"urlGroup\",\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[39,3],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\"\\n          \"],[10,1],[14,0,\"nw-resource-selector__url-group-item-name truncate\"],[12],[1,\"\\n            \"],[1,[30,3,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]],null]],[]]],[1,\"        \"],[8,[39,3],[[16,0,[29,[[52,[30,4],\"nw-resource-selector__toggled-icon\"]]]]],[[\"@icon\"],[\"icon-caret-down\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,4],null,[[\"@onClickOutside\",\"@exceptSelector\"],[[28,[37,2],[[30,1],false],null],\".nw-resource-selector\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,1,\"resource_selector\"],[14,0,\"nw-resource-selector__menu\"],[14,\"role\",\"button\"],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@showHideUrlSelector\",\"@currentUrl\",\"@currentDynamicView\",\"@showUrlSelector\"],false,[\"if\",\"on\",\"fn\",\"nw-icon\",\"click-outside\"]]",
    "moduleName": "nightwatch-web/components/nw-sidebar/url-selector-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});