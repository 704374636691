define("nightwatch-web/routes/dashboard/url/site-audit/view/checks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlSiteAuditViewChecksRoute = (_dec = Ember.inject.service, (_class = class DashboardUrlSiteAuditViewChecksRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    activate() {
      window.scrollTo(0, 0);
    }

    model() {
      const view = this.modelFor('dashboard.url.site-audit.view');
      const modelForSiteAudit = this.modelFor('dashboard.url.site-audit');
      const {
        url,
        comparisonEnabled
      } = modelForSiteAudit;
      const checksPromise = this.store.query('site-audit/audit-check', {
        search_keyword_url_id: url.id
      }).then(checks => {
        checks.forEach(check => check.set('url', url));
        return checks;
      });
      return Ember.RSVP.hash({
        url,
        view,
        comparisonEnabled,
        checks: checksPromise
      });
    }

    afterModel(model, transition) {
      // Go to the first check
      if (transition.targetName == 'dashboard.url.site-audit.view.checks.index') {
        const {
          view,
          checks
        } = model;
        const check = checks.get('firstObject');
        this.router.transitionTo('dashboard.url.site-audit.view.checks.show', view.id, check.id);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DashboardUrlSiteAuditViewChecksRoute;
});