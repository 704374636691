define("nightwatch-web/components/settings/settings-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-settings__body">
    <Settings::SettingsNav @user={{@user}} />
    <div class="nw-settings__main">
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "K7XNDohK",
    "block": "[[[10,0],[14,0,\"nw-settings__body\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[10,0],[14,0,\"nw-settings__main\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@user\",\"&default\"],false,[\"settings/settings-nav\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/settings/settings-body.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});