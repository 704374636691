define("nightwatch-web/templates/settings/subusers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aRAf7XVi",
    "block": "[[[1,[28,[35,0],[\"Users\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@subusers\"],[[28,[37,2],[\"isNew\",false,[30,1]],null]]],null],[1,\"\\n\"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"settings/subuser-list\",\"filter-by\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/settings/subusers.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});