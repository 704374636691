define("nightwatch-web/helpers/relative-date-today", ["exports", "date-fns", "nightwatch-web/helpers/relative-date"], function (_exports, _dateFns, _relativeDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relativeDateToday = relativeDateToday;
  _exports.default = void 0;

  // If date is today, return "today", otherwise use relativeDate
  function relativeDateToday([date]) {
    return (0, _dateFns.isSameDay)(new Date(), date) ? 'today' : (0, _relativeDate.relativeDate)([date]);
  }

  var _default = Ember.Helper.helper(relativeDateToday);

  _exports.default = _default;
});