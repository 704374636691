define("nightwatch-web/mixins/backlink-view-controller", ["exports", "nightwatch-web/config/environment", "lodash-es/xor", "lodash-es/isEmpty", "date-fns", "nightwatch-web/utils/refresh-route", "nightwatch-web/utils/is-testing"], function (_exports, _environment, _xor, _isEmpty, _dateFns, _refreshRoute, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fetch: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    router: Ember.inject.service(),
    backlinksNotifications: Ember.inject.service('notifications/backlinks-notifications'),
    queryParams: ['page', 'sort', 'direction', 'params', 'search', 'filters', 'one_link_per_domain', 'root_domain', 'domain', 'limit'],
    page: 1,
    limit: 50,
    sort: 'first_seen',
    direction: 'desc',
    emptyFilters: false,
    search: null,
    filters: null,
    one_link_per_domain: false,
    domain: null,
    root_domain: null,
    backlinks: Ember.computed.alias('model.backlinks'),
    backlinkView: Ember.computed.alias('model.backlinkView'),
    stats: Ember.Object.create({}),
    url: Ember.computed.alias('model.url'),
    statsDateRangeType: 'day',
    statsDateFrom: (0, _dateFns.format)((0, _dateFns.sub)(new Date(), {
      days: 1
    }), 'yyyy-MM-dd'),
    statsDateTo: (0, _dateFns.format)(new Date(), 'yyyy-MM-dd'),
    csvExportUrl: Ember.computed('url', 'filters', 'search', 'one_link_per_domain', function () {
      const token = this.session.token;
      const urlId = this.url.id;
      const filters = this.filters || '';
      const search = this.search || '';
      const oneLinkPerDomain = this.one_link_per_domain || '';
      return `${_environment.default.apiBaseURL}backlinks/export_csv?access_token=${token}&url_id=${urlId}&filters=${filters}&search=${search}&one_link_per_domain=${oneLinkPerDomain}`;
    }),
    isSearch: Ember.computed('search', 'filters', function () {
      return Ember.isPresent(this.search) || Ember.isPresent(this.filters);
    }),
    limitReached: Ember.computed('session.user.backlinksRemaining', function () {
      return this.get('session.user.backlinksRemaining') <= 0;
    }),
    selectedBacklinks: Ember.A(),
    allBacklinksSelected: Ember.computed('selectedBacklinks.[]', function () {
      if (!this.backlinks) return false;
      return (0, _isEmpty.default)((0, _xor.default)(this.selectedBacklinks.mapBy('id'), this.backlinks.mapBy('id')));
    }),
    someBacklinksSelected: Ember.computed('selectedBacklinks.[]', function () {
      return this.get('selectedBacklinks.length') > 0;
    }),
    filtersObj: Ember.computed('filters', function () {
      if (!this.filters) return {};

      try {
        return JSON.parse(decodeURI(this.filters));
      } catch (err) {
        return {};
      }
    }),

    searchBacklinks(searchTerm) {
      this.setProperties({
        page: 1,
        search: searchTerm
      });
    },

    fetchStats() {
      if (this.statsDisabled) return;
      const dateFrom = this.statsDateFrom;
      const dateTo = this.statsDateTo;
      const urlId = this.get('model.url.id');
      const filters = this.filters;
      const groupByDomain = this.one_link_per_domain;
      this.set('statsLoading', true);
      this.fetch.request('/backlink_stats', {
        data: {
          url_id: urlId,
          filters: filters,
          date_from: dateFrom,
          date_to: dateTo,
          group_by_domain: groupByDomain,
          for_overview: true
        }
      }).then(stats => {
        this.stats.setProperties(stats);
      }).finally(() => {
        this.set('statsLoading', false);
      });
    },

    updateBacklinkViewConfig(props) {
      let obj = this.get('backlinkView.filterConfig') || {};
      obj = Object.assign(obj, props);
      this.set('backlinkView.filterConfig', obj);
    },

    setDateRange: Ember._action(function ({
      type,
      start,
      end
    }) {
      const formattedDateFrom = (0, _dateFns.format)(start, 'yyyy-MM-dd');
      const formattedDateTo = (0, _dateFns.format)(end, 'yyyy-MM-dd');
      this.set('statsDateRangeType', type);
      this.set('statsDateFrom', formattedDateFrom);
      this.set('statsDateTo', formattedDateTo);
      this.fetchStats();
    }),
    addBacklinksToIgnoreList: Ember._action(function (backlinks) {
      if (backlinks.toArray) {
        backlinks = backlinks.toArray();
      } else {
        backlinks = [backlinks];
      }

      const url = this.get('model.url');
      const ignoreDomains = backlinks.mapBy('domain');
      this.router.transitionTo('dashboard.url.settings.backlinks', url, {
        queryParams: {
          ignored_domains: ignoreDomains
        }
      });
    }),
    deleteBacklinks: Ember._action(function (backlinks) {
      if (backlinks.toArray) {
        backlinks = backlinks.toArray();
      } else {
        backlinks = [backlinks];
      }

      const backlinksDisplay = backlinks.length === 1 ? 'backlink' : 'backlinks';

      if (!confirm(`Are you sure you want to delete ${backlinks.length} ${backlinksDisplay}?`)) {
        return;
      }

      const url = this.get('model.url');
      const backlinkIds = backlinks.map(b => b.id);
      const data = {
        backlink_ids: backlinkIds,
        url_id: url.id
      };
      const promise = this.fetch.post('/backlinks/bulk_destroy', {
        data
      });
      const refreshRouteName = this.router.currentRouteName;
      promise.then(() => {
        this.notifications.success(`Selected ${backlinksDisplay} deleted.`, {
          autoClear: true,
          clearDuration: _isTesting.default ? 0 : 3500
        });
        (0, _refreshRoute.default)(this, refreshRouteName);
        this.fetchStats();
        url.reload();
        this.session.user.reload();
      });
      promise.catch(() => {
        this.notifications.error(`An error occured while deleting selected backlinks.`, {
          autoClear: true,
          clearDuration: 3500
        });
      });
      return promise;
    }),
    deleteSelectedBacklinks: Ember._action(function () {
      this.deleteBacklinks(this.selectedBacklinks);
    }),
    addSelectedBacklinksToIgnoreList: Ember._action(function () {
      this.addBacklinksToIgnoreList(this.selectedBacklinks);
    }),
    saveResource: Ember._action(function (backlinkView) {
      const updateParams = Object.assign({
        filter_groups: this.get('filtersObj.filterGroups')
      }, {
        one_link_per_domain: this.one_link_per_domain
      });
      this.updateBacklinkViewConfig(updateParams);
      backlinkView.filtersSaving = true;
      const isNew = backlinkView.get('isNew');
      const promise = backlinkView.save();
      promise.then(backlinkView => {
        backlinkView.filtersSaved = true;
        backlinkView.loadCount();

        if (isNew) {
          const backlinkViews = this.get('url.backlinkViews').toArray();
          backlinkViews.pushObject(backlinkView);
          this.set('url.backlinkViews', backlinkViews);
          this.router.transitionTo('dashboard.url.backlink-view', backlinkView.id);
        }

        this.metrics.trackEvent({
          event: 'Saved Backlink View',
          name: backlinkView.get('name')
        });
        this.notifications.success('Backlink View saved successfully.', {
          autoClear: true,
          clearDuration: 4000
        });
      }).catch(() => {
        this.notifications.error('There was an error while saving backlink view.', {
          autoClear: true,
          clearDuration: 4000
        });
      }).finally(() => {
        backlinkView.filtersSaving = false;
      });
    }),
    toggleOneLinkPerDomain: Ember._action(function () {
      this.toggleProperty('one_link_per_domain');
      this.fetchStats();
      this.set('page', 1);
    }),
    toggleFilters: Ember._action(function () {
      this.toggleProperty('filtersShown');
      localStorage.setItem('nw:backlinks:filters-shown', JSON.stringify(this.filtersShown));
    }),
    setFilterConfig: Ember._action(function (filterConfig) {
      this.set('filterConfig', filterConfig);
    }),
    setFilters: Ember._action(function (filters) {
      if (filters && filters.filterGroups && filters.filterGroups.length === 1 && filters.filterGroups[0].filters[0].name == null) {
        this.setProperties({
          filters: null,
          page: 1,
          emptyFilters: true
        });
      } else {
        const urlSafeFilters = encodeURI(JSON.stringify(filters));
        this.setProperties({
          filters: urlSafeFilters,
          emptyFilters: false
        });
      }

      this.fetchStats();
    }),
    setQualityFilter: Ember._action(function (qualityFilters = []) {
      if (!qualityFilters.length) return; // Show filters if hidden

      if (!this.filtersShown) this.toggleFilters(); // Make a copy of filtersObj to modify with new filters

      const newFiltersObj = Object.assign({
        filterGroups: [{
          filters: []
        }]
      }, this.filtersObj); // Prepare newFiltersObj

      newFiltersObj.filterGroups = newFiltersObj.filterGroups // Remove existing "url_rating" filters
      .map(fg => {
        const f = (fg.filters || []).filter(f => f.name !== 'url_rating');
        return f.length ? {
          filters: f
        } : false;
      }) // Remove groups which have now been emptied
      .filter(fg => fg) // Merge with new filters
      .concat(qualityFilters.map(([name, operator, value]) => ({
        filters: [{
          name,
          operator,
          value
        }]
      }))); // Apply new filters

      this.setFilters(newFiltersObj);
    }),
    onSearchChange: Ember._action(function (searchTerm) {
      Ember.run.debounce(this, 'searchBacklinks', searchTerm, 150);
    }),
    onSelectAllToggleChange: Ember._action(function (selectAll) {
      if (selectAll) {
        this.set('selectedBacklinks', this.backlinks.toArray().concat());
      } else {
        this.set('selectedBacklinks', Ember.A());
      }
    }),
    toggleBacklinkSelected: Ember._action(function (backlink) {
      let selectedBacklinks = this.selectedBacklinks.concat();
      const addedBacklink = selectedBacklinks.findBy('id', backlink.id);

      if (addedBacklink) {
        selectedBacklinks.removeObject(addedBacklink);
      } else {
        selectedBacklinks.pushObject(backlink);
      }

      this.set('selectedBacklinks', selectedBacklinks);
    }),
    availableFilters: [{
      name: 'url_from',
      displayName: 'URL',
      type: 'string'
    }, {
      name: 'root_domain',
      displayName: 'Domain',
      type: 'string'
    }, {
      name: 'url_rating',
      displayName: 'URL Rating',
      type: 'number'
    }, {
      name: 'domain_rating',
      displayName: 'Domain Rating',
      type: 'number'
    }, {
      name: 'status',
      displayName: 'Status',
      type: 'values',
      availableValues: ['active', 'not_present', 'link_dead']
    }, {
      name: 'rel',
      displayName: 'Rel',
      type: 'values',
      availableValues: ['follow', 'nofollow']
    }, {
      name: 'first_seen',
      displayName: 'First Seen',
      type: 'date'
    }, {
      name: 'url_to',
      displayName: 'Landing Page',
      type: 'string'
    }, {
      name: 'title',
      displayName: 'Page Title',
      type: 'string'
    }, {
      name: 'anchor',
      displayName: 'Anchor Text',
      type: 'string'
    }, {
      name: 'source',
      displayName: 'Source',
      type: 'values',
      availableValues: ['Auto-Discovery', 'Manual']
    }, {
      name: 'tags',
      displayName: 'Backlink tag',
      type: 'array'
    }]
  });

  _exports.default = _default;
});