define("nightwatch-web/routes/confirm-account", ["exports", "nightwatch-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ConfirmAccountRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, (_class = class ConfirmAccountRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "errorReporter", _descriptor4, this);
    }

    beforeModel() {
      this.session.requireAuthentication();

      if (this.session.user.confirmed) {
        this.router.transitionTo('dashboard.overview');
      }
    }

    afterConfirm() {
      try {
        if (window.gtag) {
          const conversionId = 'YCxmCKa472sQ_entnQM';
          window.gtag('event', 'conversion', {
            send_to: `${_environment.default.gtagId}/${conversionId}`
          });
        }

        if (window.qp) {
          window.qp('track', 'CompleteRegistration');
        }
      } catch (e) {
        this.errorReporter.error('Conversion tracking failed after account confirmation.', e);
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      const {
        token
      } = controller;

      if (this.controller.get('tokenPresent')) {
        this.controller.set('isConfirming', true);
        this.fetch.post('/user/confirm_account', {
          data: {
            token
          }
        }).then(() => {
          localStorage.setItem('nw:needs-onboarding', JSON.stringify(true));
          this.session.user.confirmed = true;
          this.notifications.success('E-mail confirmed succesfully.', {
            autoClear: true,
            clearDuration: 3500
          });
          this.afterConfirm();
          this.router.transitionTo('start.url');
        }).catch(error => {
          var _error$errors, _error$errors$;

          this.controller.set('isConfirming', false);
          const errorMessage = ((_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : (_error$errors$ = _error$errors[0]) === null || _error$errors$ === void 0 ? void 0 : _error$errors$.title) || 'Account confirmation failed.';
          controller.set('error', errorMessage);

          if (errorMessage.indexOf('already confirmed') !== -1) {
            this.router.transitionTo('dashboard.overview');
          } else {
            controller.set('errorMessage', errorMessage);
          }
        });
      }
    }

    loading() {
      return false; //Do not show loading spinner when clicking on pages
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorReporter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = ConfirmAccountRoute;
});