define("nightwatch-web/components/backlinks/backlinks-column-switcher", ["exports", "nightwatch-web/utils/humanize", "nightwatch-web/utils/titleize"], function (_exports, _humanize, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <NwIcon @icon="icon-edit" class="nw-table__switcher-icon" role="button" {{on "click" this.openDropdown}} />
  <NwSelect
    @v1={{true}} class="nw-table__switcher-select"
    @dropdownClass="nw-power-select--l"
    @options={{this.columnsForDropdown}}
    @searchEnabled={{true}}
    @searchField="text"
    @renderInPlace={{true}}
    @selected={{lookup this.columnsForDropdown this.selectedColumnIndex}}
    @onOpen={{this.cacheApi}}
    @onClose={{this.closeDropdown}}
    @onChange={{this.onColumnSwitch}} as |option|>
    {{option.text}}
  </NwSelect>
  
  */
  {
    "id": "9zL8WvM3",
    "block": "[[[8,[39,0],[[24,0,\"nw-table__switcher-icon\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,0,[\"openDropdown\"]]],null]],[[\"@icon\"],[\"icon-edit\"]],null],[1,\"\\n\"],[8,[39,2],[[24,0,\"nw-table__switcher-select\"]],[[\"@v1\",\"@dropdownClass\",\"@options\",\"@searchEnabled\",\"@searchField\",\"@renderInPlace\",\"@selected\",\"@onOpen\",\"@onClose\",\"@onChange\"],[true,\"nw-power-select--l\",[30,0,[\"columnsForDropdown\"]],true,\"text\",true,[28,[37,3],[[30,0,[\"columnsForDropdown\"]],[30,0,[\"selectedColumnIndex\"]]],null],[30,0,[\"cacheApi\"]],[30,0,[\"closeDropdown\"]],[30,0,[\"onColumnSwitch\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,1,[\"text\"]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"option\"],false,[\"nw-icon\",\"on\",\"nw-select\",\"lookup\"]]",
    "moduleName": "nightwatch-web/components/backlinks/backlinks-column-switcher.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    metrics: Ember.inject.service(),
    classNames: ['nw-table__switcher'],
    columnsForDropdown: Ember.computed('availableColumns.[]', 'selectedColumn', 'selectedColumns.[]', function () {
      const availableColumns = this.availableColumns.filter(c => c === this.selectedColumn || this.selectedColumns.indexOf(c) < 0);
      return availableColumns.map(c => {
        const displayName = this.dropdownDisplayNames[c] || this.columnDisplayNames[c] || (0, _titleize.default)((0, _humanize.default)(Ember.String.underscore(c)));
        return {
          id: c,
          text: displayName
        };
      });
    }),

    init() {
      this._super(...arguments);

      this.selectedColumnIndex = null;
      this.powerSelectApi = null;
      this.isOpen = false;
    },

    didReceiveAttrs() {
      this.set('selectedColumnIndex', this.columnsForDropdown.findIndex(ac => ac.id === this.selectedColumn));
    },

    willDestroyElement() {
      this._super(...arguments);

      this.set('powerSelectApi', null);
    },

    openDropdown: Ember._action(function () {
      if (this.powerSelectApi) {
        if (this.isOpen) return;
        this.powerSelectApi.open();
      } else {
        // This will trigger open() on power select component
        // then the "onOpen" listener will call cacheApi()
        // which will cache the component's instance so
        // that we can manipulate it from here
        this.element.querySelector('.ember-power-select-trigger').dispatchEvent(new MouseEvent('mousedown'));
      }

      this.element.classList.add('nw-table__switcher--active');
      this.set('isOpen', true);
    }),
    closeDropdown: Ember._action(function () {
      if (!this.isOpen) return;
      this.element.classList.remove('nw-table__switcher--active');
      this.set('isOpen', false);
    }),
    onColumnSwitch: Ember._action(function (column) {
      this.switchColumn(this.index, column.id);
      this.metrics.trackEvent({
        event: 'Switched Columns',
        table: this.metricsTableName,
        columns: this.selectedColumns.join(', ')
      });
      this.closeDropdown();
    }),
    cacheApi: Ember._action(function (component) {
      if (this.powerSelectApi) return;
      this.set('powerSelectApi', component.actions);
    })
  }));

  _exports.default = _default;
});