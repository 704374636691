define("nightwatch-web/components/nw-navbar/announcement-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    {{did-insert this.onInsert}}>
    <NwPopover
      @arrow={{true}}
      @title={{this.title}}
      @buttonText="Got it"
      @isShown={{this.announcementNotificationShown}}
      @buttonAction={{this.toggleAnnouncement}}>
      {{this.safeMessage}}
    </NwPopover>
  </div>
  
  */
  {
    "id": "nXIug6xv",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@arrow\",\"@title\",\"@buttonText\",\"@isShown\",\"@buttonAction\"],[true,[30,0,[\"title\"]],\"Got it\",[30,0,[\"announcementNotificationShown\"]],[30,0,[\"toggleAnnouncement\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,0,[\"safeMessage\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"did-insert\",\"nw-popover\"]]",
    "moduleName": "nightwatch-web/components/nw-navbar/announcement-notification.hbs",
    "isStrictMode": false
  });

  let AnnouncementNotificationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember.computed('message'), _dec6 = Ember._action, (_class = class AnnouncementNotificationComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);
    }

    onInsert() {
      const isWhitelabeledUser = this.get('siteData.isWhiteLabel');

      if (isWhitelabeledUser) {
        return;
      }

      const promise = this.store.findAll('announcement');
      promise.then(announcements => {
        const announcement = announcements.get('firstObject');
        if (Ember.isBlank(announcement)) return;
        this.setProperties({
          title: announcement.get('title') || 'Update',
          message: announcement.get('message'),
          announcementNotificationShown: true
        });
      });
    }

    get safeMessage() {
      return Ember.String.htmlSafe(this.message);
    }

    toggleAnnouncement() {
      const isShown = this.announcementNotificationShown;
      this.set('announcementNotificationShown', !isShown);
      const {
        user
      } = this.session;
      if (!user || !isShown) return;
      user.announcement_seen_at = new Date();
      user.save();
      this.metrics.trackEvent({
        event: 'Seen announcement',
        title: this.title
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "safeMessage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "safeMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAnnouncement", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAnnouncement"), _class.prototype)), _class));
  _exports.default = AnnouncementNotificationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnouncementNotificationComponent);
});