define("nightwatch-web/components/nw-sidebar/group-section", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul ...attributes>
    {{#each this.filteredItems as |model|}}
      {{#if (eq model.constructor.modelName 'url')}}
        <li class="group-content-item {{if (eq @selectedUrl.id model.id) 'sidebar-item-selected'}}">
          <LinkTo @route="dashboard.url.keywords.index" @model={{model}} class="url-link group-item-link js--capture">
            <div class="url-favicon-container">
              <FaviconImage @src={{model.faviconUrl}} />
            </div>
            <div class="group-item-name truncate">
              {{text-highlight model.name query=@searchQuery}}
            </div>
          </LinkTo>
        </li>
      {{else if (eq model.constructor.modelName 'dynamic-view')}}
        <li class="group-content-item {{if (eq @selectedDynamicView.id model.id) 'sidebar-item-selected'}}">
          <LinkTo @route="dashboard.dynamic-view" @model={{model.id}} class="url-link group-item-link truncate js--capture">
            <NwIcon @icon="icon-eye" class="icon-view" />
            <div class="group-item-name truncate">
              {{text-highlight model.name query=@searchQuery}}
            </div>
          </LinkTo>
        </li>
      {{/if}}
    {{/each}}
  </ul>
  
  */
  {
    "id": "6/DjOCca",
    "block": "[[[11,\"ul\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"filteredItems\"]]],null]],null],null,[[[41,[28,[37,3],[[30,2,[\"constructor\",\"modelName\"]],\"url\"],null],[[[1,\"      \"],[10,\"li\"],[15,0,[29,[\"group-content-item \",[52,[28,[37,3],[[30,3,[\"id\"]],[30,2,[\"id\"]]],null],\"sidebar-item-selected\"]]]],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"url-link group-item-link js--capture\"]],[[\"@route\",\"@model\"],[\"dashboard.url.keywords.index\",[30,2]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"url-favicon-container\"],[12],[1,\"\\n            \"],[8,[39,5],null,[[\"@src\"],[[30,2,[\"faviconUrl\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"group-item-name truncate\"],[12],[1,\"\\n            \"],[1,[28,[35,6],[[30,2,[\"name\"]]],[[\"query\"],[[30,4]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,2,[\"constructor\",\"modelName\"]],\"dynamic-view\"],null],[[[1,\"      \"],[10,\"li\"],[15,0,[29,[\"group-content-item \",[52,[28,[37,3],[[30,5,[\"id\"]],[30,2,[\"id\"]]],null],\"sidebar-item-selected\"]]]],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"url-link group-item-link truncate js--capture\"]],[[\"@route\",\"@model\"],[\"dashboard.dynamic-view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,7],[[24,0,\"icon-view\"]],[[\"@icon\"],[\"icon-eye\"]],null],[1,\"\\n          \"],[10,0],[14,0,\"group-item-name truncate\"],[12],[1,\"\\n            \"],[1,[28,[35,6],[[30,2,[\"name\"]]],[[\"query\"],[[30,4]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],null]],[]]]],[2]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"model\",\"@selectedUrl\",\"@searchQuery\",\"@selectedDynamicView\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"link-to\",\"favicon-image\",\"text-highlight\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/nw-sidebar/group-section.hbs",
    "isStrictMode": false
  });

  class GroupSectionComponent extends _component.default {
    get items() {
      const {
        urls,
        dynamicViews
      } = this.args.urlGroup;
      return urls.toArray().concat(dynamicViews.toArray()).filterBy('isNew', false).sortBy('name');
    }

    get filteredItems() {
      const {
        searchQuery
      } = this.args;
      if (Ember.isBlank(searchQuery)) return this.items;
      const query = searchQuery.toLowerCase();

      const matches = value => {
        return value === null || value === void 0 ? void 0 : value.toLowerCase().includes(query);
      };

      return this.items.filter(model => {
        return matches(model.get('name')) || matches(model.get('url')) || matches(model.get('url_info.title'));
      });
    }

  }

  _exports.default = GroupSectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupSectionComponent);
});