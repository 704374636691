define("nightwatch-web/services/notifications/backlinks-notifications", ["exports", "nightwatch-web/utils/chevron-icon-html"], function (_exports, _chevronIconHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BacklinksNotificationsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class BacklinksNotificationsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "siteData", _descriptor4, this);

      _defineProperty(this, "disabledNotification", void 0);

      _defineProperty(this, "limitNotification", void 0);
    }

    notifyFetchingDisabled(url) {
      if (url.backlinksFetchingEnabled) return;
      if (this.session.user.isLimited) return;
      if (this.disabledNotification) return;
      this.disabledNotification = this.notifications.warning(Ember.String.htmlSafe(`You disabled automatic new backlinks discovery for this URL. <b class="blue-alt">Update settings ${_chevronIconHtml.default}</b>`), {
        onClick: () => {
          this.router.transitionTo('dashboard.url.settings.backlinks', url.id);
        }
      });
    }

    notifyLimitReached(user) {
      if (this.siteData.hideNightwatchBranding) return;
      const {
        backlinksCount,
        backlinksLimit
      } = user;
      if (backlinksCount < backlinksLimit) return;
      if (this.limitNotification) return;
      this.limitNotification = this.notifications.warning(Ember.String.htmlSafe(`You reached your plan’s backlink limit (${backlinksLimit}). Upgrade your plan to continue tracking more backlinks. <b class="blue-alt">Upgrade Plan ${_chevronIconHtml.default}</b>`), {
        onClick: () => {
          this.router.transitionTo('plans', {
            queryParams: {
              event: 'Backlinks Upgrade CTA'
            }
          });
        }
      });
    }

    clearLimitNotification() {
      this.notifications.remove(this.limitNotification);
    }

    clearNotifications() {
      this.notifications.remove(this.disabledNotification, this.limitNotification);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "siteData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BacklinksNotificationsService;
});