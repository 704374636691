define("nightwatch-web/components/graph/save-area", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
      <BasicDropdown as |dropdown|>
      <dropdown.Trigger class="{{if this.isLoading 'dimmed'}} inline-block">
        <button class="btn btn-secondary pull-right dropdown-toggle" type="button" id="graphSave">
          <span class="btn-label-default">
            <NwIcon @icon="icon-save" />
            Save
          </span>
        </button>
      </dropdown.Trigger>
      <dropdown.Content class="nw-dropdown">
        <div class="nw-dropdown--menu__save-name" aria-labelledby="graphSave">
          <label for="save-area-name" hidden>Graph name</label>
          <FocusInput
            id="save-area-name"
            class="form-control rt-input"
            placeholder="Name"
            @value={{this.name}}
            @enter={{pipe this.saveGraph dropdown.actions.close}}
            {{on "input" (pick "target.value" (fn (mut this.name)))}}
          />
          <button class="btn btn-primary"
            {{on "click" (pipe this.saveGraph dropdown.actions.close)}}
            disabled={{not this.namePresent}}
            type="button">
            <NwIcon @icon="icon-save" />
            Save
          </button>
        </div>
      </dropdown.Content>
    </BasicDropdown>
  
  */
  {
    "id": "w1UdXgdt",
    "block": "[[[1,\"  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"Trigger\"]],[[16,0,[29,[[52,[30,0,[\"isLoading\"]],\"dimmed\"],\" inline-block\"]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-secondary pull-right dropdown-toggle\"],[14,1,\"graphSave\"],[14,4,\"button\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"btn-label-default\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@icon\"],[\"icon-save\"]],null],[1,\"\\n          Save\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,1,[\"Content\"]],[[24,0,\"nw-dropdown\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"nw-dropdown--menu__save-name\"],[14,\"aria-labelledby\",\"graphSave\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"save-area-name\"],[14,\"hidden\",\"\"],[12],[1,\"Graph name\"],[13],[1,\"\\n        \"],[8,[39,3],[[24,1,\"save-area-name\"],[24,0,\"form-control rt-input\"],[24,\"placeholder\",\"Name\"],[4,[38,5],[\"input\",[28,[37,6],[\"target.value\",[28,[37,7],[[28,[37,8],[[30,0,[\"name\"]]],null]],null]],null]],null]],[[\"@value\",\"@enter\"],[[30,0,[\"name\"]],[28,[37,4],[[30,0,[\"saveGraph\"]],[30,1,[\"actions\",\"close\"]]],null]]],null],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[16,\"disabled\",[28,[37,9],[[30,0,[\"namePresent\"]]],null]],[24,4,\"button\"],[4,[38,5],[\"click\",[28,[37,4],[[30,0,[\"saveGraph\"]],[30,1,[\"actions\",\"close\"]]],null]],null],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@icon\"],[\"icon-save\"]],null],[1,\"\\n          Save\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[\"dropdown\"],false,[\"basic-dropdown\",\"if\",\"nw-icon\",\"focus-input\",\"pipe\",\"on\",\"pick\",\"fn\",\"mut\",\"not\"]]",
    "moduleName": "nightwatch-web/components/graph/save-area.hbs",
    "isStrictMode": false
  });

  let SaveArea = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class SaveArea extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      this.name = this.args.graph.name;
    }

    get namePresent() {
      return !!this.name;
    }

    saveGraph() {
      if (!this.namePresent) return;
      this.args.graph.name = this.name;
      this.args.onSaveGraph(this.args.graph);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveGraph", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "saveGraph"), _class.prototype)), _class));
  _exports.default = SaveArea;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SaveArea);
});