define("nightwatch-web/helpers/value-or-slash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.valueOrSlash = void 0;

  const valueOrSlash = params => params[0] === 0 ? 0 : params[0] || '/';

  _exports.valueOrSlash = valueOrSlash;

  var _default = Ember.Helper.helper(valueOrSlash);

  _exports.default = _default;
});