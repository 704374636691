define("nightwatch-web/serializers/user", ["exports", "nightwatch-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    isNewSerializerAPI: true,

    serializeIntoHash(data, type, record, options) {
      const payload = this.serialize(record, options);
      payload.id = record.id;
      data['user'] = payload;
      const additionalParams = ['report_hour', 'current_password', 'password', 'password_confirm', 'is_company', 'send_invoice_emails', 'invoice_email', 'invoice_name', 'invoice_address', 'invoice_state', 'invoice_city', 'invoice_postcode', 'invoice_country', 'invoice_vat_number', 'last_notifications_read_at', 'announcement_seen_at'];
      additionalParams.forEach(param => data[param] = record.attr(param));
      return data;
    }

  });

  _exports.default = _default;
});