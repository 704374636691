define("nightwatch-web/helpers/truncate-ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.truncateEllipsis = void 0;

  const truncateEllipsis = params => {
    const val = params[0];
    const n = params[1];
    if (val == null) return;
    return val.length > n ? val.substring(0, n - 1) + '…' : val;
  };

  _exports.truncateEllipsis = truncateEllipsis;

  var _default = Ember.Helper.helper(truncateEllipsis);

  _exports.default = _default;
});