define("nightwatch-web/components/keyword-list-column-switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <NwIcon @icon="icon-edit" class="nw-table__switcher-icon" role="button" {{on "click" this.openDropdown}} />
  <NwSelect
    @v1={{true}}
    @options={{this.columnsForDropdown}} class="nw-power-select--column-switcher nw-power-select--s"
    @dropdownClass="nw-power-select-dropdown--column-switcher"
    @onOpen={{this.cacheApi}}
    @onClose={{this.closeDropdown}}
    @searchField="displayName"
    @searchEnabled={{true}}
    @selected={{this.dropdownSelectedItem}}
    @onChange={{this.columnSwitch}} as |column|>
    {{column.displayName}}
  </NwSelect>
  
  */
  {
    "id": "/NhZpOEb",
    "block": "[[[8,[39,0],[[24,0,\"nw-table__switcher-icon\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,0,[\"openDropdown\"]]],null]],[[\"@icon\"],[\"icon-edit\"]],null],[1,\"\\n\"],[8,[39,2],[[24,0,\"nw-power-select--column-switcher nw-power-select--s\"]],[[\"@v1\",\"@options\",\"@dropdownClass\",\"@onOpen\",\"@onClose\",\"@searchField\",\"@searchEnabled\",\"@selected\",\"@onChange\"],[true,[30,0,[\"columnsForDropdown\"]],\"nw-power-select-dropdown--column-switcher\",[30,0,[\"cacheApi\"]],[30,0,[\"closeDropdown\"]],\"displayName\",true,[30,0,[\"dropdownSelectedItem\"]],[30,0,[\"columnSwitch\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,1,[\"displayName\"]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"column\"],false,[\"nw-icon\",\"on\",\"nw-select\"]]",
    "moduleName": "nightwatch-web/components/keyword-list-column-switcher.hbs",
    "isStrictMode": false
  });

  /**
   * Column switcher for keywords list
   * @param {Array} availableColumns
   * @param {Array} availableCompetitorColumns
   * @param {EmberModel} url
   * @param {Object} selectedColumn - current column
   * @param {Number} columnIndex - current column index
   * @param {Function} onColumnSwitch - on column change function
   * @param {Boolean[=false]} showCompetitors
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['inline-block'],
    showCompetitors: false,
    powerSelectActions: null,
    isOpen: false,
    shouldShowCompetitors: Ember.computed.and('showCompetitors', 'url.competitors.length'),
    columnsForDropdown: Ember.computed('availableColumns.[]', 'url.competitors.[]', function () {
      const availableColumns = this.availableColumns;

      if (this.shouldShowCompetitors) {
        const competitorColumns = this.get('url.competitors').map(competitor => {
          const groupName = `Competitor: ${competitor.get('displayName')}`;
          const competitorChildColumns = this.availableCompetitorColumns.map(competitorColumn => {
            return {
              name: `${competitorColumn.name}-competitor-${competitor.id}`,
              displayName: competitorColumn.displayName
            };
          });
          return {
            groupName,
            options: competitorChildColumns
          };
        });
        return availableColumns.concat(competitorColumns);
      }

      return availableColumns;
    }),
    columnsForDropdownFlatten: Ember.computed('columnsForDropdown.[]', function () {
      return this.columnsForDropdown.reduce((acc, cur) => cur.options ? acc.concat(cur.options) : acc.concat(cur), []);
    }),
    dropdownSelectedItem: Ember.computed('columnsForDropdownFlatten.[]', 'selectedColumn.name', function () {
      const columnsForDropdownFlatten = this.columnsForDropdownFlatten;
      const selectedColumnName = this.get('selectedColumn.name');
      return columnsForDropdownFlatten.findBy('name', selectedColumnName);
    }),
    columnSwitch: Ember._action(function ({
      name
    }) {
      this.onColumnSwitch(name, this.columnIndex);
    }),
    openDropdown: Ember._action(function () {
      if (this.powerSelectActions) {
        if (this.isOpen) return;
        this.powerSelectActions.open();
      } else {
        // This will trigger open() on power select component
        // then the "onOpen" listener will call cacheApi()
        // which will cache the component's instance so
        // that we can manipulate it from here
        this.element.querySelector('.ember-power-select-trigger').dispatchEvent(new MouseEvent('mousedown'));
      }

      this.set('isOpen', true);
    }),
    closeDropdown: Ember._action(function () {
      if (!this.isOpen) return;
      this.set('isOpen', false);
    }),
    cacheApi: Ember._action(function (component) {
      if (this.powerSelectActions) return;
      this.set('powerSelectActions', component.actions);
    })
  }));

  _exports.default = _default;
});