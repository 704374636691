define("nightwatch-web/helpers/relative-day", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.relativeDay = void 0;

  /**
    @helper relativeDay
    A wrapper around formatDistanceToNowStrict to provide more specific output when displaying "Next Crawl".
    Specifically – we want a user to see "in x days" when their crawl has just run.
   */
  const relativeDay = dateObj => {
    if (!dateObj) return '/';
    const days = (0, _dateFns.differenceInDays)(new Date(), new Date(dateObj));
    if (days === 0) return (0, _dateFns.formatDistanceToNowStrict)(new Date(dateObj), {
      roundingMethod: 'round',
      addSuffix: true
    });
    const daysAbs = Math.abs(days);
    const prefix = days < 0 ? 'in ' : '';
    const plural = daysAbs > 1 ? ' days' : ' day';
    const suffix = days > 0 ? ' ago' : '';
    return `${prefix}${daysAbs}${plural}${suffix}`;
  };

  _exports.relativeDay = relativeDay;

  var _default = Ember.Helper.helper((params = []) => relativeDay(params[0]));

  _exports.default = _default;
});