define("nightwatch-web/models/user-notification", ["exports", "@ember-data/model", "date-fns"], function (_exports, _model, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserNotification = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('date'), _dec11 = Ember.computed.equal('status', 'new'), _dec12 = Ember.computed.equal('notificationType', 'index_pages_change'), _dec13 = Ember.computed.equal('notificationType', 'new_keyword_suggestions'), _dec14 = Ember.computed.equal('notificationType', 'sessions_spike'), _dec15 = Ember.computed.equal('notificationType', 'new_backlinks'), _dec16 = Ember.computed('createdAt'), _dec17 = Ember.computed('isIndexedPagesChange', 'body'), _dec18 = Ember.computed('isIndexedPagesChange', 'body'), (_class = class UserNotification extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "search_keyword_url_id", _descriptor, this);

      _initializerDefineProperty(this, "search_keyword_id", _descriptor2, this);

      _initializerDefineProperty(this, "message", _descriptor3, this);

      _initializerDefineProperty(this, "notificationType", _descriptor4, this);

      _initializerDefineProperty(this, "resourceName", _descriptor5, this);

      _initializerDefineProperty(this, "title", _descriptor6, this);

      _initializerDefineProperty(this, "body", _descriptor7, this);

      _initializerDefineProperty(this, "expired", _descriptor8, this);

      _initializerDefineProperty(this, "status", _descriptor9, this);

      _initializerDefineProperty(this, "createdAt", _descriptor10, this);

      _initializerDefineProperty(this, "statusNew", _descriptor11, this);

      _initializerDefineProperty(this, "isIndexedPagesChange", _descriptor12, this);

      _initializerDefineProperty(this, "isNewKeywordSuggestions", _descriptor13, this);

      _initializerDefineProperty(this, "isSessionsSpike", _descriptor14, this);

      _initializerDefineProperty(this, "isNewBacklinks", _descriptor15, this);
    }

    get createdAtDateString() {
      return (0, _dateFns.format)(this.createdAt, 'yyyy-MM-dd');
    }

    get isIncrease() {
      let increased = false;

      if (this.isIndexedPagesChange) {
        increased = this.body.indexOf('increased from') !== -1;
      } else if (this.isSessionsSpike || this.isNewBacklinks) {
        increased = true;
      }

      return increased;
    }

    get isDecrease() {
      let decreased = false;

      if (this.isIndexedPagesChange) {
        decreased = this.body.indexOf('decreased from') !== -1;
      }

      return decreased;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search_keyword_url_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search_keyword_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "message", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notificationType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "resourceName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "body", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "expired", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "statusNew", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isIndexedPagesChange", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isNewKeywordSuggestions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isSessionsSpike", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isNewBacklinks", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createdAtDateString", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtDateString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isIncrease", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "isIncrease"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDecrease", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "isDecrease"), _class.prototype)), _class));
  _exports.default = UserNotification;
});