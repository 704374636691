define("nightwatch-web/instance-initializers/disable-metrics-for-do-not-track", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    const metricsService = appInstance.lookup('service:metrics');

    if (navigator.doNotTrack === '1') {
      metricsService.enabled = false;
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});