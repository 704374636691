define("nightwatch-web/components/toggle-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.state this.toggleAction}}
  
  */
  {
    "id": "oeRjign8",
    "block": "[[[18,1,[[30,0,[\"state\"]],[30,0,[\"toggleAction\"]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "nightwatch-web/components/toggle-container.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    state: false,
    tagName: '',
    toggleAction: Ember._action(function () {
      this.toggleProperty('state');
    })
  }));

  _exports.default = _default;
});