define("nightwatch-web/initializers/jquery-highcharts", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // Patches jQuery.highcharts for compatibility with npm-imported Highcharts
  function initialize() {
    if (!window.jQuery) return;

    window.jQuery.fn.highcharts = function () {
      var args = [].slice.call(arguments);

      if (this[0]) {
        // this[0] is the renderTo div
        // Create the chart
        if (args[0]) {
          new _highcharts.default[// eslint-disable-line no-new
          // Constructor defaults to Chart
          _highcharts.default.isString(args[0]) ? args.shift() : 'Chart'](this[0], args[0], args[1]);
          return this;
        } // When called without parameters or with the return argument,
        // return an existing chart


        return _highcharts.default.charts[_highcharts.default.attr(this[0], 'data-highcharts-chart')];
      }
    };
  }

  var _default = {
    name: 'jquery-highcharts',
    initialize
  };
  _exports.default = _default;
});