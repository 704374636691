define("nightwatch-web/helpers/friendly-url", ["exports", "punycode"], function (_exports, _punycode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.friendlyUrl = void 0;

  /*
   * A friendly URL doesn't have:
   * 1. the protocol (http(s))
   * 2. the "www." at the beginning of host
   * 3. a "/" at the end of the host when that's the last part
   */
  const friendlyUrl = inputUrl => {
    // Quickly deal with empty values and whitespace strings
    if (Ember.isBlank(inputUrl)) return '';

    try {
      // Ensure URLs have an HTTP(S) protocol
      const url = inputUrl.match(/^https?:\/\//) ? inputUrl : `https://${inputUrl.replace(/^.*:\/\//, '')}`; // Test URL validity by using the URL constructor. This will convert non-ascii in URLs to punycode

      const {
        host,
        pathname,
        search
      } = new URL(url.trim()); // Drop www. at the start of the hostname

      const niceHost = host.replace(/^www\./, ''); // Drop "/" when it's the only character in pathname and no query params are present

      const nicePath = pathname === '/' ? search ? '/' : '' : pathname; // Construct friendly URL and convert any punycode to unicode

      return _punycode.default.toUnicode(`${niceHost}${nicePath}${search}`);
    } catch (e) {
      // Any errors just return the inputUrl
      return inputUrl;
    }
  };

  _exports.friendlyUrl = friendlyUrl;

  var _default = Ember.Helper.helper((params = []) => friendlyUrl(params[0]));

  _exports.default = _default;
});