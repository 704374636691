define("nightwatch-web/components/billing/account-usage-vertical", ["exports", "nightwatch-web/components/billing/account-usage"], function (_exports, _accountUsage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <div class="progress progress-vertical">
      <NwTooltip @placement="bottom">{{this.keywordUsageMessage}}</NwTooltip>
      <div class="progress-bar {{this.keywordUsagePercentClass}}" role="progressbar" aria-valuenow={{this.keywordUsagePercent}} aria-valuemin="0" aria-valuemax="100" style={{this.keywordUsagePercentStyle}}>
      </div>
    </div>
    <div class="progress progress-vertical">
      <NwTooltip @placement="bottom">{{this.urlUsageMessage}}</NwTooltip>
      <div class="progress-bar {{this.urlUsagePercentClass}}" role="progressbar" aria-valuenow={{this.urlUsagePercent}} aria-valuemin="0" aria-valuemax="100" style={{this.urlUsagePercentStyle}}>
      </div>
    </div>
    <div class="progress progress-vertical">
      <NwTooltip @placement="bottom">{{this.backlinksUsageMessage}}</NwTooltip>
      <div class="progress-bar {{this.backlinksUsageMessage}}" role="progressbar" aria-valuenow={{this.backlinksUsagePercent}} aria-valuemin="0" aria-valuemax="100" style={{this.backlinksUsagePercentStyle}}>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "jSuSh4QL",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"progress progress-vertical\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@placement\"],[\"bottom\"]],[[\"default\"],[[[[1,[30,0,[\"keywordUsageMessage\"]]]],[]]]]],[1,\"\\n    \"],[10,0],[15,0,[29,[\"progress-bar \",[30,0,[\"keywordUsagePercentClass\"]]]]],[14,\"role\",\"progressbar\"],[15,\"aria-valuenow\",[30,0,[\"keywordUsagePercent\"]]],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[15,5,[30,0,[\"keywordUsagePercentStyle\"]]],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"progress progress-vertical\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@placement\"],[\"bottom\"]],[[\"default\"],[[[[1,[30,0,[\"urlUsageMessage\"]]]],[]]]]],[1,\"\\n    \"],[10,0],[15,0,[29,[\"progress-bar \",[30,0,[\"urlUsagePercentClass\"]]]]],[14,\"role\",\"progressbar\"],[15,\"aria-valuenow\",[30,0,[\"urlUsagePercent\"]]],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[15,5,[30,0,[\"urlUsagePercentStyle\"]]],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"progress progress-vertical\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@placement\"],[\"bottom\"]],[[\"default\"],[[[[1,[30,0,[\"backlinksUsageMessage\"]]]],[]]]]],[1,\"\\n    \"],[10,0],[15,0,[29,[\"progress-bar \",[30,0,[\"backlinksUsageMessage\"]]]]],[14,\"role\",\"progressbar\"],[15,\"aria-valuenow\",[30,0,[\"backlinksUsagePercent\"]]],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[15,5,[30,0,[\"backlinksUsagePercentStyle\"]]],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"nw-tooltip\"]]",
    "moduleName": "nightwatch-web/components/billing/account-usage-vertical.hbs",
    "isStrictMode": false
  });

  class AccountUsageVerticalComponent extends _accountUsage.default {}

  _exports.default = AccountUsageVerticalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AccountUsageVerticalComponent);
});