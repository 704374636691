define("nightwatch-web/services/overview/day-range-calculator", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DayRangeCalculatorService extends Ember.Service {
    seriesDays(startDate, endDate) {
      const diffDays = Math.abs((0, _dateFns.differenceInDays)(new Date(startDate), new Date(endDate))) + 1;
      /*
        Add one day in order to include all selected days.
         For example if the user selects 8 days from the 1st to the 8th.
         𝗔𝗨𝗚𝗨𝗦𝗧 – 𝟮𝟬𝟮𝟬
        🅇|🅇|🅇|🅇|🅇|🅇|𝟢𝟣
        𝟢𝟤|𝟢𝟥|𝟢𝟦|𝟢𝟧|𝟢𝟨|𝟢𝟩|𝟢𝟪
         > differenceInDays(new Date("2020-08-08"), new Date("2020-08-01"))
        => 7
       */

      if (diffDays <= 2) {
        return 14;
      }

      return diffDays;
    }

  }

  _exports.default = DayRangeCalculatorService;
});