define("nightwatch-web/templates/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AgEzZ+2n",
    "block": "[[[1,[28,[35,0],[\"Invoices\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"first\"],[[[[1,\"Invoices\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@invoices\"],[[30,1]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"nw-navbar\",\"nw-page-location-banner\",\"invoices/invoices-list\"]]",
    "moduleName": "nightwatch-web/templates/invoices.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});