define("nightwatch-web/routes/settings/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SettingsNotesRoute extends Ember.Route {
    model() {
      return this.store.query('note', {
        global: true
      });
    }

  }

  _exports.default = SettingsNotesRoute;
});