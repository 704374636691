define("nightwatch-web/services/graphs/graph-loading-utils", ["exports", "nightwatch-web/utils/series-object"], function (_exports, _seriesObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GraphLoadingUtilsService = (_dec = Ember.inject.service, (_class = class GraphLoadingUtilsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, seriesType) {
      // seriesType = [websiteSeries | websiteCompetitorSeries | keywordSeries | keywordCompetitorSeries]
      const existingSeries = seriesContainerObj.get(seriesType).findBy('seriesId', seriesObj.get('seriesId'));

      if (!existingSeries) {
        seriesContainerObj.get(seriesType).pushObject(seriesObj);
        pushToAllLoadedSeries(seriesObj);
      }
    }

    createSeriesContainerForDynamicView(model, properties) {
      const seriesContainer = Ember.Object.create(properties);
      return seriesContainer;
    }

    createSeriesContainerForUrlGroup(model, properties) {
      const seriesContainer = Ember.Object.create(properties);
      return seriesContainer;
    }

    createSeriesContainerForUrl(urlModel, properties) {
      const seriesContainer = Ember.Object.create(properties); // TODO: if adding support for multiple containters (multiple urls),
      // urlModel should be fetched async by properties.urlId instead of taking
      // the route's url model
      // CAREFUL: there was an async fetch with
      // this.get('store').queryRecord('url', { id: properties.urlId } ), but it had a
      // bug - every other URL in the group disappeared after this fetch!!!

      seriesContainer.set('urlModel', urlModel);
      return seriesContainer;
    }

    loadKeywordsToSeriesContainerInitially(seriesContainer, urlId) {
      const keywordParams = {
        page: 1,
        sort: 'position',
        direction: 'asc',
        limit: 50,
        url_id: urlId,
        search: ''
      };
      return this.loadKeywordsToSeriesContainer(seriesContainer, keywordParams);
    }

    loadBacklinkViewsToSeriesContainer(seriesContainer, urlId) {
      return this.store.query('backlink-view', {
        url_id: urlId
      }).then(backlinkViews => {
        const b = backlinkViews.map(b => Ember.Object.create({
          id: parseInt(b.id),
          name: b.get('name'),
          backlinkCount: b.get('backlinkCount'),
          urlId: urlId
        }));
        b.unshift(Ember.Object.create({
          id: -1,
          urlId: urlId,
          name: 'All Backlinks'
        }));
        seriesContainer.set('backlinkViews', Ember.A(b));
        seriesContainer.set('backlinkViewsLoaded', Ember.A(b));
      });
    }

    loadCompetitorsToSeriesContainer(seriesContainer, urlId) {
      return this.store.query('competitor', {
        url_id: urlId
      }).then(competitors => {
        // create "duplicates" because we need integer ids for easier filtering
        // and setting open/closed flags - so we don't pollute records in the store
        const c = competitors.map(c => Ember.Object.create({
          id: parseInt(c.id),
          url: c.get('friendlyUrl'),
          displayName: c.get('displayName'),
          urlId
        }));
        seriesContainer.set('competitors', Ember.A(c));
        seriesContainer.set('competitorsLoaded', Ember.A(c));
      });
    }

    loadDynamicViewsToSeriesContainer(seriesContainer, urlId) {
      return this.store.query('dynamic-view', {
        search_keyword_url_id: urlId
      }).then(dynamicViews => {
        const d = dynamicViews.map(d => Ember.Object.create({
          id: parseInt(d.id),
          name: d.get('name'),
          keywordCount: d.get('keyword_count'),
          urlId: urlId
        }));
        seriesContainer.set('dynamicViews', Ember.A(d));
        seriesContainer.set('dynamicViewsLoaded', Ember.A(d));
      });
    }

    loadKeywordsToSeriesContainer(seriesContainer, keywordParams) {
      return this.store.query('keyword', keywordParams).then(keywords => {
        const k = keywords.map(k => Ember.Object.create(Object.assign({
          id: parseInt(k.id),
          urlId: keywordParams.url_id
        }, k.getProperties(['position', 'query', 'engine', 'engineImgPath', 'mobile', 'fullLocation']))));
        seriesContainer.set('keywords', Ember.A(k));
        seriesContainer.set('keywordsLoaded', Ember.A(k));
      });
    }

    loadDataToSeriesContainersForGlobalDynamicView(seriesContainers, data, dynamicViewModel, pushToAllLoadedSeries) {
      data.forEach(resourceSeries => {
        const dynamicViewId = resourceSeries.dynamic_view_id;
        const dynamicViewName = resourceSeries.dynamic_view_name;
        let seriesContainerObj = seriesContainers.findBy('dynamicViewId', dynamicViewId);

        if (!seriesContainerObj) {
          seriesContainerObj = this.createSeriesContainerForDynamicView(dynamicViewModel, {
            dynamicViewId: dynamicViewId,
            dynamicView: dynamicViewModel,
            dynamicViewSeries: Ember.A()
          });
          seriesContainers.pushObject(seriesContainerObj);
        }

        if (resourceSeries.dynamic_view_series) {
          resourceSeries.dynamic_view_series.forEach(s => {
            if (['up_down'].indexOf(s.name) === -1) {
              const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
                dynamicViewId: dynamicViewId,
                dynamicViewName: dynamicViewName,
                name: s.name,
                series: s.series
              });

              this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, 'dynamicViewSeries');
            }
          });
        }
      });
    }

    loadDataToSeriesContainersForUrlGroup(seriesContainers, data, urlGroupModel, pushToAllLoadedSeries) {
      data.forEach(resourceSeries => {
        const urlGroupId = resourceSeries.url_group_id;
        const urlGroupName = resourceSeries.url_group_name;
        let seriesContainerObj = seriesContainers.findBy('urlGroupId', urlGroupId);

        if (!seriesContainerObj) {
          seriesContainerObj = this.createSeriesContainerForUrlGroup(urlGroupModel, {
            urlGroupId: urlGroupId,
            urlGroup: urlGroupModel,
            urlGroupSeries: Ember.A()
          });
          seriesContainers.pushObject(seriesContainerObj);
        }

        if (resourceSeries.url_group_series) {
          resourceSeries.url_group_series.forEach(s => {
            if (['up_down'].indexOf(s.name) === -1) {
              const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
                urlGroupId: urlGroupId,
                urlGroupName: urlGroupName,
                name: s.name,
                series: s.series
              });

              this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, 'urlGroupSeries');
            }
          });
        }
      });
    }

    loadDataToSeriesContainersForUrl(seriesContainers, data, urlModel, pushToAllLoadedSeries) {
      const stringifyId = id => {
        if (id) return String(id);
        return id;
      };

      data.forEach(resourceSeries => {
        const urlId = stringifyId(resourceSeries.url_id);
        const url = resourceSeries.url;
        const keywordId = resourceSeries.keyword_id;
        const keyword = resourceSeries.keyword;
        const dynamicViewId = resourceSeries.dynamic_view_id;
        const dynamicViewName = resourceSeries.dynamic_view_name;
        const backlinkViewId = resourceSeries.backlink_view_id;
        const backlinkViewName = resourceSeries.backlink_view_name;
        let seriesContainerObj = seriesContainers.findBy('urlId', urlId);

        if (!seriesContainerObj) {
          seriesContainerObj = this.createSeriesContainerForUrl(urlModel, {
            urlId: urlId,
            url: url,
            websiteSeries: Ember.A(),
            websiteCompetitorSeries: Ember.A(),
            dynamicViewSeries: Ember.A(),
            dynamicViewCompetitorSeries: Ember.A(),
            backlinkViewSeries: Ember.A(),
            keywordSeries: Ember.A(),
            keywordCompetitorSeries: Ember.A()
          });
          seriesContainers.pushObject(seriesContainerObj);
        }

        if (resourceSeries.url_series) {
          resourceSeries.url_series.filter(s => s.name != 'up_down').forEach(s => {
            const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
              urlId: urlId,
              url: url,
              name: s.name,
              series: s.series
            });

            this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, 'websiteSeries');
          });
        } else if (resourceSeries.keyword_series) {
          resourceSeries.keyword_series.forEach(s => {
            const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
              urlId: urlId,
              url: url,
              keywordId: keywordId,
              keyword: keyword,
              name: s.name,
              series: s.series
            });

            this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, 'keywordSeries');
          });

          if (resourceSeries.competitor_series) {
            resourceSeries.competitor_series.forEach(s => {
              const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
                urlId: urlId,
                url: url,
                keywordId: keywordId,
                keyword: keyword,
                competitorId: s.competitor_id,
                competitorUrl: s.competitor_url,
                name: s.name,
                series: s.series
              });

              this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, 'keywordCompetitorSeries');
            });
          }
        } else if (resourceSeries.dynamic_view_series) {
          resourceSeries.dynamic_view_series.forEach(s => {
            // temporarily ignore these series until they are supported on the graph
            if (['up_down'].indexOf(s.name) === -1) {
              const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
                urlId: urlId,
                url: url,
                dynamicViewId: dynamicViewId,
                dynamicViewName: dynamicViewName,
                name: s.name,
                series: s.series
              });

              this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, 'dynamicViewSeries');
            }
          });

          if (resourceSeries.competitor_series) {
            resourceSeries.competitor_series.forEach(s => {
              const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
                urlId: urlId,
                url: url,
                dynamicViewId: dynamicViewId,
                dynamicViewName: dynamicViewName,
                competitorId: s.competitor_id,
                competitorUrl: s.competitor_url,
                name: s.name,
                series: s.series
              });

              this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, 'dynamicViewCompetitorSeries');
            });
          }
        } else if (resourceSeries.backlink_view_series) {
          resourceSeries.backlink_view_series.forEach(s => {
            if (['backlinks_all'].indexOf(s.name) === -1) {
              const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
                urlId: urlId,
                url: url,
                backlinkViewId: backlinkViewId,
                backlinkViewName: backlinkViewName,
                name: s.name,
                series: s.series
              });

              this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, 'backlinkViewSeries');
            }
          });
        } else if (resourceSeries.competitor_series) {
          const type = dynamicViewId ? 'dynamicViewCompetitorSeries' : 'websiteCompetitorSeries';
          resourceSeries.competitor_series.forEach(s => {
            const seriesObj = _seriesObject.default.create(Ember.getOwner(this).ownerInjection(), {
              urlId: urlId,
              url: url,
              dynamicViewId: dynamicViewId,
              dynamicViewName: dynamicViewName,
              competitorId: s.competitor_id,
              competitorUrl: s.competitor_url,
              name: s.name,
              series: s.series
            });

            this.pushToSeries(seriesContainerObj, seriesObj, pushToAllLoadedSeries, type);
          });
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GraphLoadingUtilsService;
});