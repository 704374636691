define("nightwatch-web/templates/settings/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7lxzIbrF",
    "block": "[[[1,[28,[35,0],[\"Notes\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@title\",\"@notes\",\"@allowEditingGlobalNotes\",\"@refreshNotes\"],[\"Manage notes that apply to all URLs in your account and show up in all graphs.\",[30,1],true,[30,0,[\"refreshRoute\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"notes/note-list\"]]",
    "moduleName": "nightwatch-web/templates/settings/notes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});