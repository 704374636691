define("nightwatch-web/services/user-settings", ["exports", "nightwatch-web/utils/deep-merge", "nightwatch-web/utils/underscorify-keys"], function (_exports, _deepMerge, _underscorifyKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserSettingsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class UserSettingsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "siteData", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "errorReporter", _descriptor4, this);
    }

    init() {
      super.init(...arguments);
      this.set('userSettings', []);
    }

    getSetting({
      searchKeywordUrlId,
      dynamicViewId,
      backlinkViewId,
      siteAuditViewId
    }) {
      if (dynamicViewId || backlinkViewId || siteAuditViewId) {
        searchKeywordUrlId = null;
      } // Because Ember data transforms integer ids into strings, we stringify values for comparison


      const toStringIfPresent = val => val != null ? String(val) : null;

      return this.userSettings.find(setting => {
        return toStringIfPresent(setting.get('searchKeywordUrlId')) == toStringIfPresent(searchKeywordUrlId) && toStringIfPresent(setting.get('dynamicViewId')) == toStringIfPresent(dynamicViewId) && toStringIfPresent(setting.get('backlinkViewId')) == toStringIfPresent(backlinkViewId) && toStringIfPresent(setting.get('siteAuditViewId')) == toStringIfPresent(siteAuditViewId);
      });
    }

    saveSetting({
      searchKeywordUrlId,
      dynamicViewId,
      backlinkViewId,
      siteAuditViewId,
      settingsData
    }) {
      if (dynamicViewId || backlinkViewId || siteAuditViewId) {
        searchKeywordUrlId = null;
      } // Don't persist when we're impersonating


      if (this.session.isAdminViewingUser) return;
      let setting = this.getSetting({
        searchKeywordUrlId,
        dynamicViewId,
        backlinkViewId,
        siteAuditViewId
      });

      if (!setting) {
        setting = this.store.createRecord('user-setting', {
          searchKeywordUrlId: searchKeywordUrlId,
          dynamicViewId: dynamicViewId,
          backlinkViewId: backlinkViewId,
          siteAuditViewId: siteAuditViewId
        });
        this.userSettings.pushObject(setting);
      }

      const newSettingsData = (0, _deepMerge.default)(setting.get('settingsData'), settingsData);
      setting.set('settingsData', newSettingsData);
      return setting.save();
    }

    pushToSettings(userSettings) {
      const findExistingSetting = setting => this.userSettings.find(existingSetting => {
        return existingSetting.get('searchKeywordUrlId') == setting.get('searchKeywordUrlId') && existingSetting.get('dynamicViewId') == setting.get('dynamicViewId') && existingSetting.get('backlinkViewId') == setting.get('backlinkViewId') && existingSetting.get('siteAuditViewId') == setting.get('siteAuditViewId');
      });

      userSettings.forEach(setting => {
        const existingSetting = findExistingSetting(setting);

        if (existingSetting) {
          existingSetting.set('settingsData', setting.get('settingsData'));
        } else {
          this.userSettings.pushObject(setting);
        }
      });
    }

    loadData(params) {
      // Since this is called from model hooks, make sure it always resolves,
      // otherwise the route transition might be rejected in case of
      // user setting loading failure.
      return new Ember.RSVP.Promise(resolve => {
        const setting = this.getSetting(params);

        if (setting) {
          // If we already have it, resolve immediately
          resolve(null);
        } else {
          this.fetchData(params).then(userSettings => {
            this.pushToSettings(userSettings);
          }).catch(error => {
            this.errorReporter.error('Loading user settings data failed.', error);
          }).finally(() => {
            resolve();
          });
        }
      });
    }

    fetchData(params) {
      return this.store.query('user-setting', (0, _underscorifyKeys.default)(params));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorReporter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserSettingsService;
});