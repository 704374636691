define("nightwatch-web/controllers/settings/api-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SettingsApiAccessController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('session.user.api_access_token'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class SettingsApiAccessController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "apiAccessToken", _descriptor3, this);

      _defineProperty(this, "isSubmitting", false);
    }

    selectContents(ev) {
      ev.target.select();
    }

    generate() {
      this.set('isSubmitting', true);
      this.fetch.post('/api_access_token').then(rsp => {
        this.set('apiAccessToken', rsp.api_access_token);
        this.notifications.success('Token generated.', {
          autoClear: true
        });
      }).catch(() => {
        this.notifications.error('There was an error when trying to generate a token. Please try again.', {
          autoClear: true
        });
      }).finally(() => {
        this.set('isSubmitting', false);
      });
    }

    revoke() {
      this.set('isSubmitting', true);
      this.fetch.delete('/api_access_token').then(() => {
        this.set('apiAccessToken', null);
        this.notifications.success('Token successfully revoked.', {
          autoClear: true
        });
      }).catch(() => {
        this.notifications.error('There was an error when trying to revoke your current token. Please try again.', {
          autoClear: true
        });
      }).finally(() => {
        this.set('isSubmitting', false);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "apiAccessToken", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "generate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "generate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "revoke", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "revoke"), _class.prototype)), _class));
  _exports.default = SettingsApiAccessController;
});