define("nightwatch-web/components/dwm-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="dwm-change">
    <div class="change-wrapper">
      <UpDownStagnantIcon @value={{@keyword.last_day_change}} />
      <div class="change-number {{up-down-stagnant @keyword.last_day_change}}">
        {{#if (not (eq @keyword.last_day_change 0))}}
          {{dwm-change-value @keyword.last_day_change}}
        {{/if}}
      </div>
    </div>
  
    <div class="divider"></div>
  
    <div class="change-wrapper">
      <UpDownStagnantIcon @value={{@keyword.last_week_change}} />
      <div class="change-number {{up-down-stagnant @keyword.last_week_change}}">
        {{#if (not (eq @keyword.last_week_change 0))}}
          {{dwm-change-value @keyword.last_week_change}}
        {{/if}}
      </div>
    </div>
  
    <div class="divider"></div>
  
    <div class="change-wrapper">
      <UpDownStagnantIcon @value={{@keyword.last_month_change}} />
      <div class="change-number {{up-down-stagnant @keyword.last_month_change}}">
        {{#if (not (eq @keyword.last_month_change 0))}}
          {{dwm-change-value @keyword.last_month_change}}
        {{/if}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "vRYMFdK4",
    "block": "[[[10,0],[14,0,\"dwm-change\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"change-wrapper\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@value\"],[[30,1,[\"last_day_change\"]]]],null],[1,\"\\n    \"],[10,0],[15,0,[29,[\"change-number \",[28,[37,1],[[30,1,[\"last_day_change\"]]],null]]]],[12],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,1,[\"last_day_change\"]],0],null]],null],[[[1,\"        \"],[1,[28,[35,5],[[30,1,[\"last_day_change\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"divider\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"change-wrapper\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@value\"],[[30,1,[\"last_week_change\"]]]],null],[1,\"\\n    \"],[10,0],[15,0,[29,[\"change-number \",[28,[37,1],[[30,1,[\"last_week_change\"]]],null]]]],[12],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,1,[\"last_week_change\"]],0],null]],null],[[[1,\"        \"],[1,[28,[35,5],[[30,1,[\"last_week_change\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"divider\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"change-wrapper\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@value\"],[[30,1,[\"last_month_change\"]]]],null],[1,\"\\n    \"],[10,0],[15,0,[29,[\"change-number \",[28,[37,1],[[30,1,[\"last_month_change\"]]],null]]]],[12],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,1,[\"last_month_change\"]],0],null]],null],[[[1,\"        \"],[1,[28,[35,5],[[30,1,[\"last_month_change\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@keyword\"],false,[\"up-down-stagnant-icon\",\"up-down-stagnant\",\"if\",\"not\",\"eq\",\"dwm-change-value\"]]",
    "moduleName": "nightwatch-web/components/dwm-change.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});