define("nightwatch-web/helpers/round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.round = round;
  _exports.default = void 0;

  function round([value, decimals]) {
    if (value === null || value === undefined) {
      return null;
    } else if (value === 0) {
      return 0;
    } else {
      decimals = decimals || 0;

      if (decimals === 0) {
        return parseInt(value);
      } else {
        decimals -= 1;
      }

      const factor = Math.pow(10, decimals) * 10;
      return Math.round(value * factor) / factor;
    }
  }

  var _default = Ember.Helper.helper(round);

  _exports.default = _default;
});