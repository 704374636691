define("nightwatch-web/templates/start/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mCmRM3oA",
    "block": "[[[1,[28,[35,0],[[30,1,[\"friendlyUrl\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Integrations\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"onboarding-title\"],[12],[1,\"\\n  \"],[1,[30,1,[\"friendlyUrl\"]]],[1,\": Integrations\\n  \"],[10,1],[14,0,\"pull-right onboarding-step-count\"],[12],[1,\"4/4\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"onboarding-big-title\"],[12],[1,\"Bring in more data\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"onboarding-subtitle\"],[12],[1,\"Connect your Google Analytics and Search Console accounts:\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"row double-offset-top\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@url\",\"@wizard\",\"@autoSelect\"],[[30,0,[\"url\"]],true,true]],null],[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"If you have more than one Google Analytics or Search Console accounts, connect them all to your master account.\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"row row-separator\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-sm-7\"],[12],[10,\"hr\"],[12],[13],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn btn-primary btn-YUGE btn-start-tracking\"],[24,4,\"submit\"],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"save\"]]],null]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"btn-label-default\"],[12],[1,[52,[30,0,[\"isSaving\"]],\"Saving…\",\"START TRACKING\"]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"progressOverlayShown\"]],[[[1,\"  \"],[8,[39,6],null,[[\"@messages\",\"@onProgressFinish\"],[[30,0,[\"loadingMessages\"]],[30,0,[\"onProgressFinish\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"page-title\",\"analytics-property-chooser\",\"nw-form-info\",\"on\",\"prevent-default\",\"if\",\"overlay/loader-overlay\"]]",
    "moduleName": "nightwatch-web/templates/start/integrations.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});