define("nightwatch-web/components/overview/metrics-donut", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="metrics-chart {{unless this.value 'status--waiting'}}">
  </div>
  
  */
  {
    "id": "nXLS62of",
    "block": "[[[10,0],[15,0,[29,[\"metrics-chart \",[52,[51,[30,0,[\"value\"]]],\"status--waiting\"]]]],[12],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"unless\"]]",
    "moduleName": "nightwatch-web/components/overview/metrics-donut.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    value: 0,
    valueColor: '#ffffff',
    chartObject: null,

    didInsertElement() {
      this._super(...arguments);

      this.chartObject = this.renderChart();
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.chartObject) {
        this.renderChart();
      }
    },

    getData() {
      let {
        value,
        valueColor
      } = this;
      value = parseInt(value) || 0;
      return [{
        name: 'Value',
        y: value,
        color: valueColor,
        states: {
          hover: {
            enabled: false
          }
        }
      }, {
        name: 'Blank',
        y: 100 - value,
        color: this.noRankColor,
        states: {
          hover: {
            enabled: false
          }
        }
      }];
    },

    renderChart() {
      return (0, _jquery.default)('.metrics-chart', this.element).highcharts({
        credits: {
          enabled: false
        },
        chart: {
          type: 'pie',
          backgroundColor: null,
          margin: [3, 12, 3, 3]
        },
        exporting: false,
        legend: {
          enabled: false
        },
        title: {
          text: ''
        },
        plotOptions: {
          pie: {
            center: [45, null],
            shadow: false,
            allowPointSelect: false,
            slicedOffset: 0,
            size: '100%',
            minSize: 50,
            borderColor: null,
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        tooltip: {
          enabled: false
        },
        series: [{
          data: this.getData(),
          innerSize: '63%',
          showInLegend: false,
          states: {
            inactive: {
              opacity: 1
            },
            hover: {
              opacity: 1
            },
            active: {
              opacity: 1
            }
          },
          dataLabels: {
            enabled: false
          }
        }]
      });
    }

  }));

  _exports.default = _default;
});