define("nightwatch-web/routes/dashboard/url/settings/backlinks", ["exports", "nightwatch-web/utils/is-testing"], function (_exports, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlSettingsBacklinksRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      const url = model;
      const ignoredDomains = controller.ignored_domains;

      if (ignoredDomains && ignoredDomains.length > 0) {
        const existingIgnoredDomains = url.get('backlinksSettings.ignored_domains.content') || [];
        const newIgnoredDomains = existingIgnoredDomains.concat(ignoredDomains).uniq().compact();
        this.notifications.info('Selected domains were added to the input below. Click on save button for new settings to take effect.', {
          autoClear: true,
          clearDuration: _isTesting.default ? 0 : 5000
        });
        url.set('backlinksSettings.ignored_domains', newIgnoredDomains);
      }
    }

    resetController(controller) {
      controller.setProperties({
        ignored_domains: []
      });
    }

  }

  _exports.default = DashboardUrlSettingsBacklinksRoute;
});