define("nightwatch-web/services/faye", ["exports", "nightwatch-web/config/environment", "ember-concurrency", "nightwatch-web/utils/load-external-scripts"], function (_exports, _environment, _emberConcurrency, _loadExternalScripts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const FAYE_CLIENT_URL = `${_environment.default.fayeURL}/client.js`;
  let FayeService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class FayeService extends Ember.Service.extend(Ember.Evented) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "client", null);
    }

    *subscribe() {
      if (!_environment.default.fayeURL) return;
      if (this.client) return;
      yield (0, _loadExternalScripts.default)([FAYE_CLIENT_URL]);
      const {
        token,
        user
      } = this.session;
      this.client = new window.Faye.Client(_environment.default.fayeURL);
      this.client.disable('autodisconnect');
      this.client.addExtension({
        outgoing(message, callback) {
          message.ext = _objectSpread(_objectSpread({}, message.ext), {}, {
            access_token: token
          });
          callback(message);
        }

      });
      const channel = `/users/${user.id}/accounts/${user.accountId}`;
      this.client.subscribe(channel, ({
        eventName,
        eventParams
      }) => {
        this.trigger(`push:${eventName}`, eventParams);
      });
    }

    handlePushUpdates({
      onKeywordPositionUpdate
    }) {
      this.subscribe.perform();
      this.on('push:notifications:new', () => {
        var _this$session, _this$session$user;

        // Reload to refresh notifications count shown in the navbar
        (_this$session = this.session) === null || _this$session === void 0 ? void 0 : (_this$session$user = _this$session.user) === null || _this$session$user === void 0 ? void 0 : _this$session$user.reload();
      });
      this.on('push:keyword:position_update', ({
        id
      }) => {
        const keyword = this.store.peekRecord('keyword', id);
        if (!keyword) return;
        keyword.reload();
        onKeywordPositionUpdate();
      });
      this.on('push:url:seo_metrics_update', ({
        id
      }) => {
        const url = this.store.peekRecord('url', id);
        if (!url) return;
        url.loadStats();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "subscribe", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "subscribe"), _class.prototype)), _class));
  _exports.default = FayeService;
});