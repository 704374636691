define("nightwatch-web/mixins/selectable-keywords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    selectedKeywordIds: Ember.A(),
    selectedKeywords: Ember.computed('selectedKeywordIds.[]', 'keywords.[]', function () {
      if (!this.keywords) return;
      return this.keywords.filter(keyword => this.selectedKeywordIds.includes(String(keyword.id)));
    }),
    toggleKeywordSelected: Ember._action(function (keyword) {
      const keywordId = String(keyword.id);

      if (this.selectedKeywordIds.includes(keywordId)) {
        this.selectedKeywordIds.removeObject(keywordId);
      } else {
        this.selectedKeywordIds.pushObject(keywordId);
      }
    }),
    selectAll: Ember._action(function () {
      const keywordIds = this.keywords.mapBy('id');
      this.set('selectedKeywordIds', Ember.A(keywordIds));
    }),
    selectNone: Ember._action(function () {
      this.set('selectedKeywordIds', Ember.A());
    })
  });

  _exports.default = _default;
});