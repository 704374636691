define("nightwatch-web/mixins/filters-toggling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filtersShown: false,
    checkFiltersShown: Ember.on('init', function () {
      const filtersShown = JSON.parse(localStorage.getItem('nw:filters-shown'));
      this.set('filtersShown', filtersShown);
    }),
    toggleFilters: Ember._action(function () {
      this.toggleProperty('filtersShown');
      localStorage.setItem('nw:filters-shown', JSON.stringify(this.filtersShown));
      return false;
    })
  });

  _exports.default = _default;
});