define("nightwatch-web/components/reports/report-blocks/site-audit-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Reports::ReportBlocks::SiteAudit::Top
    @blockObject={{@blockObject}}
    @removeBlock={{@removeBlock}}
    @icon={{@icon}}
    @pagesCount={{@pagesCount}}
    @selectedSiteAuditView={{@selectedSiteAuditView}}
    @siteAuditViews={{@siteAuditViews}}
    @selectSiteAuditView={{@selectSiteAuditView}}
  />
  
  {{#if @siteAuditSnapshots.length}}
    <div class="report-block__content">
      Compare to previous snapshot
      <SnapshotSelector
        @snapshots={{@siteAuditSnapshots}}
        @selectedSnapshotId={{@selectedSiteAuditSnapshotId}}
        @onChange={{@selectSiteAuditSnapshotId}}
      />
    </div>
  {{/if}}
  
  */
  {
    "id": "wrm+aYeV",
    "block": "[[[8,[39,0],null,[[\"@blockObject\",\"@removeBlock\",\"@icon\",\"@pagesCount\",\"@selectedSiteAuditView\",\"@siteAuditViews\",\"@selectSiteAuditView\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]]],null],[1,\"\\n\\n\"],[41,[30,8,[\"length\"]],[[[1,\"  \"],[10,0],[14,0,\"report-block__content\"],[12],[1,\"\\n    Compare to previous snapshot\\n    \"],[8,[39,2],null,[[\"@snapshots\",\"@selectedSnapshotId\",\"@onChange\"],[[30,8],[30,9],[30,10]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@blockObject\",\"@removeBlock\",\"@icon\",\"@pagesCount\",\"@selectedSiteAuditView\",\"@siteAuditViews\",\"@selectSiteAuditView\",\"@siteAuditSnapshots\",\"@selectedSiteAuditSnapshotId\",\"@selectSiteAuditSnapshotId\"],false,[\"reports/report-blocks/site-audit/top\",\"if\",\"snapshot-selector\"]]",
    "moduleName": "nightwatch-web/components/reports/report-blocks/site-audit-overview.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});