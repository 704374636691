define("nightwatch-web/components/settings/settings-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <NwPageContent class="nw-page-content--single nw-settings">
    <Settings::SettingsBody @user={{@user}}>
      {{yield}}
    </Settings::SettingsBody>
  </NwPageContent>
  
  */
  {
    "id": "tXTzpqn3",
    "block": "[[[8,[39,0],[[24,0,\"nw-page-content--single nw-settings\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@user\",\"&default\"],false,[\"nw-page-content\",\"settings/settings-body\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/settings/settings-area.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});