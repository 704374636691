define("nightwatch-web/models/filter", ["exports", "@ember-data/model", "date-fns"], function (_exports, _model, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DATE_VALUE_FORMAT = 'MM/dd/yyyy';
  let Filter = (_dec = Ember.inject.service, _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.belongsTo)('filter-group', {
    async: false
  }), _dec6 = Ember.computed('value'), _dec7 = Ember.computed('value'), _dec8 = Ember.computed('field', 'condition', 'value'), _dec9 = Ember.computed('field', 'condition', 'value'), _dec10 = Ember.computed('field', 'condition', 'value'), _dec11 = Ember.computed('field'), _dec12 = Ember.computed('field'), (_class = class Filter extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "filterData", _descriptor, this);

      _initializerDefineProperty(this, "field", _descriptor2, this);

      _initializerDefineProperty(this, "condition", _descriptor3, this);

      _initializerDefineProperty(this, "value", _descriptor4, this);

      _initializerDefineProperty(this, "filterGroup", _descriptor5, this);
    }

    get dateValue() {
      return this.value ? (0, _dateFns.parse)(this.value, DATE_VALUE_FORMAT, new Date()) : null;
    }

    set dateValue(value) {
      this.value = (0, _dateFns.format)(value, DATE_VALUE_FORMAT);
    }

    get booleanValue() {
      return String(this.value) === 'true';
    }

    set booleanValue(value) {
      this.set('value', String(value));
    }

    get hasValues() {
      return this.filterData.filterHasValues(this);
    }

    get isDirty() {
      return this.filterData.filterIsDirty(this);
    }

    get isBlank() {
      return this.filterData.filterIsBlank(this);
    }

    get filterInfo() {
      return this.filterData.getFilterInfoByField(this.field);
    }

    get isBooleanField() {
      return this.filterData.isBooleanField(this.field);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filterData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "field", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "condition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterGroup", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dateValue", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "dateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "booleanValue", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "booleanValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasValues", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "hasValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDirty", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isBlank", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isBlank"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterInfo", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "filterInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isBooleanField", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "isBooleanField"), _class.prototype)), _class));
  _exports.default = Filter;
});