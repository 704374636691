define("nightwatch-web/utils/dom/slide-element", ["exports", "nightwatch-web/utils/dom/get-element"], function (_exports, _getElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.slideDown = _exports.slideUp = void 0;

  const slideUp = (target, callback = null, duration = 500) => {
    const el = (0, _getElement.default)(target);
    if (!el) return;
    el.style.transitionProperty = 'height, margin, padding';
    el.style.transitionDuration = duration + 'ms';
    el.style.boxSizing = 'border-box';
    el.style.height = el.offsetHeight + 'px';
    normalizeStyles(el);
    setTimeout(() => {
      el.style.display = 'none';
      removeProperties(el, ['height', 'padding-top', 'padding-bottom', 'margin-top', 'margin-bottom', 'overflow', 'transition-duration', 'transition-property']);
    }, duration);
    if (typeof callback === 'function') callback();
  };

  _exports.slideUp = slideUp;

  const slideDown = (target, callback = null, duration = 500) => {
    const el = (0, _getElement.default)(target);
    if (!el) return;
    el.style.removeProperty('display');
    let display = getComputedStyle(el).display;
    if (display === 'none') display = 'block';
    let height = el.offsetHeight;
    el.style.display = display;
    normalizeStyles(el);
    el.offsetHeight;
    el.style.boxSizing = 'border-box';
    el.style.transitionProperty = 'height, margin, padding';
    el.style.transitionDuration = duration + 'ms';
    el.style.height = height + 'px';
    removeProperties(el, ['padding-top', 'padding-bottom', 'margin-top', 'margin-bottom']);
    window.setTimeout(() => {
      removeProperties(el, ['height', 'overflow', 'transition-duration', 'transition-property']);
    }, duration);
    if (typeof callback === 'function') callback();
  };

  _exports.slideDown = slideDown;

  const removeProperties = (el, properties) => {
    properties.forEach(property => el.style.removeProperty(property));
  };

  const normalizeStyles = el => {
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
  };
});