define("nightwatch-web/serializers/dynamic-view", ["exports", "nightwatch-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeBelongsTo(snapshot, json, relationship) {
      let {
        key
      } = relationship;
      const belongsTo = snapshot.belongsTo(key);

      if (key === 'url') {
        if (belongsTo) {
          return json['search_keyword_url_id'] = belongsTo.id;
        }
      } else {
        key = this.keyForRelationship(relationship.key, 'belongsTo', 'serialize');

        if (belongsTo) {
          return json[key] = belongsTo.id;
        }
      }
    }

  });

  _exports.default = _default;
});