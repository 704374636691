define("nightwatch-web/routes/dashboard/url/backlinks/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlBacklinksIndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('persistence/backlinks-settings'), _dec5 = Ember.inject.service('notifications/backlinks-notifications'), _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class DashboardUrlBacklinksIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "userSettings", _descriptor3, this);

      _initializerDefineProperty(this, "backlinksSettings", _descriptor4, this);

      _initializerDefineProperty(this, "backlinksNotifications", _descriptor5, this);

      _initializerDefineProperty(this, "metrics", _descriptor6, this);

      _initializerDefineProperty(this, "session", _descriptor7, this);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        direction: {
          refreshModel: true
        },
        search: {
          refreshModel: true
        },
        filters: {
          refreshModel: true
        },
        domain: {
          refreshModel: true
        },
        root_domain: {
          refreshModel: true
        },
        one_link_per_domain: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        }
      });
    }

    activate() {
      window.scrollTo(0, 0);
    }

    beforeModel(transition) {
      const url = this.modelFor('dashboard.url');
      const searchKeywordUrlId = url.get('id');
      const {
        sort,
        direction,
        limit
      } = this.backlinksSettings.getSettings({
        searchKeywordUrlId
      });
      const queryParams = transition.to.queryParams; // When there are no params set, use the persisted params

      const shouldOverwriteQueryParams = Ember.isBlank(Object.keys(queryParams));

      if (shouldOverwriteQueryParams) {
        const newQueryParams = Object.assign({}, queryParams);

        if (Ember.isBlank(queryParams.sort) && Ember.isPresent(sort)) {
          newQueryParams.sort = sort;
          newQueryParams.direction = direction || 'desc';
        }

        if (Ember.isBlank(queryParams.limit) && Ember.isPresent(limit)) {
          newQueryParams.limit = limit;
        }

        this.router.transitionTo('dashboard.url.backlinks', url, {
          queryParams: newQueryParams
        });
      }
    }

    model(params) {
      const url = this.modelFor('dashboard.url');
      const backlinks = this.store.query('backlink', {
        url_id: url.id,
        limit: params.limit || 50,
        sort: params.sort || 'url_rating',
        direction: params.direction || 'desc',
        page: params.page || 1,
        search: params.search,
        filters: params.filters,
        one_link_per_domain: params.one_link_per_domain
      });
      return Ember.RSVP.hash({
        url: url,
        backlinks: backlinks
      });
    }

    afterModel() {
      if (this.controller) {
        this.controller.set('selectedBacklinks', Ember.A());
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model); // Fetch only when there is no stats present

      if (!controller.get('stats.all')) {
        controller.fetchStats();
      }

      const {
        user
      } = this.session;
      const filtersHidden = JSON.parse(localStorage.getItem('nw:backlinks:filters-shown')) === false;
      const filtersPresent = Ember.isPresent(controller.filters);
      controller.set('filtersShown', !filtersHidden || filtersPresent);
      this.backlinksNotifications.notifyLimitReached(user);
      this.backlinksNotifications.notifyFetchingDisabled(model.url);
      controller.set('progressCheckingEnabled', true);
      controller.handleProgress(); // Prepare model for the view.

      if (!controller.backlinkView) {
        const url = model.url;
        const backlinkView = this.store.createRecord('backlink-view', {
          url: url
        });
        controller.set('backlinkView', backlinkView);
      }
    }

    resetController(controller, isExiting) {
      controller.set('filters', null);

      if (isExiting) {
        controller.setProperties({
          stats: Ember.Object.create({}),
          backlinkView: null,
          progressCheckingEnabled: false
        });
      }
    }

    deactivate() {
      this.backlinksNotifications.clearNotifications();
    }

    async loading(transition) {
      const controller = this.controllerFor('dashboard.url.backlinks.index');
      controller.set('isLoading', true);

      try {
        await transition.promise;
      } finally {
        controller.set('isLoading', false);
      }

      return true;
    }

    didTransition() {
      this.metrics.trackEvent({
        event: 'Open Backlinks'
      });
      return true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userSettings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "backlinksSettings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "backlinksNotifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class));
  _exports.default = DashboardUrlBacklinksIndexRoute;
});