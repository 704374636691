define("nightwatch-web/adapters/filter-group", ["exports", "nightwatch-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FilterGroupAdapter extends _application.default {
    buildURL(_modelName, _id, snapshot, _requestType, query) {
      var _snapshot$belongsTo;

      const defaultUrl = super.buildURL(...arguments);
      const dynamicViewId = query && query.dynamic_view_id ? query.dynamic_view_id : snapshot && snapshot.belongsTo ? (_snapshot$belongsTo = snapshot.belongsTo('dynamicView')) === null || _snapshot$belongsTo === void 0 ? void 0 : _snapshot$belongsTo.id : undefined;
      return defaultUrl.replace(/\/filter_groups/, `/dynamic_views/${dynamicViewId}/filter_groups`);
    }

  }

  _exports.default = FilterGroupAdapter;
});