define("nightwatch-web/modifiers/scroll-parent-here", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   @param {HTMLElement} scrollToElement - Scroll offset will be calculated to this element
   @param {Array} _args - Unused args that are watched by the modifier
   @param {Boolean} enabled - Aborted unless this is true
   */
  const scrollParentHere = (scrollToElement, _args, {
    enabled
  }) => {
    if (!scrollToElement || !enabled) return; // Scrolls the native `offsetParent` of `scrollToElement`

    scrollToElement.offsetParent.scrollTo({
      top: scrollToElement.offsetTop,
      behavior: 'smooth'
    });
  };

  var _default = (0, _emberModifier.modifier)(scrollParentHere);

  _exports.default = _default;
});