define("nightwatch-web/controllers/dashboard/url/backlink-view-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlBacklinkViewSettingsController = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('model'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class DashboardUrlBacklinkViewSettingsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "backlinkView", _descriptor2, this);
    }

    save(backlinkView) {
      backlinkView.save().then(backlinkView => {
        this.notifications.success('Backlink View updated successfully.', {
          autoClear: true,
          clearDuration: 3500
        });
        this.router.transitionTo('dashboard.url.backlink-view', backlinkView.id);
      }).catch(() => {
        this.notifications.error('There was an error while updating.', {
          autoClear: true,
          clearDuration: 3500
        });
      });
    }

    delete(backlinkView) {
      if (confirm('Are you sure you want to delete this backlink view?')) {
        const url = backlinkView.get('url');
        backlinkView.destroyRecord().then(() => {
          url.get('backlinkViews').removeObject(backlinkView);
          this.notifications.success('Backlink View deleted successfully.', {
            autoClear: true,
            clearDuration: 3500
          });
        }).catch(() => {
          this.notifications.error('There was an error while deleting.', {
            autoClear: true,
            clearDuration: 3500
          });
        });
        this.router.transitionTo('dashboard.url.backlinks', url);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "backlinkView", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = DashboardUrlBacklinkViewSettingsController;
});