define("nightwatch-web/helpers/humanize-interval", ["exports", "nightwatch-web/constants/time-periods"], function (_exports, _timePeriods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INTERVALS_HUMANIZE_NAMES = void 0;
  const INTERVALS_HUMANIZE_NAMES = {
    [_timePeriods.DAILY]: 'daily',
    [_timePeriods.WEEKLY]: 'weekly',
    [_timePeriods.MONTHLY]: 'monthly'
  };
  _exports.INTERVALS_HUMANIZE_NAMES = INTERVALS_HUMANIZE_NAMES;

  var _default = Ember.Helper.helper(([interval]) => INTERVALS_HUMANIZE_NAMES[interval]);

  _exports.default = _default;
});