define("nightwatch-web/services/error-reporter", ["exports", "rollbar"], function (_exports, _rollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ErrorReporterService = (_dec = Ember.inject.service, _dec2 = Ember.computed, (_class = class ErrorReporterService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);
    }

    get userConsent() {
      var _this$session, _this$session$user;

      const consents = (_this$session = this.session) === null || _this$session === void 0 ? void 0 : (_this$session$user = _this$session.user) === null || _this$session$user === void 0 ? void 0 : _this$session$user.consents;
      return consents ? consents.includes('error_tracking') : true;
    }

    get enabled() {
      return this.config.enabled;
    }

    get person() {
      return this.notifier.options.payload.person;
    }

    set person(value) {
      if (this.userConsent) {
        this.notifier.configure({
          payload: {
            person: value
          }
        });
      }
    }

    get notifier() {
      return new _rollbar.default(this.config);
    }

    get config() {
      return Ember.getOwner(this).resolveRegistration('config:environment').rollbarConfig;
    } // Notifications


    critical(...args) {
      if (!this.userConsent) return;
      return this.notifier.critical(...args);
    }

    error(...args) {
      if (!this.userConsent) return;
      return this.notifier.error(...args);
    }

    warning(...args) {
      if (!this.userConsent) return;
      return this.notifier.warning(...args);
    }

    info(...args) {
      if (!this.userConsent) return;
      return this.notifier.info(...args);
    }

    debug(...args) {
      if (!this.userConsent) return;
      return this.notifier.debug(...args);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "notifier", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "notifier"), _class.prototype)), _class));
  _exports.default = ErrorReporterService;
});