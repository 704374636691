define("nightwatch-web/helpers/with-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withDefault = withDefault;
  _exports.default = void 0;

  function withDefault(value, defaultValue = '') {
    return Ember.isPresent(value) ? value : defaultValue;
  }

  var _default = Ember.Helper.helper(params => withDefault(params[0], params[1]));

  _exports.default = _default;
});