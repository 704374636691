define("nightwatch-web/utils/query-param-fragment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const queryParamFragment = queryParams => {
    let fragment = Object.keys(queryParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&');
    return fragment ? `?${fragment}` : '';
  };

  var _default = queryParamFragment;
  _exports.default = _default;
});