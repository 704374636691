define("nightwatch-web/templates/settings/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KBx5+hDi",
    "block": "[[[1,[28,[35,0],[\"Integrations\"],null]],[1,\"\\n\\n\"],[10,\"h2\"],[14,0,\"nw-settings__subtitle\"],[12],[1,\"Synchronise organic search results data with Nightwatch.\"],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@user\"],[[30,0,[\"session\",\"user\"]]]],null],[1,\"\\n\"]],[],false,[\"page-title\",\"settings/google-analytics-connector\"]]",
    "moduleName": "nightwatch-web/templates/settings/integrations.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});