define("nightwatch-web/templates/dashboard/url/dynamic-view/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vanEhrfr",
    "block": "[[[1,[28,[35,0],[[30,1,[\"name\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Settings\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"main-area-navbar dashboard-title\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main-area-container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,1,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"url-favicon-container\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@src\"],[[30,1,[\"url\",\"faviconUrl\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,1,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,1,[\"url\",\"url\"]]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,4],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url.dynamic-view\",[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,4],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\" Settings\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@dynamicView\",\"@isUrlView\",\"@afterDelete\"],[[30,1],true,[30,0,[\"afterDelete\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"link-to\",\"favicon-image\",\"friendly-url\",\"nw-icon\",\"dynamic-view-settings-form\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/dynamic-view/settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});