define("nightwatch-web/routes/dashboard/dynamic-view/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardDynamicViewReportsRoute extends Ember.Route {
    model() {
      return Ember.RSVP.hash({
        reports: this.store.query('report', {
          dynamic_view_id: this.modelFor('dashboard.dynamic-view').id
        }),
        dynamicView: this.modelFor('dashboard.dynamic-view')
      });
    }

    afterModel(model) {
      model.dynamicView.set('reports', model.reports);
    }

    renderTemplate() {
      this.render({
        into: 'dashboard'
      });
    }

  }

  _exports.default = DashboardDynamicViewReportsRoute;
});