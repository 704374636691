define("nightwatch-web/utils/dom/shake-element", ["exports", "nightwatch-web/utils/is-testing", "nightwatch-web/utils/dom/get-element", "nightwatch-web/utils/delay"], function (_exports, _isTesting, _getElement, _delay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const shakeElement = async target => {
    const el = (0, _getElement.default)(target);
    if (!el) return;
    el.classList.add('nudge-shake');
    await (0, _delay.default)(_isTesting.default ? 0 : 400);
    el.classList.remove('nudge-shake');
  };

  var _default = shakeElement;
  _exports.default = _default;
});