define("nightwatch-web/helpers/clipboard-supported", ["exports", "clipboard"], function (_exports, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.clipboardSupported = void 0;

  const clipboardSupported = () => _clipboard.default.isSupported();

  _exports.clipboardSupported = clipboardSupported;

  var _default = Ember.Helper.helper(clipboardSupported);

  _exports.default = _default;
});