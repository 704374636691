define("nightwatch-web/models/graph", ["exports", "@ember-data/model", "date-fns"], function (_exports, _model, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Graph = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)(), _dec6 = (0, _model.belongsTo)('url', {
    async: false
  }), _dec7 = Ember.computed('graph_data.grouping'), (_class = class Graph extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "date_from", _descriptor2, this);

      _initializerDefineProperty(this, "date_to", _descriptor3, this);

      _initializerDefineProperty(this, "relative_date_range", _descriptor4, this);

      _initializerDefineProperty(this, "graph_data", _descriptor5, this);

      _initializerDefineProperty(this, "url", _descriptor6, this);
    }

    get grouping() {
      return this.get('graph_data.grouping') || 'day';
    }

    extractDates() {
      const relativeRange = this.relative_date_range;
      let dateFrom, dateTo;

      if (relativeRange) {
        dateTo = (0, _dateFns.format)(new Date(), 'yyyy-MM-dd');
        const periodAmount = parseInt(relativeRange.split('_')[1]);
        dateFrom = (0, _dateFns.sub)(new Date(), {
          days: periodAmount
        });
      } else {
        dateFrom = (0, _dateFns.format)(new Date(this.date_from || new Date()), 'yyyy-MM-dd');
        dateTo = (0, _dateFns.format)(new Date(this.date_to || new Date()), 'yyyy-MM-dd');
      }

      return {
        dateFrom: dateFrom,
        dateTo: dateTo
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date_from", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "date_to", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "relative_date_range", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "graph_data", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "grouping", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "grouping"), _class.prototype)), _class));
  _exports.default = Graph;
});