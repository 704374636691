define("nightwatch-web/services/update-version-checker", ["exports", "ember-concurrency", "nightwatch-web/config/environment", "fetch"], function (_exports, _emberConcurrency, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CHECK_INTERVAL_MINUTES = 2;
  let UpdateVersionCheckerService = (_dec = Ember.inject.service, (_class = class UpdateVersionCheckerService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _defineProperty(this, "fingerprint", null);
    }

    *checkNewVersion() {
      yield (0, _emberConcurrency.rawTimeout)(CHECK_INTERVAL_MINUTES * 60 * 1000);

      try {
        const request = yield (0, _fetch.default)('/index.html', {
          contentType: 'text/html'
        });
        const response = yield request.text();

        if (!this.fingerprint) {
          this.fingerprint = this.parseFingerprint(response);
          return;
        }

        this.fingerprint === this.parseFingerprint(response) ? this.checkNewVersion.perform() : this.showNewVersionNotification();
      } catch (error) {
        this.checkNewVersion.perform();
        throw error;
      }
    }

    parseFingerprint(response) {
      const regEx = new RegExp(`${_environment.default.modulePrefix}-(.*)\\.js`, 'mg');
      const matches = regEx.exec(response);
      return (matches === null || matches === void 0 ? void 0 : matches.length) > 1 ? matches[1] : '';
    }

    showNewVersionNotification() {
      const msg = Ember.String.htmlSafe(`You're using an outdated version of the app. <b>Click here</b> to refresh the page.`);
      this.notifications.info(msg, {
        onClick: () => {
          window.location.reload();
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkNewVersion", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "checkNewVersion"), _class.prototype)), _class));
  _exports.default = UpdateVersionCheckerService;
});