define("nightwatch-web/templates/components/draggable-object-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GfuZOWSh",
    "block": "[[[41,[33,1],[[[1,\"  \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"acceptForDrop\"],null],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"enableClicking\",\"action\",\"yield\"]]",
    "moduleName": "nightwatch-web/templates/components/draggable-object-target.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});