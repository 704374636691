define("nightwatch-web/components/site-audit/crawling-session-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BasicDropdown as |dropdown|>
    <dropdown.Trigger class="crawling-session-dropdown">
      <NwIcon @icon="icon-calendar" />
      {{if @crawlingSession.startedAt (format-date @crawlingSession.startedAt 'do MMM h:mm aaa') 'Pending…'}}<br>
      {{yield}}
    </dropdown.Trigger>
    <dropdown.Content class="nw-dropdown nw-dropdown__full-width">
      <div class="calendar-dropdown">
        <SiteAudit::SnapshotSelectorContainer
          @wrapperClass="calendar-container"
          @currentCrawlingSession={{@crawlingSession}}
          @crawlingSessions={{@crawlingSessions}}
          @onSelectCrawlingSession={{@onSelectCrawlingSession}}
          @closeSnapshotSelector={{dropdown.actions.close}}
        />
      </div>
    </dropdown.Content>
  </BasicDropdown>
  
  */
  {
    "id": "9cbdFpbb",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Trigger\"]],[[24,0,\"crawling-session-dropdown\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\"],[\"icon-calendar\"]],null],[1,\"\\n    \"],[1,[52,[30,2,[\"startedAt\"]],[28,[37,3],[[30,2,[\"startedAt\"]],\"do MMM h:mm aaa\"],null],\"Pending…\"]],[10,\"br\"],[12],[13],[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"Content\"]],[[24,0,\"nw-dropdown nw-dropdown__full-width\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"calendar-dropdown\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@wrapperClass\",\"@currentCrawlingSession\",\"@crawlingSessions\",\"@onSelectCrawlingSession\",\"@closeSnapshotSelector\"],[\"calendar-container\",[30,2],[30,3],[30,4],[30,1,[\"actions\",\"close\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"dropdown\",\"@crawlingSession\",\"@crawlingSessions\",\"@onSelectCrawlingSession\",\"&default\"],false,[\"basic-dropdown\",\"nw-icon\",\"if\",\"format-date\",\"yield\",\"site-audit/snapshot-selector-container\"]]",
    "moduleName": "nightwatch-web/components/site-audit/crawling-session-selector.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});