define("nightwatch-web/initializers/input-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  const initialize = () => Ember.Checkbox.reopen({
    attributeBindings: ['data-id']
  });

  _exports.initialize = initialize;
  var _default = {
    name: 'input-attributes',
    initialize
  };
  _exports.default = _default;
});