define("nightwatch-web/components/tag-list", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tag-list" ...attributes>
    {{#each @tags as |tag|}}
      <TagItem @tag={{tag}} @isExpandable={{@isExpandable}} @deleteTag={{@deleteTag}} data-test-tag-item={{true}} />
    {{/each}}
  
    {{#if this.canAddANewTag}}
      <NwIcon @icon="icon-plus-circle" class="add-new-tag" role="button" data-test-add-tag {{on "click" (stop-propagation this.addNewTag)}} />
    {{/if}}
  
    {{#if this.isAdding}}
      <TagNewForm @onSubmit={{this.submitNewTag}} @onDismiss={{this.dismissNewTag}} />
    {{/if}}
  </div>
  
  */
  {
    "id": "ZP1JR45X",
    "block": "[[[11,0],[24,0,\"tag-list\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@tag\",\"@isExpandable\",\"@deleteTag\"],[[30,3],[30,4],[30,5]]],null],[1,\"\\n\"]],[3]],null],[1,\"\\n\"],[41,[30,0,[\"canAddANewTag\"]],[[[1,\"    \"],[8,[39,4],[[24,0,\"add-new-tag\"],[24,\"role\",\"button\"],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"addNewTag\"]]],null]],null]],[[\"@icon\"],[\"icon-plus-circle\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isAdding\"]],[[[1,\"    \"],[8,[39,7],null,[[\"@onSubmit\",\"@onDismiss\"],[[30,0,[\"submitNewTag\"]],[30,0,[\"dismissNewTag\"]]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@tags\",\"tag\",\"@isExpandable\",\"@deleteTag\"],false,[\"each\",\"-track-array\",\"tag-item\",\"if\",\"nw-icon\",\"on\",\"stop-propagation\",\"tag-new-form\"]]",
    "moduleName": "nightwatch-web/components/tag-list.hbs",
    "isStrictMode": false
  });

  let TagsList = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class TagsList extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isAdding", _descriptor, this);
    }

    get canAddANewTag() {
      return this.args.editableTags && !this.isAdding;
    }

    addNewTag() {
      this.isAdding = true;
    }

    dismissNewTag() {
      this.isAdding = false;
    }

    submitNewTag(newTag) {
      this.args.addTag(newTag);
      this.isAdding = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isAdding", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addNewTag", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addNewTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismissNewTag", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "dismissNewTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitNewTag", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "submitNewTag"), _class.prototype)), _class));
  _exports.default = TagsList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TagsList);
});