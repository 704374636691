define("nightwatch-web/controllers/dashboard/url/backlinks/enable", ["exports", "nightwatch-web/utils/dom/fade-element"], function (_exports, _fadeElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlBacklinksEnableController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, (_class = class DashboardUrlBacklinksEnableController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);
    }

    checkForBacklinks(url) {
      const _check = url => {
        if (!this.fetchingBacklinks) {
          return;
        }

        url.reload().then(async url => {
          if (Ember.isPresent(url.get('backlinksStatus.last_ahrefs_fetch_done_at'))) {
            this.set('fetchingBacklinks', false);
            await (0, _fadeElement.fadeOut)('#splash-overlay');
            this.router.transitionTo('dashboard.url.backlinks', url);
          } else {
            Ember.run.later(() => {
              _check(url);
            }, 1500);
          }
        });
      };

      _check(url);
    }

    enableBacklinks() {
      const url = this.model;
      const promise = this.fetch.post(`/urls/${url.id}/enable_backlinks`);
      this.set('isEnabling', true);
      promise.then(() => {
        // To get backlinks total
        url.reload().then(() => {
          this.router.transitionTo('dashboard.url.backlinks', url);
        });
      });
      promise.catch(() => {
        this.notifications.error('Something went wrong. Please contact support.', {
          autoClear: true,
          clearDuration: 10000
        });
      });
      promise.finally(() => {
        this.set('isEnabling', false);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "enableBacklinks", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "enableBacklinks"), _class.prototype)), _class));
  _exports.default = DashboardUrlBacklinksEnableController;
});