define("nightwatch-web/templates/dashboard/url/backlinks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I6UkAW3X",
    "block": "[[[1,[28,[35,0],[\"Backlinks\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"main-area-navbar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main-area-container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"url-favicon-container\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@src\"],[[30,0,[\"url\",\"faviconUrl\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,0,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,1,[\"url\"]]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,4],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url.backlinks\",[30,1]]],[[\"default\"],[[[[1,\"Backlinks\"]],[]]]]],[1,\"\\n      \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"backlinksEnabled\"]],[[[1,\"          \"],[10,0],[14,0,\"settings-cog-container\"],[12],[1,\"\\n            \"],[8,[39,1],[[24,0,\"btn btn-secondary-1 btn-settings\"]],[[\"@route\",\"@model\"],[\"dashboard.url.settings.backlinks\",[30,0,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[8,[39,4],null,[[\"@icon\"],[\"icon-settings\"]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[1,\"    \"],[10,0],[14,1,\"backlinks_progress\"],[14,0,\"pull-right\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"link-to\",\"favicon-image\",\"friendly-url\",\"nw-icon\",\"limited-access\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/backlinks.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});