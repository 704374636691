define("nightwatch-web/adapters/result", ["exports", "nightwatch-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ResultAdapter extends _application.default {
    buildURL(_modelName, _id, snapshot, _requestType, query) {
      var _snapshot$belongsTo;

      const keywordId = query && query.keyword_id ? query.keyword_id : snapshot && snapshot.belongsTo ? (_snapshot$belongsTo = snapshot.belongsTo('keyword')) === null || _snapshot$belongsTo === void 0 ? void 0 : _snapshot$belongsTo.id : undefined;
      return super.buildURL(...arguments).replace(/\/results/, `/keywords/${keywordId}/results`);
    }

    handleResponse(status, headers, payload, requestData) {
      if (status >= 400) return super.handleResponse(...arguments); // some results may not have an ID and that's no good for Ember data

      if (Array.isArray(payload)) {
        payload = payload.map(result => _objectSpread(_objectSpread({}, result), {}, {
          id: result.id || new Date(result.created_at).getTime()
        }));
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

  }

  _exports.default = ResultAdapter;
});