define("nightwatch-web/components/nw-color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (uuid) as |id|}}
    <div class="nw-color-picker" ...attributes>
      <label for={{id}} hidden>
        Color Picker
      </label>
      <input
        class="nw-color-picker__input"
        id={{id}}
        type="color"
        value={{@color}}
        {{on "input" (pick "target.value" @onColorChange)}}
        role="button"
      />
    </div>
  {{/let}}
  
  */
  {
    "id": "VApKxdNA",
    "block": "[[[44,[[28,[37,1],null,null]],[[[1,\"  \"],[11,0],[24,0,\"nw-color-picker\"],[17,2],[12],[1,\"\\n    \"],[10,\"label\"],[15,\"for\",[30,1]],[14,\"hidden\",\"\"],[12],[1,\"\\n      Color Picker\\n    \"],[13],[1,\"\\n    \"],[11,\"input\"],[24,0,\"nw-color-picker__input\"],[16,1,[30,1]],[16,2,[30,3]],[24,\"role\",\"button\"],[24,4,\"color\"],[4,[38,2],[\"input\",[28,[37,3],[\"target.value\",[30,4]],null]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]],[\"id\",\"&attrs\",\"@color\",\"@onColorChange\"],false,[\"let\",\"uuid\",\"on\",\"pick\"]]",
    "moduleName": "nightwatch-web/components/nw-color-picker.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});