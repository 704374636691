define("nightwatch-web/adapters/keyword-suggestion", ["exports", "nightwatch-web/adapters/application", "nightwatch-web/utils/is-type"], function (_exports, _application, _isType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class KeywordSuggestionAdapter extends _application.default {
    handleResponse(status, headers, payload, requestData) {
      if (status >= 400) return super.handleResponse(...arguments);
      const meta = {
        total: headers['X-Total-Count'] || headers['x-total-count'],
        pages: headers['X-Page-Count'] || headers['x-page-count']
      };

      if (Array.isArray(payload)) {
        payload = {
          'keyword-suggestions': payload,
          meta
        };
      } else if ((0, _isType.isObjectType)(payload)) {
        payload = {
          'keyword-suggestion': payload,
          meta
        };
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

  }

  _exports.default = KeywordSuggestionAdapter;
});