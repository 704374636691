define("nightwatch-web/routes/dashboard/dynamic-view/keywords", ["exports", "nightwatch-web/utils/set-saved-sorting"], function (_exports, _setSavedSorting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardDynamicViewKeywordsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('persistence/keyword-list-sorting'), (_class = class DashboardDynamicViewKeywordsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "keywordListSorting", _descriptor2, this);

      _defineProperty(this, "limit", 50);
    }

    setupController(controller, dynamicView) {
      super.setupController(controller, dynamicView);
      const url = this.modelFor('dashboard.url');
      (0, _setSavedSorting.default)(this, controller, 'dashboard.dynamic-view.keywords', {
        viewId: dynamicView.id
      });
      controller.enteredRoute(dynamicView, url);
    }

    afterModel(model, transition) {
      model.filtersSaved = false;
      this.controllerFor('dashboard').setSelectedDynamicView(model);

      if (transition.targetName === 'dashboard.dynamic-view.index') {
        this.router.transitionTo('dashboard.dynamic-view.keywords', model, {
          queryParams: {
            page: 1
          }
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "keywordListSorting", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DashboardDynamicViewKeywordsRoute;
});