define("nightwatch-web/components/nw-page-location-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-page-location-banner">
    <h1>
      {{yield to="first"}}
      {{#if (has-block "second")}}
        <NwIcon @icon="icon-chevron-right" />
        {{yield to="second"}}
      {{/if}}
    </h1>
  </div>
  
  */
  {
    "id": "fSU9ABe7",
    "block": "[[[10,0],[14,0,\"nw-page-location-banner\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[8,[39,3],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\"\\n      \"],[18,2,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&first\",\"&second\"],false,[\"yield\",\"if\",\"has-block\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/nw-page-location-banner.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});