define("nightwatch-web/helpers/parse-date", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.parseDate = void 0;

  const parseDate = ([dateString, formatString, referenceDate = new Date(), options = {}]) => {
    return (0, _dateFns.parse)(dateString, formatString, referenceDate, options);
  };

  _exports.parseDate = parseDate;

  var _default = Ember.Helper.helper(parseDate);

  _exports.default = _default;
});