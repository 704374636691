define("nightwatch-web/helpers/tag-with-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*eslint no-unused-vars: 0*/
  var _default = Ember.Helper.helper(([tag, ...rest]) => `${tag.name} (${tag.count || 0})`);

  _exports.default = _default;
});