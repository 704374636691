define("nightwatch-web/helpers/docs-url", ["exports", "nightwatch-web/constants/docs"], function (_exports, _docs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.docsUrl = void 0;

  const docsUrl = ([key]) => _docs.default[key] || 'https://docs.nightwatch.io';

  _exports.docsUrl = docsUrl;

  var _default = Ember.Helper.helper(docsUrl);

  _exports.default = _default;
});