define("nightwatch-web/helpers/uuid", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const uuid = () => (0, _emberUuid.v4)();

  var _default = Ember.Helper.helper(uuid);

  _exports.default = _default;
});