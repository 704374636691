define("nightwatch-web/templates/dashboard/url/backlinks/disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EjfwLX2M",
    "block": "[[[10,0],[14,0,\"splash-blurred-background\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"splash-blurred-cta-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"blurred-cta\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Start tracking backlinks\"],[13],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"backlink tracking is not enabled on your plan\"],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"truncate switch-button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"goToPlans\"]]],null],[12],[1,\"\\n      Upgrade my account\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"info\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@icon\"],[\"icon-info\"]],null],[1,\" Subscribe to a bigger plan to track backlinks.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"on\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/backlinks/disabled.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});