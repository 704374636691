define("nightwatch-web/transforms/day-date", ["exports", "@ember-data/serializer/transform", "date-fns"], function (_exports, _transform, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DATE_FORMAT = 'yyyy-MM-dd';

  class DayDateTransform extends _transform.default {
    serialize(value) {
      return (0, _dateFns.format)(value, DATE_FORMAT);
    }

    deserialize(dateString) {
      return (0, _dateFns.parse)(dateString, DATE_FORMAT, new Date());
    }

  }

  _exports.default = DayDateTransform;
});