define("nightwatch-web/templates/plan-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jaOeMeMr",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"first\"],[[[[8,[39,2],null,[[\"@route\"],[\"plans\"]],[[\"default\"],[[[[1,\"Plans\"]],[]]]]]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],null,null,null],[1,\"\\n\"]],[],false,[\"nw-navbar\",\"nw-page-location-banner\",\"link-to\",\"loading/large-horizontal\"]]",
    "moduleName": "nightwatch-web/templates/plan-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});