define("nightwatch-web/services/site-data", ["exports", "nightwatch-web/constants/site-data"], function (_exports, _siteData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SiteDataService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = class SiteDataService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "intercom", _descriptor3, this);

      _initializerDefineProperty(this, "metrics", _descriptor4, this);

      _defineProperty(this, "timezones", _siteData.default.timezones);

      _defineProperty(this, "countries", _siteData.default.countries);

      _defineProperty(this, "languages", _siteData.default.languages);

      _defineProperty(this, "countryLanguages", _siteData.default.countryLanguages);

      _defineProperty(this, "countriesExtended", _siteData.default.countriesExtended);

      _initializerDefineProperty(this, "_siteTitle", _descriptor5, this);

      _initializerDefineProperty(this, "_siteLogoUrl", _descriptor6, this);
    }

    init() {
      super.init(...arguments);
      this.siteTitle = 'Nightwatch';
      this.siteLogoUrl = '/assets/images/nightwatch-logo.png';
    }

    get siteTitle() {
      return this._siteTitle;
    }

    set siteTitle(value) {
      if (Ember.isBlank(value)) return;
      this._siteTitle = value;
      this.updateFavicon();
      this.updateLiveChat();
    }

    get siteLogoUrl() {
      return this._siteLogoUrl;
    }

    set siteLogoUrl(value) {
      if (Ember.isBlank(value)) return;
      this._siteLogoUrl = value;
    }

    get isWhiteLabel() {
      return Ember.isPresent(this.siteTitle) && this.siteTitle !== 'Nightwatch';
    }

    get hideNightwatchBranding() {
      var _this$session, _this$session$user;

      if ((_this$session = this.session) !== null && _this$session !== void 0 && (_this$session$user = _this$session.user) !== null && _this$session$user !== void 0 && _this$session$user.isOwner) return false;
      return this.isWhiteLabel;
    }

    afterLoadUser(user) {
      var _user$site, _user$site2;

      if (!(user !== null && user !== void 0 && user.id)) return;
      this.siteLogoUrl = (_user$site = user.site) === null || _user$site === void 0 ? void 0 : _user$site.whitelabel_logo_url;
      this.siteTitle = (_user$site2 = user.site) === null || _user$site2 === void 0 ? void 0 : _user$site2.whitelabel_title;
    } // for cases when we cant get this info from session.user (login, signup,...)


    async load() {
      const data = await this.fetch.request('/site_data/site_data');
      this.siteLogoUrl = data.whitelabel_logo_url;
      this.siteTitle = data.whitelabel_title;
      return data;
    }

    updateLiveChat() {
      var _this$session2, _this$session2$user, _this$session3, _this$session3$user;

      if (this.isWhiteLabel && (_this$session2 = this.session) !== null && _this$session2 !== void 0 && (_this$session2$user = _this$session2.user) !== null && _this$session2$user !== void 0 && _this$session2$user.isLimited || Ember.isBlank((_this$session3 = this.session) === null || _this$session3 === void 0 ? void 0 : (_this$session3$user = _this$session3.user) === null || _this$session3$user === void 0 ? void 0 : _this$session3$user.id)) {
        this.intercom.shutdown();
      }
    }

    updateFavicon() {
      const link = document.querySelector("link[rel*='icon']");
      if (!link) return;
      link.href = this.isWhiteLabel ? '/assets/icons/favicon-whitelabel.ico' : '/assets/icons/favicon.ico';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intercom", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_siteTitle", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_siteLogoUrl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SiteDataService;
});