define("nightwatch-web/models/plan", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Plan = (_dec = (0, _model.attr)('string', {
    readOnly: true
  }), _dec2 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec3 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec4 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec5 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec6 = (0, _model.attr)('boolean', {
    readOnly: true
  }), _dec7 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec8 = (0, _model.attr)('boolean', {
    readOnly: true
  }), _dec9 = (0, _model.attr)('number', {
    readOnly: true
  }), _dec10 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec11 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec12 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec13 = (0, _model.attr)('boolean', {
    readOnly: true
  }), _dec14 = (0, _model.attr)('boolean', {
    readOnly: true
  }), _dec15 = (0, _model.attr)('boolean', {
    readOnly: true
  }), _dec16 = Ember.computed('places', 'apiAccess', 'agency'), _dec17 = Ember.computed('price', 'periodName'), (_class = class Plan extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "price", _descriptor2, this);

      _initializerDefineProperty(this, "keywordLimit", _descriptor3, this);

      _initializerDefineProperty(this, "urlLimit", _descriptor4, this);

      _initializerDefineProperty(this, "accuracy", _descriptor5, this);

      _initializerDefineProperty(this, "backlinksEnabled", _descriptor6, this);

      _initializerDefineProperty(this, "backlinksLimit", _descriptor7, this);

      _initializerDefineProperty(this, "youtube", _descriptor8, this);

      _initializerDefineProperty(this, "period", _descriptor9, this);

      _initializerDefineProperty(this, "displayName", _descriptor10, this);

      _initializerDefineProperty(this, "periodName", _descriptor11, this);

      _initializerDefineProperty(this, "planCategoryName", _descriptor12, this);

      _initializerDefineProperty(this, "apiAccess", _descriptor13, this);

      _initializerDefineProperty(this, "places", _descriptor14, this);

      _initializerDefineProperty(this, "agency", _descriptor15, this);
    }

    get features() {
      const featuresArray = [];

      if (this.apiAccess) {
        featuresArray.push('API');
      }

      if (this.agency) {
        featuresArray.push('Subusers');
        featuresArray.push('White-Label');
      }

      return featuresArray.join(', ');
    }

    get monthlyPrice() {
      switch (this.periodName) {
        case 'monthly':
          return this.price;

        case 'yearly':
          return this.price / 12;

        default:
          return null;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "price", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "keywordLimit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "urlLimit", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "accuracy", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "backlinksEnabled", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "backlinksLimit", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "youtube", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "period", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "displayName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "periodName", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "planCategoryName", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "apiAccess", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "places", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "agency", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "features", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "features"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "monthlyPrice", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "monthlyPrice"), _class.prototype)), _class));
  _exports.default = Plan;
});