define("nightwatch-web/components/graph/nw-time-series-chart", ["exports", "@glimmer/component", "chartjs-adapter-date-fns", "date-fns/locale", "nightwatch-web/utils/graphs/nw-chart-controllers", "chart.js", "nightwatch-web/utils/graphs/scales"], function (_exports, _component, _chartjsAdapterDateFns, _locale, _nwChartControllers, _chart, _scales) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @statsLoaded}}
    <Graph::NwChart
      ...attributes
      @chartOptions={{this.chartOptions}}
    />
    {{#if @isLoading}}
      <div class="extended-overview-chart--loader__overlay">
        <Loading::SmallHorizontal />
      </div>
    {{/if}}
  {{else}}
    <div class="extended-overview-chart--loader">
      <Loading::SmallHorizontal />
    </div>
  {{/if}}
  
  */
  {
    "id": "m30BsJTd",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[17,2]],[[\"@chartOptions\"],[[30,0,[\"chartOptions\"]]]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,0],[14,0,\"extended-overview-chart--loader__overlay\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[10,0],[14,0,\"extended-overview-chart--loader\"],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@statsLoaded\",\"&attrs\",\"@isLoading\"],false,[\"if\",\"graph/nw-chart\",\"loading/small-horizontal\"]]",
    "moduleName": "nightwatch-web/components/graph/nw-time-series-chart.hbs",
    "isStrictMode": false
  });

  _chart.Chart.register(_chart.BarElement, _chart.LinearScale, _chart.CategoryScale, _chart.TimeScale, _chart.LineElement, _chart.PointElement, _chart.Tooltip, _nwChartControllers.NwLineController, _nwChartControllers.NwBarController);
  /**
    @class NwTimeSeriesChartComponent
  
    Renders series data over time
  
    @argument series {Array[Object]} Array of series data objects to graph
    @argument label {String} Y axis title. Takes precedence over series label
    @argument isLoading {Boolean} Whether the series data is loading
    @argument statsLoaded {Boolean} Whether the series data is loaded
    @argument timeUnit {String} Time range to group data by - day, week or month
   **/


  class NwTimeSeriesChartComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "maxTicks", 15);
    }

    get datasets() {
      return this.args.series.reduce((datasets, {
        label,
        type,
        data,
        scaleId,
        backgroundColor,
        borderColor
      }) => {
        datasets.push({
          label,
          type,
          data,
          yAxisID: scaleId,
          backgroundColor,
          borderColor,
          pointRadius: data.length >= 91 ? 0 : 2.5,
          barThickness: 'flex',
          fill: type !== 'line'
        });
        return datasets;
      }, []);
    }

    scaleLabel(scaleId) {
      var _ref2, _this$args$label, _this$args, _this$args$series, _this$args$series$fin;

      return (_ref2 = (_this$args$label = (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.label) !== null && _this$args$label !== void 0 ? _this$args$label : (_this$args$series = this.args.series) === null || _this$args$series === void 0 ? void 0 : (_this$args$series$fin = _this$args$series.findBy('scaleId', scaleId)) === null || _this$args$series$fin === void 0 ? void 0 : _this$args$series$fin.label) !== null && _ref2 !== void 0 ? _ref2 : '';
    }

    get yScales() {
      return this.args.series.mapBy('scaleId').uniq().reduce((yScales, scaleId) => {
        const scale = _scales.default[scaleId];
        yScales[scaleId] = _objectSpread(_objectSpread({}, scale), {}, {
          // First scale on left, otherwise on right
          position: Object.keys(yScales).length === 0 ? 'left' : 'right',
          title: _objectSpread(_objectSpread({}, scale.title), {}, {
            text: this.scaleLabel(scaleId)
          })
        });
        return yScales;
      }, {});
    }

    get scales() {
      return _objectSpread({
        x: {
          type: 'time',
          stacked: true,
          time: {
            unit: this.args.timeUnit,
            tooltipFormat: 'EEEE, LLL d, yyyy'
          },
          ticks: {
            source: 'data',
            maxRotation: 0,
            autoSkip: false,
            callback: (tick, index, {
              length: ticksLength
            }) => {
              var _Math$ceil;

              if (ticksLength <= this.maxTicks) return tick;
              const interval = (_Math$ceil = Math.ceil(ticksLength / this.maxTicks)) !== null && _Math$ceil !== void 0 ? _Math$ceil : 0;
              return index % interval === 0 ? tick : '';
            }
          },
          adapters: {
            date: {
              locale: _locale.en
            }
          },
          grid: {
            drawOnChartArea: false
          }
        },
        y: {
          display: false
        }
      }, this.yScales);
    }

    get chartOptions() {
      var _this$datasets, _this$datasets$;

      return {
        data: {
          datasets: this.datasets,
          responsive: true,
          maintainAspectRatio: true
        },
        options: {
          tension: 0.45,
          animation: {
            duration: 150
          },
          scales: this.scales,
          interaction: {
            mode: 'index',
            intersect: false
          },
          plugins: {
            tooltip: {
              displayColors: Boolean((_this$datasets = this.datasets) === null || _this$datasets === void 0 ? void 0 : (_this$datasets$ = _this$datasets[0]) === null || _this$datasets$ === void 0 ? void 0 : _this$datasets$.backgroundColor)
            }
          }
        }
      };
    }

  }

  _exports.default = NwTimeSeriesChartComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NwTimeSeriesChartComponent);
});