define("nightwatch-web/serializers/filter-group", ["exports", "@ember-data/serializer/rest", "active-model-adapter"], function (_exports, _rest, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      filters: {
        serialize: 'records',
        deserialize: 'records'
      }
    },

    normalizeArrayResponse(store, primaryType, payload) {
      const newPayload = {
        filter_groups: payload
      };
      return this._super(store, primaryType, newPayload);
    },

    normalizeSingleResponse(store, primaryType, payload, recordId) {
      const newPayload = {
        filter_group: payload
      };
      return this._super(store, primaryType, newPayload, recordId);
    },

    serializeIntoHash(data, type, record, options) {
      const root = Ember.String.underscore(type.modelName);
      return data[root] = this.serialize(record, options);
    }

  });

  _exports.default = _default;
});