define("nightwatch-web/utils/track-unbounce-conversion", ["exports", "nightwatch-web/utils/is-testing", "nightwatch-web/utils/load-external-scripts"], function (_exports, _isTesting, _loadExternalScripts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    @function trackUnbounceConversion
  
    Unbounce runs paid ads and landing pages for us. This function needs to be
    called when a user has "converted" so that Unbounce can track conversions
    for the ads and landing pages.
  
    An Unbounce metrics adapter would be preferred, but the script is inflexible
   */
  const trackUnbounceConversion = () => {
    if (_isTesting.default) return;
    window._ubaq = [];

    window._ubaq.push(['trackGoal', 'convert']);

    const ubScriptSrc = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'd3pkntwtp2ukl5.cloudfront.net/uba.js';
    (0, _loadExternalScripts.default)([ubScriptSrc]);
  };

  var _default = trackUnbounceConversion;
  _exports.default = _default;
});