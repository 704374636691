define("nightwatch-web/controllers/dashboard/url/dynamic-views/new", ["exports", "nightwatch-web/mixins/dynamic-view-handling", "nightwatch-web/mixins/filter-group-handling", "nightwatch-web/mixins/selectable-keywords", "nightwatch-web/mixins/keywords-sort-handling", "date-fns/format"], function (_exports, _dynamicViewHandling, _filterGroupHandling, _selectableKeywords, _keywordsSortHandling, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlDynamicViewsNewController = (_dec = Ember.inject.service('persistence/keyword-list'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class DashboardUrlDynamicViewsNewController extends Ember.Controller.extend(_dynamicViewHandling.default, _filterGroupHandling.default, _selectableKeywords.default, _keywordsSortHandling.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "keywordListPersistence", _descriptor, this);

      _initializerDefineProperty(this, "url", _descriptor2, this);

      _initializerDefineProperty(this, "selectedDateRange", _descriptor3, this);

      _initializerDefineProperty(this, "allowSortObserver", _descriptor4, this);
    }

    get dynamicView() {
      return this.model;
    }

    enteredRoute(dynamicView, url) {
      dynamicView.url = url;
      this.url = url;
      const {
        type,
        start,
        end
      } = this.keywordListPersistence.parseDateRange();
      this.selectedDateRange = {
        type,
        start,
        end
      };
      this.prepareNewFilterGroup(dynamicView);
      this.allowSortObserver = false;
      this.fetchKeywords();
    }

    get statsDateFrom() {
      return (0, _format.default)(this.selectedDateRange.start, 'yyyy-MM-dd');
    }

    get statsDateTo() {
      return (0, _format.default)(this.selectedDateRange.end, 'yyyy-MM-dd');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keywordListPersistence", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedDateRange", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "allowSortObserver", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = DashboardUrlDynamicViewsNewController;
});