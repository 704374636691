define("nightwatch-web/components/serp-opportunity", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MODIFIER = _exports.ICON = _exports.TITLE = _exports.TYPE = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="inline-block serp-opportunity {{this.classModifier}}">
    <NwTooltip>{{this.title}}</NwTooltip>
    {{#if (string-contains this.icon "fa-")}}
      <i class="fa {{this.icon}} serp-opportunity__icon"></i>
    {{else}}
      <NwIcon @icon={{this.icon}} class="serp-opportunity__icon" />
    {{/if}}
  </span>
  
  */
  {
    "id": "jPeplUyB",
    "block": "[[[10,1],[15,0,[29,[\"inline-block serp-opportunity \",[30,0,[\"classModifier\"]]]]],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,[30,0,[\"title\"]]]],[]]]]],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"icon\"]],\"fa-\"],null],[[[1,\"    \"],[10,\"i\"],[15,0,[29,[\"fa \",[30,0,[\"icon\"]],\" serp-opportunity__icon\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],[[24,0,\"serp-opportunity__icon\"]],[[\"@icon\"],[[30,0,[\"icon\"]]]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"nw-tooltip\",\"if\",\"string-contains\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/serp-opportunity.hbs",
    "isStrictMode": false
  });

  const TYPE = {
    FEATURED: 'featured_snippet',
    LOCAL_PACK: 'local_pack',
    IMAGE: 'places_image',
    KNOWLEDGE: 'knowledge_panel'
  };
  _exports.TYPE = TYPE;
  const TITLE = {
    [TYPE.FEATURED]: 'Featured Snippet',
    [TYPE.LOCAL_PACK]: 'Local Pack',
    [TYPE.IMAGE]: 'Image Carousel',
    [TYPE.KNOWLEDGE]: 'Knowledge Panel'
  };
  _exports.TITLE = TITLE;
  const ICON = {
    [TYPE.FEATURED]: 'fa-quote-right',
    [TYPE.LOCAL_PACK]: 'fa-list',
    [TYPE.IMAGE]: 'fa-picture-o',
    [TYPE.KNOWLEDGE]: 'icon-knowledge-panel'
  };
  _exports.ICON = ICON;
  const MODIFIER = {
    RANKING: 'ranking',
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable'
  };
  _exports.MODIFIER = MODIFIER;
  let SerpOpportunityComponent = (_dec = Ember.computed('isAvailable', 'isRanking'), _dec2 = Ember.computed('isAvailable', 'isRanking'), (_class = class SerpOpportunityComponent extends _component.default {
    get isAvailable() {
      return !!this.args.keyword.get(`available_serp_features.${this.args.type}`);
    }

    get isRanking() {
      return !!this.args.keyword.get(`position_${this.args.type}`);
    }

    get icon() {
      return ICON[this.args.type];
    }

    get title() {
      const {
        isAvailable,
        isRanking
      } = this;
      const feature = TITLE[this.args.type];
      const available = isAvailable ? 'available' : 'unavailable';
      let result = `${feature} ${available}`;

      if (isAvailable) {
        result += `, keyword ${isRanking ? 'is' : ' not'} ranking`;
      }

      return result;
    }

    get classModifier() {
      const {
        isAvailable,
        isRanking
      } = this;
      const modifier = isRanking ? MODIFIER.RANKING : isAvailable ? MODIFIER.AVAILABLE : MODIFIER.UNAVAILABLE;
      return `serp-opportunity--${modifier}`;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "title", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "classModifier", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "classModifier"), _class.prototype)), _class));
  _exports.default = SerpOpportunityComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SerpOpportunityComponent);
});