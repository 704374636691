define("nightwatch-web/templates/start/keywords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KgS7l2m3",
    "block": "[[[1,[28,[35,0],[[30,1,[\"friendlyUrl\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Keywords\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"onboarding-title\"],[12],[1,\"\\n  \"],[1,[30,1,[\"friendlyUrl\"]]],[1,\": Keywords\\n  \"],[10,1],[14,0,\"pull-right onboarding-step-count\"],[12],[1,\"2/4\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"onboarding-big-title\"],[12],[1,\"Which keywords would you like to track?\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"onboarding-subtitle small-screen-hide\"],[12],[1,\"Enter keywords and choose the search engine:\"],[13],[1,\"\\n\"],[8,[39,1],null,[[\"@class\",\"@url\",\"@onboarding\",\"@onKeywordsAdd\"],[\"double-offset-top\",[30,1],true,[30,0,[\"onKeywordsAdd\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"edit-keyword-form\"]]",
    "moduleName": "nightwatch-web/templates/start/keywords.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});