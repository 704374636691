define("nightwatch-web/routes/start/competitors", ["exports", "nightwatch-web/mixins/competitor-actions"], function (_exports, _competitorActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StartCompetitorsRoute extends Ember.Route.extend(_competitorActions.default) {
    afterModel(model) {
      this.controllerFor('start').set('model', model);
      this.addCompetitorToUrl(model);
      this.addCompetitorToUrl(model);
      this.addCompetitorToUrl(model);
    }

    deactivate() {
      this.deleteUnsavedCompetitors();
    }

  }

  _exports.default = StartCompetitorsRoute;
});