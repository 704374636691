define("nightwatch-web/serializers/pinned-item", ["exports", "@ember-data/serializer/rest", "nightwatch-web/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      graph: {
        serialize: 'records',
        deserialize: 'records'
      }
    },

    serializeIntoHash(data, type, record, options) {
      const payload = this.serialize(record, options);

      if (payload.item) {
        if (payload.item.type === 'group') {
          payload.url_group_id = payload.item.id;
        } else if (payload.item.type === 'dynamic_view') {
          payload.dynamic_view_id = payload.item.id;
        } else {
          payload.search_keyword_url_id = payload.item.id;
        }
      }

      delete payload.item;
      data['pinned_item'] = payload;
      return data;
    }

  });

  _exports.default = _default;
});