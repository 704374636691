define("nightwatch-web/utils/extract-tld", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = url => {
    if (!url) {
      return null;
    }

    if (url.indexOf('.') === -1) {
      return null;
    }

    let hostname = ''; // Find & remove protocol (schema) and get hostname

    if (url.indexOf('://') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    } // Find & remove port number


    hostname = hostname.split(':')[0]; // Find & remove "?"

    hostname = hostname.split('?')[0];
    return hostname.split('.').reverse()[0]; // tld
  };

  _exports.default = _default;
});