define("nightwatch-web/components/graph/nw-pie-chart", ["exports", "@glimmer/component", "chart.js"], function (_exports, _component, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-pie-chart {{if @isLoading "nw-pie-chart--loading"}}">
    <Graph::NwChart
      class="nw-pie-chart__chart"
      ...attributes
      @chartOptions={{this.chartOptions}}
    />
  </div>
  
  */
  {
    "id": "klzW0YZT",
    "block": "[[[10,0],[15,0,[29,[\"nw-pie-chart \",[52,[30,1],\"nw-pie-chart--loading\"]]]],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"nw-pie-chart__chart\"],[17,2]],[[\"@chartOptions\"],[[30,0,[\"chartOptions\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isLoading\",\"&attrs\"],false,[\"if\",\"graph/nw-chart\"]]",
    "moduleName": "nightwatch-web/components/graph/nw-pie-chart.hbs",
    "isStrictMode": false
  });

  _chart.Chart.register(_chart.ArcElement, _chart.PieController, _chart.Tooltip);
  /**
    @class NwPieChartComponent
  
    Renders a pie chart of series data
  
    @argument series {Array[Number]} Array of data to graph
    @argument isLoading {Boolean} Whether the series data is loading
   */


  class NwPieChartComponent extends _component.default {
    get datasets() {
      const {
        series
      } = this.args;
      return [{
        data: series.mapBy('data'),
        backgroundColor: series.mapBy('backgroundColor')
      }];
    }

    get labels() {
      return this.args.series.mapBy('label');
    }

    get chartOptions() {
      const {
        labels,
        datasets
      } = this;
      return {
        type: 'pie',
        data: {
          responsive: true,
          maintainAspectRatio: false,
          labels,
          datasets
        },
        options: {
          animation: {
            duration: 150
          },
          title: {
            display: false
          },
          legend: {
            display: false
          },
          elements: {
            arc: {
              borderWidth: 0
            }
          }
        }
      };
    }

  }

  _exports.default = NwPieChartComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NwPieChartComponent);
});