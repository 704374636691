define("nightwatch-web/services/mixpanel", ["exports", "nightwatch-web/utils/underscorify-keys"], function (_exports, _underscorifyKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MixpanelService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('session.user.consents.[]'), (_class = class MixpanelService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "metrics", _descriptor4, this);

      _initializerDefineProperty(this, "theme", _descriptor5, this);
    }

    get userConsent() {
      var _this$session, _this$session$user;

      const consents = (_this$session = this.session) === null || _this$session === void 0 ? void 0 : (_this$session$user = _this$session.user) === null || _this$session$user === void 0 ? void 0 : _this$session$user.consents;
      return consents ? consents.includes('mixpanel_tracking') : true;
    }

    afterLogin(userMetrics) {
      var _window$mixpanel, _window$mixpanel2;

      if (!this.userConsent) {
        this.metrics.invoke('willDestroy', 'Mixpanel', {});
        return;
      }

      this.metrics.identify('Mixpanel', _objectSpread(_objectSpread({}, (0, _underscorifyKeys.default)(userMetrics)), {}, {
        distinctId: userMetrics.registrationEmail,
        $email: userMetrics.email,
        $name: userMetrics.name || userMetrics.email,
        $timezone: userMetrics.timezone,
        $app_version: 'nightwatch',
        $created: userMetrics.createdAtFormatted,
        theme: this.theme.current
      }));
      (_window$mixpanel = window.mixpanel) === null || _window$mixpanel === void 0 ? void 0 : _window$mixpanel.people.increment('num_logins');
      (_window$mixpanel2 = window.mixpanel) === null || _window$mixpanel2 === void 0 ? void 0 : _window$mixpanel2.register({
        app_version: 'nightwatch'
      });
      this.updateProps();
    }

    updateProps() {
      if (!this.userConsent) return;
      this.fetch.post('/users/update_mixpanel_props');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "userConsent", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "userConsent"), _class.prototype)), _class));
  _exports.default = MixpanelService;
});