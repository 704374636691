define("nightwatch-web/templates/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7aqGFPl1",
    "block": "[[[1,[28,[35,0],[\"Notifications\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"first\"],[[[[1,\"Notifications\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],[[24,0,\"nw-page-content--single all-notifications\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,[[\"@keywords\",\"@currentPage\"],[[30,0,[\"notifications\"]],[30,0,[\"page\"]]]],null],[1,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"user-notifications-list\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"notificationsByDate\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"user-notifications-list__item user-notifications-list__item--date\"],[12],[1,\"\\n        \"],[1,[28,[35,7],[[28,[37,8],[[30,1,[\"value\"]],\"yyyy-MM-dd\"],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,1,[\"items\"]]],null]],null],null,[[[1,\"        \"],[8,[39,9],[[24,0,\"user-notifications-list__item\"]],[[\"@onNotificationClick\",\"@notification\"],[[30,0,[\"onNotificationClick\"]],[30,2]]],null],[1,\"\\n\"]],[2]],null]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n   \"],[8,[39,4],null,[[\"@keywords\",\"@currentPage\",\"@class\"],[[30,0,[\"notifications\"]],[30,0,[\"page\"]],\"offset-bottom\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"notificationGroup\",\"notification\"],false,[\"page-title\",\"nw-navbar\",\"nw-page-location-banner\",\"nw-page-content\",\"user-notifications/user-notifications-pagination\",\"each\",\"-track-array\",\"relative-date-today\",\"parse-date\",\"user-notifications/user-notification-item\"]]",
    "moduleName": "nightwatch-web/templates/notifications.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});