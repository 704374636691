define("nightwatch-web/templates/settings/subusers/subuser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JtffcQzP",
    "block": "[[[8,[39,0],null,[[\"@subuser\",\"@urlGroups\",\"@onClose\"],[[30,1,[\"subuser\"]],[30,1,[\"urlGroups\"]],[30,0,[\"transitionToUsers\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"settings/subuser-modal-form\"]]",
    "moduleName": "nightwatch-web/templates/settings/subusers/subuser.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});