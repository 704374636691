define("nightwatch-web/components/nw-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AttachTooltip
    @class="nw-tooltip--v2 {{@class}}"
    @animation="fade"
    @showDuration={{100}}
    @placement={{or @placement "top"}}
    ...attributes
  >
    {{yield}}
  </AttachTooltip>
  
  */
  {
    "id": "vqffJpMb",
    "block": "[[[8,[39,0],[[17,1]],[[\"@class\",\"@animation\",\"@showDuration\",\"@placement\"],[[29,[\"nw-tooltip--v2 \",[30,2]]],\"fade\",100,[28,[37,1],[[30,3],\"top\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[18,4,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@class\",\"@placement\",\"&default\"],false,[\"attach-tooltip\",\"or\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/nw-tooltip.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});