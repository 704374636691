define("nightwatch-web/helpers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleize = titleize;
  _exports.default = void 0;

  function titleize(target = '') {
    return target.split('_').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
  }

  var _default = Ember.Helper.helper((params = []) => titleize(params[0]));

  _exports.default = _default;
});