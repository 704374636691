define("nightwatch-web/controllers/dashboard/dynamic-views/new", ["exports", "nightwatch-web/mixins/dynamic-view-handling", "nightwatch-web/mixins/filter-group-handling", "nightwatch-web/mixins/selectable-keywords", "nightwatch-web/mixins/keywords-sort-handling", "lodash-es/isEmpty"], function (_exports, _dynamicViewHandling, _filterGroupHandling, _selectableKeywords, _keywordsSortHandling, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardDynamicViewsNewController extends Ember.Controller.extend(_dynamicViewHandling.default, _filterGroupHandling.default, _keywordsSortHandling.default, _selectableKeywords.default) {
    fetchKeywords(filters = {}) {
      this.set('keywordsLoading', true);
      let params = {
        page: this.page,
        sort: this.sort,
        direction: this.direction,
        limit: this.limit,
        scope: 'account',
        group_id: this.groupId
      };

      if ((0, _isEmpty.default)(filters) && this.get('model.filtersValid')) {
        filters = Ember.assign(filters, {
          filter_groups: JSON.stringify(this.serializeFilters())
        });
      }

      params = Ember.assign(params, filters);
      const keywordsPromise = this.store.query('keyword', params);
      return keywordsPromise.then(keywords => {
        this.set('keywords', keywords);
        this.set('initialKeywordLoadingDone', true);
        this.set('keywordsLoading', false);
      });
    }

  }

  _exports.default = DashboardDynamicViewsNewController;
});