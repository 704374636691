define("nightwatch-web/initializers/nprogress", ["exports", "nprogress"], function (_exports, _nprogress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _nprogress.default.configure({
      minimum: 0.08,
      easing: 'ease',
      positionUsing: '',
      speed: 200,
      trickle: true,
      trickleSpeed: 200,
      showSpinner: false,
      barSelector: '[role="bar"]',
      spinnerSelector: '[role="spinner"]',
      parent: 'body',
      template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
    });
  }

  var _default = {
    name: 'nprogress',
    initialize
  };
  _exports.default = _default;
});