define("nightwatch-web/utils/highchart-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sliceHighlighter = void 0;

  const sliceHighlighter = (instance = {}, selectors = {}) => {
    const series = instance.series || [];
    return (e, highlight) => {
      if (!series.length) return;
      const selector = Object.keys(selectors).find(s => e.currentTarget.classList.contains(s.replace('.', '')));
      const id = selectors[selector];
      const point = (series[0].points || [])[id];
      if (!point) return;
      return highlight ? point.setState('hover') : point.setState();
    };
  };

  _exports.sliceHighlighter = sliceHighlighter;
});