define("nightwatch-web/helpers/is-last", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isLast = void 0;

  const isLast = params => params[0] - params[1] === 1;

  _exports.isLast = isLast;

  var _default = Ember.Helper.helper(isLast);

  _exports.default = _default;
});