define("nightwatch-web/mixins/site-audit/controller", ["exports", "nightwatch-web/utils/site-audit-properties"], function (_exports, _siteAuditProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    fetch: Ember.inject.service(),
    notifications: Ember.inject.service(),
    pages: Ember.computed.alias('model.pages'),
    url: Ember.computed.alias('model.url'),
    queryParams: ['page', 'sort', 'direction', 'params', 'search', 'filters'],
    page: 1,
    limit: 50,
    sort: 'created_at',
    direction: 'desc',
    isLoading: false,
    search: null,
    filters: null,
    availableFilters: _siteAuditProperties.AVAILABLE_FILTERS,
    filtersObj: Ember.computed('filters', function () {
      if (!this.filters) return {};

      try {
        return JSON.parse(decodeURIComponent(this.filters));
      } catch (err) {
        return {};
      }
    }),

    fetchStats() {
      const data = {
        crawling_session_id: this.get('crawlingSession.id'),
        comparison_crawling_session_id: this.get('comparisonCrawlingSession.id'),
        filters: this.filters,
        search: this.search
      };
      return this.fetch.request('/site_audit/stats', {
        data
      });
    },

    loadStats() {
      return this.fetchStats().then(stats => {
        this.set('stats', stats);
      });
    },

    applyFilters(filters) {
      if (filters && filters.filterGroups && filters.filterGroups.length === 1 && filters.filterGroups[0].filters[0].name == null) {
        this.setProperties({
          filters: null,
          page: 1,
          emptyFilters: true
        });
      } else {
        this.setProperties({
          page: 1
        });
        const urlSafeFilters = encodeURI(JSON.stringify(filters));
        this.setProperties({
          filters: urlSafeFilters,
          emptyFilters: false
        });
      }

      this.loadStats();
    },

    setFilterConfig: Ember._action(function (filterConfig) {
      this.set('filterConfig', filterConfig);
    }),
    setFilters: Ember._action(function (filters) {
      this.applyFilters(filters);
    }),
    setLevelFilter: Ember._action(function (levelFilters = []) {
      if (!levelFilters.length) return; // Make a copy of filtersObj to modify with new filters

      const newFiltersObj = Object.assign({
        filterGroups: [{
          filters: []
        }]
      }, this.filtersObj); // Prepare newFiltersObj

      newFiltersObj.filterGroups = newFiltersObj.filterGroups // Remove existing "depth" filters
      .map(fg => {
        const f = (fg.filters || []).filter(f => f.name !== 'depth');
        return f.length ? {
          filters: f
        } : false;
      }) // Remove groups which have now been emptied
      .filter(fg => fg) // Merge with new filters
      .concat(levelFilters.map(([name, operator, value]) => ({
        filters: [{
          name,
          operator,
          value
        }]
      }))); // Apply new filters

      this.applyFilters(newFiltersObj);
    }),
    saveResource: Ember._action(function (siteAuditView) {
      siteAuditView.set('filterConfig', {
        filter_groups: this.get('filtersObj.filterGroups')
      });
      siteAuditView.filtersSaving = true;
      siteAuditView.save().then(siteAuditView => {
        siteAuditView.filtersSaved = true;
        this.notifications.success('Site audit view saved successfully', {
          autoClear: true
        });
        this.router.transitionTo('dashboard.url.site-audit.view.show', siteAuditView.id);
        const urlSiteAuditViews = this.get('url.siteAuditViews').toArray(); // Add new view (if it's not already present) so it shows up in the sidebar

        if (!urlSiteAuditViews.includes(siteAuditView)) {
          urlSiteAuditViews.pushObject(siteAuditView);
          this.set('url.siteAuditViews', urlSiteAuditViews.toArray());
        }

        siteAuditView.loadCount();
      }).catch(() => {
        this.notifications.error('There was an error while saving the site audit view', {
          autoClear: true
        });
      }).finally(() => {
        siteAuditView.filtersSaving = false;
      });
    })
  });

  _exports.default = _default;
});