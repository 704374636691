define("nightwatch-web/helpers/array-contains-date", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContainsDate = arrayContainsDate;
  _exports.default = void 0;

  function arrayContainsDate([array, date]) {
    return array.any(arrayDate => (0, _dateFns.isSameDay)(arrayDate, date));
  }

  var _default = Ember.Helper.helper(arrayContainsDate);

  _exports.default = _default;
});