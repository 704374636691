define("nightwatch-web/components/url-group-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <form class="nw-form" {{on "submit" (prevent-default @saveGroup)}}>
    <div class="nw-form__row nw-form__row--full">
      <div class="nw-form__group">
        <label for="group_name" class="nw-form__label">Name</label>
        <FocusInput @value={{@group.name}} id="group_name" class="nw-form__input" required="required" autocomplete="off" />
      </div>
    </div>
  
    {{#if @group.errors.length}}
      <div class="nw-form__row">
        <div class="nw-form__group">
          {{#each @group.errors as |error|}}
            <div class="nw-form__error-message">
              {{capitalize error.attribute}}
              {{error.message}}
            </div>
          {{/each}}
        </div>
      </div>
    {{/if}}
  
    <div class="nw-form__row">
      <div class="nw-form__group nw-form__group--row">
        {{#unless @group.isNew}}
          <button type="button" disabled={{@group.isSaving}} {{on "click" @deleteGroup}} class="nw-button offset-right">Delete</button>
        {{/unless}}
        <button type="submit" disabled={{@group.isSaving}} class="nw-button nw-button--submit">Save</button>
      </div>
    </div>
  </form>
  
  */
  {
    "id": "Npq+dpCP",
    "block": "[[[11,\"form\"],[24,0,\"nw-form\"],[4,[38,0],[\"submit\",[28,[37,1],[[30,1]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"nw-form__row nw-form__row--full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"nw-form__group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"group_name\"],[14,0,\"nw-form__label\"],[12],[1,\"Name\"],[13],[1,\"\\n      \"],[8,[39,2],[[24,1,\"group_name\"],[24,0,\"nw-form__input\"],[24,\"required\",\"required\"],[24,\"autocomplete\",\"off\"]],[[\"@value\"],[[30,2,[\"name\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,2,[\"errors\",\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"nw-form__row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-form__group\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,2,[\"errors\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"nw-form__error-message\"],[12],[1,\"\\n            \"],[1,[28,[35,6],[[30,3,[\"attribute\"]]],null]],[1,\"\\n            \"],[1,[30,3,[\"message\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"nw-form__row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"nw-form__group nw-form__group--row\"],[12],[1,\"\\n\"],[41,[51,[30,2,[\"isNew\"]]],[[[1,\"        \"],[11,\"button\"],[16,\"disabled\",[30,2,[\"isSaving\"]]],[24,0,\"nw-button offset-right\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,4]],null],[12],[1,\"Delete\"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,\"button\"],[15,\"disabled\",[30,2,[\"isSaving\"]]],[14,0,\"nw-button nw-button--submit\"],[14,4,\"submit\"],[12],[1,\"Save\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@saveGroup\",\"@group\",\"error\",\"@deleteGroup\"],false,[\"on\",\"prevent-default\",\"focus-input\",\"if\",\"each\",\"-track-array\",\"capitalize\",\"unless\"]]",
    "moduleName": "nightwatch-web/components/url-group-form.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});