define("nightwatch-web/components/social-sharing-popup", ["exports", "@glimmer/component", "nightwatch-web/config/environment", "ember-concurrency"], function (_exports, _component, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="social-sharing-popup" ...attributes>
    {{#if @screenshotingTask.isRunning}}
      <div data-test-loading-indicator class="social-sharing-popup__title text-center" style="padding-top: 12px">
        Preparing ...
      </div>
  
       <Loading::Spinner class="social-sharing-popup__title" />
    {{else}}
      <div class="social-sharing-popup__title">
        <span>Share the progress using a private link:</span>
      </div>
      <div class="social-sharing-popup__link clearfix">
        <label for="sharing-page-link" hidden>Sharing link:</label>
        <input id="sharing-page-link" data-test-sharing-page-link required value={{this.link}} class="pull-left" {{on "click" this.selectInput}}>
        {{#if (clipboard-supported)}}
          <span class="pull-right">
            <i class="fa fa-clipboard icon-copy" {{copy-on-click this.link onSuccess=(perform this.copySuccess) onError=this.copyError}}>
              <AttachTooltip @class="nw-tooltip--v2">
                {{if this.hasCopied "Copied!" "Copy"}}
              </AttachTooltip>
            </i>
          </span>
        {{/if}}
      </div>
      <div class="social-sharing-popup__sn-buttons">
        <NwSharePanel @url={{this.link}} @shareText={{this.shareText}} />
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "XHD0GEv+",
    "block": "[[[11,0],[24,0,\"social-sharing-popup\"],[17,1],[12],[1,\"\\n\"],[41,[30,2,[\"isRunning\"]],[[[1,\"    \"],[10,0],[14,0,\"social-sharing-popup__title text-center\"],[14,5,\"padding-top: 12px\"],[12],[1,\"\\n      Preparing ...\\n    \"],[13],[1,\"\\n\\n     \"],[8,[39,1],[[24,0,\"social-sharing-popup__title\"]],null,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"social-sharing-popup__title\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"Share the progress using a private link:\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"social-sharing-popup__link clearfix\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"sharing-page-link\"],[14,\"hidden\",\"\"],[12],[1,\"Sharing link:\"],[13],[1,\"\\n      \"],[11,\"input\"],[24,1,\"sharing-page-link\"],[24,\"required\",\"\"],[16,2,[30,0,[\"link\"]]],[24,0,\"pull-left\"],[4,[38,2],[\"click\",[30,0,[\"selectInput\"]]],null],[12],[13],[1,\"\\n\"],[41,[28,[37,3],null,null],[[[1,\"        \"],[10,1],[14,0,\"pull-right\"],[12],[1,\"\\n          \"],[11,\"i\"],[24,0,\"fa fa-clipboard icon-copy\"],[4,[38,4],[[30,0,[\"link\"]]],[[\"onSuccess\",\"onError\"],[[28,[37,5],[[30,0,[\"copySuccess\"]]],null],[30,0,[\"copyError\"]]]]],[12],[1,\"\\n            \"],[8,[39,6],null,[[\"@class\"],[\"nw-tooltip--v2\"]],[[\"default\"],[[[[1,\"\\n              \"],[1,[52,[30,0,[\"hasCopied\"]],\"Copied!\",\"Copy\"]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"social-sharing-popup__sn-buttons\"],[12],[1,\"\\n      \"],[8,[39,7],null,[[\"@url\",\"@shareText\"],[[30,0,[\"link\"]],[30,0,[\"shareText\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@screenshotingTask\"],false,[\"if\",\"loading/spinner\",\"on\",\"clipboard-supported\",\"copy-on-click\",\"perform\",\"attach-tooltip\",\"nw-share-panel\"]]",
    "moduleName": "nightwatch-web/components/social-sharing-popup.hbs",
    "isStrictMode": false
  });

  let SocialSharingPopupComponent = (_dec = Ember._tracked, _dec2 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class SocialSharingPopupComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "hasCopied", _descriptor, this);
    }

    get link() {
      if (!this.args.page) return '';
      return `${_environment.default.sharingPagesUrl}/${this.args.page.token}`;
    }

    get shareText() {
      if (!this.args.page) return '';
      return `SEO Progress for ${this.args.page.title}`;
    }

    *copySuccess() {
      this.hasCopied = true;
      yield (0, _emberConcurrency.rawTimeout)(900);
      this.hasCopied = false;
    }

    copyError() {
      this.notifications.error('Something went wrong while copying the link. Please contact support', {
        autoClear: true
      });
    }

    selectInput(event) {
      event.target.select();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hasCopied", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "copySuccess", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "copySuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyError", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "copyError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectInput"), _class.prototype)), _class));
  _exports.default = SocialSharingPopupComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SocialSharingPopupComponent);
});