define("nightwatch-web/services/session", ["exports", "nightwatch-web/utils/is-testing", "js-md5", "ember-concurrency", "ember-fetch/errors"], function (_exports, _isTesting, _jsMd, _emberConcurrency, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const AFTER_LOGIN_URL = '/overview';
  const AFTER_LOGOUT_URL = '/';
  const TOKEN_KEY = 'nw:session:token';
  const FROM_ADMIN_KEY = 'nw:session:from-admin';
  const TRACK_REGISTERED_KEY = 'nw:session:track-registered';
  const REQUIRE_AUTHENTICATION_ROUTE = 'login';
  const PROHIBIT_AUTHENTICATION_ROUTE = 'dashboard.overview';
  let SessionService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec10 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec11 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class SessionService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "siteData", _descriptor2, this);

      _initializerDefineProperty(this, "metrics", _descriptor3, this);

      _initializerDefineProperty(this, "intercom", _descriptor4, this);

      _initializerDefineProperty(this, "fetch", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "theme", _descriptor7, this);

      _initializerDefineProperty(this, "user", _descriptor8, this);
    }

    get isAuthenticated() {
      return Ember.isPresent(this.token);
    }

    get token() {
      return localStorage.getItem(TOKEN_KEY);
    }

    set token(value) {
      value !== null ? localStorage.setItem(TOKEN_KEY, value) : localStorage.removeItem(TOKEN_KEY);
    }

    get isAdminViewingUser() {
      return JSON.parse(localStorage.getItem(FROM_ADMIN_KEY));
    }

    set isAdminViewingUser(value) {
      // Disable all metrics for token authenticated sessions
      this.metrics.enabled = value !== true;
      value !== null ? localStorage.setItem(FROM_ADMIN_KEY, JSON.stringify(value)) : localStorage.removeItem(FROM_ADMIN_KEY);
    }

    get trackRegistered() {
      return JSON.parse(localStorage.getItem(TRACK_REGISTERED_KEY));
    }

    set trackRegistered(value) {
      value !== null ? localStorage.setItem(TRACK_REGISTERED_KEY, JSON.stringify(value)) : localStorage.removeItem(TRACK_REGISTERED_KEY);
    }

    *trackLogin() {
      // It's important that the "Registered" event is provided to Intercom which is only booted after login
      if (this.trackRegistered) {
        this.metrics.trackEvent({
          event: 'Registered'
        });
        this.trackRegistered = null;
        return;
      }

      yield (0, _emberConcurrency.rawTimeout)(1000);
      this.metrics.trackEvent({
        event: 'Logged In'
      });
    }

    *login({
      email,
      password,
      skipRedirect
    }) {
      const data = {
        email,
        password,
        beta_signup_token: (0, _jsMd.default)(decodeURIComponent(email)).slice(-10)
      };
      let response;

      try {
        response = yield this.fetch.post('/token', {
          data
        });
      } catch (error) {
        return this.handleLoginError(error);
      }

      this.token = response.access_token;
      this.isAdminViewingUser = null;
      if (_isTesting.default || skipRedirect) return;
      window.location = AFTER_LOGIN_URL;
    }

    handleLoginError(error) {
      if ((0, _errors.isServerErrorResponse)(error) && error.status === 503) {
        this.router.transitionTo('maintenance');
        return;
      }

      if ((0, _errors.isUnauthorizedResponse)(error)) {
        var _error$payload, _explanation, _explanation2;

        let explanation = error === null || error === void 0 ? void 0 : (_error$payload = error.payload) === null || _error$payload === void 0 ? void 0 : _error$payload.explanation;
        if ((_explanation = explanation) !== null && _explanation !== void 0 && _explanation.match(/Your plan does not support API access/)) explanation = 'Login not allowed. Please contact support.';
        throw (_explanation2 = explanation) !== null && _explanation2 !== void 0 ? _explanation2 : 'Incorrect e-mail or password.';
      }

      throw error;
    }

    logout(redirectToLogin = true) {
      this.metrics.trackEvent({
        event: 'Logged Out'
      });
      this.intercom.shutdown();
      this.invalidate();
      this.user = null;

      if (redirectToLogin) {
        window.location = AFTER_LOGOUT_URL;
      }
    }

    *loadUser() {
      if (this.user) return this.user;
      const users = yield this.store.query('user', {
        without_stats: true
      });
      this.user = users.firstObject;
      this.siteData.afterLoadUser(this.user);
      this.theme.afterLoadUser(this.user);
      return this.user;
    }

    ingestQueryParamToken(token) {
      if (token !== this.token) {
        this.intercom.shutdown();
      }

      this.token = token; // Persist - in case an admin visits the site with some elses token
      // without the t parameter

      this.isAdminViewingUser = true;
    }

    invalidate() {
      this.token = null;
      this.isAdminViewingUser = null;
    }

    requireActive() {
      var _this$user;

      if ((_this$user = this.user) !== null && _this$user !== void 0 && _this$user.isInactive) this.router.transitionTo('plans');
    }

    requireAuthentication(route) {
      if (this.isAuthenticated) return;
      this.router.transitionTo(route || REQUIRE_AUTHENTICATION_ROUTE);
    }

    prohibitAuthentication(route) {
      if (!this.isAuthenticated) return;
      this.router.transitionTo(route || PROHIBIT_AUTHENTICATION_ROUTE);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intercom", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "trackLogin", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "trackLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "login", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadUser", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loadUser"), _class.prototype)), _class));
  _exports.default = SessionService;
});