define("nightwatch-web/components/modal-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-modal__header">
    <div class="nw-modal__header-content">{{yield}}</div>
    <button class="nw-button nw-button--plain nw-modal__close" {{on "click" @onClose}} type="button">
      <NwIcon class="nw-modal__close-icon" @icon="svg-times" />
    </button>
  </div>
  
  */
  {
    "id": "cA32xjHE",
    "block": "[[[10,0],[14,0,\"nw-modal__header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nw-modal__header-content\"],[12],[18,2,null],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"nw-button nw-button--plain nw-modal__close\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"nw-modal__close-icon\"]],[[\"@icon\"],[\"svg-times\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@onClose\",\"&default\"],false,[\"yield\",\"on\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/modal-header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});