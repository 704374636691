define("nightwatch-web/services/graphs/graph-resource-loading-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GraphResourceLoadingUtilsService = (_dec = Ember.inject.service('graphs/graph-loading-utils'), (_class = class GraphResourceLoadingUtilsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "graphLoadingUtils", _descriptor, this);
    }

    loadResource(resourceType, currentSeriesContainer, urlId) {
      if (resourceType == 'keywords') {
        if (currentSeriesContainer.get('keywordsLoaded')) return;
        currentSeriesContainer.set('keywordsLoading', true); // Keywords need competitors loaded

        this.graphLoadingUtils.loadCompetitorsToSeriesContainer(currentSeriesContainer, urlId);
        this.graphLoadingUtils.loadKeywordsToSeriesContainerInitially(currentSeriesContainer, urlId).then(() => {
          currentSeriesContainer.set('keywordsLoaded', true);
        }).finally(() => {
          currentSeriesContainer.set('keywordsLoading', false);
        });
      } else if (resourceType == 'competitors') {
        if (currentSeriesContainer.get('competitorsLoaded')) return;
        currentSeriesContainer.set('competitorsLoading', true);
        this.graphLoadingUtils.loadCompetitorsToSeriesContainer(currentSeriesContainer, urlId).finally(() => {
          currentSeriesContainer.set('competitorsLoading', false);
        });
      } else if (resourceType == 'dynamicViews') {
        if (currentSeriesContainer.get('dynamicViewsLoaded')) return;
        currentSeriesContainer.set('dynamicViewsLoading', true); // Views need competitors loaded

        this.graphLoadingUtils.loadCompetitorsToSeriesContainer(currentSeriesContainer, urlId);
        this.graphLoadingUtils.loadDynamicViewsToSeriesContainer(currentSeriesContainer, urlId).finally(() => {
          currentSeriesContainer.set('dynamicViewsLoading', false);
        });
      } else if (resourceType == 'backlinkViews') {
        if (currentSeriesContainer.get('backlinkViewsLoaded')) return;
        currentSeriesContainer.set('backlinkViewsLoading', true);
        this.graphLoadingUtils.loadBacklinkViewsToSeriesContainer(currentSeriesContainer, urlId).finally(() => {
          currentSeriesContainer.set('backlinkViewsLoading', false);
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "graphLoadingUtils", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GraphResourceLoadingUtilsService;
});