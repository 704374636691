define("nightwatch-web/components/sort-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (if (eq @direction 'desc') 'down' 'up') as |icon|}}
    <NwIcon @icon="icon-chevron-{{icon}}" ...attributes />
  {{/let}}
  
  */
  {
    "id": "fZtCoX1R",
    "block": "[[[44,[[52,[28,[37,2],[[30,1],\"desc\"],null],\"down\",\"up\"]],[[[1,\"  \"],[8,[39,3],[[17,3]],[[\"@icon\"],[[29,[\"icon-chevron-\",[30,2]]]]],null],[1,\"\\n\"]],[2]]]],[\"@direction\",\"icon\",\"&attrs\"],false,[\"let\",\"if\",\"eq\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/sort-direction.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});