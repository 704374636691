define("nightwatch-web/controllers/dashboard/url/backlink-view", ["exports", "nightwatch-web/mixins/backlink-view-controller"], function (_exports, _backlinkViewController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlBacklinkViewController extends Ember.Controller.extend(_backlinkViewController.default) {}

  _exports.default = DashboardUrlBacklinkViewController;
});