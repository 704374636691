define("nightwatch-web/utils/humanize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = str => {
    return str.replace(/_id$/, '').replace(/_/g, ' ').replace(/-/g, ' ').replace(/^\w/g, function (s) {
      return s.toUpperCase();
    });
  };

  _exports.default = _default;
});