define("nightwatch-web/routes/dashboard/url/site-audit/all/checks/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DashboardUrlSiteAuditAllChecksShowRoute = (_dec = Ember._action, (_class = class DashboardUrlSiteAuditAllChecksShowRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        direction: {
          refreshModel: true
        },
        search: {
          refreshModel: true
        },
        filters: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        }
      });
    }

    didCheckChange(checkId) {
      return this.currentCheckId != checkId;
    }

    shouldReloadStats(model) {
      if (this.forceReloadStats) {
        this.set('forceReloadStats', false);
        return true;
      } // if is refreshing or if crawling session did change


      const crawlingSessionId = Ember.get(model, 'crawlingSession.id');
      const crawlingSessionStatus = Ember.get(model, 'crawlingSession.status');
      const comparisonCrawlingSessionId = Ember.get(model, 'comparisonCrawlingSession.id');
      const {
        currentCrawlingSessionId,
        currentCrawlingSessionStatus,
        currentComparisonCrawlingSessionId
      } = this;
      const isProcessing = Ember.get(model, 'crawlingSession.isPendingOrProcessing');
      if (isProcessing) return true;
      const shouldReload = crawlingSessionId != currentCrawlingSessionId || comparisonCrawlingSessionId != currentComparisonCrawlingSessionId || crawlingSessionStatus != currentCrawlingSessionStatus;
      return shouldReload;
    }

    activate() {
      window.scrollTo(0, 0);
    }

    model(params) {
      const parentModel = this.modelFor('dashboard.url.site-audit.all.checks');
      const {
        url,
        crawlingSession,
        comparisonCrawlingSession
      } = parentModel;
      const {
        comparisonEnabled
      } = this.modelFor('dashboard.url.site-audit');
      let {
        checks
      } = parentModel;
      let check = checks.findBy('id', params.audit_check_id);

      if (!check) {
        // This happens when we save a new check and transition to site-audit.all.checks.show.
        // The parent route doesn't have updated checks.
        check = this.store.peekRecord('site-audit/audit-check', params.audit_check_id);
        checks = this.store.query('site-audit/audit-check', {
          search_keyword_url_id: url.id
        }).then(checks => {
          checks.forEach(check => check.set('url', url));
          return checks;
        });
        this.set('forceReloadStats', true);
      }

      const encodedCheckFilters = encodeURI(JSON.stringify(check.get('filterConfig')));
      let filters;

      if (this.didCheckChange(check.id)) {
        // When new check is selected, always take filters from the check
        filters = encodedCheckFilters;
      } else if (params.filters) {
        filters = params.filters;
      }

      const pagesPromise = this.store.query('site-audit/page', {
        crawling_session_id: crawlingSession.id,
        search_keyword_url_id: url.id,
        limit: params.limit || 50,
        sort: params.sort || 'created_at',
        direction: params.direction || 'desc',
        page: params.page || 1,
        search: params.search,
        filters: filters
      });
      return Ember.RSVP.hash({
        url,
        crawlingSession,
        comparisonCrawlingSession,
        checks,
        check,
        comparisonEnabled,
        pages: pagesPromise
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model); // Set initial filters from saved view

      if (!controller.filters) {
        controller.set('filters', encodeURIComponent(JSON.stringify(model.check.get('filterConfigWithUnderscore'))));
      }

      if (this.shouldReloadStats(model)) {
        controller.loadStats();
      }

      if (this.didCheckChange(Ember.get(model, 'check.id'))) {
        controller.set('newCheck', null);
        controller.set('filters', encodeURIComponent(JSON.stringify(model.check.get('filterConfigWithUnderscore'))));
      }

      controller.setProperties({
        comparisonEnabled: model.comparisonEnabled
      });
      this.setProperties({
        currentCheckId: Ember.get(model, 'check.id'),
        currentCrawlingSessionId: Ember.get(model, 'crawlingSession.id'),
        currentCrawlingSessionStatus: Ember.get(model, 'crawlingSession.status'),
        currentComparisonCrawlingSessionId: Ember.get(model, 'comparisonCrawlingSession.id')
      });
    }

    async loading(transition) {
      const controller = this.controllerFor('dashboard.url.site-audit.all.checks.show');
      controller.set('isLoading', true);

      try {
        await transition.promise;
      } finally {
        controller.set('isLoading', false);
      }

      return true;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = DashboardUrlSiteAuditAllChecksShowRoute;
});