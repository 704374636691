define("nightwatch-web/components/contextual-panel/serp-preview/live/local-pack-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.result.title}}
  
  */
  {
    "id": "QmZpvkQu",
    "block": "[[[1,[30,0,[\"result\",\"title\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "nightwatch-web/components/contextual-panel/serp-preview/live/local-pack-result.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['nw-serps__local-pack-result'],
    classNameBindings: ['result.isMatching:nw-serps__result--highlighted'],
    result: null
  }));

  _exports.default = _default;
});