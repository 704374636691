define("nightwatch-web/routes/dashboard/url/site-audit/view/checks/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlSiteAuditViewChecksIndexRoute extends Ember.Route {
    beforeModel(transition) {
      // This situation happens when you are in the
      // Audit Checks route and you click on the Audit Checks again.
      // At that time the screen goes blank because it wants to go to the index template,
      // but the index route is not defined. This is a fix to prevent it.
      transition.abort();
    }

  }

  _exports.default = DashboardUrlSiteAuditViewChecksIndexRoute;
});