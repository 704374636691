define("nightwatch-web/utils/shift-to-utc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    @function shiftToUTC
  
    Takes an existing *local date* and returns a UTC-shifted date.
  
    Caution – returned date will still report that it is in the user's timezone.
    This is because js Date objects do not store TZ info.
  
    @param {Date} localDate Local date to shift from
   */
  const shiftToUTC = localDate => {
    return new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate());
  };

  var _default = shiftToUTC;
  _exports.default = _default;
});