define("nightwatch-web/helpers/format-amount-whole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAmountWhole = formatAmountWhole;
  _exports.default = void 0;

  function formatAmountWhole([number]) {
    const parsed = Number.parseFloat(number);

    if (isNaN(parsed)) {
      return '';
    }

    return parsed.toLocaleString('en-US').split('.')[0];
  }

  var _default = Ember.Helper.helper(formatAmountWhole);

  _exports.default = _default;
});