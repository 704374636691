define("nightwatch-web/routes/dashboard/url/backlink-view-new", ["exports", "nightwatch-web/mixins/backlink-view-route"], function (_exports, _backlinkViewRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlBacklinkViewNewRoute extends Ember.Route.extend(_backlinkViewRoute.default) {}

  _exports.default = DashboardUrlBacklinkViewNewRoute;
});