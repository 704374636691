define("nightwatch-web/components/site-audit/status-chart-container", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="distribution-container">
    <div class="distribution-content">
      <Graph::NwPieChart @series={{this.chartData}} />
    </div>
    <div class="distribution-legend">
      {{#each this.chartData as |series|}}
        <OverviewLegendItem
          data-test-legend-item
          @data={{series}}
          @comparisonEnabled={{this.comparisonEnabled}}
        />
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "DshEhp6L",
    "block": "[[[10,0],[14,0,\"distribution-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"distribution-content\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@series\"],[[30,0,[\"chartData\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"distribution-legend\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"chartData\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@data\",\"@comparisonEnabled\"],[[30,1],[30,0,[\"comparisonEnabled\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"series\"],false,[\"graph/nw-pie-chart\",\"each\",\"-track-array\",\"overview-legend-item\"]]",
    "moduleName": "nightwatch-web/components/site-audit/status-chart-container.hbs",
    "isStrictMode": false
  });

  class PageDepthChartContainer extends _component.default {
    get chartData() {
      var _this$args$stats, _this$args$stats2, _this$args$stats3, _this$args$stats4, _this$args$stats5, _this$args$stats6;

      return [{
        label: 'OK',
        backgroundColor: '#27b14e',
        data: (_this$args$stats = this.args.stats) === null || _this$args$stats === void 0 ? void 0 : _this$args$stats.ok_pages_count,
        change: (_this$args$stats2 = this.args.stats) === null || _this$args$stats2 === void 0 ? void 0 : _this$args$stats2.ok_pages_count_change
      }, {
        label: 'REDIRECT',
        backgroundColor: '#ceca3f',
        data: (_this$args$stats3 = this.args.stats) === null || _this$args$stats3 === void 0 ? void 0 : _this$args$stats3.redirection_pages_count,
        change: (_this$args$stats4 = this.args.stats) === null || _this$args$stats4 === void 0 ? void 0 : _this$args$stats4.redirection_pages_count_change
      }, {
        label: 'ERROR',
        backgroundColor: '#d8232e',
        data: (_this$args$stats5 = this.args.stats) === null || _this$args$stats5 === void 0 ? void 0 : _this$args$stats5.error_pages_count,
        change: (_this$args$stats6 = this.args.stats) === null || _this$args$stats6 === void 0 ? void 0 : _this$args$stats6.error_pages_count_change
      }];
    }

  }

  _exports.default = PageDepthChartContainer;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PageDepthChartContainer);
});