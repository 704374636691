define("nightwatch-web/components/up-down-stagnant-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (not @value)}}
    <NwIcon @icon="icon-stagnant" />
  {{else if (gt @value 0)}}
    <NwIcon @icon="icon-up-arrow" />
  {{else}}
    <NwIcon @icon="icon-down-arrow" />
  {{/if}}
  
  */
  {
    "id": "YVf+Q23L",
    "block": "[[[41,[28,[37,1],[[30,1]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@icon\"],[\"icon-stagnant\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,1],0],null],[[[1,\"  \"],[8,[39,2],null,[[\"@icon\"],[\"icon-up-arrow\"]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@icon\"],[\"icon-down-arrow\"]],null],[1,\"\\n\"]],[]]]],[]]]],[\"@value\"],false,[\"if\",\"not\",\"nw-icon\",\"gt\"]]",
    "moduleName": "nightwatch-web/components/up-down-stagnant-icon.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});