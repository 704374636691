define("nightwatch-web/routes/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InvoicesRoute extends Ember.Route {
    model() {
      return this.store.findAll('invoice');
    }

  }

  _exports.default = InvoicesRoute;
});