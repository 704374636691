define("nightwatch-web/templates/dashboard/url/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mluAhyRh",
    "block": "[[[1,[28,[35,0],[\"Notes\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"main-area-navbar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main-area-container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,1,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"url-favicon-container\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@src\"],[[30,1,[\"url\",\"faviconUrl\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,1,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,1,[\"url\",\"url\"]]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,4],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\" Notes\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,5],[[24,0,\"nw-page-content--single\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,6],null,[[\"@notes\",\"@url\"],[[30,1,[\"url\",\"notes\"]],[30,1,[\"url\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"link-to\",\"favicon-image\",\"friendly-url\",\"nw-icon\",\"nw-page-content\",\"notes/note-list\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/notes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});