define("nightwatch-web/services/power-calendar", ["exports", "ember-power-calendar/services/power-calendar"], function (_exports, _powerCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /*
    Overriding power-calendar service to explicitly set locale globally
    as per https://ember-power-calendar.com/docs/i18n
   */
  class PowerCalendarService extends _powerCalendar.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "locale", 'en');
    }

  }

  _exports.default = PowerCalendarService;
});