define("nightwatch-web/templates/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vLEfxDHp",
    "block": "[[[1,[28,[35,0],[\"Password Reset\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"nw-password-reset nw-split\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nw-split__page\"],[12],[1,\"\\n\"],[41,[30,0,[\"resetToken\"]],[[[1,\"      \"],[8,[39,2],[[24,0,\"nw-bright-form bright\"]],[[\"@resetToken\"],[[30,0,[\"resetToken\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,3],[[24,0,\"nw-bright-form bright\"]],null,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"nw-split__page-sidebar\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"nw-flexible-eye\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"if\",\"change-password-form\",\"reset-password-form\"]]",
    "moduleName": "nightwatch-web/templates/password-reset.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});