define("nightwatch-web/helpers/competitor-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.competitorDisplay = void 0;

  const competitorDisplay = params => {
    const column = params[0];
    const competitors = params[1];
    const competitorId = column.split('-').reverse()[0];
    const competitor = competitors.findBy('id', competitorId);

    if (competitor) {
      return 'Competitor: ' + competitor.get('displayName');
    } else {
      return 'Competitor deleted. Please switch column.';
    }
  };

  _exports.competitorDisplay = competitorDisplay;

  var _default = Ember.Helper.helper(competitorDisplay);

  _exports.default = _default;
});