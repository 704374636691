define("nightwatch-web/components/invoices/invoices-list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-table__col nw-table__col--invoice-date">{{format-date this.invoice.invoiceDate "MMM dd, yyyy"}}</div>
  <div class="nw-table__col nw-table__col--invoice-type">{{this.invoice.invoiceType}}</div>
  <div class="nw-table__col nw-table__col--invoice-amount">{{this.invoice.currency}} {{format-amount this.invoice.amount}}</div>
  <div class="nw-table__col">
    <a href={{this.invoice.url}} class="rt-link offset-right" target="_blank" rel="noopener noreferrer">Download</a>
    <button class="btn btn-secondary-1 btn-tiny {{unless this.canRegenerate 'dimmed'}} {{if this.isRegenerating 'disabled'}}" {{on "click" (fn this.regenerate this.invoice)}} type="button">
      <NwTooltip>{{this.regenerateButtonTooltip}}</NwTooltip>
      {{if this.isRegenerating 'Regenerating…' 'Regenerate'}}
    </button>
  
    {{#if this.isRegenerated}}
      <span class="button-offset green"><i class="fa fa-check"></i>Regenerated</span>
    {{/if}}
  </div>
  
  */
  {
    "id": "7a/EUVAm",
    "block": "[[[10,0],[14,0,\"nw-table__col nw-table__col--invoice-date\"],[12],[1,[28,[35,0],[[30,0,[\"invoice\",\"invoiceDate\"]],\"MMM dd, yyyy\"],null]],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-table__col nw-table__col--invoice-type\"],[12],[1,[30,0,[\"invoice\",\"invoiceType\"]]],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-table__col nw-table__col--invoice-amount\"],[12],[1,[30,0,[\"invoice\",\"currency\"]]],[1,\" \"],[1,[28,[35,1],[[30,0,[\"invoice\",\"amount\"]]],null]],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-table__col\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"invoice\",\"url\"]]],[14,0,\"rt-link offset-right\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"Download\"],[13],[1,\"\\n  \"],[11,\"button\"],[16,0,[29,[\"btn btn-secondary-1 btn-tiny \",[52,[51,[30,0,[\"canRegenerate\"]]],\"dimmed\"],\" \",[52,[30,0,[\"isRegenerating\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"regenerate\"]],[30,0,[\"invoice\"]]],null]],null],[12],[1,\"\\n    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,[30,0,[\"regenerateButtonTooltip\"]]]],[]]]]],[1,\"\\n    \"],[1,[52,[30,0,[\"isRegenerating\"]],\"Regenerating…\",\"Regenerate\"]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isRegenerated\"]],[[[1,\"    \"],[10,1],[14,0,\"button-offset green\"],[12],[10,\"i\"],[14,0,\"fa fa-check\"],[12],[13],[1,\"Regenerated\"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"format-date\",\"format-amount\",\"unless\",\"if\",\"on\",\"fn\",\"nw-tooltip\"]]",
    "moduleName": "nightwatch-web/components/invoices/invoices-list-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['nw-table__row'],
    invoiceActions: Ember.inject.service(),
    daysLimit: 10,
    isRegenerating: false,
    canRegenerate: Ember.computed('invoice.invoiceDate', function () {
      const limitDaysAgo = new Date().getTime() - this.daysLimit * 24 * 60 * 60 * 1000;
      const invoiceDateTimestamp = new Date(this.get('invoice.invoiceDate')).getTime();
      return invoiceDateTimestamp > limitDaysAgo;
    }),
    regenerateButtonTooltip: Ember.computed('canRegenerate', function () {
      const canRegenerate = this.canRegenerate;

      if (canRegenerate) {
        return 'Override invoice with your current billing information.';
      }

      return 'Can not regenerate invoices older than 10 days.';
    }),
    regenerate: Ember._action(function (invoice) {
      if (!this.canRegenerate) return;
      this.set('isRegenerating', true);
      const regeneratePromise = this.invoiceActions.regenerate(invoice);
      regeneratePromise.then(() => {
        this.set('isRegenerated', true);
      });
      regeneratePromise.finally(() => {
        this.set('isRegenerating', false);
      });
    })
  }));

  _exports.default = _default;
});