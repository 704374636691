define("nightwatch-web/components/site-audit/page-details-images", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
    <div
      class="site-audit-details__section-title uppercase double-offset-top"
      data-test-images-title>
      Images ({{this.totalImgCount}})
    </div>
  </div>
  
  {{#if this.isDataLoading}}
    <Loading::Spinner />
  {{else}}
    <SiteAudit::Health @maxPoints={{this.totalImgCount}} @points={{this.restImagesCount}} />
  
    <div data-test-images-table class="site-audit-details-table">
      {{#if this.images.length}}
        <div class="site-audit-details-header">
          <div class="column-num">#</div>
          <div class="column-src">src</div>
          <div class="column-alt">alt</div>
        </div>
        <div class="site-audit-details-content">
          {{#each this.images as |pageImage index|}}
            <div class="site-audit-details-row" data-test-image-row>
              <div class="column-num">
                {{add-number index 1}}
              </div>
              <div class="column-src">
                <div class="truncate-left text-nowrap">
                  &lrm;{{pageImage.path}}&lrm;
                </div>
              </div>
              <div class="column-alt">
                <SiteAudit::BooleanIcon @value={{pageImage.alt}} />
              </div>
            </div>
          {{/each}}
        </div>
      {{else}}
        There are no images with missing alt attributes.
      {{/if}}
    </div>
  {{/if}}
  
  */
  {
    "id": "AEmhbHKh",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,0],[14,0,\"site-audit-details__section-title uppercase double-offset-top\"],[12],[1,\"\\n    Images (\"],[1,[30,0,[\"totalImgCount\"]]],[1,\")\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isDataLoading\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@maxPoints\",\"@points\"],[[30,0,[\"totalImgCount\"]],[30,0,[\"restImagesCount\"]]]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"site-audit-details-table\"],[12],[1,\"\\n\"],[41,[30,0,[\"images\",\"length\"]],[[[1,\"      \"],[10,0],[14,0,\"site-audit-details-header\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"column-num\"],[12],[1,\"#\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"column-src\"],[12],[1,\"src\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"column-alt\"],[12],[1,\"alt\"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"site-audit-details-content\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"images\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"site-audit-details-row\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"column-num\"],[12],[1,\"\\n              \"],[1,[28,[35,5],[[30,2],1],null]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"column-src\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"truncate-left text-nowrap\"],[12],[1,\"\\n                ‎\"],[1,[30,1,[\"path\"]]],[1,\"‎\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"column-alt\"],[12],[1,\"\\n              \"],[8,[39,6],null,[[\"@value\"],[[30,1,[\"alt\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      There are no images with missing alt attributes.\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"pageImage\",\"index\"],false,[\"if\",\"loading/spinner\",\"site-audit/health\",\"each\",\"-track-array\",\"add-number\",\"site-audit/boolean-icon\"]]",
    "moduleName": "nightwatch-web/components/site-audit/page-details-images.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['site-audit-details-stats'],
    shownStats: (0, _emberAwesomeMacros.gt)('totalImgCount', 0),
    restImagesCount: (0, _emberAwesomeMacros.difference)('totalImgCount', 'images.length')
  }));

  _exports.default = _default;
});