define("nightwatch-web/components/site-audit/status-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.responseCode}}
  
  */
  {
    "id": "G5FaSCh7",
    "block": "[[[1,[30,0,[\"responseCode\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "nightwatch-web/components/site-audit/status-badge.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'span',
    classNames: ['site-audit-status-badge'],
    classNameBindings: ['responseCodeType'],
    responseCodeType: Ember.computed('responseCode', function () {
      const responseCode = this.responseCode || '0';
      return `site-audit-status-badge--${responseCode.toString().substr(0, 1)}xx`;
    })
  }));

  _exports.default = _default;
});