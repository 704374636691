define("nightwatch-web/services/dashboard-notifications", ["exports", "nightwatch-web/config/environment", "nightwatch-web/constants/docs", "fetch", "nightwatch-web/utils/chevron-icon-html"], function (_exports, _environment, _docs, _fetch, _chevronIconHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardNotificationsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class DashboardNotificationsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "siteData", _descriptor4, this);

      _defineProperty(this, "dashboardNotice", void 0);

      _defineProperty(this, "paymentNotification", void 0);

      _defineProperty(this, "trialNotification", void 0);

      _defineProperty(this, "billingDateNotification", void 0);

      _defineProperty(this, "gaErrorNotification", void 0);

      _defineProperty(this, "noKeywordsNotification", void 0);

      _defineProperty(this, "stillProcessingNotification", void 0);

      _defineProperty(this, "notRankingNotification", void 0);
    }

    showSubscriptionNotifications(user) {
      if (this.siteData.hideNightwatchBranding) return;
      this.notifyTrialEnd(user);
      this.notifyPaymentProblem(user);
      this.notifyMissingBillingInfo(user);
    }

    async showNotice() {
      try {
        const request = await (0, _fetch.default)(`${_environment.default.serverURL}html_snippets/dashboard_notice_nw`, {
          contentType: 'text/html'
        });
        const response = await request.json();

        if (response !== null && response !== void 0 && response.content) {
          this.notifications.remove(this.dashboardNotice);
          this.dashboardNotice = this.showInfoMsg(Ember.String.htmlSafe(response.content));
        }
      } catch {
        console.info('No new dashboard notifications.');
      }
    }

    notifyTrialEnd(user) {
      if (user.stripeCustomer) return; // Stripe customers are automatically billed at trial end

      if (!user.isTrial) return;
      let msg;
      const daysToEnd = user.daysToInactivity;
      const plansText = `Make sure to subscribe to one of <b class="blue-alt">our plans</b>.`;

      if (user.isInactive) {
        msg = Ember.String.htmlSafe(`Your trial period ended. ${plansText}`);
      } else {
        if (daysToEnd <= 4) {
          if (daysToEnd > 1) {
            msg = Ember.String.htmlSafe(`Your trial period is about to end in ${daysToEnd} days. ${plansText}`);
          } else if (daysToEnd === 1) {
            msg = Ember.String.htmlSafe(`Your trial period is about to end tomorrow. ${plansText}`);
          } else {
            msg = Ember.String.htmlSafe(`Your trial period ends today. ${plansText}`);
          }
        }
      }

      if (msg) {
        this.notifications.remove(this.trialNotification);

        const onClick = () => {
          this.router.transitionTo('plans');
          this.notifications.clearAll();
        };

        this.trialNotification = this.showWarningMsg(msg, onClick, 'subscription-notification notification-trial-end');
      }
    }

    notifyPaymentProblem(user) {
      if (!user.hasPaymentNotification) return;
      const msg = Ember.String.htmlSafe(`We're unable to renew your subscription because of a payment problem. To keep your subscription, please <b>update your payment method ${_chevronIconHtml.default}</b>`);
      this.notifications.remove(this.paymentNotification);
      this.paymentNotification = this.notifications.error(msg, {
        cssClasses: 'subscription-notification',
        onClick: () => {
          this.router.transitionTo('update-payment');
          this.notifications.clearAll();
        }
      });
    }

    notifyMissingBillingInfo(user) {
      if (!user.missingBillingInfo) return;
      if (user.isLimited) return;
      const msg = Ember.String.htmlSafe(`To correctly process your VAT exemption, please <b class="blue-alt">fill out your company info ${_chevronIconHtml.default}</b>`);
      this.notifications.remove(this.billingDateNotification);
      this.billingDateNotification = this.notifications.warning(msg, {
        cssClasses: 'subscription-notification',
        onClick: () => {
          this.router.transitionTo('settings.billing');
          this.notifications.clearAll();
        }
      });
    }

    showUrlNotifications(url) {
      if (Ember.isPresent(url.google_analytics_error)) {
        this.notifyGoogleAnalyticsError(url);
      } else {
        this.notifications.remove(this.gaErrorNotification);
      }
    }

    showKeywordNotifications(params) {
      this.notifyNoKeywords(params);
      const isStillProcessing = this.notifyStillProcessing(params);
      if (isStillProcessing) return;
      this.notifyNotRanking(params);
    }

    notifyNoKeywords({
      keywords,
      user,
      url
    }) {
      if (!url) return;
      let msg = '';
      let onClick = null;

      if (user.canAddKeywords) {
        msg = Ember.String.htmlSafe(`You haven't added any keywords for tracking. Don't let the owl sleep. <b class="blue-alt">Add keywords ${_chevronIconHtml.default}</b>`);
        const addKeywordsRoute = this.router.urlFor('dashboard.url.keywords.new', url);

        onClick = () => {
          this.router.transitionTo(addKeywordsRoute);
          this.notifications.clearAll();
        };
      } else {
        msg = `No keywords added yet. This account doesn't have the permission to add keywords. Please ask your account manager to add some keywords for tracking.`;
      }

      if (keywords.length === 0) {
        this.clearUrlKeywordsNotifications();
        this.noKeywordsNotification = this.showWarningMsg(msg, onClick);
        return true;
      }

      return false;
    }

    notifyStillProcessing({
      keywords
    }) {
      const notProcessedCount = keywords.filter(k => Ember.isBlank(k.last_processed_at)).length;

      if (notProcessedCount > 0 && notProcessedCount === keywords.length) {
        this.clearUrlKeywordsNotifications();
        this.stillProcessingNotification = this.showWarningMsg('The data for your keywords is being processed - it can take a few minutes...');
        return true;
      }

      return false;
    }

    notifyNotRanking({
      keywords
    }) {
      const notRankingCount = keywords.filter(k => Ember.isPresent(k.last_processed_at) && Ember.isBlank(k.position)).length;

      if (notRankingCount > 0 && notRankingCount === keywords.length) {
        const msg = Ember.String.htmlSafe(`Your website is not ranking for any of the <b>${keywords.length}</b> tracked keywords. <b class="blue-alt">Read why</b> ${_chevronIconHtml.default}`);

        const onClick = () => {
          if (this.siteData.hideNightwatchBranding) return;
          window.open(_docs.default.websiteIsntRanking, '_blank');
          this.notifications.clearAll();
        };

        this.clearUrlKeywordsNotifications();
        this.notRankingNotification = this.showWarningMsg(msg, onClick);
        return true;
      }

      return false;
    }

    showWarningMsg(msg, onClick, cssClasses = '') {
      return this.notifications.warning(msg, {
        cssClasses,
        onClick
      });
    }

    showInfoMsg(msg) {
      return this.notifications.info(msg);
    }

    notifyGoogleAnalyticsError(url) {
      if (this.session.user.isLimited) return;
      const msg = Ember.String.htmlSafe(`There is an authorization problem with your Google Analytics integration. Please reconnect your Google Analytics property in <b class="blue-alt">settings</b>.`);
      this.gaErrorNotification = this.notifications.warning(msg, {
        onClick: () => {
          this.router.transitionTo('dashboard.url.settings', url.id);
          this.notifications.clearAll();
        }
      });
    }

    clearUrlKeywordsNotifications() {
      this.notifications.remove(this.gaErrorNotification, this.noKeywordsNotification, this.stillProcessingNotification, this.notRankingNotification);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "siteData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DashboardNotificationsService;
});