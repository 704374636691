define("nightwatch-web/services/reports/report-utils", ["exports", "nightwatch-web/utils/universal-graph-config", "nightwatch-web/constants/time-periods", "nightwatch-web/constants/docs", "date-fns", "nightwatch-web/utils/chevron-icon-html", "nightwatch-web/config/environment"], function (_exports, _universalGraphConfig, _timePeriods, _docs, _dateFns, _chevronIconHtml, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ReportUtilsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class ReportUtilsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "keywordColumnsData", _descriptor2, this);

      _initializerDefineProperty(this, "siteData", _descriptor3, this);

      _defineProperty(this, "defaultColumns", ['keyword-position', 'keyword-mini-graph', 'keyword-last-position-change', 'keyword-dwm-change']);

      _defineProperty(this, "templates", [{
        name: 'basic_report'
      }, {
        name: 'progress_report'
      }, {
        name: 'competitor_report'
      }]);

      _defineProperty(this, "allBlocks", [{
        name: 'keyword_overview'
      }, {
        name: 'keyword_list'
      }, {
        name: 'traffic_overview'
      }, {
        name: 'backlink_overview'
      }, {
        name: 'graph'
      }, {
        name: 'site_audit_overview'
      }, {
        name: 'site_audit_pages'
      }]);

      _defineProperty(this, "availableGroupings", Object.values(_universalGraphConfig.GROUPING));

      _defineProperty(this, "defaultGrouping", _universalGraphConfig.DEFAULT_GROUPING);

      _defineProperty(this, "blockIcons", {
        keyword_overview: 'fa-tasks',
        keyword_list: 'fa-list-ul',
        traffic_overview: 'fa-line-chart',
        backlink_overview: 'fa-chain',
        graph: 'fa-bar-chart',
        site_audit_overview: 'fa-sitemap',
        site_audit_pages: 'fa-files-o'
      });
    }

    prepareLogos(logos) {
      const result = logos.map(logo => ({
        id: Number(logo.get('id')),
        imgSrc: `${_environment.default.serverURL}${logo.get('logo')}`
      }));

      if (!this.siteData.hideNightwatchBranding) {
        result.push({
          id: -1,
          imgSrc: '/assets/images/nightwatch-report-logo.png'
        });
      }

      return result;
    }

    getReportInterval(report) {
      const start = new Date(report.get('start_time'));
      const end = new Date(report.get('end_time'));
      const daysDiff = Math.abs((0, _dateFns.differenceInDays)(start, end));
      if (daysDiff == 1) return _timePeriods.DAILY;
      if (daysDiff == 7) return _timePeriods.WEEKLY;
      if (daysDiff == 30) return _timePeriods.MONTHLY;
      return null;
    }

    extractAvailableCompetitorColumns(competitors) {
      const competitorColumns = [];
      competitors.forEach(competitor => {
        const competitorChildColumns = []; // No support for these in the backend currently

        const unsupportedColumns = ['keyword-position-places-image', 'keyword-position-local-pack', 'keyword-dwm-change'];
        this.get('keywordColumnsData.availableCompetitorColumns').filter(c => unsupportedColumns.indexOf(c.name) === -1).forEach(competitorColumn => {
          competitorChildColumns.push({
            id: `${competitorColumn.name}-competitor-${competitor.id}`,
            text: competitorColumn.displayName
          });
        });
        competitorColumns.push({
          groupName: `Competitor: ${competitor.get('displayName')}`,
          options: competitorChildColumns
        });
      });
      return competitorColumns;
    }

    toggleKeywordLimitNotification(count, limit) {
      const className = 'report-too-many-keywords-error-notification';
      const notificationShown = document.querySelectorAll(`.${className}`).length;

      if (count && count >= limit) {
        if (notificationShown) return;
        const msg = Ember.String.htmlSafe(`The total limit for reports is ${limit} keywords. We suggest creating segmented reports using <b>keyword views ${this.siteData.hideNightwatchBranding ? '' : _chevronIconHtml.default}</b>`);
        this.notifications.error(msg, {
          cssClasses: `${className}`,
          onClick: () => {
            if (this.siteData.hideNightwatchBranding) return;
            window.open(_docs.default.keywordViewsAndLists, '_blank');
            this.notifications.clearAll();
          }
        });
      } else {
        Array.from(document.querySelectorAll(`.${className}`)).forEach(e => e.remove());
      }
    }

    toggleHighKeywordCountWarningNotification(keywordCount) {
      const className = 'report-too-many-keywords-warning-notification';
      const keywordCountNotificationThreshold = 5000;
      const notificationShown = document.querySelectorAll(`.${className}`).length;

      if (keywordCount >= keywordCountNotificationThreshold) {
        if (notificationShown) return;
        const msg = Ember.String.htmlSafe(`Reports with more than ${keywordCountNotificationThreshold} keywords will take longer to create. We suggest creating segmented reports using <b class="blue-alt">keyword views ${this.siteData.hideNightwatchBranding ? '' : _chevronIconHtml.default}</b>`);
        this.notifications.warning(msg, {
          cssClasses: `${className}`,
          onClick: () => {
            if (this.siteData.hideNightwatchBranding) return;
            window.open(_docs.default.keywordViewsAndLists, '_blank');
            this.notifications.clearAll();
          }
        });
      } else {
        Array.from(document.querySelectorAll(`.${className}`)).forEach(e => e.remove());
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "keywordColumnsData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ReportUtilsService;
});