define("nightwatch-web/initializers/site-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  const initialize = application => {
    application.inject('controller', 'siteData', 'service:siteData');
    application.inject('route', 'siteData', 'service:siteData');
    application.inject('component', 'siteData', 'service:siteData');
  };

  _exports.initialize = initialize;
  var _default = {
    name: 'site-data',
    initialize
  };
  _exports.default = _default;
});