define("nightwatch-web/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZLqagAg4",
    "block": "[[[1,[28,[35,0],[\"Login\"],null]],[1,\"\\n\\n\"],[41,[30,0,[\"showNightwatchBranding\"]],[[[1,\"  \"],[10,0],[14,0,\"nw-login nw-split\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"nw-split__page\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"nw-bright-form bright\"]],null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"nw-split__page-sidebar\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-flexible-eye\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"nw-login nw-centered\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"nw-centered__page\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"nw-bright-form bright\"]],null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"page-title\",\"if\",\"login-form\"]]",
    "moduleName": "nightwatch-web/templates/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});