define("nightwatch-web/templates/dashboard/url/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TisgoRSg",
    "block": "[[[1,[28,[35,0],[[30,0,[\"url\",\"friendlyUrl\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[[30,1,[\"name\"]]],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"main-area-navbar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main-area-container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,0,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[[30,0,[\"url\",\"url\"]]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,3],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url.reports\",[30,0,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n        Reports\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"isNewVersion\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@url\",\"@report\",\"@logos\"],[[30,0,[\"url\"]],[30,1],[30,0,[\"whitelabelLogos\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,6],null,[[\"@url\",\"@report\",\"@logos\",\"@keywords\",\"@keywordParams\",\"@selectedKeywordIds\",\"@toggleKeywordSelected\",\"@selectAllKeywords\",\"@selectNoKeywords\",\"@onSearchChange\",\"@fetchPage\",\"@sortKeywords\",\"@goToReport\",\"@jc\"],[[30,0,[\"url\"]],[30,1],[30,0,[\"whitelabelLogos\"]],[30,0,[\"keywords\"]],[30,0,[\"keywordParams\"]],[30,0,[\"selectedKeywordIds\"]],[30,0,[\"toggleKeywordSelected\"]],[30,0,[\"selectAllKeywords\"]],[30,0,[\"selectNoKeywords\"]],[30,0,[\"onSearchChange\"]],[30,0,[\"fetchPage\"]],[30,0,[\"sortKeywords\"]],[30,0,[\"goToReport\"]],[30,0,[\"jc\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"link-to\",\"friendly-url\",\"nw-icon\",\"if\",\"reports/report-form\",\"report-form\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/report.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});