define("nightwatch-web/helpers/parse-iso-date", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.parseISODate = void 0;

  const parseISODate = ([dateString]) => {
    return (0, _dateFns.parseISO)(dateString);
  };

  _exports.parseISODate = parseISODate;

  var _default = Ember.Helper.helper(parseISODate);

  _exports.default = _default;
});