define("nightwatch-web/components/settings/subuser-list", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h2 class="nw-settings__subtitle">Manage user access to your account</h2>
  
  <div class="nw-table nw-table--v2 nw-table--users">
    {{#if @subusers.length}}
      <div class="nw-table__row nw-table__row--bordered nw-table__row--header">
        <div class="nw-table__col nw-table__col--first">Email</div>
        <div class="nw-table__col nw-table__col--access">Access</div>
        <div class="nw-table__col nw-table__col--url-limit">URL limit</div>
        <div class="nw-table__col nw-table__col--keyword-limit">Keyword limit</div>
        <div class="nw-table__col nw-table__col--created">Created</div>
        <div class="nw-table__col nw-table__col--last-access">Last access</div>
        <div class="nw-table__col nw-table__col--actions"></div>
      </div>
    {{/if}}
  
    {{#each @subusers as |subuser|}}
      <Settings::SubuserListItem
        @subuser={{subuser}}
        @deleteSubuser={{fn this.deleteSubuser subuser}}
        @sendInviteEmail={{fn this.sendInviteEmail subuser}}
        @edit={{fn this.edit subuser}}
      />
    {{/each}}
  </div>
  
  <LinkTo @route="settings.subusers.new" class="nw-button nw-button--plain">
    <NwIcon class="nw-button__icon" @icon="icon-plus-circle" /> Add user
  </LinkTo>
  
  */
  {
    "id": "nMHpTiRK",
    "block": "[[[10,\"h2\"],[14,0,\"nw-settings__subtitle\"],[12],[1,\"Manage user access to your account\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"nw-table nw-table--v2 nw-table--users\"],[12],[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"nw-table__row nw-table__row--bordered nw-table__row--header\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-table__col nw-table__col--first\"],[12],[1,\"Email\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"nw-table__col nw-table__col--access\"],[12],[1,\"Access\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"nw-table__col nw-table__col--url-limit\"],[12],[1,\"URL limit\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"nw-table__col nw-table__col--keyword-limit\"],[12],[1,\"Keyword limit\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"nw-table__col nw-table__col--created\"],[12],[1,\"Created\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"nw-table__col nw-table__col--last-access\"],[12],[1,\"Last access\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"nw-table__col nw-table__col--actions\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@subuser\",\"@deleteSubuser\",\"@sendInviteEmail\",\"@edit\"],[[30,2],[28,[37,4],[[30,0,[\"deleteSubuser\"]],[30,2]],null],[28,[37,4],[[30,0,[\"sendInviteEmail\"]],[30,2]],null],[28,[37,4],[[30,0,[\"edit\"]],[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\\n\"],[8,[39,5],[[24,0,\"nw-button nw-button--plain\"]],[[\"@route\"],[\"settings.subusers.new\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,6],[[24,0,\"nw-button__icon\"]],[[\"@icon\"],[\"icon-plus-circle\"]],null],[1,\" Add user\\n\"]],[]]]]],[1,\"\\n\"]],[\"@subusers\",\"subuser\"],false,[\"if\",\"each\",\"-track-array\",\"settings/subuser-list-item\",\"fn\",\"link-to\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/settings/subuser-list.hbs",
    "isStrictMode": false
  });

  let SubuserList = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class SubuserList extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);
    }

    deleteSubuser(subuser) {
      if (confirm('Are you sure you want to delete this user?')) {
        subuser.destroyRecord().catch(error => {
          this.notifications.error(`Error: ${error.errors[0].detail}`, {
            autoClear: true,
            clearDuration: 10000
          });
        });
      }
    }

    sendInviteEmail(subuser) {
      if (subuser.hasLoggedIn) return;
      const promise = this.fetch.post(`/subusers/${subuser.id}/send_invite_email`);
      promise.catch(() => {
        this.notifications.error('An error occurred while sending invite email.', {
          autoClear: true,
          clearDuration: 3500
        });
      });
      return promise.then(() => {
        this.notifications.success('Email sent.', {
          autoClear: true,
          clearDuration: 3500
        });
      });
    }

    edit(subuser) {
      return this.router.transitionTo('settings.subusers.subuser', subuser.id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteSubuser", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deleteSubuser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendInviteEmail", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "sendInviteEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype)), _class));
  _exports.default = SubuserList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SubuserList);
});