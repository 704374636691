define("nightwatch-web/constants/time-periods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MONTHLY = _exports.WEEKLY = _exports.DAILY = void 0;
  const DAILY = 86400;
  _exports.DAILY = DAILY;
  const WEEKLY = 604800;
  _exports.WEEKLY = WEEKLY;
  const MONTHLY = 2592000;
  _exports.MONTHLY = MONTHLY;
});