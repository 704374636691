define("nightwatch-web/components/reset-password-form", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-reset-password-form" ...attributes>
    <form {{on "submit" (prevent-default (perform this.save))}}>
      <h1>Reset your password</h1>
  
      <div class="nw-form__row">
        <div class="nw-form__group">
          <label for="email" class="nw-form__label">Email</label>
          <Input
            @value={{this.email}}
            id="email"
            class="nw-form__input {{if this.error "nw-form__input--error"}}"
            @type="email"
            autocomplete="email"
            required
            {{on "keyup" (fn (mut this.error) null)}} />
        </div>
      </div>
  
      {{#if this.error}}
        <div class="nw-form__row">
          <div class="nw-form__group">
            <div class="nw-form__error-message">{{this.error}}</div>
          </div>
        </div>
      {{/if}}
  
  
  
      <div class="nw-form__row">
        <div class="nw-form__group">
          <button type="submit" disabled={{this.save.isRunning}} class="nw-button nw-button--submit">
            {{if this.save.isIdle "Reset password" "Resetting..."}}
          </button>
        </div>
      </div>
    </form>
  
    <p class="nw-bright-form__footer">
      Already know your password?
      <LinkTo @route="login">Login now</LinkTo>
    </p>
  </div>
  
  */
  {
    "id": "PsRgqhgm",
    "block": "[[[11,0],[24,0,\"nw-reset-password-form\"],[17,1],[12],[1,\"\\n  \"],[11,\"form\"],[4,[38,0],[\"submit\",[28,[37,1],[[28,[37,2],[[30,0,[\"save\"]]],null]],null]],null],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Reset your password\"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"nw-form__row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-form__group\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"email\"],[14,0,\"nw-form__label\"],[12],[1,\"Email\"],[13],[1,\"\\n        \"],[8,[39,3],[[24,1,\"email\"],[16,0,[29,[\"nw-form__input \",[52,[30,0,[\"error\"]],\"nw-form__input--error\"]]]],[24,\"autocomplete\",\"email\"],[24,\"required\",\"\"],[4,[38,0],[\"keyup\",[28,[37,5],[[28,[37,6],[[30,0,[\"error\"]]],null],null],null]],null]],[[\"@value\",\"@type\"],[[30,0,[\"email\"]],\"email\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"error\"]],[[[1,\"      \"],[10,0],[14,0,\"nw-form__row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"nw-form__group\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"nw-form__error-message\"],[12],[1,[30,0,[\"error\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\\n\\n    \"],[10,0],[14,0,\"nw-form__row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"nw-form__group\"],[12],[1,\"\\n        \"],[10,\"button\"],[15,\"disabled\",[30,0,[\"save\",\"isRunning\"]]],[14,0,\"nw-button nw-button--submit\"],[14,4,\"submit\"],[12],[1,\"\\n          \"],[1,[52,[30,0,[\"save\",\"isIdle\"]],\"Reset password\",\"Resetting...\"]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[14,0,\"nw-bright-form__footer\"],[12],[1,\"\\n    Already know your password?\\n    \"],[8,[39,7],null,[[\"@route\"],[\"login\"]],[[\"default\"],[[[[1,\"Login now\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"on\",\"prevent-default\",\"perform\",\"input\",\"if\",\"fn\",\"mut\",\"link-to\"]]",
    "moduleName": "nightwatch-web/components/reset-password-form.hbs",
    "isStrictMode": false
  });

  /**
    @class ResetPasswordFormComponent
  
    Reset password form for requesting a password reset email
   */
  let ResetPasswordFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class ResetPasswordFormComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "email", _descriptor4, this);

      _initializerDefineProperty(this, "error", _descriptor5, this);
    }

    *save() {
      try {
        yield this.fetch.post('/password_resets', {
          data: {
            email: this.email
          }
        });
      } catch (response) {
        var _response$payload;

        this.error = (response === null || response === void 0 ? void 0 : (_response$payload = response.payload) === null || _response$payload === void 0 ? void 0 : _response$payload.error) || 'Something broke. Please contact us if error persists.';
        return;
      }

      this.notifications.success('E-mail with password reset instructions sent. Check your inbox and visit the password reset link.');
      this.router.transitionTo('login');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = ResetPasswordFormComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ResetPasswordFormComponent);
});