define("nightwatch-web/controllers/dashboard/url/settings/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlSettingsConfigurationController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.controller, _dec5 = Ember.computed('dashboard.urlGroups.[]'), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class DashboardUrlSettingsConfigurationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "urlUtils", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "dashboard", _descriptor4, this);
    }

    get urlGroups() {
      return this.get('dashboard.urlGroups');
    }

    onUrlDeleted() {
      this.notifications.success('URL deleted.', {
        autoClear: true,
        clearDuration: 3500
      });
      this.router.transitionTo('dashboard.overview');
    }

    async onUrlUpdate(url, urlGroupChanged) {
      if (urlGroupChanged) {
        const newUrlGroup = url.get('urlGroup.content');
        const oldUrlGroup = this.store.peekAll('url-group').filter(function (urlGroup) {
          const urlInOldGroup = urlGroup.get('urls').findBy('id', url.id);
          return urlInOldGroup && urlGroup.id !== newUrlGroup.id;
        })[0];

        if (oldUrlGroup) {
          const oldUrlGroupUrls = oldUrlGroup.get('urls').toArray();
          oldUrlGroupUrls.removeObject(url);
          oldUrlGroup.set('urls', oldUrlGroupUrls);
          oldUrlGroup.reload();
          this.dashboard.deactivateUrlGroup(oldUrlGroup);
          this.dashboard.activateUrlGroup(newUrlGroup, true);
        }

        newUrlGroup.reload();
      }

      this.urlUtils.refreshGraphs(url);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "urlUtils", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dashboard", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "urlGroups", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "urlGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUrlDeleted", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onUrlDeleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUrlUpdate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onUrlUpdate"), _class.prototype)), _class));
  _exports.default = DashboardUrlSettingsConfigurationController;
});