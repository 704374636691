define("nightwatch-web/components/getting-started", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert (perform this.loadVideos)}}>
    {{#if this.loadVideos.isRunning}}
      <Loading::SmallHorizontal />
    {{else}}
      <div class="getting-started__list row">
        {{#each this.videos as |video index|}}
          <div class="col-sm-4">
            <div class="getting-started__list__item {{if (eq this.currentVideo.id video.id) 'active'}}" role="button" {{on "click" (fn (mut this.currentVideo) video)}}>
              {{add-number index 1}}. {{video.title}}
            </div>
          </div>
        {{/each}}
      </div>
      <div class="getting-started__video">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe title="Getting started" class="embed-responsive-item" src={{this.currentVideo.embedCode}}>
          </iframe>
        </div>
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "4BczQ49V",
    "block": "[[[11,0],[4,[38,0],[[28,[37,1],[[30,0,[\"loadVideos\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,0,[\"loadVideos\",\"isRunning\"]],[[[1,\"    \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"getting-started__list row\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"videos\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"col-sm-4\"],[12],[1,\"\\n          \"],[11,0],[16,0,[29,[\"getting-started__list__item \",[52,[28,[37,6],[[30,0,[\"currentVideo\",\"id\"]],[30,1,[\"id\"]]],null],\"active\"]]]],[24,\"role\",\"button\"],[4,[38,7],[\"click\",[28,[37,8],[[28,[37,9],[[30,0,[\"currentVideo\"]]],null],[30,1]],null]],null],[12],[1,\"\\n            \"],[1,[28,[35,10],[[30,2],1],null]],[1,\". \"],[1,[30,1,[\"title\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"getting-started__video\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"embed-responsive embed-responsive-16by9\"],[12],[1,\"\\n        \"],[10,\"iframe\"],[14,\"title\",\"Getting started\"],[14,0,\"embed-responsive-item\"],[15,\"src\",[30,0,[\"currentVideo\",\"embedCode\"]]],[12],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"video\",\"index\"],false,[\"did-insert\",\"perform\",\"if\",\"loading/small-horizontal\",\"each\",\"-track-array\",\"eq\",\"on\",\"fn\",\"mut\",\"add-number\"]]",
    "moduleName": "nightwatch-web/components/getting-started.hbs",
    "isStrictMode": false
  });

  let GettingStartedComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class GettingStartedComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "videos", _descriptor2, this);

      _initializerDefineProperty(this, "currentVideo", _descriptor3, this);
    }

    *loadVideos() {
      this.videos = yield this.store.findAll('help-link');
      this.currentVideo = this.videos.firstObject;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "videos", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentVideo", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadVideos", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "loadVideos"), _class.prototype)), _class));
  _exports.default = GettingStartedComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GettingStartedComponent);
});