define("nightwatch-web/components/reports/report-grouping-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <div class="btn-group">
      {{#each @groupings as |groupingOption|}}
        <button class="btn btn-secondary-5 btn-grouping {{if (string-eq @selected groupingOption) 'active'}}" {{on "click" (fn @changeGrouping groupingOption)}} type="button">
          {{capitalize groupingOption}}
        </button>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "PVT3vw9Z",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"btn-group\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"      \"],[11,\"button\"],[16,0,[29,[\"btn btn-secondary-5 btn-grouping \",[52,[28,[37,3],[[30,4],[30,3]],null],\"active\"]]]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,5],[30,3]],null]],null],[12],[1,\"\\n        \"],[1,[28,[35,6],[[30,3]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@groupings\",\"groupingOption\",\"@selected\",\"@changeGrouping\"],false,[\"each\",\"-track-array\",\"if\",\"string-eq\",\"on\",\"fn\",\"capitalize\"]]",
    "moduleName": "nightwatch-web/components/reports/report-grouping-chooser.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});