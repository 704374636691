define("nightwatch-web/components/snapshot-selector", ["exports", "@glimmer/component", "date-fns"], function (_exports, _component, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  <div class="nw-snapshot-selector"
    {{did-insert this.snapshotsUpdated}}
    {{did-update this.snapshotsUpdated @snapshots}}
  >
    <NwSelect
      @v1={{true}}
      class="nw-power-select--s nw-power-select--low"
      @dropdownClass="nw-power-select--s nw-snapshot-selector__dropdown"
      @options={{this.options}}
      @selected={{this.selectedOption}}
      @onChange={{this.onSelected}}
      @onOpen={{this.onOpen}}
      @onClose={{fn (mut this.isOpen) false}}
      @closeOnSelect={{false}}
      @extra={{hash
        snapshotDates=this.snapshotDates
        dayClass=this.dayClass
        showCalendar=this.isOpen
        center=this.calendarMonth
        onCenterChange=this.onMonthChanged
        onDateSelected=this.onDateSelected
      }}
      @afterOptionsComponent={{component "snapshot-selector/calendar"}}
      @selectedItemComponent={{component "snapshot-selector/selected-item"}}
      as |option|>
      {{humanize option.label}}
    </NwSelect>
  </div>
  
  */
  {
    "id": "trQ8pGLI",
    "block": "[[[1,\"\\n\"],[11,0],[24,0,\"nw-snapshot-selector\"],[4,[38,0],[[30,0,[\"snapshotsUpdated\"]]],null],[4,[38,1],[[30,0,[\"snapshotsUpdated\"]],[30,1]],null],[12],[1,\"\\n  \"],[8,[39,2],[[24,0,\"nw-power-select--s nw-power-select--low\"]],[[\"@v1\",\"@dropdownClass\",\"@options\",\"@selected\",\"@onChange\",\"@onOpen\",\"@onClose\",\"@closeOnSelect\",\"@extra\",\"@afterOptionsComponent\",\"@selectedItemComponent\"],[true,\"nw-power-select--s nw-snapshot-selector__dropdown\",[30,0,[\"options\"]],[30,0,[\"selectedOption\"]],[30,0,[\"onSelected\"]],[30,0,[\"onOpen\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"isOpen\"]]],null],false],null],false,[28,[37,5],null,[[\"snapshotDates\",\"dayClass\",\"showCalendar\",\"center\",\"onCenterChange\",\"onDateSelected\"],[[30,0,[\"snapshotDates\"]],[30,0,[\"dayClass\"]],[30,0,[\"isOpen\"]],[30,0,[\"calendarMonth\"]],[30,0,[\"onMonthChanged\"]],[30,0,[\"onDateSelected\"]]]]],[50,\"snapshot-selector/calendar\",0,null,null],[50,\"snapshot-selector/selected-item\",0,null,null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,7],[[30,2,[\"label\"]]],null]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@snapshots\",\"option\"],false,[\"did-insert\",\"did-update\",\"nw-select\",\"fn\",\"mut\",\"hash\",\"component\",\"humanize\"]]",
    "moduleName": "nightwatch-web/components/snapshot-selector.hbs",
    "isStrictMode": false
  });

  const SNAPSHOT_PERIODS = ['week', 'month', 'quarter', 'year'];
  const NONE_OPTION = {
    label: 'none',
    snapshot: null
  };
  /**
    @class SnapshotSelectorComponent
  
    A dropdown selector for choosing Site Audit crawling "snapshots".
  
    Provides options based on recent periods, and a calendar to pick specific snapshots.
  
    @argument snapshots {Array[CrawlingSession]}
    @argument selectedSnapshotId {String} null|<id>|"last_week"|"last_month"|"last_quarter"
    @argument onChange {function(selectedSnapshotId)}
   */

  let SnapshotSelectorComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class SnapshotSelectorComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isOpen", _descriptor, this);

      _initializerDefineProperty(this, "calendarMonth", _descriptor2, this);
    }

    close() {}

    get snapshotDates() {
      return this.args.snapshots // turn dates to start of day timestamps
      .map(snapshot => (0, _dateFns.getUnixTime)((0, _dateFns.startOfDay)(new Date(snapshot.startedAt)))) // filter out unique values
      .filter((time, idx, arr) => arr.indexOf(time) === idx) // Sort from old to new
      .sort() // turn timestamps back to dates
      .map(snapshot => new Date(snapshot));
    }

    get minDate() {
      return this.snapshotDates.firstObject;
    }

    get maxDate() {
      return this.snapshotDates.lastObject;
    }

    get options() {
      const options = [NONE_OPTION];

      const snapshotInLastPeriod = period => {
        return snapshot => {
          switch (period) {
            case 'week':
              return new Date(snapshot.startedAt) > (0, _dateFns.startOfWeek)((0, _dateFns.sub)(new Date(), {
                weeks: 1
              })) && new Date(snapshot.startedAt) < (0, _dateFns.endOfWeek)((0, _dateFns.sub)(new Date(), {
                weeks: 1
              }));

            case 'month':
              return new Date(snapshot.startedAt) > (0, _dateFns.startOfMonth)((0, _dateFns.sub)(new Date(), {
                months: 1
              })) && new Date(snapshot.startedAt) < (0, _dateFns.endOfMonth)((0, _dateFns.sub)(new Date(), {
                months: 1
              }));

            case 'year':
              return new Date(snapshot.startedAt) > (0, _dateFns.startOfYear)((0, _dateFns.sub)(new Date(), {
                years: 1
              })) && new Date(snapshot.startedAt) < (0, _dateFns.endOfYear)((0, _dateFns.sub)(new Date(), {
                years: 1
              }));

            case 'quarter':
              return new Date(snapshot.startedAt) > (0, _dateFns.startOfQuarter)((0, _dateFns.subQuarters)(new Date(), 1)) && new Date(snapshot.startedAt) < (0, _dateFns.endOfQuarter)((0, _dateFns.subQuarters)(new Date(), 1));

            default:
              return new Error('Invalid period type', period);
          }
        };
      };

      SNAPSHOT_PERIODS.forEach(period => {
        const snapshot = this.args.snapshots.find(snapshotInLastPeriod(period));

        if (snapshot) {
          options.push({
            label: `last_${period}`,
            snapshot: {
              id: `last_${period}`
            }
          });
        }
      });
      return options;
    }

    get selectedOption() {
      const {
        selectedSnapshotId,
        snapshots
      } = this.args;
      const periodOption = this.options.find(option => {
        var _option$snapshot;

        return ((_option$snapshot = option.snapshot) === null || _option$snapshot === void 0 ? void 0 : _option$snapshot.id) === selectedSnapshotId;
      });
      if (periodOption) return periodOption;
      const snapshot = snapshots.findBy('id', selectedSnapshotId);

      if (snapshot) {
        return {
          label: (0, _dateFns.format)(snapshot.startedAt, 'MMMM dd, yyyy'),
          snapshot
        };
      }

      return NONE_OPTION;
    }

    snapshotsUpdated() {
      this.calendarMonth = this.maxDate;
    }

    onOpen(powerSelect) {
      this.isOpen = true; // Store the close action so that this component can close the PowerSelect

      this.close = powerSelect.actions.close;
    }

    onSelected(option) {
      var _option$snapshot2;

      this.args.onChange((_option$snapshot2 = option.snapshot) === null || _option$snapshot2 === void 0 ? void 0 : _option$snapshot2.id);
      this.close();
    }

    onMonthChanged({
      date
    }) {
      if (new Date(date) < (0, _dateFns.startOfMonth)(new Date(this.minDate)) || new Date(date) < (0, _dateFns.endOfMonth)(new Date(this.maxDate))) return;
      this.calendarMonth = date;
    }

    onDateSelected({
      date
    }) {
      const matchingSnapshot = this.args.snapshots.find(snapshot => (0, _dateFns.startOfDay)(new Date(snapshot.startedAt)).getDay() === date.getDay());
      if (!matchingSnapshot) return;
      this.args.onChange(matchingSnapshot.id);
      this.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "calendarMonth", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "snapshotsUpdated", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "snapshotsUpdated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpen", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelected", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMonthChanged", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onMonthChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDateSelected", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onDateSelected"), _class.prototype)), _class));
  _exports.default = SnapshotSelectorComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SnapshotSelectorComponent);
});