define("nightwatch-web/services/external-script-loader", ["exports", "nightwatch-web/utils/load-external-scripts"], function (_exports, _loadExternalScripts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExternalScriptLoaderService extends Ember.Service {
    async loadBillingScripts() {
      const scripts = ['https://js.braintreegateway.com/web/3.51.0/js/client.min.js', 'https://js.braintreegateway.com/web/3.51.0/js/hosted-fields.min.js', 'https://js.braintreegateway.com/web/3.51.0/js/paypal-checkout.min.js', 'https://js.braintreegateway.com/web/3.51.0/js/three-d-secure.min.js'];
      await (0, _loadExternalScripts.default)(scripts, 15);
    }

  }

  _exports.default = ExternalScriptLoaderService;
});