define("nightwatch-web/services/stripe", ["exports", "nightwatch-web/config/environment", "ember-concurrency", "nightwatch-web/utils/underscorify-keys", "nightwatch-web/utils/load-external-scripts", "nightwatch-web/utils/is-testing"], function (_exports, _environment, _emberConcurrency, _underscorifyKeys, _loadExternalScripts, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StripeService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec4 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec5 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class StripeService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "checkoutButtonId", _descriptor2, this);
    }

    *loadStripeScripts() {
      if (_isTesting.default) return;
      yield (0, _loadExternalScripts.default)(['https://js.stripe.com/v3/']);
    }

    *redirectToCheckout({
      planId,
      buttonId
    }) {
      this.checkoutButtonId = buttonId;

      try {
        var _window, _window$Rewardful;

        yield this.loadStripeScripts.perform();
        const referral = (_window = window) === null || _window === void 0 ? void 0 : (_window$Rewardful = _window.Rewardful) === null || _window$Rewardful === void 0 ? void 0 : _window$Rewardful.referral;
        const response = yield this.fetch.post('/stripe/checkout_sessions', {
          data: (0, _underscorifyKeys.default)({
            planId,
            referral
          })
        });
        const stripe = window.Stripe(_environment.default.STRIPE_PUBLISHABLE_KEY);
        yield stripe.redirectToCheckout({
          sessionId: response.sessionId
        });
      } finally {
        this.checkoutButtonId = null;
      }
    }

    *redirectToBillingPortal() {
      const returnUrl = window.location.href;
      const response = yield this.fetch.post('/stripe/customer_portal_sessions', {
        data: (0, _underscorifyKeys.default)({
          returnUrl
        })
      });
      window.location = response.url;
      /*
        At this point a browser redirect will probably be initiated
        Keep the task running for 2 seconds for UI state purposes
      */

      yield (0, _emberConcurrency.rawTimeout)(2000);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "checkoutButtonId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadStripeScripts", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadStripeScripts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToCheckout", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToCheckout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToBillingPortal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToBillingPortal"), _class.prototype)), _class));
  _exports.default = StripeService;
});