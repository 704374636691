define("nightwatch-web/components/adwords-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @keyword.isGoogle}}
    {{#if (eq @value null)}}
      <NwTooltip>Retrieving data (can take up to 24 hours)</NwTooltip>
      <NwIcon @icon="icon-clock" />
    {{else}}
      {{@value}}
      {{#if (eq @value "/")}}
        <NwHelpTooltip>This data is currently not available for this keyword.</NwHelpTooltip>
      {{/if}}
    {{/if}}
  {{else}}
    /
  {{/if}}
  
  */
  {
    "id": "hqEXjl5n",
    "block": "[[[41,[30,1,[\"isGoogle\"]],[[[41,[28,[37,1],[[30,2],null],null],[[[1,\"    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"Retrieving data (can take up to 24 hours)\"]],[]]]]],[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[\"icon-clock\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,2]],[1,\"\\n\"],[41,[28,[37,1],[[30,2],\"/\"],null],[[[1,\"      \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"This data is currently not available for this keyword.\"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]]],[]],[[[1,\"  /\\n\"]],[]]]],[\"@keyword\",\"@value\"],false,[\"if\",\"eq\",\"nw-tooltip\",\"nw-icon\",\"nw-help-tooltip\"]]",
    "moduleName": "nightwatch-web/components/adwords-value.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});