define("nightwatch-web/mixins/dynamic-view-handling", ["exports", "lodash-es/isEmpty", "lodash-es/isEqual"], function (_exports, _isEmpty, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filterData: Ember.inject.service(),
    dashboardState: Ember.inject.service(),
    queryParams: ['page', 'direction', 'sort', 'groupId'],
    page: 1,
    sort: 'created_at',
    direction: 'desc',
    limit: 50,
    defaultLimit: 50,
    initialKeywordLoadingDone: false,
    keywordsLoading: false,
    groupId: null,
    filterGroupsCache: null,

    setPerPageLimit() {
      let limit = this.getPerPageLimit();
      this.set('limit', limit || this.defaultLimit);
    },

    getPerPageLimit() {
      const searchKeywordUrlId = this.get('url.id');
      const dynamicViewId = this.get('model.id');

      try {
        let setting = this.userSettings.getSetting({
          searchKeywordUrlId,
          dynamicViewId
        }); // Try parent setting (for URL when there is no setting for dynamic view)

        if (!setting) {
          setting = this.userSettings.getSetting({
            searchKeywordUrlId
          });
        }

        return setting.get('settingsData').keywords.limit;
      } catch (e) {
        return this.defaultLimit;
      }
    },

    notifyFilterChange: Ember._action(function () {
      if (this.initialKeywordLoadingDone) {
        Ember.run.debounce(this, 'fetchKeywordsAfterFilterChange', 150);
      }
    }),

    serializeFilters() {
      const filterGroups = this.get('model.filterGroups');
      const filterGroupsPayload = filterGroups.map(filterGroup => filterGroup.serialize());
      const notEmptyFilterGroups = this.filterData.removeEmptyFiltersFromGroups(filterGroupsPayload);
      return notEmptyFilterGroups;
    },

    cacheFilters() {
      this.filterGroupsCache = this.serializeFilters();
    },

    fetchKeywordsAfterFilterChange() {
      if (this.filterGroupsCache) {
        const filtersDidChange = !(0, _isEqual.default)(this.filterGroupsCache, this.serializeFilters()); // observer that sends notifyFilterChange is sometimes acting strangely
        // and sending false notifications so we have to check if filters really did change

        if (!filtersDidChange) return;
      }

      this.set('model.filtersSaved', false);
      this.validateFilters(this.model, false); // After nullifying the first and only filter

      const visibleFilterGroups = this.get('model.filterGroups').filter(filterGroup => !filterGroup.get('markedAsDeleted'));
      const filterBlank = visibleFilterGroups.length === 1 && visibleFilterGroups[0].get('filters.length') === 1 && visibleFilterGroups[0].get('filters.firstObject.isBlank');
      const isSingleEmptyValueFilter = this.get('model.filterGroups.length') === 1 && this.get('model.filterGroups.firstObject.filters.length') === 1 && this.get('model.filterGroups.firstObject.filters.firstObject.field') && this.get('model.filterGroups.firstObject.filters.firstObject.condition') && !['present', 'absent'].includes(this.get('model.filterGroups.firstObject.filters.firstObject.condition')) && ['', null, undefined].includes(this.get('model.filterGroups.firstObject.filters.firstObject.value'));
      const valueChanged = this.filterGroupsCache && this.filterGroupsCache.length && this.filterGroupsCache[0].filters[0] && this.filterGroupsCache[0].filters[0].value !== this.get('model.filterGroups.firstObject.filters.firstObject.value');
      if (valueChanged) this.set('page', 1);

      if (isSingleEmptyValueFilter && valueChanged) {
        this.fetchKeywords();
        if (this.fetchKeywordStats) this.fetchKeywordStats();
        this.filterGroupsCache = this.serializeFilters();
      } else if (filterBlank || this.get('model.filtersValid')) {
        const filterGroupsParams = this.serializeFilters();
        const params = {};

        if (filterGroupsParams.filter_groups && filterGroupsParams.filter_groups.length) {
          params.filter_groups = JSON.stringify(filterGroupsParams);
        }

        this.fetchKeywords(params);
        if (this.fetchKeywordStats) this.fetchKeywordStats(params);
        this.cacheFilters();
      }
    },

    fetchKeywords(filters = {}) {
      this.set('keywordsLoading', true);
      let params = {
        dynamic_view_id: this.model.id,
        page: this.page,
        sort: this.sort,
        direction: this.direction,
        limit: this.limit,
        url_id: this.get('url.id'),
        date_from: this.statsDateFrom,
        date_to: this.statsDateTo
      };

      if (!params.url_id) {
        var _this$dashboardState, _this$dashboardState$;

        params.group_id = (_this$dashboardState = this.dashboardState) === null || _this$dashboardState === void 0 ? void 0 : (_this$dashboardState$ = _this$dashboardState.currentUrlGroup) === null || _this$dashboardState$ === void 0 ? void 0 : _this$dashboardState$.id;
      }

      if ((0, _isEmpty.default)(filters) && this.get('model.filtersValid')) {
        const appliedFilters = this.serializeFilters();

        if (appliedFilters.length) {
          filters = Ember.assign(filters, {
            filter_groups: JSON.stringify(appliedFilters)
          });
        }
      }

      params = Ember.assign(params, filters);
      const keywordsPromise = this.store.query('keyword', params);
      return keywordsPromise.then(keywords => {
        this.set('keywords', keywords);
        this.set('initialKeywordLoadingDone', true);
        this.set('keywordsLoading', false);
      });
    },

    pageChanged: Ember.observer('page', function () {
      if (this.get('model.isLoaded')) {
        Ember.run.once(this, 'fetchKeywords');
      }
    }),
    limitChanged: Ember.observer('limit', function () {
      if (!this.allowSortObserver) return;

      if (this.get('model.isLoaded')) {
        this.set('page', 1);
        Ember.run.once(this, 'fetchKeywords');
      }
    }),
    sortingChanged: Ember.observer('sort', 'direction', function () {
      if (!this.allowSortObserver) return;

      if (this.get('model.isLoaded')) {
        this.set('page', 1);
        Ember.run.once(this, 'fetchKeywords');
      }
    })
  });

  _exports.default = _default;
});