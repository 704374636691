define("nightwatch-web/mixins/keywords-sort-handling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEYWORD_PATHS = {
    url: 'dashboard.url.keywords',
    dynamicView: 'dashboard.dynamic-view.keywords',
    urlDynamicView: 'dashboard.url.dynamic-view',
    newDynamicView: 'dashboard.dynamic-views.new',
    newUrlDynamicView: 'dashboard.url.dynamic-views.new'
  };

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    sortKeywords: Ember._action(function (resource, scope, field, direction) {
      const args = [KEYWORD_PATHS[scope], resource, {
        queryParams: {
          page: 1,
          direction,
          sort: field
        }
      }].compact();
      this.router.transitionTo(...args);
    })
  });

  _exports.default = _default;
});