define("nightwatch-web/routes/dashboard/url/backlinks/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlBacklinksNewRoute extends Ember.Route {
    activate() {
      window.scrollTo(0, 0);
    }

    model() {
      return this.modelFor('dashboard.url');
    }

  }

  _exports.default = DashboardUrlBacklinksNewRoute;
});