define("nightwatch-web/components/site-audit/boolean-icon", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.textFirst}}
    {{#if this.text}}
      <span
        data-test-text
        class="site-audit-boolean-icon__text site-audit-boolean-icon__text--first">
        {{this.text}}
      </span>
    {{/if}}
    <i class="fa fa-{{this.faIcon}} status-icon-{{this.statusIcon}}"></i>
  {{else}}
    <i class="fa fa-{{this.faIcon}} status-icon-{{this.statusIcon}}"></i>
    {{#if this.text}}
      <span data-test-text class="site-audit-boolean-icon__text">{{this.text}}</span>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "J3JNBOFo",
    "block": "[[[41,[30,0,[\"textFirst\"]],[[[41,[30,0,[\"text\"]],[[[1,\"    \"],[10,1],[14,0,\"site-audit-boolean-icon__text site-audit-boolean-icon__text--first\"],[12],[1,\"\\n      \"],[1,[30,0,[\"text\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"i\"],[15,0,[29,[\"fa fa-\",[30,0,[\"faIcon\"]],\" status-icon-\",[30,0,[\"statusIcon\"]]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"i\"],[15,0,[29,[\"fa fa-\",[30,0,[\"faIcon\"]],\" status-icon-\",[30,0,[\"statusIcon\"]]]]],[12],[13],[1,\"\\n\"],[41,[30,0,[\"text\"]],[[[1,\"    \"],[10,1],[14,0,\"site-audit-boolean-icon__text\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\"]]",
    "moduleName": "nightwatch-web/components/site-audit/boolean-icon.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'span',
    classNames: ['status-icon-area', 'site-audit-boolean-icon'],
    trueText: '',
    falseText: '',
    reverse: false,
    isValuePositive: (0, _emberAwesomeMacros.conditional)('reverse', (0, _emberAwesomeMacros.not)('value'), 'value'),
    faIcon: (0, _emberAwesomeMacros.conditional)('isValuePositive', (0, _emberAwesomeMacros.raw)('check'), (0, _emberAwesomeMacros.raw)('times')),
    statusIcon: (0, _emberAwesomeMacros.conditional)('isValuePositive', (0, _emberAwesomeMacros.raw)('ok'), (0, _emberAwesomeMacros.raw)('bad')),
    text: Ember.computed('isValuePositive', 'trueText', 'falseText', 'reverse', function () {
      if (this.reverse) {
        if (this.isValuePositive) {
          return this.falseText;
        } else {
          return this.trueText;
        }
      } else {
        if (this.isValuePositive) {
          return this.trueText;
        } else {
          return this.falseText;
        }
      }
    })
  }));

  _exports.default = _default;
});