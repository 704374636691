define("nightwatch-web/templates/settings/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBbaHvJs",
    "block": "[[[1,[28,[35,0],[\"Billing\"],null]],[1,\"\\n\\n\"],[10,\"h2\"],[14,0,\"nw-settings__subtitle\"],[12],[1,\"Your current plan\"],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@customerPortalButtonText\"],[\"Manage Stripe Billing\"]],null],[1,\"\\n\\n\"],[41,[51,[30,0,[\"session\",\"user\",\"stripeCustomer\"]]],[[[1,\"  \"],[10,\"hr\"],[14,0,\"nw-form__separator\"],[12],[13],[1,\"\\n\\n  \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],null]],[],false,[\"page-title\",\"billing/nw-current-plan\",\"unless\",\"billing/info-form\"]]",
    "moduleName": "nightwatch-web/templates/settings/billing.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});