define("nightwatch-web/templates/plan-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A/A1+BTN",
    "block": "[[[1,[28,[35,0],[\"Plans\"],null]],[1,\"\\n\"],[1,[28,[35,0],[[30,0,[\"plan\",\"displayName\"]]],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"first\",\"second\"],[[[[8,[39,3],null,[[\"@route\"],[\"plans\"]],[[\"default\"],[[[[1,\"Plans\"]],[]]]]]],[]],[[[1,[30,0,[\"plan\",\"displayName\"]]]],[]]]]],[1,\"\\n\\n\"],[8,[39,4],[[24,0,\"nw-page-content--single plan-success\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h1\"],[14,0,\"with-bottom-border\"],[12],[1,\"Subscribe\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Thank you! You successfully subscribed to \"],[10,\"strong\"],[12],[1,[30,0,[\"plan\",\"displayName\"]]],[1,\" plan\"],[13],[1,\".\"],[13],[1,\"\\n  \"],[10,2],[14,0,\"gray\"],[12],[1,\"Enter your billing details (for invoices):\"],[13],[1,\"\\n\\n  \"],[8,[39,5],null,[[\"@afterSave\",\"@skipSave\",\"@hideEmailSection\"],[[30,0,[\"afterSave\"]],true,true]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"page-title\",\"nw-navbar\",\"nw-page-location-banner\",\"link-to\",\"nw-page-content\",\"billing/info-form\"]]",
    "moduleName": "nightwatch-web/templates/plan-success.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});