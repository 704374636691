define("nightwatch-web/components/contextual-panel/serp-preview/live/featured-snippet-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-serps__result-placeholder"></div>
  <div class="nw-serps__result-placeholder"></div>
  <div class="nw-serps__result-placeholder"></div>
  <div class="nw-serps__result-placeholder nw-serps__result-placeholder--short"></div>
  <div class="nw-serps__featured-result-title">
    <a href={{this.result.url}} target="_blank" rel="noopener noreferrer" class="nw-serps__featured-result-link">{{this.result.title}}</a>
  </div>
  <div class="nw-serps__featured-result-href">{{this.result.url}}</div>
  <i class="nw-serps__featured-result-icon fa fa-quote-right">
    <NwTooltip @placement="left">Featured Snippet</NwTooltip>
  </i>
  
  */
  {
    "id": "8VvmP7yg",
    "block": "[[[10,0],[14,0,\"nw-serps__result-placeholder\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__result-placeholder\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__result-placeholder\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__result-placeholder nw-serps__result-placeholder--short\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__featured-result-title\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"result\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"nw-serps__featured-result-link\"],[12],[1,[30,0,[\"result\",\"title\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__featured-result-href\"],[12],[1,[30,0,[\"result\",\"url\"]]],[13],[1,\"\\n\"],[10,\"i\"],[14,0,\"nw-serps__featured-result-icon fa fa-quote-right\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@placement\"],[\"left\"]],[[\"default\"],[[[[1,\"Featured Snippet\"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"nw-tooltip\"]]",
    "moduleName": "nightwatch-web/components/contextual-panel/serp-preview/live/featured-snippet-result.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['nw-serps__featured-result'],
    classNameBindings: ['result.isMatching:nw-serps__result--highlighted'],
    result: null
  }));

  _exports.default = _default;
});