define("nightwatch-web/services/graphs/graph-utils", ["exports", "randomcolor", "nightwatch-web/constants/graph-colors", "nightwatch-web/helpers/titleize", "nightwatch-web/helpers/friendly-url", "nightwatch-web/utils/universal-graph-config", "lodash-es/difference"], function (_exports, _randomcolor, _graphColors, _titleize, _friendlyUrl, _universalGraphConfig, _difference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GraphUtilsService = (_dec = Ember.inject.service('graphs/graph-data-transforms'), (_class = class GraphUtilsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "graphDataTransforms", _descriptor, this);
    }

    nextColor(availableColors, usedColors) {
      const downcaseFn = function downcase(str) {
        return str.toLowerCase();
      };

      const diff = (0, _difference.default)(_graphColors.default.map(downcaseFn), usedColors.map(downcaseFn));
      const color = diff.length > 0 ? diff[0] : (0, _randomcolor.default)();
      return color;
    }

    exportPdf(chartObject, filename) {
      chartObject.exportChart({
        type: 'application/pdf',
        filename: filename,
        resources: {
          js: "var img = document.createElement('img'); img.src = 'https://api.nightwatch.io/images/nightwatch-email-logo.png'; img.style.position = 'absolute'; img.style.zIndex = 9999999; document.body.appendChild(img);"
        }
      });
    }

    buildSeriesGraphTitle(series) {
      const {
        displayName,
        keywordModel,
        competitorUrl,
        url,
        dynamicViewName,
        backlinkViewName
      } = series;
      let name = (0, _titleize.titleize)(displayName);

      if (keywordModel && keywordModel.get('isLoaded')) {
        // DO NOT LET the codemod to fix this to the new getters. Query is a computed property on a
        // proxy, so it needs to be accessed via Ember's getter
        const {
          query,
          mobile,
          fullLocationShortened
        } = keywordModel.getProperties('query', 'mobile', 'fullLocationShortened');
        name += ` | ${query}`;
        const deviceDisplay = mobile ? '📱' : '🖥';
        name += ` (${deviceDisplay} ${fullLocationShortened})`;
      }

      if (competitorUrl) {
        name += ` | ${competitorUrl}`;
      } else if (url) {
        name += ` | ${(0, _friendlyUrl.friendlyUrl)(url)}`;
      }

      if (dynamicViewName) {
        name += ` | ${dynamicViewName}`;
      }

      if (backlinkViewName) {
        name += ` | ${backlinkViewName}`;
      }

      return name;
    }

    transformSeriesForChart(series) {
      const {
        seriesId,
        timestampedSeries,
        yAxis,
        color,
        keywordId,
        seriesType,
        zIndex,
        isLoading,
        groupingApproximation
      } = series;
      const name = this.buildSeriesGraphTitle(series);
      const data = timestampedSeries;
      const cursor = keywordId ? 'pointer' : 'default';
      const className = keywordId ? _universalGraphConfig.SERIES_WITH_SERP_PREVIEW_CLASS : '';
      const type = seriesType;
      const approximation = groupingApproximation;
      const dataGrouping = {
        approximation
      };
      return {
        id: seriesId,
        seriesId,
        name,
        data,
        yAxis,
        color,
        cursor,
        className,
        type,
        zIndex,
        isLoading,
        dataGrouping
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "graphDataTransforms", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GraphUtilsService;
});