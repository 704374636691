define("nightwatch-web/helpers/short-date", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.shortDate = void 0;

  const shortDate = params => (0, _dateFns.format)(new Date(params[0]), 'MM-dd-yyyy');

  _exports.shortDate = shortDate;

  var _default = Ember.Helper.helper(shortDate);

  _exports.default = _default;
});