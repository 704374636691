define("nightwatch-web/utils/uuid-generator", ["exports", "ember-uuid/utils/uuid-generator"], function (_exports, _uuidGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.default;
    }
  });
  Object.defineProperty(_exports, "v4", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.v4;
    }
  });
  Object.defineProperty(_exports, "v1", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.v1;
    }
  });
  Object.defineProperty(_exports, "parse", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.parse;
    }
  });
  Object.defineProperty(_exports, "unparse", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.unparse;
    }
  });
});