define("nightwatch-web/components/site-audit/progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    {{#if @crawlingSession.isPendingOrProcessing}}
      <span class="progress-message">
        {{#if @crawlingSession.isPending}}
          Pending ...
        {{else}}
          Crawling...
        {{/if}}
      </span>
  
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped active"
          role="progressbar"
          style="width: 100%"
        >
        </div>
      </div>
    {{else}}
      {{#if @crawlingSession.forcefullyTerminated}}
        <SiteAudit::CrawlingSessionEndReason
          @reason={{@crawlingSession.endReason}}
        />
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "pxuy4JJ7",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,2,[\"isPendingOrProcessing\"]],[[[1,\"    \"],[10,1],[14,0,\"progress-message\"],[12],[1,\"\\n\"],[41,[30,2,[\"isPending\"]],[[[1,\"        Pending ...\\n\"]],[]],[[[1,\"        Crawling...\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"progress\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"progress-bar progress-bar-striped active\"],[14,\"role\",\"progressbar\"],[14,5,\"width: 100%\"],[12],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,2,[\"forcefullyTerminated\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@reason\"],[[30,2,[\"endReason\"]]]],null],[1,\"\\n\"]],[]],null]],[]]],[13]],[\"&attrs\",\"@crawlingSession\"],false,[\"if\",\"site-audit/crawling-session-end-reason\"]]",
    "moduleName": "nightwatch-web/components/site-audit/progress.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});