define("nightwatch-web/serializers/serp-preview", ["exports", "@ember-data/serializer/rest", "nightwatch-web/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: '_id',
    attrs: {
      organic: {
        deserialize: 'records'
      },
      localPack: {
        deserialize: 'records'
      },
      knowledgePanel: {
        deserialize: 'records'
      },
      featuredSnippet: {
        deserialize: 'records'
      },
      carousel: {
        deserialize: 'records'
      }
    },

    normalize(modelClass, hash, ...rest) {
      ['organic', 'local_pack', 'knowledge_panel', 'featured_snippet', 'carousel'].forEach(key => this.normalizeResultsPayload(key, hash));
      delete hash.serp.results;
      hash = _objectSpread(_objectSpread({}, hash), hash.serp);
      delete hash.serp;
      return this._super(modelClass, hash, ...rest);
    },

    normalizeResultsPayload(key, hash) {
      const results = hash.serp.results || {};
      hash[key] = (results[key] || []).filter(result => !!result).map((result, index) => {
        result.id = `${hash._id}_${index}`;
        return result;
      });
    }

  });

  _exports.default = _default;
});