define("nightwatch-web/components/stripe-signup-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (service "stripe") as |stripe|}}
    <SignupForm
      @signupToken={{@signupToken}}
      @planId={{@planId}}
      @paymentGateway={{@paymentGateway}}
      {{!--
        Do not redirect subuser or whitelabel signups to Stripe
        Both of which will have a signupToken argument
      --}}
      @onSuccess={{unless @signupToken (perform stripe.redirectToCheckout)}}
      ...attributes
      {{did-insert (perform stripe.loadStripeScripts)}}
    />
  {{/let}}
  
  */
  {
    "id": "Qz2MgRM+",
    "block": "[[[44,[[28,[37,1],[\"stripe\"],null]],[[[1,\"  \"],[8,[39,2],[[17,2],[4,[38,5],[[28,[37,4],[[30,1,[\"loadStripeScripts\"]]],null]],null]],[[\"@signupToken\",\"@planId\",\"@paymentGateway\",\"@onSuccess\"],[[30,3],[30,4],[30,5],[52,[51,[30,3]],[28,[37,4],[[30,1,[\"redirectToCheckout\"]]],null]]]],null],[1,\"\\n\"]],[1]]]],[\"stripe\",\"&attrs\",\"@signupToken\",\"@planId\",\"@paymentGateway\"],false,[\"let\",\"service\",\"signup-form\",\"unless\",\"perform\",\"did-insert\"]]",
    "moduleName": "nightwatch-web/components/stripe-signup-form.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});