define("nightwatch-web/routes/dashboard/url/report", ["exports", "nightwatch-web/constants/time-periods", "date-fns"], function (_exports, _timePeriods, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlReportRoute extends Ember.Route {
    activate() {
      window.scrollTo(0, 0);
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('url', this.modelFor('dashboard.url'));
      controller.set('whitelabelLogos', this.store.findAll('whitelabel-logo'));
    }

    afterModel(report) {
      if (report.get('scheduled')) {
        report.set('end_time', new Date());
        const reportInterval = String(report.get('report_interval'));

        if (reportInterval === _timePeriods.MONTHLY.toString()) {
          report.set('start_time', (0, _dateFns.sub)(new Date(), {
            days: 30
          }));
        } else if (reportInterval === _timePeriods.WEEKLY.toString()) {
          report.set('start_time', (0, _dateFns.sub)(new Date(), {
            days: 7
          }));
        }
      }

      if (!report.get('recipients')) {
        report.set('recipients', ['']);
      }

      report.setEmailParams();
    }

    renderTemplate() {
      this.render({
        into: 'dashboard'
      });
    }

  }

  _exports.default = DashboardUrlReportRoute;
});