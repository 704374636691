define("nightwatch-web/components/graph/graph-series-provider", ["exports", "nightwatch-web/utils/universal-graph-config", "nightwatch-web/constants/graph-colors", "lodash-es/isEmpty", "lodash-es/flatten", "lodash-es/concat", "date-fns"], function (_exports, _universalGraphConfig, _graphColors, _isEmpty, _flatten, _concat, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span {{in-viewport onEnter=this.didEnterViewport}}></span>
  {{yield (hash
    url=this.url
    globalDynamicView=this.globalDynamicView
    urlGroup=this.urlGroup
    graph=this.graph
    chartObject=this.chartObject
    setChartObject=(fn (mut this.chartObject))
    grouping=this.grouping
    options=this.options
    allLoadedSeries=this.allLoadedSeries
    selectedSeries=this.selectedSeries
    selectedSeriesWithData=this.selectedSeriesWithData
    dateRangeType=this.dateRangeType
    dateFrom=this.dateFrom
    dateTo=this.dateTo
    isLoading=this.isLoading
    loadingInitially=this.loadingInitially
    currentSeriesContainer=this.currentSeriesContainer
    keywordSearch=this.keywordSearch
    isReadyToLoadSeries=this.isReadyToLoadSeries
    availableGroupings=this.availableGroupings
    isEmptyGraph=this.isEmptyGraph
    hasEnteredViewport=this.hasEnteredViewport
    saveGraph=this.saveGraph
    exportPdf=this.exportPdf
    toggleBacklinkView=this.toggleBacklinkView
    toggleWebsiteCompetitor=this.toggleWebsiteCompetitor
    toggleDynamicView=this.toggleDynamicView
    triggerRerender=this.triggerRerender
    changeGrouping=this.changeGrouping
    toggleKeyword=this.toggleKeyword
    onKeywordSearchChange=this.onKeywordSearchChange
    toggleSelectedSeries=this.toggleSelectedSeries
    onTabSwitch=this.onTabSwitch
    setDateRange=this.setDateRange
    changeColor=this.changeColor)}}
  
  */
  {
    "id": "qj65vyit",
    "block": "[[[11,1],[4,[38,0],null,[[\"onEnter\"],[[30,0,[\"didEnterViewport\"]]]]],[12],[13],[1,\"\\n\"],[18,1,[[28,[37,2],null,[[\"url\",\"globalDynamicView\",\"urlGroup\",\"graph\",\"chartObject\",\"setChartObject\",\"grouping\",\"options\",\"allLoadedSeries\",\"selectedSeries\",\"selectedSeriesWithData\",\"dateRangeType\",\"dateFrom\",\"dateTo\",\"isLoading\",\"loadingInitially\",\"currentSeriesContainer\",\"keywordSearch\",\"isReadyToLoadSeries\",\"availableGroupings\",\"isEmptyGraph\",\"hasEnteredViewport\",\"saveGraph\",\"exportPdf\",\"toggleBacklinkView\",\"toggleWebsiteCompetitor\",\"toggleDynamicView\",\"triggerRerender\",\"changeGrouping\",\"toggleKeyword\",\"onKeywordSearchChange\",\"toggleSelectedSeries\",\"onTabSwitch\",\"setDateRange\",\"changeColor\"],[[30,0,[\"url\"]],[30,0,[\"globalDynamicView\"]],[30,0,[\"urlGroup\"]],[30,0,[\"graph\"]],[30,0,[\"chartObject\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"chartObject\"]]],null]],null],[30,0,[\"grouping\"]],[30,0,[\"options\"]],[30,0,[\"allLoadedSeries\"]],[30,0,[\"selectedSeries\"]],[30,0,[\"selectedSeriesWithData\"]],[30,0,[\"dateRangeType\"]],[30,0,[\"dateFrom\"]],[30,0,[\"dateTo\"]],[30,0,[\"isLoading\"]],[30,0,[\"loadingInitially\"]],[30,0,[\"currentSeriesContainer\"]],[30,0,[\"keywordSearch\"]],[30,0,[\"isReadyToLoadSeries\"]],[30,0,[\"availableGroupings\"]],[30,0,[\"isEmptyGraph\"]],[30,0,[\"hasEnteredViewport\"]],[30,0,[\"saveGraph\"]],[30,0,[\"exportPdf\"]],[30,0,[\"toggleBacklinkView\"]],[30,0,[\"toggleWebsiteCompetitor\"]],[30,0,[\"toggleDynamicView\"]],[30,0,[\"triggerRerender\"]],[30,0,[\"changeGrouping\"]],[30,0,[\"toggleKeyword\"]],[30,0,[\"onKeywordSearchChange\"]],[30,0,[\"toggleSelectedSeries\"]],[30,0,[\"onTabSwitch\"]],[30,0,[\"setDateRange\"]],[30,0,[\"changeColor\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"in-viewport\",\"yield\",\"hash\",\"fn\",\"mut\"]]",
    "moduleName": "nightwatch-web/components/graph/graph-series-provider.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: '',
    graphSeriesFetcher: Ember.inject.service(),
    graphResourceLoadingUtils: Ember.inject.service('graphs/graph-resource-loading-utils'),
    graphLoadingUtils: Ember.inject.service('graphs/graph-loading-utils'),
    graphSeriesStatsLoading: Ember.inject.service('graphs/graph-series-traffic-stats-loading'),
    graphUtils: Ember.inject.service('graphs/graph-utils'),
    store: Ember.inject.service(),
    metrics: Ember.inject.service(),
    currentSeriesContainer: Ember.computed.reads('seriesContainers.firstObject'),
    graphDataTransforms: Ember.inject.service('graphs/graph-data-transforms'),
    stackedColumnSeries: Ember.computed.filterBy('selectedSeries', 'isStackedColumnSeries'),
    loadInViewport: false,
    hasEnteredViewport: false,
    dateRangeType: '30_days',

    // Fallback in case a function is not passed to component as prop
    onGraphSettingsUpdate() {},

    init() {
      this._super(...arguments);

      this.setProperties({
        allLoadedSeries: [],
        selectedSeries: [],
        chartObject: {},
        availableGroupings: Object.values(_universalGraphConfig.GROUPING)
      });
    },

    didDatesChange(dateFrom, dateTo) {
      return this.cachedDateFrom != dateFrom || this.cachedDateTo != dateTo;
    },

    didGraphChange(graphId) {
      return this.cachedGraphId == graphId;
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.loadSeries();
    },

    loadSeries() {
      if (!this.isReadyToLoadSeries) return; // This date change detection is important for graphs in pinned items.
      // Dates are passed into this component, while in other places the
      // internal setDateRange action is called. If we detect date change,
      // we have to call loadGraphSeries(isRefresh = true)

      const {
        dateFrom,
        dateTo,
        graph,
        chartObject
      } = this;
      this.set('cachedDateFrom', dateFrom);
      this.set('cachedDateTo', dateTo);
      this.set('cachedGraphId', graph === null || graph === void 0 ? void 0 : graph.id);

      if ((0, _isEmpty.default)(chartObject) || this.didGraphChange(graph === null || graph === void 0 ? void 0 : graph.id)) {
        this.loadGraphSeries(false);
      } else if (this.didDatesChange(dateFrom, dateTo)) {
        this.loadGraphSeries(true);
      }
    },

    viewportReady: Ember.computed('loadInViewport', 'hasEnteredViewport', function () {
      return this.loadInViewport ? this.hasEnteredViewport : true;
    }),
    isReadyToLoadSeries: Ember.computed('viewportReady', 'url.id', 'globalDynamicView.id', 'urlGroup.id', function () {
      return this.viewportReady && (this.get('url.id') || this.get('globalDynamicView.id') || this.get('urlGroup.id'));
    }),
    extractedStackedColumnSeries: Ember.computed('stackedColumnSeries.[]', 'selectedSeries.@each.active', function () {
      return (0, _flatten.default)(this.stackedColumnSeries.map(s => this.graphDataTransforms.extractStackedColumnSeries(s)));
    }),
    selectedSeriesWithData: Ember.computed('allLoadedSeries.[]', 'selectedSeries.[]', 'selectedSeries.@each.active', function () {
      const stackedColumnSeries = this.extractedStackedColumnSeries;
      const basicSeries = this.selectedSeries.filterBy('isBasicSeries');
      return (0, _concat.default)(stackedColumnSeries, basicSeries).filterBy('active');
    }),
    isEmptyGraph: Ember.computed('selectedSeriesWithData.[]', function () {
      return (this.selectedSeriesWithData || []).every(s => {
        return s && s.series != null && s.series.length == 0;
      });
    }),

    loadGraphSeries(isRefresh = false) {
      if (!isRefresh) {
        this.set('loadingInitially', true);
      }

      const graph = this.graph;
      this.set('isLoading', true); // 1. Set selected series

      let selectedSeries;
      this.set('allLoadedSeries', Ember.A());

      if (isRefresh) {
        selectedSeries = this.selectedSeries;
      } else {
        if (!graph) {
          this.set('isLoading', false);
          return;
        }

        selectedSeries = this.graphDataTransforms.emberizeSeries(graph.get('graph_data.series'));
        this.set('selectedSeries', Ember.A(selectedSeries));
      }

      selectedSeries.forEach(s => s.set('isLoading', true)); // 2. Fetch data needed
      // 2. 1 First, gather all url_ids and keyword_ids, dynamic_view_ids, backlink_view_ids

      const toIds = (selectedSeries, name) => selectedSeries.mapBy(name).compact().map(String).uniq();

      const urlIds = toIds(selectedSeries, 'urlId');
      const competitorIds = toIds(selectedSeries.filterBy('urlId'), 'competitorId');
      const keywordIds = toIds(selectedSeries, 'keywordId');
      const dynamicViewIds = toIds(selectedSeries, 'dynamicViewId');
      const backlinkViewIds = toIds(selectedSeries, 'backlinkViewId');
      const urlGroupIds = toIds(selectedSeries, 'urlGroupId'); // 2. 2 Fetch data for these urls, keywords and views

      let dateFrom, dateTo;

      if (this.dateFrom && this.dateTo) {
        ({
          dateFrom,
          dateTo
        } = this.getProperties('dateFrom', 'dateTo'));
      } else {
        ({
          dateFrom,
          dateTo
        } = graph.extractDates());
      }

      this.setProperties({
        dateFrom: dateFrom,
        dateTo: dateTo
      });
      const promise = this.graphSeriesFetcher.fetchGraphSeries({
        url_ids: urlIds,
        keyword_ids: keywordIds,
        dynamic_view_ids: dynamicViewIds,
        url_group_ids: urlGroupIds,
        competitor_ids: competitorIds,
        dynamic_view_url_ids: dynamicViewIds,
        date_from: dateFrom,
        date_to: dateTo
      }); // Here load all the URL series together with keywords, dynamic views

      promise.then(data => {
        if (this.isDestroying || this.isDestroyed) return;
        this.set('loadingInitially', false);
        this.set('isLoading', false);
        this.loadDataToSeriesContainers(seriesContainers, data, this.pushToAllLoadedSeries.bind(this)); // Need this to calculate traffic stats

        const dateFrom = this.dateFrom;
        const dateTo = this.dateTo;

        if (this.currentSeriesContainer) {
          this.currentSeriesContainer.setProperties({
            dateFrom,
            dateTo
          });
          this.graphSeriesStatsLoading.loadStats(this.currentSeriesContainer, this.selectedSeries);
        } // backlinkView series are loaded async


        selectedSeries.filter(s => s.backlinkViewId == null).forEach(s => {
          s.set('isLoading', false);
          const loadedSeries = this.allLoadedSeries.findBy('seriesId', s.get('seriesId'));

          if (loadedSeries) {
            s.set('series', loadedSeries.get('series'));
          }
        });
      }); // 3. Transform fetched data into observable objects for series selector and chart
      // Client Schema:
      // seriesContainers = [
      //   {
      //     url_id: 10,
      //     url: "rt.com",
      //     websiteSeries: [{ seriesName: "Average Rank", url: "rt.com", series: [[]] }, ...],
      //     websiteCompetitorSeries: [{ seriesName: "Avg Rank Competitor abs.com", url: "rt.com", url_id: 10, competitor_id: 10, series: [[]] }, ...],
      //     keywordSeries: [{ seriesName: "Organic Position: goji berries", url: "rt.com", url_id: 10, keyword_id: 20, series: [[]] }, ...]
      //     keywordCompetitorSeries: [{ seriesName: "Competitor Position: adidas shoes", url: "rt.com", url_id: 10, keyword_id: 20, competitorId: 30, series: [[]] }, ...]
      //   }, ...
      // ]

      const seriesContainers = Ember.A();
      this.set('seriesContainers', seriesContainers); // Here load all the backlink series (async, because they are slow)

      const backlinkSeriesPromise = this.graphSeriesFetcher.fetchGraphSeries({
        backlink_view_ids: backlinkViewIds,
        date_from: dateFrom,
        date_to: dateTo,
        url_id_for_all_backlinks: graph.get('url.id') || this.get('url.id')
      });
      backlinkSeriesPromise.then(data => {
        if (this.isDestroying || this.isDestroyed) return;
        this.loadDataToSeriesContainers(seriesContainers, data, this.pushToAllLoadedSeries.bind(this));
        selectedSeries.filter(s => Ember.isPresent(s.backlinkViewId)).forEach(s => {
          s.set('isLoading', false);
          const loadedSeries = this.allLoadedSeries.findBy('seriesId', s.get('seriesId'));

          if (loadedSeries) {
            s.set('series', loadedSeries.get('series'));
          }
        });
      });
    },

    pushToAllLoadedSeries(seriesObj) {
      this.allLoadedSeries.pushObject(seriesObj);
    },

    loadDataToSeriesContainers(seriesContainers, data, pushToAllLoadedSeries) {
      if (this.get('url.id')) {
        this.graphLoadingUtils.loadDataToSeriesContainersForUrl(seriesContainers, data, this.url, pushToAllLoadedSeries);
      } else if (this.get('globalDynamicView.id')) {
        this.graphLoadingUtils.loadDataToSeriesContainersForGlobalDynamicView(seriesContainers, data, this.globalDynamicView, pushToAllLoadedSeries);
      } else if (this.get('urlGroup.id')) {
        this.graphLoadingUtils.loadDataToSeriesContainersForUrlGroup(seriesContainers, data, this.urlGroup, pushToAllLoadedSeries);
      }
    },

    fetchKeywordsBySearch(keywordSearch) {
      const seriesContainer = this.currentSeriesContainer;
      const keywordParams = {
        page: 1,
        sort: 'query',
        direction: 'asc',
        limit: 100,
        url_id: seriesContainer.get('urlId'),
        search: keywordSearch
      };
      this.loadKeywordsToSeriesContainer(seriesContainer, keywordParams);
    },

    loadKeywordsToSeriesContainer(seriesContainer, keywordParams) {
      this.store.query('keyword', keywordParams).then(keywords => {
        const k = keywords.map(k => Ember.Object.create(Object.assign({
          id: parseInt(k.id),
          urlId: keywordParams.url_id
        }, k.getProperties(['position', 'query', 'engine', 'engineImgPath', 'mobile', 'fullLocation']))));
        seriesContainer.set('keywords', Ember.A(k));
      });
    },

    afterColorChange() {
      this.triggerRerender();
      const {
        graph,
        selectedSeries
      } = this;
      this.serializeSelectedSeriesIntoGraph(graph, selectedSeries);
      this.onGraphSettingsUpdate();
    },

    serializeSelectedSeriesIntoGraph(graph, selectedSeries) {
      graph.set('graph_data.series', this.graphDataTransforms.serializeSelectedSeries(selectedSeries));
    },

    didEnterViewport: Ember._action(function () {
      if (!this.loadInViewport || this.hasEnteredViewport) return;
      this.set('hasEnteredViewport', true);
      this.loadSeries();
    }),
    onTabSwitch: Ember._action(function (tab) {
      const currentSeriesContainer = this.currentSeriesContainer;
      if (!currentSeriesContainer) return;
      const urlId = this.get('url.id');
      this.graphResourceLoadingUtils.loadResource(tab, currentSeriesContainer, urlId);
    }),
    saveGraph: Ember._action(function (graph) {
      const selectedSeries = this.selectedSeries;
      this.serializeSelectedSeriesIntoGraph(graph, selectedSeries);
      graph.save().then(() => {
        if (this.afterNewGraphSave) {
          this.afterNewGraphSave(graph);
        }

        this.notifications.success('Graph saved.', {
          autoClear: true,
          clearDuration: 2500
        });
        this.metrics.trackEvent({
          event: 'Saved Graph',
          name: graph.get('name'),
          num_dimensions: graph.get('graph_data.series.length')
        });
      });
    }),
    exportPdf: Ember._action(function () {
      const filename = this.get('model.name') || this.get('url.friendlyUrl');
      const chartObject = this.chartObject;
      this.graphUtils.exportPdf(chartObject, filename);
    }),
    changeGrouping: Ember._action(function (grouping) {
      this.set('graph.graph_data.grouping', grouping);
    }),
    toggleBacklinkView: Ember._action(function (backlinkView) {
      const seriesContainers = this.seriesContainers;
      const seriesContainer = seriesContainers.findBy('urlId', backlinkView.get('urlId'));
      const loadedSeries = seriesContainer.get('backlinkViewSeries').findBy('backlinkViewId', backlinkView.get('id'));

      if (loadedSeries) {
        backlinkView.set('isOpened', !backlinkView.get('isOpened'));
      } else {
        backlinkView.set('isLoadingSeries', true);
        const isAllBacklinks = backlinkView.get('id') == -1;
        const params = {
          backlink_view_ids: [backlinkView.get('id')],
          date_from: this.dateFrom,
          date_to: this.dateTo
        };

        if (isAllBacklinks) {
          params['url_id_for_all_backlinks'] = backlinkView.get('urlId');
        }

        const promise = this.graphSeriesFetcher.fetchGraphSeries(params);
        promise.then(data => {
          this.loadDataToSeriesContainers(seriesContainers, data, this.pushToAllLoadedSeries.bind(this));
          backlinkView.set('isLoadingSeries', false);
          backlinkView.set('isOpened', true);
        });
      }
    }),
    toggleWebsiteCompetitor: Ember._action(function (competitor) {
      const seriesContainers = this.seriesContainers;
      const seriesContainer = seriesContainers.findBy('urlId', competitor.get('urlId'));
      const loadedSeries = seriesContainer.get('websiteCompetitorSeries').findBy('competitorId', competitor.get('id'));

      if (loadedSeries) {
        competitor.set('isOpened', !competitor.get('isOpened'));
      } else {
        competitor.set('isLoadingSeries', true);
        const promise = this.graphSeriesFetcher.fetchGraphSeries({
          competitor_ids: [competitor.get('id')],
          date_from: this.dateFrom,
          date_to: this.dateTo
        });
        promise.then(data => {
          this.loadDataToSeriesContainers(seriesContainers, data, this.pushToAllLoadedSeries.bind(this));
          competitor.set('isLoadingSeries', false);
          competitor.set('isOpened', true);
        });
      }
    }),
    toggleDynamicView: Ember._action(function (dynamicView) {
      const seriesContainers = this.seriesContainers;
      const seriesContainer = seriesContainers.findBy('urlId', dynamicView.get('urlId'));
      const loadedSeries = seriesContainer.get('dynamicViewSeries').findBy('dynamicViewId', dynamicView.get('id'));

      if (loadedSeries) {
        dynamicView.set('isOpened', !dynamicView.get('isOpened'));
      } else {
        dynamicView.set('isLoadingSeries', true);
        const promise = this.graphSeriesFetcher.fetchGraphSeries({
          dynamic_view_ids: [dynamicView.get('id')],
          date_from: this.dateFrom,
          date_to: this.dateTo
        });
        promise.then(data => {
          this.loadDataToSeriesContainers(seriesContainers, data, this.pushToAllLoadedSeries.bind(this));
          dynamicView.set('isLoadingSeries', false);
          dynamicView.set('isOpened', true);
        });
      }
    }),
    toggleKeyword: Ember._action(function (keyword) {
      const seriesContainers = this.seriesContainers;
      const seriesContainer = seriesContainers.findBy('urlId', keyword.get('urlId'));
      const loadedSeries = seriesContainer.get('keywordSeries').findBy('keywordId', keyword.get('id'));

      if (loadedSeries) {
        keyword.set('isOpened', !keyword.get('isOpened'));
      } else {
        keyword.set('isLoadingSeries', true);
        const promise = this.graphSeriesFetcher.fetchGraphSeries({
          keyword_ids: [keyword.get('id')],
          date_from: this.dateFrom,
          date_to: this.dateTo
        });
        promise.then(data => {
          this.loadDataToSeriesContainers(seriesContainers, data, this.pushToAllLoadedSeries.bind(this));
          keyword.set('isLoadingSeries', false);
          keyword.set('isOpened', true);
        });
      }
    }),
    onKeywordSearchChange: Ember._action(function (keywordSearch) {
      this.set('keywordSearch', keywordSearch);
      Ember.run.debounce(this, 'fetchKeywordsBySearch', keywordSearch, 250);
    }),
    setDateRange: Ember._action(function ({
      type,
      start,
      end
    }) {
      const formattedDateFrom = (0, _dateFns.format)(new Date(start), 'yyyy-MM-dd');
      const formattedDateTo = (0, _dateFns.format)(new Date(end), 'yyyy-MM-dd');
      this.set('dateRangeType', type);
      this.set('dateFrom', formattedDateFrom);
      this.set('dateTo', formattedDateTo);
      this.graph.setProperties({
        date_from: start,
        date_to: end,
        relative_date_range: null
      });
      const endIsToday = end.getDay() === new Date().getDay();

      if (endIsToday) {
        const daysDiff = Math.abs((0, _dateFns.differenceInDays)((0, _dateFns.startOfDay)(new Date(start)), new Date(end)));
        let relativeRange;

        if (daysDiff === 1) {
          relativeRange = 'last_1_day';
        } else if (daysDiff === 7) {
          relativeRange = 'last_7_days';
        } else if (daysDiff === 30) {
          relativeRange = 'last_30_days';
        } else if (daysDiff === 30 * 3) {
          relativeRange = 'last_90_days';
        } else if (daysDiff === 30 * 6) {
          relativeRange = 'last_180_days';
        } else if (daysDiff === 365) {
          relativeRange = 'last_365_days';
        }

        if (relativeRange) {
          this.graph.setProperties({
            date_from: null,
            date_to: null,
            relative_date_range: relativeRange
          });
        }
      }

      this.loadGraphSeries(true);
    }),
    changeColor: Ember._action(function (series, color) {
      series.color = color; // There can be many color changes in a very short time while searching

      Ember.run.debounce(this, 'afterColorChange', 250);
    }),
    triggerRerender: Ember._action(function () {
      this.set('selectedSeries', this.selectedSeries.toArray());
    }),
    toggleSelectedSeries: Ember._action(function (series) {
      const selectedSeries = this.selectedSeries.findBy('seriesId', series.get('seriesId'));

      if (selectedSeries) {
        this.selectedSeries.removeObject(selectedSeries);
      } else {
        const limit = this.seriesLimit;

        if (limit && this.get('selectedSeries.length') >= limit) {
          return this.notifications.clearAll().error(`You can select up to ${limit} series.`, {
            autoClear: true
          });
        }

        const usedColors = this.selectedSeries.mapBy('color');
        series.set('color', this.graphUtils.nextColor(_graphColors.default, usedColors));
        series.set('active', true);
        this.selectedSeries.pushObject(series);
        this.graphSeriesStatsLoading.loadStats(this.currentSeriesContainer, this.selectedSeries);
        this.metrics.trackEvent({
          event: 'Add Dimension',
          name: series.displayName
        });
      } // to trigger rerender (is-in helper)


      this.triggerRerender();
      this.set('graph.graph_data.series', this.graphDataTransforms.serializeSelectedSeries(this.selectedSeries));
      this.onGraphSettingsUpdate();
    })
  }));

  _exports.default = _default;
});