define("nightwatch-web/components/contextual-panel/serp-preview/live/google-place-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-serps__organic-result-title">
    {{#if (and this.showTextChange this.result.titleDiff this.result.title)}}
      <DiffWords @oldText={{this.result.titleDiff}} @newText={{this.result.title}} />
    {{else}}
      {{this.result.title}}
    {{/if}}
  </div>
  {{#if this.result.description}}
    <div class="nw-serps__organic-result-description">{{this.result.description}}</div>
  {{else}}
    <div class="nw-serps__result-placeholder"></div>
  {{/if}}
  <div class="nw-serps__result-placeholder nw-serps__result-placeholder--short"></div>
  <div class="nw-serps__organic-result-stats">
    <b class="nw-serps__organic-result-rank">{{this.result.rank}}.</b>
    {{#if this.result.rankDiff}}
      <span class="nw-serps__organic-result-rankdiff nw-serps__organic-result-rankdiff--{{this.result.rankDiff.change}}">{{this.result.rankDiff.amount}}</span>
    {{/if}}
  </div>
  <div class="nw-serps__google-place">
    {{#if this.result.url}}
      <a class="nw-serps__google-place-link" href={{this.result.url}} target="_blank" rel="noopener noreferrer"><i class="fa fa-globe"></i> Website</a>
    {{else}}
      <span class="nw-serps__google-place-link"><i class="fa fa-globe"></i> Website</span>
    {{/if}}
    <span class="nw-serps__google-place-link"><i class="fa fa-location-arrow"></i> Directions</span>
  </div>
  
  */
  {
    "id": "KfhQ+CfB",
    "block": "[[[10,0],[14,0,\"nw-serps__organic-result-title\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"showTextChange\"]],[30,0,[\"result\",\"titleDiff\"]],[30,0,[\"result\",\"title\"]]],null],[[[1,\"    \"],[8,[39,2],null,[[\"@oldText\",\"@newText\"],[[30,0,[\"result\",\"titleDiff\"]],[30,0,[\"result\",\"title\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"result\",\"title\"]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[41,[30,0,[\"result\",\"description\"]],[[[1,\"  \"],[10,0],[14,0,\"nw-serps__organic-result-description\"],[12],[1,[30,0,[\"result\",\"description\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"nw-serps__result-placeholder\"],[12],[13],[1,\"\\n\"]],[]]],[10,0],[14,0,\"nw-serps__result-placeholder nw-serps__result-placeholder--short\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__organic-result-stats\"],[12],[1,\"\\n  \"],[10,\"b\"],[14,0,\"nw-serps__organic-result-rank\"],[12],[1,[30,0,[\"result\",\"rank\"]]],[1,\".\"],[13],[1,\"\\n\"],[41,[30,0,[\"result\",\"rankDiff\"]],[[[1,\"    \"],[10,1],[15,0,[29,[\"nw-serps__organic-result-rankdiff nw-serps__organic-result-rankdiff--\",[30,0,[\"result\",\"rankDiff\",\"change\"]]]]],[12],[1,[30,0,[\"result\",\"rankDiff\",\"amount\"]]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,0],[14,0,\"nw-serps__google-place\"],[12],[1,\"\\n\"],[41,[30,0,[\"result\",\"url\"]],[[[1,\"    \"],[10,3],[14,0,\"nw-serps__google-place-link\"],[15,6,[30,0,[\"result\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[10,\"i\"],[14,0,\"fa fa-globe\"],[12],[13],[1,\" Website\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"nw-serps__google-place-link\"],[12],[10,\"i\"],[14,0,\"fa fa-globe\"],[12],[13],[1,\" Website\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,1],[14,0,\"nw-serps__google-place-link\"],[12],[10,\"i\"],[14,0,\"fa fa-location-arrow\"],[12],[13],[1,\" Directions\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"and\",\"diff-words\"]]",
    "moduleName": "nightwatch-web/components/contextual-panel/serp-preview/live/google-place-result.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['nw-serps__organic-result', 'nw-serps__organic-result--google-place'],
    classNameBindings: ['result.isMatching:nw-serps__result--highlighted'],
    result: null
  }));

  _exports.default = _default;
});