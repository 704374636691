define("nightwatch-web/instance-initializers/error-reporter", ["exports", "@ember-data/adapter/error", "ember-ajax-fetch/errors"], function (_exports, _error, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    const errorReporterService = appInstance.lookup('service:error-reporter');

    const oldOnError = Ember.onerror || function () {}; // Only report the first instance of this error. Otherwise it will spam occurrences


    let removeChildReported = false;

    const ignoreRemoveChild = error => {
      if (removeChildReported) return true;

      if ((error === null || error === void 0 ? void 0 : error.message) === "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.") {
        removeChildReported = true;
        return false;
      }
    };

    const ignoreRequest = error => {
      return (error === null || error === void 0 ? void 0 : error.isAdapterError) || // Ignore Ember Data adapter errors
      (0, _errors.isFetchError)(error) || // Ignore failed request errors
      (0, _errors.isTimeoutError)(error) || // Ignore request timeouts
      (error === null || error === void 0 ? void 0 : error.message) === 'Network request failed' || (error === null || error === void 0 ? void 0 : error.message) === 'Failed to fetch' || (error === null || error === void 0 ? void 0 : error.message) === 'Ajax authorization failed';
    };

    const ignoreError = error => {
      return error instanceof _error.AbortError || (error === null || error === void 0 ? void 0 : error.name) === 'TransitionAborted' || // See https://github.com/emberjs/ember.js/issues/12505
      ignoreRequest(error) || ignoreRemoveChild(error);
    };

    const reportError = (...args) => {
      const [error] = args;
      if (ignoreError(error)) return;
      if (errorReporterService.enabled) errorReporterService.error(...args);
      throw error;
    };

    Ember.onerror = (...args) => {
      oldOnError(...args);
      reportError(...args);
    }; // Fired when ember's internal promise implementation throws an unhandled exception


    Ember.RSVP.on('error', reason => reportError(reason));
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});