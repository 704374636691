define("nightwatch-web/utils/dom/get-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getElement = target => {
    if (typeof target === 'string') {
      return document.querySelector(target);
    } else if ((target === null || target === void 0 ? void 0 : target.nodeType) === Node.ELEMENT_NODE) {
      return target;
    }

    throw `getElement - invalid target type ${typeof target}`;
  };

  var _default = getElement;
  _exports.default = _default;
});