define("nightwatch-web/components/site-audit/page-depth-chart-container", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="distribution-container">
    <div class="distribution-content">
      <Graph::NwPieChart @series={{this.chartData}} />
    </div>
    <div class="distribution-legend">
      {{#each this.chartData as |series|}}
        <OverviewLegendItem
          data-test-legend-item
          @data={{series}}
          @comparisonEnabled={{this.comparisonEnabled}}
          @clickLegendItem={{this.clickLegendItem}}
        />
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "mYsaP822",
    "block": "[[[10,0],[14,0,\"distribution-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"distribution-content\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@series\"],[[30,0,[\"chartData\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"distribution-legend\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"chartData\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@data\",\"@comparisonEnabled\",\"@clickLegendItem\"],[[30,1],[30,0,[\"comparisonEnabled\"]],[30,0,[\"clickLegendItem\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"series\"],false,[\"graph/nw-pie-chart\",\"each\",\"-track-array\",\"overview-legend-item\"]]",
    "moduleName": "nightwatch-web/components/site-audit/page-depth-chart-container.hbs",
    "isStrictMode": false
  });

  let PageDepthChartContainer = (_dec = Ember._action, (_class = class PageDepthChartContainer extends _component.default {
    get chartData() {
      var _this$args$stats, _this$args$stats2, _this$args$stats3, _this$args$stats4, _this$args$stats5, _this$args$stats6, _this$args$stats7, _this$args$stats8;

      return [{
        label: 'Level 1',
        backgroundColor: '#48AAED',
        data: (_this$args$stats = this.args.stats) === null || _this$args$stats === void 0 ? void 0 : _this$args$stats.level_1_pages_count,
        change: (_this$args$stats2 = this.args.stats) === null || _this$args$stats2 === void 0 ? void 0 : _this$args$stats2.level_1_pages_count_change
      }, {
        label: 'Level 2',
        backgroundColor: '#1883CA',
        data: (_this$args$stats3 = this.args.stats) === null || _this$args$stats3 === void 0 ? void 0 : _this$args$stats3.level_2_pages_count,
        change: (_this$args$stats4 = this.args.stats) === null || _this$args$stats4 === void 0 ? void 0 : _this$args$stats4.level_2_pages_count_change
      }, {
        label: 'Level 3',
        backgroundColor: '#004979',
        data: (_this$args$stats5 = this.args.stats) === null || _this$args$stats5 === void 0 ? void 0 : _this$args$stats5.level_3_pages_count,
        change: (_this$args$stats6 = this.args.stats) === null || _this$args$stats6 === void 0 ? void 0 : _this$args$stats6.level_3_pages_count_change
      }, {
        label: 'Level 4',
        backgroundColor: '#404040',
        data: (_this$args$stats7 = this.args.stats) === null || _this$args$stats7 === void 0 ? void 0 : _this$args$stats7.level_4_pages_count,
        change: (_this$args$stats8 = this.args.stats) === null || _this$args$stats8 === void 0 ? void 0 : _this$args$stats8.level_4_pages_count_change
      }];
    }

    clickLegendItem(item) {
      const newFilters = [];

      switch (item) {
        case 'Level 1':
          newFilters.push(['depth', 'equals', 1]);
          break;

        case 'Level 2':
          newFilters.push(['depth', 'equals', 2]);
          break;

        case 'Level 3':
          newFilters.push(['depth', 'equals', 3]);
          break;

        case 'Level 4':
          newFilters.push(['depth', 'equals', 4]);
          break;
      }

      this.args.setLevelFilter(newFilters);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "clickLegendItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clickLegendItem"), _class.prototype)), _class));
  _exports.default = PageDepthChartContainer;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PageDepthChartContainer);
});