define("nightwatch-web/utils/site-audit-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AVAILABLE_FILTERS = _exports.PROPERTIES = void 0;
  const PROPERTIES = [{
    name: 'page',
    displayName: 'Page',
    type: 'string',
    sortField: 'url',
    filterAvailable: false,
    pinned: true
  }, {
    name: 'url',
    displayName: 'Page URL',
    type: 'string'
  }, {
    name: 'url_from',
    displayName: 'Linked from',
    type: 'string'
  }, {
    name: 'response_code',
    displayName: 'Response code',
    type: 'number'
  }, {
    name: 'redirect_code',
    displayName: 'Redirection code',
    type: 'values',
    availableValues: ['301', '302']
  }, {
    name: 'nofollow',
    displayName: 'Nofollow',
    type: 'values',
    availableValues: ['true', 'false']
  }, {
    name: 'noindex',
    displayName: 'Noindex',
    type: 'values',
    availableValues: ['true', 'false']
  }, {
    name: 'internal_linking_urls_count',
    displayName: 'Linking pages',
    type: 'number'
  }, {
    name: 'internal_broken_links_count',
    displayName: 'Broken links count',
    type: 'number'
  }, {
    name: 'missing_alt_images_count',
    displayName: 'Missing alt images count',
    type: 'number'
  }, {
    name: 'indexable',
    displayName: 'Indexable',
    type: 'values',
    availableValues: ['true', 'false']
  }, {
    name: 'internal_urls_count',
    displayName: 'Internal links',
    type: 'number'
  }, {
    name: 'external_urls_count',
    displayName: 'External links',
    type: 'number'
  }, {
    name: 'load_time',
    displayName: 'Load time (ms)',
    type: 'number'
  }, {
    name: 'byte_size',
    displayName: 'Byte size',
    type: 'number'
  }, {
    name: 'title',
    displayName: 'Page title',
    type: 'string'
  }, {
    name: 'title_length',
    displayName: 'Title length',
    type: 'number'
  }, {
    name: 'meta_description',
    displayName: 'Description',
    type: 'string'
  }, {
    name: 'meta_description_length',
    displayName: 'Description length',
    type: 'number'
  }, {
    name: 'depth',
    displayName: 'Depth',
    type: 'number'
  }, {
    name: 'h1',
    displayName: 'H1',
    type: 'string'
  }, {
    name: 'h1_count',
    displayName: 'H1 count',
    type: 'number'
  }, {
    name: 'h1_length',
    displayName: 'H1 length',
    type: 'number'
  }, {
    name: 'h2',
    displayName: 'H2',
    type: 'string'
  }, {
    name: 'h2_length',
    displayName: 'H2 length',
    type: 'number'
  }, {
    name: 'h3',
    displayName: 'H3',
    type: 'string'
  }, {
    name: 'h3_length',
    displayName: 'H3 length',
    type: 'number'
  }, {
    name: 'meta_charset',
    displayName: 'Charset',
    type: 'string'
  }, {
    name: 'meta_viewport',
    displayName: 'Viewport',
    type: 'string'
  }, {
    name: 'og_description',
    displayName: 'OG description',
    type: 'string'
  }, {
    name: 'og_title',
    displayName: 'OG title',
    type: 'string'
  }, {
    name: 'og_image',
    displayName: 'OG image',
    type: 'string'
  }, {
    name: 'og_url',
    displayName: 'OG url',
    type: 'string'
  }, {
    name: 'blocked_by_robots',
    displayName: 'Blocked by robots',
    type: 'values',
    availableValues: ['true', 'false']
  }, {
    name: 'is_unique_title',
    displayName: 'Unique title',
    type: 'values',
    availableValues: ['true', 'false']
  }, {
    name: 'is_unique_description',
    displayName: 'Unique desc.',
    type: 'values',
    availableValues: ['true', 'false']
  }, {
    name: 'is_unique_h1',
    displayName: 'Unique H1',
    type: 'values',
    availableValues: ['true', 'false']
  }].map(p => {
    if (!p.sortField) p.sortField = p.name;

    if (p.filterAvailable == undefined) {
      p.filterAvailable = true;
    }

    return p;
  });
  _exports.PROPERTIES = PROPERTIES;
  const AVAILABLE_FILTERS = PROPERTIES.filter(p => p.filterAvailable);
  _exports.AVAILABLE_FILTERS = AVAILABLE_FILTERS;
});