define("nightwatch-web/constants/docs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DOCS = {
    keywordViewsAndLists: 'https://docs.nightwatch.io/en/articles/4878015-creating-views-and-keyword-lists',
    websiteIsntRanking: 'https://docs.nightwatch.io/en/articles/4874366-faq-the-website-isn-t-ranking',
    tos: 'https://docs.nightwatch.io/en/articles/4895540-terms-of-service',
    privacy: 'https://docs.nightwatch.io/en/articles/4895535-privacy-policy',
    trackingKeywordRankings: 'https://docs.nightwatch.io/en/articles/4874339-tracking-keywords',
    faqMetrics: 'https://docs.nightwatch.io/en/articles/4878067-faq-nightwatch-metrics',
    whatNightwatchTracks: 'https://docs.nightwatch.io/en/articles/4877997-types-of-rankings',
    backlinksTracking: 'https://docs.nightwatch.io/en/articles/4894848-monitoring-backlinks'
  };
  var _default = DOCS;
  _exports.default = _default;
});