define("nightwatch-web/helpers/text-highlight", ["exports", "ember-text-highlight/helpers/text-highlight", "ember-text-highlight/-private/indices-implementation", "ember-text-highlight/-private/regex-implementation"], function (_exports, _textHighlight, _indicesImplementation, _regexImplementation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _textHighlight.default;
    }
  });
  Object.defineProperty(_exports, "indicesImplementation", {
    enumerable: true,
    get: function () {
      return _indicesImplementation.default;
    }
  });
  Object.defineProperty(_exports, "regexImplementation", {
    enumerable: true,
    get: function () {
      return _regexImplementation.default;
    }
  });
});