define("nightwatch-web/routes/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlanRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class PlanRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "locator", _descriptor2, this);

      _initializerDefineProperty(this, "externalScriptLoader", _descriptor3, this);

      _initializerDefineProperty(this, "errorReporter", _descriptor4, this);

      _initializerDefineProperty(this, "metrics", _descriptor5, this);

      _initializerDefineProperty(this, "fetch", _descriptor6, this);
    }

    async beforeModel() {
      this.session.requireAuthentication();

      try {
        await this.externalScriptLoader.loadBillingScripts();
      } catch (error) {
        this.notifications.error('There was an error while setting up the payment form. Please reload the page, or contact support if issue persists.');
        this.errorReporter.error('Loading billing scripts failed.', error);
      }
    }

    model(params) {
      return Ember.RSVP.hash({
        plan: this.store.findRecord('plan', params.plan_id),
        paymentData: this.fetch.request('/user/payment_token', {
          data: {
            version: '3'
          }
        })
      });
    }

    afterModel() {
      if (this.session.user.invoice_country) {
        return;
      } // Don't return a promise – we don't want to wait for the locator to load


      this.locator.getCountry().then(data => {
        const country = data['country_name'];
        this.session.user.invoice_country = country;
      }).catch(() => {// console.log("Country lookup failed.");
      });
    }

    willTransition() {
      this.session.user.rollbackAttributes();
      return true;
    }

    didTransition() {
      this.metrics.trackEvent({
        event: 'Subscribe Form'
      });
      return true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "locator", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "externalScriptLoader", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorReporter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class));
  _exports.default = PlanRoute;
});