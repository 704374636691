define("nightwatch-web/templates/dashboard/url/reports/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "loY3ln/w",
    "block": "[[[1,[28,[35,0],[[30,1,[\"url\",\"friendlyUrl\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Create Report\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"main-area-navbar clearfix\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,1,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"url-favicon-container\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@src\"],[[30,1,[\"url\",\"faviconUrl\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[28,[35,3],[[30,1,[\"url\",\"url\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,4],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url.reports\",[30,1,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n      Reports\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@dynamicView\",\"@url\",\"@report\",\"@logos\",\"@onReportSave\"],[[30,1,[\"dynamicView\"]],[30,1,[\"url\"]],[30,1,[\"report\"]],[30,1,[\"whitelabelLogos\"]],[30,0,[\"onReportSave\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"link-to\",\"favicon-image\",\"friendly-url\",\"nw-icon\",\"reports/report-form\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/reports/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});