define("nightwatch-web/utils/dom/fade-element", ["exports", "nightwatch-web/utils/delay", "nightwatch-web/utils/dom/get-element"], function (_exports, _delay, _getElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fadeIn = _exports.fadeOut = void 0;

  const fadeOut = async (target, duration = 500) => {
    const el = (0, _getElement.default)(target);
    if (!el) return;
    el.style.opacity = 1;
    el.style.transition = `opacity ${duration}ms ease-out`;
    el.style.opacity = 0;
    await (0, _delay.default)(duration);
    el.style.display = 'none';
  };

  _exports.fadeOut = fadeOut;

  const fadeIn = async (target, duration = 500) => {
    const el = (0, _getElement.default)(target);
    if (!el) return;
    el.style.transition = `opacity ${duration}ms ease-in`;
    el.style.display = 'block';
    el.style.opacity = 0;
    await (0, _delay.default)(duration);
    el.style.opacity = 1;
  };

  _exports.fadeIn = fadeIn;
});