define("nightwatch-web/templates/changelog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z1OHGWiU",
    "block": "[[[1,[28,[35,0],[\"Changelog\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"first\"],[[[[1,\"Changelog\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],[[24,0,\"nw-page-content--single nw-changelog\"]],null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[30,0,[\"groupedChangelogs\"]]],null],null,[[[1,\"    \"],[10,0],[14,0,\"nw-changelog__entry\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"nw-changelog__title\"],[12],[1,[28,[35,6],[[30,2]],null]],[13],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"nw-changelog__entry-item\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"nw-changelog__entry-tags\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,3,[\"lowerCaseTags\"]]],null]],null],null,[[[1,\"              \"],[10,1],[15,0,[29,[\"nw-changelog__label nw-changelog__label--\",[30,4]]]],[12],[1,[30,4]],[13],[1,\"\\n\"]],[4]],null],[1,\"          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"nw-changelog__entry-message\"],[12],[1,\"\\n            \"],[1,[28,[35,8],[[30,3,[\"message\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1,2]],null]],[]]]]],[1,\"\\n\"]],[\"changelogs\",\"date\",\"changelog\",\"tag\"],false,[\"page-title\",\"nw-navbar\",\"nw-page-location-banner\",\"nw-page-content\",\"each\",\"-each-in\",\"full-month-date\",\"-track-array\",\"html-safe\"]]",
    "moduleName": "nightwatch-web/templates/changelog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});