define("nightwatch-web/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dXLu9gwG",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"first\"],[[[[1,\"Settings\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@user\"],[[30,0,[\"session\",\"user\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"nw-navbar\",\"nw-page-location-banner\",\"settings/settings-area\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});