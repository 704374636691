define("nightwatch-web/controllers/dashboard/url/site-audit/all/index", ["exports", "nightwatch-web/mixins/site-audit/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlSiteAuditAllIndexController extends Ember.Controller.extend(_controller.default) {}

  _exports.default = DashboardUrlSiteAuditAllIndexController;
});