define("nightwatch-web/helpers/audit-check-tooltip", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COUNT_CONVERT_DIC = ['no', 'a'];

  const humanizeCount = count => COUNT_CONVERT_DIC[count] || count;

  const areOrIs = count => count == 1 ? 'is' : 'are';

  const pluralizeIfNeed = (count, type) => count == 1 ? type : (0, _emberInflector.pluralize)(type);

  const auditCheckTooltip = ([count, type]) => {
    const safeCount = count || 0;
    return `There ${areOrIs(safeCount)} ${humanizeCount(safeCount)} ${pluralizeIfNeed(safeCount, type)} present on this page`;
  };

  var _default = Ember.Helper.helper(auditCheckTooltip);

  _exports.default = _default;
});