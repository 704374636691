define("nightwatch-web/helpers/humanize", ["exports", "nightwatch-web/utils/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abbreviate = abbreviate;
  _exports.default = void 0;

  function abbreviate([str]) {
    return (0, _humanize.default)(str);
  }

  var _default = Ember.Helper.helper(abbreviate);

  _exports.default = _default;
});