define("nightwatch-web/utils/set-saved-sorting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (context, controller, routePath, resourceParams) => {
    const persistedSortParams = context.keywordListSorting.getSorting(resourceParams);
    const routeSortParams = context.paramsFor(routePath);
    const isDefaultSorting = routeSortParams['sort'] === 'created_at' && routeSortParams['direction'] === 'desc';

    if (isDefaultSorting && persistedSortParams.sort && persistedSortParams.direction) {
      controller.setProperties(persistedSortParams);
    }
  };

  _exports.default = _default;
});