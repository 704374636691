define("nightwatch-web/transitions", ["exports", "velocity", "nightwatch-web/utils/is-testing"], function (_exports, _velocity, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // Disable transitions during test runs
  if (_isTesting.default) {
    _velocity.default.mock = true;
  }

  function _default() {
    this.transition(this.fromRoute('start.url'), this.toRoute('start.keywords'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('start.keywords'), this.toRoute('start.competitors'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('start.competitors'), this.toRoute('start.integrations'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.childOf('.liquid-bind-inline'), this.use('toDown'));
  }
});