define("nightwatch-web/components/reports/report-format", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-panel double-offset-top">
    <div class="nw-panel__header">
      <span class="nw-panel__title">Format</span>
    </div>
    <div class="nw-panel__body clearfix">
      {{#each this.formats as |format|}}
        <div class="report-format {{if (eq @report.report_format format) 'report-format--selected'}}" role="button" {{on "click" (fn this.setSelectedFormat format)}}>
          <div class="report-format__icon"><i class="fa fa-file report-format-icon"></i></div>
          <div class="report-format__name">{{capitalize format}}</div>
        </div>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "0vFMkucd",
    "block": "[[[10,0],[14,0,\"nw-panel double-offset-top\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nw-panel__header\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"nw-panel__title\"],[12],[1,\"Format\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"nw-panel__body clearfix\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"formats\"]]],null]],null],null,[[[1,\"      \"],[11,0],[16,0,[29,[\"report-format \",[52,[28,[37,3],[[30,2,[\"report_format\"]],[30,1]],null],\"report-format--selected\"]]]],[24,\"role\",\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"setSelectedFormat\"]],[30,1]],null]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"report-format__icon\"],[12],[10,\"i\"],[14,0,\"fa fa-file report-format-icon\"],[12],[13],[13],[1,\"\\n        \"],[10,0],[14,0,\"report-format__name\"],[12],[1,[28,[35,6],[[30,1]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"format\",\"@report\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"on\",\"fn\",\"capitalize\"]]",
    "moduleName": "nightwatch-web/components/reports/report-format.hbs",
    "isStrictMode": false
  });

  let ReportFormatComponent = (_dec = Ember._action, (_class = class ReportFormatComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "formats", ['pdf', 'csv', 'html']);
    }

    setSelectedFormat(format) {
      this.args.report.report_format = format;
      this.args.onReportFormatSelect();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setSelectedFormat", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedFormat"), _class.prototype)), _class));
  _exports.default = ReportFormatComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ReportFormatComponent);
});