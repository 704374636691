define("nightwatch-web/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmFVE0YR",
    "block": "[[[10,\"title\"],[12],[1,[30,0,[\"model\",\"title\"]]],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "nightwatch-web/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});