define("nightwatch-web/utils/graphs/fill-gaps", ["exports", "date-fns", "date-fns/format"], function (_exports, _dateFns, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const fillGaps = data => {
    const fillPoints = [];

    const getNextDate = index => {
      var _data;

      const dateStr = (_data = data[index + 1]) === null || _data === void 0 ? void 0 : _data[0];
      return dateStr ? (0, _dateFns.parseISO)(dateStr) : null;
    };

    data.forEach(([dateStr, value], index) => {
      const nextDate = getNextDate(index); // Do nothing when last value is reached

      if (!nextDate) return;
      const date = (0, _dateFns.parseISO)(dateStr); // Find how many days we need to fill

      let daysToFill = (0, _dateFns.differenceInDays)(nextDate, date) - 1; // 1 = expected days between points

      if (daysToFill <= 0) return; // Create a point for each missing day

      const points = [];

      while (daysToFill > 0) {
        const pointDate = (0, _dateFns.add)(date, {
          days: daysToFill
        }); // Using `unshift` because we're working backwards

        points.unshift([(0, _format.default)(pointDate, 'yyyy-MM-dd'), value]);
        daysToFill--;
      } // Store our fillPoints with fillAfterDateStr as a location reference
      // Use this later to avoid mutating data while we're iterating


      fillPoints.push({
        points,
        fillAfterDateStr: dateStr
      });
    }); // Analysed all data – now we can begin mutating

    fillPoints.forEach(({
      fillAfterDateStr,
      points
    }) => {
      // Find the index where we should fill by looking up fillAfterDateStr
      const fillAfterIndex = data.findIndex(([dateStr]) => dateStr === fillAfterDateStr);
      if (!fillAfterIndex) return; // Finally insert our fillPoints *after* this index

      data.splice(fillAfterIndex + 1, 0, ...points);
    });
    return data;
  };

  var _default = fillGaps;
  _exports.default = _default;
});