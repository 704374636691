define("nightwatch-web/utils/html-ui-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Tooltip = _exports.html = void 0;

  /**
   * Helper function to construct an HTML tree
   * @param  {String}       tag         HTML element tag
   * @param  {Object}       [attrs={}]  Element attributes
   * @param  {Node|String}  children    Child node(s); HTML element(s) or text
   * @return {[Node]}                   Node
   */
  const html = (tag, attrs = {}, ...children) => {
    const el = document.createElement(tag);
    Object.keys(attrs).forEach(attr => {
      el.setAttribute(attr, attrs[attr]);
    });
    children.forEach(c => {
      if (!c.toString()) return; // Skip empty strings

      if (typeof c !== 'object') {
        c = document.createTextNode(c.toString());
      }

      el.appendChild(c);
    });
    return el;
  };
  /*
   * Global tooltips;
   * - import into your component
   * - instantiate it on a variable: const tooltip = Tooltip()
   * - show: tooltip.show({ top offset, left offset, html content })
   * - hide: tooltip.hide()
   * - destroy when no longer needed: tooltip.destroy()
   */


  _exports.html = html;

  const Tooltip = () => {
    let instance = null;

    function show({
      x,
      y,
      content
    }) {
      if (!instance) init();
      instance.innerHTML = content;
      instance.setAttribute('style', `top:${y}px;left:${x}px;display:block`);
    }

    function hide() {
      if (!instance) return;
      instance.setAttribute('style', 'display:none');
      instance.innerHTML = '';
    }

    function init() {
      instance = document.createElement('div');
      instance.className = 'nw-tooltip';
      hide();
      document.body.appendChild(instance);
    }

    function destroy() {
      if (!instance) return;
      instance.remove();
      instance = null;
      return null;
    }

    return {
      show,
      hide,
      destroy
    };
  };

  _exports.Tooltip = Tooltip;
});