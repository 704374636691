define("nightwatch-web/routes/dashboard/url/settings/competitors", ["exports", "nightwatch-web/mixins/competitor-actions"], function (_exports, _competitorActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlSettingsCompetitorsRoute extends Ember.Route.extend(_competitorActions.default) {
    activate() {
      window.scrollTo(0, 0);
    }

    model() {
      return Ember.RSVP.hash({
        url: this.modelFor('dashboard.url'),
        competitors: this.store.query('competitor', {
          url_id: this.modelFor('dashboard.url').id
        })
      });
    }

    afterModel(model) {
      const url = model.url;
      url.set('competitors', model.competitors);

      if (model.competitors.get('length') === 0) {
        this.addCompetitorToUrl(url);
      }
    }

    deactivate() {
      this.deleteUnsavedCompetitors();
    }

  }

  _exports.default = DashboardUrlSettingsCompetitorsRoute;
});