define("nightwatch-web/helpers/competitor-object-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.competitorObjectPath = void 0;

  const competitorObjectPath = params => {
    // example: keyword-position-competitor-15
    const columnName = params[0];
    const node = params[1];
    const competitorId = columnName.split('-').reverse()[0];

    if (node) {
      return `competitor_results.${competitorId}.${node}`;
    } else {
      return `competitor_results.${competitorId}`;
    }
  };

  _exports.competitorObjectPath = competitorObjectPath;

  var _default = Ember.Helper.helper(competitorObjectPath);

  _exports.default = _default;
});