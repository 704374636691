define("nightwatch-web/constants/filters/filter-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    string: [{
      name: 'contains',
      displayName: 'contains'
    }, {
      name: 'equals',
      displayName: 'equals'
    }, {
      name: 'starts_with',
      displayName: 'starts with'
    }, {
      name: 'ends_with',
      displayName: 'ends with'
    }, {
      name: 'not_contains',
      displayName: "doesn't contain"
    }, {
      name: 'not_starts_with',
      displayName: "doesn't start with"
    }, {
      name: 'not_ends_with',
      displayName: "doesn't end with"
    }, {
      name: 'is_present',
      displayName: 'is present'
    }, {
      name: 'is_blank',
      displayName: 'is blank'
    }],
    presence: [{
      name: 'is_present',
      displayName: 'is present'
    }, {
      name: 'is_blank',
      displayName: 'is blank'
    }],
    number: [{
      name: 'gte',
      displayName: '>='
    }, {
      name: 'lte',
      displayName: '<='
    }, {
      name: 'gt',
      displayName: '>'
    }, {
      name: 'lt',
      displayName: '<'
    }, {
      name: 'equals',
      displayName: '='
    }],
    date: [{
      name: 'gte',
      displayName: '>='
    }, {
      name: 'lte',
      displayName: '<='
    }, {
      name: 'gt',
      displayName: '>'
    }, {
      name: 'lt',
      displayName: '<'
    }, {
      name: 'equals',
      displayName: '='
    }],
    array: [{
      name: 'array_has',
      displayName: 'is'
    }, {
      name: 'array_has_not',
      displayName: 'is not'
    }],
    values: []
  };
  _exports.default = _default;
});