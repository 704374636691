define("nightwatch-web/components/tags-toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button class="tags-button inline-block btn btn-secondary-2 {{if @tagsShown 'active'}}" {{on 'click' @onClick}} type="button" ...attributes>
    <NwTooltip>Toggle display of tags</NwTooltip>
    <NwIcon @icon="icon-tags" />
  </button>
  
  */
  {
    "id": "PBz5r8hk",
    "block": "[[[11,\"button\"],[16,0,[29,[\"tags-button inline-block btn btn-secondary-2 \",[52,[30,1],\"active\"]]]],[24,4,\"button\"],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"Toggle display of tags\"]],[]]]]],[1,\"\\n  \"],[8,[39,3],null,[[\"@icon\"],[\"icon-tags\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@tagsShown\",\"&attrs\",\"@onClick\"],false,[\"if\",\"on\",\"nw-tooltip\",\"nw-icon\"]]",
    "moduleName": "nightwatch-web/components/tags-toggle-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});