define("nightwatch-web/components/nw-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AttachPopover
    @class="nw-popover"
    @isShown={{@isShown}}
    @arrow={{@arrow}}
    @hideOn={{@hideOn}}
    @showOn={{@showOn}}>
    <div class="popover-content">
      <div class="popover-content__title">
        {{@title}}
      </div>
      <div class="popover-content__body">
        {{yield}}
      </div>
      <div class="popover-content__footer">
        <button {{on "click" @buttonAction}} type="button">
          {{@buttonText}}
        </button>
      </div>
    </div>
  </AttachPopover>
  
  */
  {
    "id": "AurcT1Kv",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@isShown\",\"@arrow\",\"@hideOn\",\"@showOn\"],[\"nw-popover\",[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"popover-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"popover-content__title\"],[12],[1,\"\\n      \"],[1,[30,5]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"popover-content__body\"],[12],[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"popover-content__footer\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,6]],null],[12],[1,\"\\n        \"],[1,[30,7]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@isShown\",\"@arrow\",\"@hideOn\",\"@showOn\",\"@title\",\"@buttonAction\",\"@buttonText\",\"&default\"],false,[\"attach-popover\",\"yield\",\"on\"]]",
    "moduleName": "nightwatch-web/components/nw-popover.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});