define("nightwatch-web/templates/start/competitors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ie/qqXY",
    "block": "[[[1,[28,[35,0],[[30,1,[\"friendlyUrl\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Competitors\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"onboarding-title\"],[12],[1,\"\\n  \"],[1,[30,1,[\"friendlyUrl\"]]],[1,\": Competitors\\n  \"],[10,1],[14,0,\"pull-right onboarding-step-count\"],[12],[1,\"3/4\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"onboarding-big-title\"],[12],[1,\"Who are your competitors?\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"onboarding-subtitle\"],[12],[1,\"Insert some websites you're competing against:\"],[13],[1,\"\\n\"],[8,[39,1],[[24,0,\"double-offset-top\"]],[[\"@url\",\"@saved\",\"@isSaving\",\"@onboarding\",\"@user\",\"@addCompetitor\",\"@save\",\"@delete\"],[[30,1],[30,0,[\"saved\"]],[30,0,[\"isSaving\"]],true,[30,0,[\"session\",\"user\"]],[30,0,[\"addCompetitor\"]],[28,[37,2],[[30,0,[\"saveCompetitor\"]]],null],[30,0,[\"deleteCompetitor\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"url-competitors\",\"perform\"]]",
    "moduleName": "nightwatch-web/templates/start/competitors.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});