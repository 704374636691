define("nightwatch-web/components/reports/report-blocks/backlink-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-report-block__top">
    <div class="report-block__header">
      <i class="fa fa-chain block-icon"></i> {{humanize @blockObject.name}}
    </div>
    <div class="report-block__selector">
      <NwSelect
        class="nw-power-select--s nw-power-select--low"
        @v1={{true}}
        @dropdownClass="nw-power-select--s"
        @options={{@backlinkViews}}
        @selected={{@selectedBacklinkView}}
        @onChange={{@selectBacklinkView}} as |backlinkView|>
        {{backlinkView.name}}
      </NwSelect>
    </div>
    <div class="report-block__amount">
      {{abbreviate-number @backlinkCount}} backlinks
      <NwIcon @icon="icon-cross" class="cursor-pointer" role="button" {{on "click" (fn @removeBlock @blockObject)}} />
    </div>
  </div>
  
  */
  {
    "id": "QofbQHnK",
    "block": "[[[10,0],[14,0,\"nw-report-block__top\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"report-block__header\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-chain block-icon\"],[12],[13],[1,\" \"],[1,[28,[35,0],[[30,1,[\"name\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"report-block__selector\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"nw-power-select--s nw-power-select--low\"]],[[\"@v1\",\"@dropdownClass\",\"@options\",\"@selected\",\"@onChange\"],[true,\"nw-power-select--s\",[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,5,[\"name\"]]],[1,\"\\n    \"]],[5]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"report-block__amount\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,6]],null]],[1,\" backlinks\\n    \"],[8,[39,3],[[24,0,\"cursor-pointer\"],[24,\"role\",\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,7],[30,1]],null]],null]],[[\"@icon\"],[\"icon-cross\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@blockObject\",\"@backlinkViews\",\"@selectedBacklinkView\",\"@selectBacklinkView\",\"backlinkView\",\"@backlinkCount\",\"@removeBlock\"],false,[\"humanize\",\"nw-select\",\"abbreviate-number\",\"nw-icon\",\"on\",\"fn\"]]",
    "moduleName": "nightwatch-web/components/reports/report-blocks/backlink-overview.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});