define("nightwatch-web/utils/underscorify-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const underscorifyKeys = object => {
    return Object.keys(object).reduce((newObject, key) => {
      newObject[Ember.String.underscore(Ember.String.decamelize(key))] = object[key];
      return newObject;
    }, {});
  };

  var _default = underscorifyKeys;
  _exports.default = _default;
});