define("nightwatch-web/components/power-calendar", ["exports", "ember-power-calendar/components/power-calendar"], function (_exports, _powerCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _powerCalendar.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "daysComponent", 'power-calendar/days-en');
    }

  }

  _exports.default = _default;
});