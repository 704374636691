define("nightwatch-web/controllers/dashboard/url/dashboard", ["exports", "nightwatch-web/constants/default-data", "date-fns"], function (_exports, _defaultData, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlDashboardController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('persistence/dashboard'), _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, (_class = class DashboardUrlDashboardController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "keywordStats", _descriptor, this);

      _initializerDefineProperty(this, "dashboardPersistence", _descriptor2, this);

      _initializerDefineProperty(this, "fetch", _descriptor3, this);

      _initializerDefineProperty(this, "url", _descriptor4, this);

      _initializerDefineProperty(this, "selectedDateRange", _descriptor5, this);

      _initializerDefineProperty(this, "trafficStatsLoading", _descriptor6, this);

      _initializerDefineProperty(this, "backlinkStatsLoading", _descriptor7, this);

      _initializerDefineProperty(this, "urlStatsLoading", _descriptor8, this);

      _initializerDefineProperty(this, "trafficStats", _descriptor9, this);

      _initializerDefineProperty(this, "backlinkStats", _descriptor10, this);

      _defineProperty(this, "defaultBacklinksStats", _defaultData.default.defaultBacklinksStats);

      _defineProperty(this, "defaultTrafficStats", _defaultData.default.defaultTrafficStats);
    }

    get googleAnalyticsConnected() {
      return Ember.isPresent(this.url.google_analytics_profile_id);
    }

    init() {
      super.init(...arguments);
      const {
        type,
        start,
        end
      } = this.dashboardPersistence.parseDateRange();
      this.selectedDateRange = {
        type,
        start,
        end
      };
    }

    get statsDateFrom() {
      return (0, _dateFns.format)(this.selectedDateRange.start, 'yyyy-MM-dd');
    }

    get statsDateTo() {
      return (0, _dateFns.format)(this.selectedDateRange.end, 'yyyy-MM-dd');
    }

    fetchStats() {
      this.fetchBacklinkStats();
      this.fetchTrafficStats();
      this.fetchKeywordStats();
      this.fetchUrlStats();
    }

    fetchTrafficStats() {
      const dateFrom = this.statsDateFrom;
      const dateTo = this.statsDateTo;
      const urlId = this.url.id;
      this.trafficStatsLoading = true;
      this.fetch.request('/traffic_stats', {
        data: {
          url_id: urlId,
          date_from: dateFrom,
          date_to: dateTo
        }
      }).then(stats => {
        this.trafficStats = stats;
      }).finally(() => {
        this.trafficStatsLoading = false;
      });
    }

    fetchKeywordStats() {
      const {
        statsDateFrom,
        statsDateTo,
        url
      } = this;
      this.keywordStats.load({
        url,
        statsDateFrom,
        statsDateTo
      });
    }

    fetchBacklinkStats() {
      const urlId = this.url.id;
      this.backlinkStatsLoading = true;
      this.fetch.request('/backlink_stats', {
        data: {
          url_id: urlId,
          date_from: this.statsDateFrom,
          date_to: this.statsDateTo,
          for_overview: true
        }
      }).then(stats => {
        this.backlinkStats = stats;
      }).finally(() => {
        this.backlinkStatsLoading = false;
      });
    }

    fetchUrlStats() {
      const dateFrom = this.statsDateFrom;
      const dateTo = this.statsDateTo;
      this.urlStatsLoading = true;
      this.url.loadStats(dateFrom, dateTo).finally(() => {
        this.urlStatsLoading = false;
      });
    }

    setDateRange({
      start,
      end,
      type
    }) {
      this.dashboardPersistence.persistDateRange(type);
      this.selectedDateRange = {
        type,
        start,
        end
      };
      this.fetchStats();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keywordStats", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dashboardPersistence", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedDateRange", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "trafficStatsLoading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "backlinkStatsLoading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "urlStatsLoading", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "trafficStats", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "backlinkStats", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setDateRange", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setDateRange"), _class.prototype)), _class));
  _exports.default = DashboardUrlDashboardController;
});