define("nightwatch-web/helpers/prettify-commas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.prettifyCommas = void 0;

  // New York,New York,United States to New York, New York, United States
  const prettifyCommas = params => (params[0] || '').split(',').map(Function.prototype.call, String.prototype.trim).join(', ');

  _exports.prettifyCommas = prettifyCommas;

  var _default = Ember.Helper.helper(prettifyCommas);

  _exports.default = _default;
});