define("nightwatch-web/services/intercom", ["exports", "nightwatch-web/config/environment", "nightwatch-web/utils/underscorify-keys"], function (_exports, _environment, _underscorifyKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IntercomService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class IntercomService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);

      _initializerDefineProperty(this, "siteData", _descriptor3, this);
    }

    get appId() {
      return _environment.default.intercom.appId;
    }

    get enabled() {
      return !this.session.isAdminViewingUser && !this.siteData.hideNightwatchBranding;
    }

    showMessages() {
      var _window, _window$Intercom;

      (_window = window) === null || _window === void 0 ? void 0 : (_window$Intercom = _window.Intercom) === null || _window$Intercom === void 0 ? void 0 : _window$Intercom.call(_window, 'showMessages');
    }

    showNewMessage() {
      var _window2, _window2$Intercom;

      (_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$Intercom = _window2.Intercom) === null || _window2$Intercom === void 0 ? void 0 : _window2$Intercom.call(_window2, 'showNewMessage');
    }

    shutdown() {
      var _window3, _window3$Intercom;

      (_window3 = window) === null || _window3 === void 0 ? void 0 : (_window3$Intercom = _window3.Intercom) === null || _window3$Intercom === void 0 ? void 0 : _window3$Intercom.call(_window3, 'shutdown');
    }

    afterLogin(userMetrics) {
      if (!this.enabled) return;
      this.activateMetricsAdapter();
      this.metrics.identify('Intercom', _objectSpread(_objectSpread({}, (0, _underscorifyKeys.default)(userMetrics)), {}, {
        created_at: userMetrics.createdAtUnix,
        company: {
          company_id: userMetrics.accountId,
          name: userMetrics.name,
          plan: userMetrics.planName
        }
      }));
    }

    activateMetricsAdapter() {
      const {
        appId
      } = this;
      if (!appId) return;
      this.metrics.activateAdapters([..._environment.default.metricsAdapters, {
        name: 'Intercom',
        config: {
          appId
        }
      }]);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IntercomService;
});