define("nightwatch-web/templates/dashboard/url/settings/competitors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iovpPiEV",
    "block": "[[[1,[28,[35,0],[[30,1,[\"friendlyUrl\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Competitors\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@url\",\"@saved\",\"@isSaving\",\"@addCompetitor\",\"@save\",\"@delete\",\"@isTabsForm\"],[[30,1,[\"url\"]],[30,0,[\"saved\"]],[30,0,[\"isSaving\"]],[30,0,[\"addCompetitor\"]],[28,[37,2],[[30,0,[\"saveCompetitor\"]]],null],[30,0,[\"deleteCompetitor\"]],true]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"url-competitors\",\"perform\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/settings/competitors.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});