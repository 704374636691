define("nightwatch-web/utils/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = str => {
    return str.toLowerCase().replace(/(?:^|\s|-)\S/g, function (m) {
      return m.toUpperCase();
    });
  };

  _exports.default = _default;
});