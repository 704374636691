define("nightwatch-web/templates/dashboard/url/settings/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EGuSJ5op",
    "block": "[[[1,[28,[35,0],[[30,1,[\"friendlyUrl\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Configuration\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@url\",\"@urlGroup\",\"@urlGroups\",\"@store\",\"@onUrlUpdate\",\"@onUrlDeleted\"],[[30,1],[30,1,[\"urlGroup\"]],[30,0,[\"urlGroups\"]],[30,0,[\"store\"]],[30,0,[\"onUrlUpdate\"]],[30,0,[\"onUrlDeleted\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"url-form\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/settings/configuration.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});