define("nightwatch-web/controllers/dashboard/url/dynamic-view", ["exports", "nightwatch-web/mixins/dynamic-view-handling", "nightwatch-web/mixins/filters-toggling", "nightwatch-web/mixins/filter-group-handling", "nightwatch-web/mixins/selectable-keywords", "nightwatch-web/mixins/keywords-sort-handling", "date-fns/format"], function (_exports, _dynamicViewHandling, _filtersToggling, _filterGroupHandling, _selectableKeywords, _keywordsSortHandling, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlDashboardDynamicViewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('persistence/keyword-list'), _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('model'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class DashboardUrlDashboardDynamicViewController extends Ember.Controller.extend(_dynamicViewHandling.default, _keywordsSortHandling.default, _filtersToggling.default, _filterGroupHandling.default, _selectableKeywords.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "keywordListPersistence", _descriptor3, this);

      _initializerDefineProperty(this, "keywordStats", _descriptor4, this);

      _defineProperty(this, "queryParams", [...this.queryParams, 'jc']);

      _defineProperty(this, "jc", false);

      _initializerDefineProperty(this, "dynamicView", _descriptor5, this);

      _initializerDefineProperty(this, "url", _descriptor6, this);

      _initializerDefineProperty(this, "filterGroups", _descriptor7, this);

      _initializerDefineProperty(this, "selectedDateRange", _descriptor8, this);

      _initializerDefineProperty(this, "initialKeywordLoadingDone", _descriptor9, this);

      _initializerDefineProperty(this, "selectedKeywordIds", _descriptor10, this);
    }

    init() {
      super.init(...arguments);
      const {
        type,
        start,
        end
      } = this.keywordListPersistence.parseDateRange();
      this.selectedDateRange = {
        type,
        start,
        end
      };
    }

    get statsDateFrom() {
      return (0, _format.default)(this.selectedDateRange.start, 'yyyy-MM-dd');
    }

    get statsDateTo() {
      return (0, _format.default)(this.selectedDateRange.end, 'yyyy-MM-dd');
    }

    enteredRoute(dynamicView, url) {
      this.url = url;
      const filterGroups = this.store.query('filter-group', {
        dynamic_view_id: dynamicView.id
      });
      this.setPerPageLimit();
      filterGroups.then(filterGroups => {
        this.filterGroups = filterGroups;
        this.cacheFilters();

        if (filterGroups.length === 0) {
          this.prepareNewFilterGroup(dynamicView);
        } else {
          filterGroups.forEach(filterGroup => {
            filterGroup.dynamicView = dynamicView;
          });
        }
      });
      this.initialKeywordLoadingDone = false;
      this.selectedKeywordIds = [];
      this.fetchKeywords(); // Don't allow `sortingChanged` observer to trigger before controller is setup
      // (otherwise we get double keyword fetch)

      this.allowSortObserver = true;
      this.fetchKeywordStats();
    }

    fetchKeywordStats() {
      const {
        url,
        statsDateFrom,
        statsDateTo,
        dynamicView
      } = this;
      this.keywordStats.load({
        url,
        statsDateFrom,
        statsDateTo,
        dynamicView
      });
    }

    exportSelectedKeywords() {
      this.router.transitionTo('dashboard.url.reports.new', this.url, {
        queryParams: {
          keyword_ids: this.selectedKeywords.mapBy('id'),
          viewId: this.model.id
        }
      });
    }

    exportKeyword(keyword) {
      this.router.transitionTo('dashboard.url.reports.new', this.url, {
        queryParams: {
          keyword_ids: [keyword.id],
          viewId: this.model.id
        }
      });
    }

    setDateRange({
      start,
      end,
      type
    }) {
      this.keywordListPersistence.persistDateRange(type);
      this.selectedDateRange = {
        type,
        start,
        end
      };
      this.fetchKeywordStats();
      this.fetchKeywords();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "keywordListPersistence", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "keywordStats", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dynamicView", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filterGroups", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedDateRange", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "initialKeywordLoadingDone", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedKeywordIds", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "exportSelectedKeywords", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "exportSelectedKeywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportKeyword", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "exportKeyword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDateRange", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setDateRange"), _class.prototype)), _class));
  _exports.default = DashboardUrlDashboardDynamicViewController;
});