define("nightwatch-web/components/reports/report-blocks/traffic-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-report-block__top">
    <div class="report-block__header">
      <i class="fa fa-line-chart block-icon"></i> {{humanize @blockObject.name}}
    </div>
    <div class="report-block__amount">
      <NwIcon @icon="icon-cross" class="cursor-pointer" role="button" {{on "click" (fn @removeBlock @blockObject)}} />
    </div>
  </div>
  
  */
  {
    "id": "9sD+69Pg",
    "block": "[[[10,0],[14,0,\"nw-report-block__top\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"report-block__header\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-line-chart block-icon\"],[12],[13],[1,\" \"],[1,[28,[35,0],[[30,1,[\"name\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"report-block__amount\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"cursor-pointer\"],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,2],[30,1]],null]],null]],[[\"@icon\"],[\"icon-cross\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@blockObject\",\"@removeBlock\"],false,[\"humanize\",\"nw-icon\",\"on\",\"fn\"]]",
    "moduleName": "nightwatch-web/components/reports/report-blocks/traffic-overview.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});