define("nightwatch-web/components/period-change-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <div class="change-wrapper">
      <UpDownStagnantIcon @value={{@changeValue}} />
      <div class="change-number {{up-down-stagnant @changeValue}}">
        {{#if (not (eq @changeValue 0))}}
          {{dwm-change-value @changeValue}}
        {{/if}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "BvEcT0k3",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"change-wrapper\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@value\"],[[30,2]]],null],[1,\"\\n    \"],[10,0],[15,0,[29,[\"change-number \",[28,[37,1],[[30,2]],null]]]],[12],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,2],0],null]],null],[[[1,\"        \"],[1,[28,[35,5],[[30,2]],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@changeValue\"],false,[\"up-down-stagnant-icon\",\"up-down-stagnant\",\"if\",\"not\",\"eq\",\"dwm-change-value\"]]",
    "moduleName": "nightwatch-web/components/period-change-value.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});