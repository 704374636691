define("nightwatch-web/routes/dashboard/url/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlDashboardRoute extends Ember.Route {
    activate() {
      window.scrollTo(0, 0);
    }

    setupController(controller, model) {
      super.setupController(controller, model); // Fetch only when there is no stats present

      controller.fetchStats();
    }

  }

  _exports.default = DashboardUrlDashboardRoute;
});