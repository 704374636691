define("nightwatch-web/components/site-audit/recurring-site-audit-switcher", ["exports", "nightwatch-web/constants/time-periods"], function (_exports, _timePeriods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="recurring-site-audit-switcher__toggler">
    <XToggle
      @value={{this.url.siteAuditInterval}}
      @onToggle={{this.toggleRecurring}}
      @theme="light"
      @size="large"
      @class="inline-block"
    />
  </div>
  <div class="recurring-site-audit-switcher__text">
    <span class="form-info cursor-pointer" data-test-recurring-site-audit-toggler role="button" {{on "click" this.toggleRecurring}}>Run crawler automatically on a regular basis</span>
  </div>
  {{#if this.url.siteAuditInterval}}
    <div class="recurring-site-audit-switcher__select inline-block" data-test-select-interval>
      <NwSelect
        @v1={{true}} class="nw-power-select--s"
        @dropdownClass="nw-power-select--s"
        @options={{this.availableIntervals}}
        @selected={{this.url.siteAuditInterval}}
        @onChange={{this.setSiteAuditInterval}} as |interval|>
        {{capitalize (humanize-interval interval)}}
      </NwSelect>
    </div>
  {{/if}}
  
  */
  {
    "id": "9mQ15HMC",
    "block": "[[[10,0],[14,0,\"recurring-site-audit-switcher__toggler\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@value\",\"@onToggle\",\"@theme\",\"@size\",\"@class\"],[[30,0,[\"url\",\"siteAuditInterval\"]],[30,0,[\"toggleRecurring\"]],\"light\",\"large\",\"inline-block\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"recurring-site-audit-switcher__text\"],[12],[1,\"\\n  \"],[11,1],[24,0,\"form-info cursor-pointer\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,0,[\"toggleRecurring\"]]],null],[12],[1,\"Run crawler automatically on a regular basis\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"url\",\"siteAuditInterval\"]],[[[1,\"  \"],[10,0],[14,0,\"recurring-site-audit-switcher__select inline-block\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"nw-power-select--s\"]],[[\"@v1\",\"@dropdownClass\",\"@options\",\"@selected\",\"@onChange\"],[true,\"nw-power-select--s\",[30,0,[\"availableIntervals\"]],[30,0,[\"url\",\"siteAuditInterval\"]],[30,0,[\"setSiteAuditInterval\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,4],[[28,[37,5],[[30,1]],null]],null]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"interval\"],false,[\"x-toggle\",\"on\",\"if\",\"nw-select\",\"capitalize\",\"humanize-interval\"]]",
    "moduleName": "nightwatch-web/components/site-audit/recurring-site-audit-switcher.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['recurring-site-audit-switcher'],
    availableIntervals: Object.freeze([_timePeriods.WEEKLY, _timePeriods.MONTHLY]),
    setSiteAuditInterval: Ember._action(function (interval) {
      Ember.set(this, 'url.siteAuditInterval', interval);
    }),
    toggleRecurring: Ember._action(function () {
      if (Ember.isBlank(Ember.get(this, 'url.siteAuditInterval'))) {
        Ember.set(this, 'url.siteAuditInterval', _timePeriods.MONTHLY);
      } else {
        Ember.set(this, 'url.siteAuditInterval', null);
      }
    })
  }));

  _exports.default = _default;
});