define("nightwatch-web/routes/dashboard/url/site-audit", ["exports", "nightwatch-web/utils/is-testing"], function (_exports, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlSiteAuditRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class DashboardUrlSiteAuditRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        crawling_session_id: {
          refreshModel: true
        },
        comparison_crawling_session_id: {
          refreshModel: true
        },
        c: {
          refreshModel: true
        }
      });

      _defineProperty(this, "crawlingInProgress", false);
    }

    activate() {
      window.scrollTo(0, 0);
    }

    beforeModel() {
      if (this.session.user.siteAuditEnabled) return;
      this.router.transitionTo('dashboard.url.site-audit-disabled');
    }

    model(params) {
      const url = this.modelFor('dashboard.url');
      const {
        crawling_session_id: id,
        comparison_crawling_session_id: comparisonId,
        c: comparisonEnabled
      } = params;
      return this.fetchCrawlingSessions(url.id).then(crawlingSessions => {
        const crawlingSession = id ? crawlingSessions.findBy('id', id) : crawlingSessions.toArray()[0];
        const comparisonCrawlingSession = comparisonId ? crawlingSessions.findBy('id', comparisonId) : crawlingSessions.toArray()[1];

        if (!crawlingSession) {
          this.router.transitionTo('dashboard.url.settings.site-audit', url, {
            queryParams: {
              startCrawling: true
            }
          });
          return;
        }

        return {
          url,
          crawlingSessions,
          crawlingSession,
          comparisonCrawlingSession,
          comparisonEnabled
        };
      });
    }

    afterModel(model) {
      this.refreshUntilDone(model.crawlingSession, model.url);
    }

    fetchCrawlingSessions(urlId) {
      // Return most recent 1000 for now. Fix when it gets slow or when there are more sessions than that
      return this.store.query('site-audit/crawling-session', {
        limit: 1000,
        sort: 'created_at',
        direction: 'desc',
        search_keyword_url_id: urlId
      });
    }

    refreshViewCounts(url) {
      var _url$siteAuditViews;

      // This will reflect in sidebar view counts when crawling
      ((_url$siteAuditViews = url.siteAuditViews) !== null && _url$siteAuditViews !== void 0 ? _url$siteAuditViews : []).forEach(view => view.loadCount());
    }

    refreshUntilDone(crawlingSession, url) {
      if (this.crawlingInProgress) {
        return false;
      }

      const _scheduleRefresh = () => {
        if (crawlingSession.isDone) {
          // We get here when we are refreshing and the session just got done.
          // We need one last stats refresh to fetch the newest stats.
          if (this.crawlingInProgress) {
            this.notifications.success('Crawling finished!', {
              autoClear: false
            });
            this.refreshViewCounts(url);
            this.refresh().finally(() => {
              this.crawlingInProgress = false;
            });
          } else {
            this.crawlingInProgress = false;
          }

          return;
        }

        this.crawlingInProgress = true;
        if (_isTesting.default) return; // Refresh views counts in a bigger interval as they aren't that important in real time

        Ember.run.later(() => this.refreshViewCounts(url), 5000); // Finally because "TransitionAborted" might happen
        // when toggling between the routes, or the model fetch may fail

        this.refresh().finally(() => Ember.run.later(() => _scheduleRefresh(), 2500));
      };

      _scheduleRefresh();
    }

    async setupController(controller, model) {
      super.setupController(controller, model);

      if (!controller.crawling_session_id) {
        controller.crawling_session_id = model.crawlingSession.id;
      }
    }

    error(e) {
      var _this$controller, _this$controller$mode, _this$controller$mode2;

      if (this !== null && this !== void 0 && (_this$controller = this.controller) !== null && _this$controller !== void 0 && (_this$controller$mode = _this$controller.model) !== null && _this$controller$mode !== void 0 && (_this$controller$mode2 = _this$controller$mode.crawlingSession) !== null && _this$controller$mode2 !== void 0 && _this$controller$mode2.isDone) {
        this.router.transitionTo('error');
      } else {// Do nothing. When server error (downtime) happens while automatically refreshing, don't transition to error page.
      }

      throw e;
    }

    willTransition(transition) {
      if (transition.targetName.includes('site-audit')) return; // Stop refreshing when going out of the site audit.

      this.crawlingInProgress = false;
    }

    resetController(controller, isExiting) {
      if (!isExiting) return;
      controller.crawling_session_id = null;
      controller.comparison_crawling_session_id = null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = DashboardUrlSiteAuditRoute;
});