define("nightwatch-web/utils/generate-user-metrics", ["exports", "date-fns", "nightwatch-web/config/environment"], function (_exports, _dateFns, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    @function generateUserMetrics
    A standardized set of attributes for providing to external tracking services
   */
  const generateUserMetrics = (user, siteDataService) => {
    var _user$user_info, _user$plan, _user$plan$settings, _user$plan2, _user$plan2$settings, _user$plan3, _user$plan3$settings, _questionnaire$findBy, _questionnaire$findBy2, _questionnaire$findBy3;

    const questionnaire = (_user$user_info = user.user_info) === null || _user$user_info === void 0 ? void 0 : _user$user_info.signup_questionnaire;
    return {
      distinctId: user.registration_email,
      userId: user.id,
      name: user.name,
      company: user.is_company ? user.name : undefined,
      accountId: user.accountId,
      adminUrl: `https://admin.nightwatch.io/admin/users?utf8=%E2%9C%93&q%5Bemail_contains%5D=${encodeURIComponent(user.email)}`,
      email: user.email,
      registrationEmail: user.registration_email,
      planId: (_user$plan = user.plan) === null || _user$plan === void 0 ? void 0 : (_user$plan$settings = _user$plan.settings) === null || _user$plan$settings === void 0 ? void 0 : _user$plan$settings.id,
      planName: (_user$plan2 = user.plan) === null || _user$plan2 === void 0 ? void 0 : (_user$plan2$settings = _user$plan2.settings) === null || _user$plan2$settings === void 0 ? void 0 : _user$plan2$settings.name,
      planPeriodName: (_user$plan3 = user.plan) === null || _user$plan3 === void 0 ? void 0 : (_user$plan3$settings = _user$plan3.settings) === null || _user$plan3$settings === void 0 ? void 0 : _user$plan3$settings.period_name,
      planEndDate: user.active_until,
      planStatus: user.status,
      planGroupId: user.planGroupId,
      backlinksUsed: user.backlinksUsed,
      keywordsUsed: user.keywordsUsed,
      urlsUsed: user.urlsUsed,
      backlinksRemaining: user.backlinksRemaining,
      keywordsRemaining: user.keywords_remaining,
      urlsRemaining: user.urls_remaining,
      backlinksLimit: user.backlinksLimit,
      keywordsLimit: user.keyword_limit,
      urlsLimit: user.url_limit,
      analyticsConnected: user.googleAnalyticsEnabled,
      timezone: user.timezone,
      createdAt: user.user_created_at,
      createdAtUnix: `${(0, _dateFns.getUnixTime)(new Date(user.user_created_at) || new Date())}`,
      createdAtFormatted: (0, _dateFns.formatISO)(new Date(user.user_created_at || new Date())),
      nightwatchWebCodeVersion: _environment.default.COMMIT_REF,
      questionnaireCompany: questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$findBy = questionnaire.findBy('key', 'signup_company_description')) === null || _questionnaire$findBy === void 0 ? void 0 : _questionnaire$findBy.answer,
      questionnaireEmployees: questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$findBy2 = questionnaire.findBy('key', 'signup_employee_count')) === null || _questionnaire$findBy2 === void 0 ? void 0 : _questionnaire$findBy2.answer,
      questionnaireReferral: questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$findBy3 = questionnaire.findBy('key', 'signup_referral')) === null || _questionnaire$findBy3 === void 0 ? void 0 : _questionnaire$findBy3.answer,
      isLimited: user.isLimited,
      isAdmin: user.isAdmin,
      isOwner: user.isOwner,
      siteTitle: siteDataService.siteTitle,
      isWhiteLabel: siteDataService.isWhiteLabel,
      hideNightwatchBranding: siteDataService.hideNightwatchBranding
    };
  };

  var _default = generateUserMetrics;
  _exports.default = _default;
});