define("nightwatch-web/components/site-audit/site-audit-section-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @dynamicView}}
    <LinkTo @route="dashboard.url.site-audit.view.show" @model={{@dynamicView.id}}>Overview</LinkTo>
    <LinkTo data-test-link="siteAuditViewChecks" @route="dashboard.url.site-audit.view.checks" @model={{@dynamicView.id}}>Audit Checks</LinkTo>
  {{else}}
    <LinkTo @route="dashboard.url.site-audit.all.index">Overview</LinkTo>
    <LinkTo data-test-link="siteAuditChecks" @route="dashboard.url.site-audit.all.checks">Audit Checks</LinkTo>
  {{/if}}
  
  */
  {
    "id": "xa0mJRHt",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url.site-audit.view.show\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"Overview\"]],[]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.url.site-audit.view.checks\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"Audit Checks\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\"],[\"dashboard.url.site-audit.all.index\"]],[[\"default\"],[[[[1,\"Overview\"]],[]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\"],[\"dashboard.url.site-audit.all.checks\"]],[[\"default\"],[[[[1,\"Audit Checks\"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@dynamicView\"],false,[\"if\",\"link-to\"]]",
    "moduleName": "nightwatch-web/components/site-audit/site-audit-section-links.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});