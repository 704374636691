define("nightwatch-web/components/click-catcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{on 'click' (stop-propagation @clickHandler)}} role="button">
    {{yield}}
  </div>
  
  */
  {
    "id": "Rl3kLAkC",
    "block": "[[[11,0],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1]],null]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@clickHandler\",\"&default\"],false,[\"on\",\"stop-propagation\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/click-catcher.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});