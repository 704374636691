define("nightwatch-web/services/google-analytics-integration", ["exports", "nightwatch-web/config/environment", "nightwatch-web/utils/load-external-scripts"], function (_exports, _environment, _loadExternalScripts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const GOOGLE_API_SCRIPT_URL = 'https://apis.google.com/js/api.js';
  let GoogleAnalyticsIntegrationService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, (_class = class GoogleAnalyticsIntegrationService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "errorReporter", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "auth", _descriptor5, this);
    }

    connectGa() {
      return this.auth ? this.auth.grantOfflineAccess({
        redirect_uri: 'postmessage',
        prompt: 'consent select_account'
      }).then(authResult => this._saveToken(authResult)) : Promise.reject(new Error('Connecting GA failed. Google API auth2 library unavailable.'));
    }

    disableAnalytics() {
      return this.fetch.post('/user/disable_google_analytics');
    }

    async prepareGoogleClient() {
      if (this.auth) return;
      await (0, _loadExternalScripts.default)([GOOGLE_API_SCRIPT_URL]);
      window.gapi.load('auth2', async () => {
        try {
          await window.gapi.auth2.init({
            client_id: _environment.default.googleClientId,
            scope: 'https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/webmasters.readonly'
          });
        } catch (gapiError) {
          var _gapiError$details, _gapiError$details2;

          let message;

          if ((_gapiError$details = gapiError.details) !== null && _gapiError$details !== void 0 && _gapiError$details.match(/^Not a valid origin/)) {
            this.errorReporter.warning(`Invalid GA origin: (${window.location.host})`);
            message = `The domain (${window.location.host}) is not enabled for Google Analytics. Please contact support and we will enable it for you.`;
          }

          if ((_gapiError$details2 = gapiError.details) !== null && _gapiError$details2 !== void 0 && _gapiError$details2.match(/^Cookies are not enabled/)) {
            message = 'Cookies are required for this feature. Please enable Cookies in your browser and try again.';
          }

          if (!message) {
            // Report unhandled error
            this.errorReporter.error(gapiError);
            message = 'Unable to connect to Google Analytics. Please contact support.';
          }

          this.notifications.clearAll().error(message, {
            autoClear: true,
            clearDuration: 10000
          });
          return;
        }

        this.auth = window.gapi.auth2.getAuthInstance();
      });
    }

    fetchProfiles() {
      return this.fetch.request('/ganal_profiles');
    }

    _saveToken(authResult) {
      return fetch(`${_environment.default.serverURL}auth/google_oauth2/callback?access_token=${this.session.token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: new URLSearchParams(authResult)
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errorReporter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = GoogleAnalyticsIntegrationService;
});