define("nightwatch-web/routes/dashboard/url/reports/new", ["exports", "nightwatch-web/constants/time-periods"], function (_exports, _timePeriods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlReportsNewRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class DashboardUrlReportsNewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "urlUtils", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);
    }

    activate() {
      window.scrollTo(0, 0);
    }

    model(params) {
      const dynamicView = params.viewId ? this.store.findRecord('dynamic-view', params.viewId) : null;
      const name = this.get('dynamicView.name') || this.modelFor('dashboard.url').get('friendlyUrl');
      const report = this.store.createRecord('report', {
        url: this.modelFor('dashboard.url'),
        scheduled: false,
        schedule_interval: _timePeriods.WEEKLY,
        day_in_month: 1,
        local_report_day: 0,
        local_report_hour: 12,
        recipients: [''],
        graph_ids: [],
        report_format: 'pdf',
        date_range_type: 'week',
        name: `Report for ${name}`
      });
      report.setEmailParams();
      return Ember.RSVP.hash({
        dynamicView,
        url: this.modelFor('dashboard.url'),
        report,
        whitelabelLogos: this.store.findAll('whitelabel-logo')
      });
    }

    afterModel(model) {
      if (model.dynamicView) {
        model.report.set('dynamicView', model.dynamicView);
        const reportName = `${model.dynamicView.get('name')} Report`;
        model.report.set('name', reportName);
      }

      let defaultLogo;

      if (defaultLogo = model.whitelabelLogos.findBy('default', true)) {
        model.report.set('whitelabel_logo_id', defaultLogo.id);
      } else {
        model.report.set('whitelabel_logo_id', -1);
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      this.urlUtils.loadUrlRelationships(model.url);
    }

    didTransition() {
      this.metrics.trackEvent({
        event: 'Create Report'
      });
      return true;
    }

    renderTemplate() {
      this.render({
        into: 'dashboard'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "urlUtils", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class));
  _exports.default = DashboardUrlReportsNewRoute;
});