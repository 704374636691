define("nightwatch-web/mixins/graph-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    metrics: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);

      const url = this.modelFor('dashboard.url');
      controller.setProperties({
        url
      });
    },

    resetController() {
      this.controller.setProperties({
        serpPreviewKeywordId: null,
        serpPreviewKeywordModel: null,
        serpPreviewTimestamp: null
      });
    },

    actions: {
      didTransition() {
        this.metrics.trackEvent({
          event: 'Open Graph',
          name: this.controller.get('model.name')
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});