define("nightwatch-web/models/serp-preview", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ENGINE = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    gt,
    equal
  } = Ember.computed;
  const ENGINE = {
    GOOGLE: 'google',
    GOOGLE_PLACES: 'google_places'
  };
  _exports.ENGINE = ENGINE;
  let SerpPreview = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)(), _dec11 = (0, _model.hasMany)('serp-result-organic'), _dec12 = (0, _model.hasMany)('serp-result-local'), _dec13 = (0, _model.hasMany)('serp-result-carousel'), _dec14 = (0, _model.hasMany)('serp-result-knowledge-panel'), _dec15 = (0, _model.hasMany)('serp-result-featured'), _dec16 = gt('organic.length', 0), _dec17 = gt('localPack.length', 0), _dec18 = gt('carousel.length', 0), _dec19 = gt('knowledgePanel.length', 0), _dec20 = gt('featuredSnippet.length', 0), _dec21 = equal('engine', ENGINE.GOOGLE), _dec22 = equal('engine', ENGINE.GOOGLE_PLACES), (_class = class SerpPreview extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "version", _descriptor, this);

      _initializerDefineProperty(this, "keywordId", _descriptor2, this);

      _initializerDefineProperty(this, "date", _descriptor3, this);

      _initializerDefineProperty(this, "query", _descriptor4, this);

      _initializerDefineProperty(this, "engine", _descriptor5, this);

      _initializerDefineProperty(this, "country", _descriptor6, this);

      _initializerDefineProperty(this, "language", _descriptor7, this);

      _initializerDefineProperty(this, "location", _descriptor8, this);

      _initializerDefineProperty(this, "resultsTotal", _descriptor9, this);

      _initializerDefineProperty(this, "urlMatches", _descriptor10, this);

      _initializerDefineProperty(this, "organic", _descriptor11, this);

      _initializerDefineProperty(this, "localPack", _descriptor12, this);

      _initializerDefineProperty(this, "carousel", _descriptor13, this);

      _initializerDefineProperty(this, "knowledgePanel", _descriptor14, this);

      _initializerDefineProperty(this, "featuredSnippet", _descriptor15, this);

      _initializerDefineProperty(this, "hasOrganic", _descriptor16, this);

      _initializerDefineProperty(this, "hasLocalPack", _descriptor17, this);

      _initializerDefineProperty(this, "hasCarousel", _descriptor18, this);

      _initializerDefineProperty(this, "hasKnowledgePanel", _descriptor19, this);

      _initializerDefineProperty(this, "hasFeaturedSnippet", _descriptor20, this);

      _initializerDefineProperty(this, "isGoogle", _descriptor21, this);

      _initializerDefineProperty(this, "isGooglePlaces", _descriptor22, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "version", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "keywordId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "engine", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "resultsTotal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "urlMatches", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "organic", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "localPack", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "carousel", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "knowledgePanel", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "featuredSnippet", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "hasOrganic", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hasLocalPack", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "hasCarousel", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "hasKnowledgePanel", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "hasFeaturedSnippet", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "isGoogle", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "isGooglePlaces", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SerpPreview;
});