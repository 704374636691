define("nightwatch-web/templates/dashboard/dynamic-view/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hmPFtqBK",
    "block": "[[[1,[28,[35,0],[[30,1,[\"name\"]]],null]],[1,\"\\n\"],[1,[28,[35,0],[\"Settings\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"main-area-navbar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main-area-container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"dashboard.dynamic-view\",[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\" Settings\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@urlGroups\",\"@dynamicView\",\"@afterDelete\",\"@onDynamicViewUpdate\"],[[30,0,[\"urlGroups\"]],[30,1],[30,0,[\"afterDelete\"]],[30,0,[\"onDynamicViewUpdate\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"link-to\",\"nw-icon\",\"dynamic-view-settings-form\",\"component\",\"-outlet\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/dynamic-view/settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});