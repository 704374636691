define("nightwatch-web/utils/is-testing", ["exports", "nightwatch-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isTesting = _environment.default.environment === 'test';
  var _default = isTesting;
  _exports.default = _default;
});