define("nightwatch-web/controllers/changelog", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ChangelogController = (_dec = Ember.computed('model.[]'), (_class = class ChangelogController extends Ember.Controller {
    get groupedChangelogs() {
      return this.model.reduce((grouped, changelog) => {
        const dateKey = (0, _dateFns.format)(new Date(changelog.date), 'MMMM dd, yyyy');
        grouped[dateKey] ? grouped[dateKey].push(changelog) : grouped[dateKey] = [changelog];
        return grouped;
      }, {});
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "groupedChangelogs", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "groupedChangelogs"), _class.prototype)), _class));
  _exports.default = ChangelogController;
});