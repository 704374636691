define("nightwatch-web/components/site-audit/snapshot-selector-container/nav", ["exports", "ember-awesome-macros/array", "ember-awesome-macros", "ember-macro-helpers/curried-computed", "date-fns"], function (_exports, _array, _emberAwesomeMacros, _curriedComputed, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <nav class="ember-power-calendar-nav">
    <button
      data-test-to-prev-year
      type="button"
      class="ember-power-calendar-nav-control"
      disabled={{this.prevYearDisabled}}
      onclick={{fn this.calendar.actions.moveCenter -12 'month'}}>
      «
    </button>
    <button
      data-test-to-prev-month
      type="button"
      class="ember-power-calendar-nav-control"
      disabled={{this.prevMonthDisabled}}
      onclick={{fn this.calendar.actions.moveCenter -1 'month'}}>
      ‹
    </button>
    <div class="ember-power-calendar-nav-title">
      {{format-date this.calendar.center 'MMMM yyyy'}}
    </div>
    <button
      data-test-to-next-month
      type="button"
      class="ember-power-calendar-nav-control"
      disabled={{this.nextMonthDisabled}}
      onclick={{fn this.calendar.actions.moveCenter 1 'month'}}>
      ›
    </button>
    <button
      data-test-to-next-year
      class="ember-power-calendar-nav-control"
      disabled={{this.nextYearDisabled}}
      onclick={{fn this.calendar.actions.moveCenter 12 'month'}}
      type="button">
      »
    </button>
  </nav>
  
  */
  {
    "id": "3ceItJHU",
    "block": "[[[10,\"nav\"],[14,0,\"ember-power-calendar-nav\"],[12],[1,\"\\n  \"],[10,\"button\"],[14,0,\"ember-power-calendar-nav-control\"],[15,\"disabled\",[30,0,[\"prevYearDisabled\"]]],[15,\"onclick\",[28,[37,0],[[30,0,[\"calendar\",\"actions\",\"moveCenter\"]],-12,\"month\"],null]],[14,4,\"button\"],[12],[1,\"\\n    «\\n  \"],[13],[1,\"\\n  \"],[10,\"button\"],[14,0,\"ember-power-calendar-nav-control\"],[15,\"disabled\",[30,0,[\"prevMonthDisabled\"]]],[15,\"onclick\",[28,[37,0],[[30,0,[\"calendar\",\"actions\",\"moveCenter\"]],-1,\"month\"],null]],[14,4,\"button\"],[12],[1,\"\\n    ‹\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ember-power-calendar-nav-title\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"calendar\",\"center\"]],\"MMMM yyyy\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"button\"],[14,0,\"ember-power-calendar-nav-control\"],[15,\"disabled\",[30,0,[\"nextMonthDisabled\"]]],[15,\"onclick\",[28,[37,0],[[30,0,[\"calendar\",\"actions\",\"moveCenter\"]],1,\"month\"],null]],[14,4,\"button\"],[12],[1,\"\\n    ›\\n  \"],[13],[1,\"\\n  \"],[10,\"button\"],[14,0,\"ember-power-calendar-nav-control\"],[15,\"disabled\",[30,0,[\"nextYearDisabled\"]]],[15,\"onclick\",[28,[37,0],[[30,0,[\"calendar\",\"actions\",\"moveCenter\"]],12,\"month\"],null]],[14,4,\"button\"],[12],[1,\"\\n    »\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"fn\",\"format-date\"]]",
    "moduleName": "nightwatch-web/components/site-audit/snapshot-selector-container/nav.hbs",
    "isStrictMode": false
  });

  const dateCreator = (0, _curriedComputed.default)(value => new Date(value));

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    sortedAvailableDates: (0, _array.sort)((0, _array.mapBy)('crawlingSessions', (0, _emberAwesomeMacros.raw)('startedAt')), Ember.compare),
    minDate: dateCreator((0, _array.first)('sortedAvailableDates')),
    maxDate: dateCreator((0, _array.last)('sortedAvailableDates')),
    centerDate: dateCreator('calendar.center'),
    startOfCenterMonth: (0, _emberAwesomeMacros.computed)('centerDate', d => (0, _dateFns.startOfMonth)(d)),
    endOfCenterMonth: (0, _emberAwesomeMacros.computed)('centerDate', d => (0, _dateFns.endOfMonth)(d)),
    prevMonthDisabled: (0, _emberAwesomeMacros.gt)('minDate', 'startOfCenterMonth'),
    prevYearDisabled: (0, _emberAwesomeMacros.gt)('minDate', (0, _emberAwesomeMacros.computed)(dateCreator('startOfCenterMonth'), d => (0, _dateFns.sub)(d, {
      years: 1
    }))),
    nextMonthDisabled: (0, _emberAwesomeMacros.lt)('maxDate', 'endOfCenterMonth'),
    nextYearDisabled: (0, _emberAwesomeMacros.lt)('maxDate', (0, _emberAwesomeMacros.computed)(dateCreator('endOfCenterMonth'), d => (0, _dateFns.add)(d, {
      years: 1
    })))
  }));

  _exports.default = _default;
});