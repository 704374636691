define("nightwatch-web/constants/site-audit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.crawlingSessionEndReasonExplanations = void 0;
  const crawlingSessionEndReasonExplanations = {
    url_limit: {
      message: 'Page limit reached.',
      icon: 'icon-info',
      iconClasses: 'icon--end-reason-info'
    },
    depth_limit: {
      message: 'Page depth limit reached.',
      icon: 'icon-info',
      iconClasses: 'icon--end-reason-info'
    },
    time_limit: {
      message: 'Maximum crawling duration reached.',
      icon: 'icon-warning-triangle',
      iconClasses: 'warning-icon'
    },
    error_limit: {
      message: 'Terminated due to large amount of page errors.',
      icon: 'icon-warning-triangle',
      iconClasses: 'warning-icon'
    },
    crash: {
      message: 'An error occurred in the crawler.',
      icon: 'icon-warning-triangle',
      iconClasses: 'warning-icon'
    }
  };
  _exports.crawlingSessionEndReasonExplanations = crawlingSessionEndReasonExplanations;
  var _default = {
    crawlingSessionEndReasonExplanations
  };
  _exports.default = _default;
});