define("nightwatch-web/components/tag-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tag-item {{if @isExpandable 'expandable'}}" ...attributes>
    <span>{{@tag}}</span>
    <NwIcon @icon="icon-cross" class="icon-delete-tag" data-test-delete-tag role="button" {{on 'click' (stop-propagation (fn @deleteTag @tag))}} />
  </div>
  
  */
  {
    "id": "Ltaj+gdC",
    "block": "[[[11,0],[16,0,[29,[\"tag-item \",[52,[30,1],\"expandable\"]]]],[17,2],[12],[1,\"\\n  \"],[10,1],[12],[1,[30,3]],[13],[1,\"\\n  \"],[8,[39,1],[[24,0,\"icon-delete-tag\"],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[28,[37,4],[[30,4],[30,3]],null]],null]],null]],[[\"@icon\"],[\"icon-cross\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isExpandable\",\"&attrs\",\"@tag\",\"@deleteTag\"],false,[\"if\",\"nw-icon\",\"on\",\"stop-propagation\",\"fn\"]]",
    "moduleName": "nightwatch-web/components/tag-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});