define("nightwatch-web/components/site-audit/page-details-audit-checks", ["exports", "ember-awesome-macros/array", "ember-awesome-macros"], function (_exports, _array, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="col-md-6 page-details-audit-checks__col--without-padding-left">
    <div class="site-audit-details__section-title offset-top" data-test-problems-title>Problematic ({{this.problematics.length}})</div>
    <div data-test-problems>
      {{#each this.problematics as |auditCheck|}}
        <div data-test-problem-badge class="page-details-audit-checks__issue-badge--problematic">
          {{auditCheck.name}}
        </div>
      {{else}}
        There are no problematic issues on this page.
      {{/each}}
    </div>
  </div>
  <div class="col-md-6">
    <div class="site-audit-details__section-title offset-top" data-test-warnings-title>Warning ({{this.warnings.length}})</div>
    <div data-test-warnings>
      {{#each this.warnings as |auditCheck|}}
        <div data-test-warning-badge class="page-details-audit-checks__issue-badge--warning">
          {{auditCheck.name}}
        </div>
      {{else}}
        There are no warnings for this page.
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "n+gfqGFN",
    "block": "[[[10,0],[14,0,\"col-md-6 page-details-audit-checks__col--without-padding-left\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"site-audit-details__section-title offset-top\"],[12],[1,\"Problematic (\"],[1,[30,0,[\"problematics\",\"length\"]]],[1,\")\"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"problematics\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"page-details-audit-checks__issue-badge--problematic\"],[12],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],[[[1,\"      There are no problematic issues on this page.\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"col-md-6\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"site-audit-details__section-title offset-top\"],[12],[1,\"Warning (\"],[1,[30,0,[\"warnings\",\"length\"]]],[1,\")\"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"warnings\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"page-details-audit-checks__issue-badge--warning\"],[12],[1,\"\\n        \"],[1,[30,2,[\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],[[[1,\"      There are no warnings for this page.\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"auditCheck\",\"auditCheck\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "nightwatch-web/components/site-audit/page-details-audit-checks.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['page-details-audit-checks'],
    problematics: (0, _array.filterBy)('auditChecks', (0, _emberAwesomeMacros.raw)('category'), (0, _emberAwesomeMacros.raw)('problematic')),
    warnings: (0, _array.filterBy)('auditChecks', (0, _emberAwesomeMacros.raw)('category'), (0, _emberAwesomeMacros.raw)('warning'))
  }));

  _exports.default = _default;
});