define("nightwatch-web/controllers/dashboard/url/backlink-view-new", ["exports", "nightwatch-web/mixins/backlink-view-controller"], function (_exports, _backlinkViewController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DashboardUrlBacklinkViewNewController = (_dec = Ember._action, (_class = class DashboardUrlBacklinkViewNewController extends Ember.Controller.extend(_backlinkViewController.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "statsDisabled", true);
    }

    didTransition() {
      this.metrics.trackEvent({
        event: 'Create Backlink View'
      });
      return true;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class));
  _exports.default = DashboardUrlBacklinkViewNewController;
});