define("nightwatch-web/helpers/subscription-amounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subscriptionAmounts = subscriptionAmounts;
  _exports.default = void 0;

  function subscriptionAmounts([amounts]) {
    // Options:
    // When there is VAT present, display: ($120 + 22% VAT)
    // When there is coupon present, display ($120 - 10% discount)
    // When there are VAT and coupon present, display: ($120 + 22% VAT - 10% discount)
    // When there are no VAT and coupon, return empty string.
    let msg = '';

    if (Ember.isPresent(amounts.vat_rate) && amounts.vat_rate > 0) {
      msg = `$${amounts.amount_original} + ${amounts.vat_rate}% VAT`;

      if (Ember.isPresent(amounts.coupon_percent) && amounts.coupon_percent > 0) {
        msg += ` - ${amounts.coupon_percent}% discount`;
      }
    } else if (Ember.isPresent(amounts.coupon_percent) && amounts.coupon_percent > 0) {
      msg += `$${amounts.amount_original} - ${amounts.coupon_percent}% discount`;
    }

    return Ember.isPresent(msg) ? `(${msg})` : msg;
  }

  var _default = Ember.Helper.helper(subscriptionAmounts);

  _exports.default = _default;
});