define("nightwatch-web/serializers/site-audit/view", ["exports", "nightwatch-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIntoHash: function (data, type, record, options) {
      this._super(data, type, record, options);

      data['view'] = data['site_audit/view'];
      data['view'].search_keyword_url_id = data['view'].url_id;
      delete data['site_audit/view'];
    }
  });

  _exports.default = _default;
});