define("nightwatch-web/components/keyword-engine-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <img src={{@keyword.engineImgPath}} class="engine-icon" alt="" role="presentation">
  </div>
  
  */
  {
    "id": "NEB3AX8m",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,2,[\"engineImgPath\"]]],[14,0,\"engine-icon\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@keyword\"],false,[]]",
    "moduleName": "nightwatch-web/components/keyword-engine-icon.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});