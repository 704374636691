define("nightwatch-web/components/truncate-with-tooltip", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-truncate" {{did-insert this.onInsert}}>
    {{#if this.showTooltip}}
      <NwTooltip @class="nw-truncate--tooltip">
        {{@message}}
      </NwTooltip>
    {{/if}}
    {{yield}}
  </div>
  
  */
  {
    "id": "R/CGEBy5",
    "block": "[[[11,0],[24,0,\"nw-truncate\"],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"showTooltip\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@class\"],[\"nw-truncate--tooltip\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@message\",\"&default\"],false,[\"did-insert\",\"if\",\"nw-tooltip\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/truncate-with-tooltip.hbs",
    "isStrictMode": false
  });

  let TruncateWithTooltip = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class TruncateWithTooltip extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "showTooltip", _descriptor, this);
    }

    onInsert(element) {
      if (element.scrollWidth > element.offsetWidth) {
        this.showTooltip = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showTooltip", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype)), _class));
  _exports.default = TruncateWithTooltip;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TruncateWithTooltip);
});