define("nightwatch-web/helpers/up-down-stagnant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.upDownStagnant = void 0;

  const upDownStagnant = params => {
    let changeNumber = params[0];

    if (params[1]) {
      changeNumber = -changeNumber;
    }

    if (changeNumber > 0) {
      return 'up';
    } else if (changeNumber < 0) {
      return 'down';
    } else {
      return 'stagnant';
    }
  };

  _exports.upDownStagnant = upDownStagnant;

  var _default = Ember.Helper.helper(upDownStagnant);

  _exports.default = _default;
});