define("nightwatch-web/components/progress-bar", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="progress" ...attributes>
    <div class="progress-bar {{@scaleClass}}"
      role="progressbar"
      aria-valuenow={{this.barWidth}}
      aria-valuemin={{this.minValue}}
      aria-valuemax={{this.maxValue}}
      {{style width=(concat this.barWidth "%")}}
      >
    </div>
    <div class="stick"></div>
    <br>
  </div>
  {{#if (not (eq @showScale false))}}
    <div class="progress-scale">
      <div data-test-pb-min-value-label class="pull-left">
        {{if @minValueLabel @minValueLabel this.minValue}}
      </div>
      <div data-test-pb-max-value-label class="pull-right">
        {{if @maxValueLabel @maxValueLabel this.maxValue}}
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "AD4p0zqg",
    "block": "[[[11,0],[24,0,\"progress\"],[17,1],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"progress-bar \",[30,2]]]],[24,\"role\",\"progressbar\"],[16,\"aria-valuenow\",[30,0,[\"barWidth\"]]],[16,\"aria-valuemin\",[30,0,[\"minValue\"]]],[16,\"aria-valuemax\",[30,0,[\"maxValue\"]]],[4,[38,0],null,[[\"width\"],[[28,[37,1],[[30,0,[\"barWidth\"]],\"%\"],null]]]],[12],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"stick\"],[12],[13],[1,\"\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,3],false],null]],null],[[[1,\"  \"],[10,0],[14,0,\"progress-scale\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"pull-left\"],[12],[1,\"\\n      \"],[1,[52,[30,4],[30,4],[30,0,[\"minValue\"]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n      \"],[1,[52,[30,5],[30,5],[30,0,[\"maxValue\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@scaleClass\",\"@showScale\",\"@minValueLabel\",\"@maxValueLabel\"],false,[\"style\",\"concat\",\"if\",\"not\",\"eq\"]]",
    "moduleName": "nightwatch-web/components/progress-bar.hbs",
    "isStrictMode": false
  });

  /**
    @argument scaleClass
    @argument value
    @argument maxValue
    @argument minValue
    @argument minValueLabel
    @argument maxValueLabel
    @argument showScale
   */
  class ProgressBarComponent extends _component.default {
    get minValue() {
      var _this$args$minValue;

      return (_this$args$minValue = this.args.minValue) !== null && _this$args$minValue !== void 0 ? _this$args$minValue : 0;
    }

    get maxValue() {
      var _this$args$maxValue;

      return (_this$args$maxValue = this.args.maxValue) !== null && _this$args$maxValue !== void 0 ? _this$args$maxValue : 100;
    }

    get scaleCoef() {
      return 100 / (this.maxValue - this.minValue);
    }

    get barWidth() {
      return Math.round(this.scaleCoef * (this.args.value - this.minValue));
    }

  }

  _exports.default = ProgressBarComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProgressBarComponent);
});