define("nightwatch-web/templates/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PA2bn13h",
    "block": "[[[1,[28,[35,0],[\"Plans\"],null]],[1,\"\\n\"],[1,[28,[35,0],[[30,1,[\"plan\",\"displayName\"]]],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"first\",\"second\"],[[[[8,[39,3],null,[[\"@route\"],[\"plans\"]],[[\"default\"],[[[[1,\"Plans\"]],[]]]]]],[]],[[[1,[30,1,[\"plan\",\"displayName\"]]]],[]]]]],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@mode\",\"@plan\",\"@paymentData\",\"@afterPaymentSuccess\"],[\"subscribe\",[30,1,[\"plan\"]],[30,1,[\"paymentData\"]],[30,0,[\"afterPaymentSuccess\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"nw-navbar\",\"nw-page-location-banner\",\"link-to\",\"billing/payment-area\"]]",
    "moduleName": "nightwatch-web/templates/plan.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});