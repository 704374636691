define("nightwatch-web/routes/dashboard/url/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardUrlNotesRoute extends Ember.Route {
    activate() {
      window.scrollTo(0, 0);
    }

    model() {
      const url = this.modelFor('dashboard.url');
      return url.loadNotes().then(() => {
        return {
          url: url,
          notes: url.get('notes')
        };
      });
    }

  }

  _exports.default = DashboardUrlNotesRoute;
});