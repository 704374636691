define("nightwatch-web/templates/components/object-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6+K6Z2Pr",
    "block": "[[[6,[39,0],null,[[\"action\"],[[28,[37,1],[[30,0],\"handleObjectDropped\"],null]]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"object-bin-title\"],[12],[1,[34,2]],[13],[1,\"\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[6,[39,6],null,[[\"action\",\"content\"],[\"handleObjectDragged\",[30,1]]],[[\"default\"],[[[[1,\"      \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]]]]]],[1]],null]],[]]]]]],[\"obj\",\"&default\"],false,[\"draggable-object-target\",\"action\",\"name\",\"each\",\"-track-array\",\"model\",\"draggable-object\",\"yield\"]]",
    "moduleName": "nightwatch-web/templates/components/object-bin.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});