define("nightwatch-web/templates/update-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qpuCZkml",
    "block": "[[[1,[28,[35,0],[\"Update Payment Method\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"first\",\"second\"],[[[[8,[39,3],null,[[\"@route\"],[\"plans\"]],[[\"default\"],[[[[1,\"Plans\"]],[]]]]]],[]],[[[1,\"Subscription\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"session\",\"user\",\"stripeCustomer\"]],[[[1,\"  \"],[10,0],[14,0,\"settings-area subscription-area row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-md-8 col-md-offset-2\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"with-bottom-border\"],[12],[1,\"Update payment method\"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"Current payment method: \"],[10,\"strong\"],[12],[1,\"Stripe Subscription\"],[13],[13],[1,\"\\n\\n      \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"Update Payment Details\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,6],null,[[\"@mode\",\"@plan\",\"@paymentData\",\"@afterPaymentSuccess\"],[\"update\",[30,1,[\"plan\"]],[30,1,[\"paymentData\"]],[30,0,[\"afterPaymentSuccess\"]]]],null],[1,\"\\n\"]],[]]]],[\"@model\"],false,[\"page-title\",\"nw-navbar\",\"nw-page-location-banner\",\"link-to\",\"if\",\"billing/customer-portal-button\",\"billing/payment-area\"]]",
    "moduleName": "nightwatch-web/templates/update-payment.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});