define("nightwatch-web/components/reports/report-whitelabel", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-panel double-offset-top">
    <div class="nw-panel__header">
      <span class="nw-panel__title">White-label</span>
    </div>
    <div class="nw-panel__body clearfix">
      <div class="report-logo-text">Use the logo:</div>
      <NwSelect
        class="report-form__logo-selector"
        @v1={{true}}
        @options={{@logos}}
        @selected={{find-by "id" @report.whitelabel_logo_id @logos}}
        @placeholder="Logo"
        @onChange={{pick "id" this.selectLabel}} as |logo|>
        <img class="report-form__logo-img" src={{logo.imgSrc}} alt="" role="presentation">
      </NwSelect>
    </div>
  </div>
  
  */
  {
    "id": "0M/cMzXy",
    "block": "[[[10,0],[14,0,\"nw-panel double-offset-top\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nw-panel__header\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"nw-panel__title\"],[12],[1,\"White-label\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"nw-panel__body clearfix\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"report-logo-text\"],[12],[1,\"Use the logo:\"],[13],[1,\"\\n    \"],[8,[39,0],[[24,0,\"report-form__logo-selector\"]],[[\"@v1\",\"@options\",\"@selected\",\"@placeholder\",\"@onChange\"],[true,[30,1],[28,[37,1],[\"id\",[30,2,[\"whitelabel_logo_id\"]],[30,1]],null],\"Logo\",[28,[37,2],[\"id\",[30,0,[\"selectLabel\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"img\"],[14,0,\"report-form__logo-img\"],[15,\"src\",[30,3,[\"imgSrc\"]]],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@logos\",\"@report\",\"logo\"],false,[\"nw-select\",\"find-by\",\"pick\"]]",
    "moduleName": "nightwatch-web/components/reports/report-whitelabel.hbs",
    "isStrictMode": false
  });

  /**
   * Report white label selector
   * @param {EmberModel} report
   * @param {EmberArray} logos - Options list
   * @param {EmberAction} onSelect - On select action
   */
  let ReportWhitelabelComponent = (_dec = Ember._action, (_class = class ReportWhitelabelComponent extends _component.default {
    selectLabel(labelId) {
      this.args.report.whitelabel_logo_id = labelId;
      this.args.onSelect();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectLabel", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectLabel"), _class.prototype)), _class));
  _exports.default = ReportWhitelabelComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ReportWhitelabelComponent);
});