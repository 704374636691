define("nightwatch-web/constants/date-ranges", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relativeDateRanges = void 0;

  const relativeDateRanges = () => {
    return [{
      type: 'day',
      name: 'Last day',
      start: (0, _dateFns.sub)(new Date(), {
        days: 1
      }),
      end: new Date()
    }, {
      type: '2_days',
      name: 'Last 2 days',
      start: (0, _dateFns.sub)(new Date(), {
        days: 2
      }),
      end: new Date()
    }, {
      type: 'week',
      name: 'Last 7 days',
      start: (0, _dateFns.sub)(new Date(), {
        days: 7
      }),
      end: new Date()
    }, {
      type: '2_weeks',
      name: 'Last 14 days',
      start: (0, _dateFns.sub)(new Date(), {
        days: 14
      }),
      end: new Date()
    }, {
      type: '30_days',
      name: 'Last 30 days',
      start: (0, _dateFns.sub)(new Date(), {
        days: 30
      }),
      end: new Date()
    }, {
      type: 'last_month',
      name: 'Last month',
      start: (0, _dateFns.startOfMonth)((0, _dateFns.sub)(new Date(), {
        months: 1
      })),
      end: (0, _dateFns.endOfMonth)((0, _dateFns.sub)(new Date(), {
        months: 1
      }))
    }, {
      type: 'this_month',
      name: 'This month',
      start: (0, _dateFns.startOfMonth)(new Date()),
      end: new Date()
    }, {
      type: '3_months',
      name: 'Last 3 months',
      start: (0, _dateFns.startOfMonth)((0, _dateFns.sub)(new Date(), {
        months: 3
      })),
      end: new Date()
    }, {
      type: '6_months',
      name: 'Last 6 months',
      start: (0, _dateFns.startOfMonth)((0, _dateFns.sub)(new Date(), {
        months: 6
      })),
      end: new Date()
    }, {
      type: '12_months',
      name: 'Last year',
      start: (0, _dateFns.startOfMonth)((0, _dateFns.sub)(new Date(), {
        months: 12
      })),
      end: new Date()
    }];
  };

  _exports.relativeDateRanges = relativeDateRanges;
});