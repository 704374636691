define("nightwatch-web/utils/small-universal-graph-config", ["exports", "jquery", "highcharts/highstock"], function (_exports, _jquery, _highstock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AXIS = _exports.MAX_DENSITY_FOR_MARKERS = _exports.SERIES_WITH_SERP_PREVIEW_CLASS = _exports.EMPTY_SEGMENTS_ID = _exports.EMPTY_SEGMENTS_TITLE = _exports.DEFAULT_GROUPING = _exports.GROUPING = _exports.COLORS = void 0;
  const COLORS = {
    GRID_LINE_DARK: '#2a2a2a',
    GRID_LINE_LIGHT: '#c3c3c3',
    X_AXIS_LINE: '#a7a7a7',
    NAVIGATOR_OUTLINE: '#4d4d4d'
  };
  _exports.COLORS = COLORS;
  const GROUPING = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month'
  };
  _exports.GROUPING = GROUPING;
  const DEFAULT_GROUPING = GROUPING.DAY;
  _exports.DEFAULT_GROUPING = DEFAULT_GROUPING;
  const EMPTY_SEGMENTS_TITLE = 'Not in search engine results';
  _exports.EMPTY_SEGMENTS_TITLE = EMPTY_SEGMENTS_TITLE;
  const EMPTY_SEGMENTS_ID = 'empty-segments-series';
  _exports.EMPTY_SEGMENTS_ID = EMPTY_SEGMENTS_ID;
  const SERIES_WITH_SERP_PREVIEW_CLASS = 'series-with-serp-preview';
  _exports.SERIES_WITH_SERP_PREVIEW_CLASS = SERIES_WITH_SERP_PREVIEW_CLASS;
  const MAX_DENSITY_FOR_MARKERS = 0.05; // points per px

  _exports.MAX_DENSITY_FOR_MARKERS = MAX_DENSITY_FOR_MARKERS;
  const AXIS = {
    POSITION: 'axis-y-position',
    ANALYTICS: 'axis-y-analytics',
    ANNOTATION: 'axis-y-annotation',
    TIMELINE: 'axis-x-timeline'
  };
  _exports.AXIS = AXIS;
  var _default = {
    rangeSelector: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      },
      chartOptions: {
        legend: {
          enabled: true
        }
      }
    },
    scrollbar: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      },
      line: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      },
      series: {
        animation: false,
        dataGrouping: {
          forced: false,
          units: [[DEFAULT_GROUPING, [1]]]
        },
        marker: {
          states: {
            select: {
              radius: 6,
              fillColor: '#ffffff',
              lineColor: '#70C83F',
              lineWidth: 5
            }
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    yAxis: [// For Ranks (inverted)
    {
      id: AXIS.POSITION,
      title: {
        text: ''
      },
      reversed: true,
      gridLineColor: COLORS.GRID_LINE_DARK,
      gridLineWidth: 1,
      labels: {
        enabled: false
      },
      opposite: false,
      maxPadding: 0,
      minPadding: 0,
      startOnTick: true,
      endOnTick: true,
      min: 1,
      allowDecimals: false,
      showEmpty: false,
      visible: false,
      tickPositioner: function () {
        let min = Math.floor(this.dataMin),
            max = Math.ceil(this.dataMax);

        if (min === max && min === 100) {
          return [1, 100];
        }

        return [min, max];
      }
    }, {
      id: AXIS.ANALYTICS,
      title: {
        text: ''
      },
      gridLineColor: COLORS.GRID_LINE_DARK,
      gridLineWidth: 1,
      labels: {
        enabled: false,
        x: 10,
        y: 4
      },
      showEmpty: false,
      visible: false,
      tickPositioner: function () {
        let min = Math.floor(this.dataMin),
            max = Math.ceil(this.dataMax); // When series is a constant, add a smaller so the line is shown on the top

        if (min > 0 && min == max) {
          min = min - 1;
        }

        return [min, max];
      }
    }, // For annotations
    {
      id: AXIS.ANNOTATION,
      visible: false
    }],
    xAxis: {
      id: AXIS.TIMELINE,
      type: 'datetime',
      lineWidth: 1,
      tickColor: 'transparent',
      lineColor: '#666666',
      labels: {
        enabled: false
      }
    },
    chart: {
      spacing: [0, 5, 0, 5],
      backgroundColor: null
    },
    tooltip: {
      hideDelay: 0,
      enabled: true,
      useHTML: true,
      positioner: function (labelWidth) {
        const chartWidth = this.chart.chartWidth;
        return {
          x: chartWidth / 2 - labelWidth / 2,
          y: 100
        };
      },
      formatter: function () {
        let date, text, y;
        const {
          points,
          x
        } = this;
        date = _highstock.default.dateFormat('%A, %b %d, %Y', new Date(x));
        text = `<b>${date}</b>`;
        let userNotes = [];

        const colorLiteral = color => {
          if (typeof color == 'string') return color; // If it's not string, it's a gradient.

          return color.stops[0][1];
        };

        _jquery.default.each(this.points, function (i, point) {
          if (this.point.userNote) {
            // Don't show it.
            userNotes.push(this.point.userNote);
          } else if (point.series.options.seriesId === EMPTY_SEGMENTS_ID) {
            const {
              seriesColor,
              parentSeriesId
            } = point.series.options;

            if (!points.isAny('series.options.seriesId', parentSeriesId)) {
              text += `<br><span style="color:${colorLiteral(seriesColor)}">\u25CF</span> ${point.series.name}: <b>${EMPTY_SEGMENTS_TITLE}</b>`;
            }
          } else {
            y = point.y % 1 === 0 ? point.y : point.y.toFixed(2);
            text += `<br><span style="color:${colorLiteral(point.color)}">\u25CF</span> ${point.series.name}: <b>${y}</b>`;
          }
        });

        if (userNotes.length > 0) {
          text += '<br><br> <b>Note</b> <br>';
          userNotes.forEach(note => {
            text += `${note}`;
          });
        }

        return text;
      },
      shared: true,
      split: false,
      padding: 0,
      borderWidth: 0,
      backgroundColor: 'transparent'
    }
  };
  _exports.default = _default;
});