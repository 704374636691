define("nightwatch-web/components/tags-container", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <TagList
      @tags={{@taggableModel.tags}}
      @editableTags={{@editableTags}}
      @isExpandable={{true}}
      @deleteTag={{this.deleteTag}}
      @addTag={{this.addTag}}
    />
  </div>
  
  */
  {
    "id": "vNV9346q",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@tags\",\"@editableTags\",\"@isExpandable\",\"@deleteTag\",\"@addTag\"],[[30,2,[\"tags\"]],[30,3],true,[30,0,[\"deleteTag\"]],[30,0,[\"addTag\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@taggableModel\",\"@editableTags\"],false,[\"tag-list\"]]",
    "moduleName": "nightwatch-web/components/tags-container.hbs",
    "isStrictMode": false
  });

  let TagsContainer = (_dec = (0, _emberConcurrency.task)({
    enqueue: true
  }), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class TagsContainer extends _component.default {
    *saveModel() {
      if (!this.args.withSave) return;
      yield this.args.taggableModel.save();
    }

    addTag(tag) {
      if (this.args.taggableModel.tags.includes(tag)) return;
      this.args.taggableModel.tags.pushObject(tag);
      this.saveModel.perform();
    }

    deleteTag(tag) {
      if (!this.args.taggableModel.tags.includes(tag)) return;
      this.args.taggableModel.tags.removeObject(tag);
      this.saveModel.perform();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "saveModel", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "saveModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTag", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteTag", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTag"), _class.prototype)), _class));
  _exports.default = TagsContainer;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TagsContainer);
});