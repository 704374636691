define("nightwatch-web/initializers/inject-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  const initialize = application => {
    application.inject('controller', 'notifications', 'service:notifications');
    application.inject('route', 'notifications', 'service:notifications');
    application.inject('component', 'notifications', 'service:notifications');
  };

  _exports.initialize = initialize;
  var _default = {
    name: 'inject-notifications',
    initialize
  };
  _exports.default = _default;
});