define("nightwatch-web/components/nw-navbar/theme-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (service 'theme') as |theme|}}
    <a
      href="#"
      class="nw-menu-item nw-menu-item__bottom--theme-switch"
      {{on 'click' (stop-propagation (perform theme.switch))}}
    >
      <p>
        Switch Color Mode
      </p>
      <XToggle
        @tagName=""
        @value={{theme.isDark}}
        @theme="light"
        @size="medium"
      />
    </a>
  {{/let}}
  
  */
  {
    "id": "CBJgofC2",
    "block": "[[[44,[[28,[37,1],[\"theme\"],null]],[[[1,\"  \"],[11,3],[24,6,\"#\"],[24,0,\"nw-menu-item nw-menu-item__bottom--theme-switch\"],[4,[38,2],[\"click\",[28,[37,3],[[28,[37,4],[[30,1,[\"switch\"]]],null]],null]],null],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Switch Color Mode\\n    \"],[13],[1,\"\\n    \"],[8,[39,5],null,[[\"@tagName\",\"@value\",\"@theme\",\"@size\"],[\"\",[30,1,[\"isDark\"]],\"light\",\"medium\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]],[\"theme\"],false,[\"let\",\"service\",\"on\",\"stop-propagation\",\"perform\",\"x-toggle\"]]",
    "moduleName": "nightwatch-web/components/nw-navbar/theme-switch.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});