define("nightwatch-web/adapters/user", ["exports", "nightwatch-web/adapters/application", "nightwatch-web/utils/append-to-url"], function (_exports, _application, _appendToUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserAdapter extends _application.default {
    buildURL() {
      return (0, _appendToUrl.default)(super.buildURL(...arguments).replace(/users?\/?\d*/, 'user'), 'without_stats=true');
    }

    handleResponse(status, headers, payload, requestData) {
      if (status >= 500) return super.handleResponse(...arguments);

      if (status === 401) {
        // Unauthorized while trying to update the password
        payload = {
          errors: {
            password: payload.error
          }
        };
        status = 422;
      } else if (status === 422) {
        if (payload.error) {
          payload = {
            errors: {
              base: payload.error
            }
          };
        } // error_meta will be populated users controller
        // when creating a new user will fail. It's used to check
        // the case when a RT user is trying to sign up for a NW account. In this
        // case we need to show a custom validation message in the signup form.


        if (payload.errors) {
          payload.errors.error_meta = payload.error_meta;
        }
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

  }

  _exports.default = UserAdapter;
});