define("nightwatch-web/components/settings/google-analytics-connector", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="nw-settings__integrations" {{did-insert this.prepareGoogleClient}}>
    <div class="nw-settings__integration nw-settings__integration--ga">
      <img class="nw-settings__integration--image-ga" src="/assets/icons/integrations-ga.png" alt="" role="presentation">Google Analytics
    </div>
    <div class="nw-settings__integration nw-settings__integration--sc">
      <img class="nw-settings__integration--image-sc" src="/assets/icons/integrations-gconsole.png" alt="" role="presentation">Search Console
    </div>
      <div class="nw-settings__integration--toggle">
        <XToggle @value={{this.analyticsEnabled}} @onToggle={{this.onSwitch}} @disabled={{not this.googleAnalyticsIntegration.auth}} @theme="light" @size="large" />
      </div>
  </div>
  {{#if (and this.analyticsEnabled @user.google_analytics_updated_at)}}
    <div class="nw-settings__integrations--last-update">
      <NwIcon class="nw-settings__integrations--update-icon" @icon="svg-schedule" /> Last updated:
      {{relative-date @user.google_analytics_updated_at}}
    </div>
  {{/if}}
  
  */
  {
    "id": "kg7T4SsG",
    "block": "[[[11,0],[24,0,\"nw-settings__integrations\"],[4,[38,0],[[30,0,[\"prepareGoogleClient\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"nw-settings__integration nw-settings__integration--ga\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,0,\"nw-settings__integration--image-ga\"],[14,\"src\",\"/assets/icons/integrations-ga.png\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"Google Analytics\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"nw-settings__integration nw-settings__integration--sc\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,0,\"nw-settings__integration--image-sc\"],[14,\"src\",\"/assets/icons/integrations-gconsole.png\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"Search Console\\n  \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"nw-settings__integration--toggle\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@value\",\"@onToggle\",\"@disabled\",\"@theme\",\"@size\"],[[30,0,[\"analyticsEnabled\"]],[30,0,[\"onSwitch\"]],[28,[37,2],[[30,0,[\"googleAnalyticsIntegration\",\"auth\"]]],null],\"light\",\"large\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"analyticsEnabled\"]],[30,1,[\"google_analytics_updated_at\"]]],null],[[[1,\"  \"],[10,0],[14,0,\"nw-settings__integrations--last-update\"],[12],[1,\"\\n    \"],[8,[39,5],[[24,0,\"nw-settings__integrations--update-icon\"]],[[\"@icon\"],[\"svg-schedule\"]],null],[1,\" Last updated:\\n    \"],[1,[28,[35,6],[[30,1,[\"google_analytics_updated_at\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@user\"],false,[\"did-insert\",\"x-toggle\",\"not\",\"if\",\"and\",\"nw-icon\",\"relative-date\"]]",
    "moduleName": "nightwatch-web/components/settings/google-analytics-connector.hbs",
    "isStrictMode": false
  });

  let GoogleAnalyticsConnectorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('session.user.googleAnalyticsEnabled'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class GoogleAnalyticsConnectorComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "googleAnalyticsIntegration", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "analyticsEnabled", _descriptor3, this);
    }

    reloadUser() {
      this.session.user.reload();
    }

    prepareGoogleClient() {
      this.googleAnalyticsIntegration.prepareGoogleClient();
    }

    async onSwitch() {
      if (this.analyticsEnabled) {
        await this.googleAnalyticsIntegration.disableAnalytics();
      } else {
        await this.googleAnalyticsIntegration.connectGa();
      }

      this.reloadUser();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "googleAnalyticsIntegration", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analyticsEnabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "prepareGoogleClient", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "prepareGoogleClient"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSwitch", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSwitch"), _class.prototype)), _class));
  _exports.default = GoogleAnalyticsConnectorComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GoogleAnalyticsConnectorComponent);
});