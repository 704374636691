define("nightwatch-web/components/billing/checkout-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (service "stripe") (uuid) as |stripe buttonId|}}
    <div role="button" {{on "click" (perform stripe.redirectToCheckout (hash planId=@planId buttonId=buttonId))}} ...attributes>
      {{#if (and stripe.redirectToCheckout.isRunning (eq stripe.checkoutButtonId buttonId))}}
        Redirecting to Stripe...
      {{else if (has-block)}}
        {{yield}}
      {{else}}
        Stripe Checkout
      {{/if}}
    </div>
  {{/let}}
  
  */
  {
    "id": "+KwvmQPF",
    "block": "[[[44,[[28,[37,1],[\"stripe\"],null],[28,[37,2],null,null]],[[[1,\"  \"],[11,0],[24,\"role\",\"button\"],[17,3],[4,[38,3],[\"click\",[28,[37,4],[[30,1,[\"redirectToCheckout\"]],[28,[37,5],null,[[\"planId\",\"buttonId\"],[[30,4],[30,2]]]]],null]],null],[12],[1,\"\\n\"],[41,[28,[37,7],[[30,1,[\"redirectToCheckout\",\"isRunning\"]],[28,[37,8],[[30,1,[\"checkoutButtonId\"]],[30,2]],null]],null],[[[1,\"      Redirecting to Stripe...\\n\"]],[]],[[[41,[48,[30,5]],[[[1,\"      \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"      Stripe Checkout\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[1,2]]]],[\"stripe\",\"buttonId\",\"&attrs\",\"@planId\",\"&default\"],false,[\"let\",\"service\",\"uuid\",\"on\",\"perform\",\"hash\",\"if\",\"and\",\"eq\",\"has-block\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/billing/checkout-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});