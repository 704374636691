define("nightwatch-web/serializers/report", ["exports", "nightwatch-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeArrayResponse(store, primaryType, payload) {
      const newPayload = {
        reports: payload
      };
      newPayload.reports.forEach(function (report) {
        report.url_id = report.search_keyword_url_id;
        report.graph_ids = report.graph_ids.map(String);
      });
      return this._super(store, primaryType, newPayload);
    },

    normalizeSingleResponse(store, primaryType, payload, recordId) {
      const newPayload = {
        report: payload
      };
      newPayload.report.graph_ids = newPayload.report.graph_ids.map(String);
      newPayload.report.url_id = payload.search_keyword_url_id;
      return this._super(store, primaryType, newPayload, recordId);
    },

    serializeBelongsTo(snapshot, json, relationship) {
      const {
        key
      } = relationship;
      const belongsTo = snapshot.belongsTo(key);

      if (key === 'url') {
        if (belongsTo) {
          return json['search_keyword_url_id'] = belongsTo.id;
        }
      } else if (key === 'dynamicView') {
        if (belongsTo) {
          return json['dynamic_view_id'] = belongsTo.id;
        }
      }
    }

  });

  _exports.default = _default;
});