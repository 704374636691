define("nightwatch-web/helpers/random-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.randomHash = randomHash;
  _exports.default = void 0;

  function randomHash() {
    return Math.random().toString(16).slice(2, 8);
  }

  var _default = Ember.Helper.helper(randomHash);

  _exports.default = _default;
});