define("nightwatch-web/helpers/lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.lookup = void 0;

  const lookup = params => params[0][params[1]];

  _exports.lookup = lookup;

  var _default = Ember.Helper.helper(lookup);

  _exports.default = _default;
});