define("nightwatch-web/components/filters-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.allFilters as |filter|}}
    {{#if filter.hasValues}}
      <div class="filter-summary">
        <NwIcon @icon="icon-circle" />
        {{#if filter.isBooleanField}}
          {{filter.filterInfo.display_name}} = {{filter.value}}
        {{else}}
          {{filter.filterInfo.display_name}} {{lookup this.conditionsDisplayMapper filter.condition}}
          {{#if (eq filter.filterInfo.data_type 'string')}}
            '{{filter.value}}'
          {{else if (eq filter.filterInfo.data_type 'select')}}
            {{#let (find-by "value" filter.value filter.filterInfo.options) as |option|}}
              {{option.display}}
            {{/let}}
          {{else}}
            {{filter.value}}
          {{/if}}
        {{/if}}
      </div>
    {{/if}}
  {{/each}}
  
  */
  {
    "id": "sJK27jN+",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"allFilters\"]]],null]],null],null,[[[41,[30,1,[\"hasValues\"]],[[[1,\"    \"],[10,0],[14,0,\"filter-summary\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@icon\"],[\"icon-circle\"]],null],[1,\"\\n\"],[41,[30,1,[\"isBooleanField\"]],[[[1,\"        \"],[1,[30,1,[\"filterInfo\",\"display_name\"]]],[1,\" = \"],[1,[30,1,[\"value\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,1,[\"filterInfo\",\"display_name\"]]],[1,\" \"],[1,[28,[35,4],[[30,0,[\"conditionsDisplayMapper\"]],[30,1,[\"condition\"]]],null]],[1,\"\\n\"],[41,[28,[37,5],[[30,1,[\"filterInfo\",\"data_type\"]],\"string\"],null],[[[1,\"          '\"],[1,[30,1,[\"value\"]]],[1,\"'\\n\"]],[]],[[[41,[28,[37,5],[[30,1,[\"filterInfo\",\"data_type\"]],\"select\"],null],[[[44,[[28,[37,7],[\"value\",[30,1,[\"value\"]],[30,1,[\"filterInfo\",\"options\"]]],null]],[[[1,\"            \"],[1,[30,2,[\"display\"]]],[1,\"\\n\"]],[2]]]],[]],[[[1,\"          \"],[1,[30,1,[\"value\"]]],[1,\"\\n        \"]],[]]]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[1]],null]],[\"filter\",\"option\"],false,[\"each\",\"-track-array\",\"if\",\"nw-icon\",\"lookup\",\"eq\",\"let\",\"find-by\"]]",
    "moduleName": "nightwatch-web/components/filters-summary.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    filterData: Ember.inject.service(),
    conditionsDisplayMapper: {
      starts_with: 'starts with',
      not_starts_with: "doesn't start with",
      ends_with: 'ends with',
      not_ends_with: "doesn't end with",
      less: '<',
      less_or_equal: '<=',
      more: '>',
      more_or_equal: '>=',
      equals: 'is',
      not_equals: 'is not',
      present: 'is present',
      absent: 'is absent',
      contains: 'contains',
      not_contains: "doesn't contain"
    },
    allFilters: Ember.computed('filterGroups.[]', 'filterGroups.@each.visibleFilters', function () {
      let filters = [];
      this.filterGroups.filter(fg => !fg.get('markedAsDeleted')).forEach(filterGroup => {
        filters = filters.concat(filterGroup.get('filters').toArray());
      });
      return filters;
    })
  }));

  _exports.default = _default;
});