define("nightwatch-web/components/tag-new-form", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <form {{on "submit" (stop-propagation (fn @onSubmit this.value))}} class="tag-new-form">
    <FocusInput
      class="tag-item"
      data-test-new-tag={{true}}
      @value={{this.value}}
      @escape-press={{@onDismiss}}
      @focus-out={{this.focusOut}}
    />
  </form>
  
  */
  {
    "id": "SJ7P47LN",
    "block": "[[[11,\"form\"],[24,0,\"tag-new-form\"],[4,[38,0],[\"submit\",[28,[37,1],[[28,[37,2],[[30,1],[30,0,[\"value\"]]],null]],null]],null],[12],[1,\"\\n  \"],[8,[39,3],[[24,0,\"tag-item\"]],[[\"@value\",\"@escape-press\",\"@focus-out\"],[[30,0,[\"value\"]],[30,2],[30,0,[\"focusOut\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@onSubmit\",\"@onDismiss\"],false,[\"on\",\"stop-propagation\",\"fn\",\"focus-input\"]]",
    "moduleName": "nightwatch-web/components/tag-new-form.hbs",
    "isStrictMode": false
  });

  let TagsList = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class TagsList extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "value", _descriptor, this);
    }

    focusOut() {
      Ember.isPresent(this.value) ? this.args.onSubmit(this.value) : this.args.onDismiss();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "focusOut", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "focusOut"), _class.prototype)), _class));
  _exports.default = TagsList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TagsList);
});