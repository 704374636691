define("nightwatch-web/adapters/site-audit/page", ["exports", "nightwatch-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SiteAuditPageAdapter extends _application.default {
    handleResponse(status, headers, payload, requestData) {
      const meta = {
        total: headers['X-Total-Count'] || headers['x-total-count'],
        pages: headers['X-Page-Count'] || headers['x-page-count']
      };

      if (payload) {
        if (payload.constructor === Array) {
          payload = {
            'site-audit/pages': payload
          };
        } else {
          payload = {
            'site-audit/page': payload
          };
        }

        payload.meta = meta;
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

  }

  _exports.default = SiteAuditPageAdapter;
});