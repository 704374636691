define("nightwatch-web/services/overview/site-audit-overview-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SiteAuditOverviewStateService extends Ember.Service {
    init() {
      super.init(...arguments);
      const pagesCategory = this.parseFromLocalStorage('nw:site-audit:pages-category', 'total-pages');
      const showExtendedOverview = this.parseFromLocalStorage('nw:site-audit:show-extended-overview', true);
      this.set('pagesCategory', pagesCategory);
      this.set('showExtendedOverview', showExtendedOverview);
    }

    parseFromLocalStorage(property, defaultValue) {
      const item = localStorage.getItem(property);

      if (!item) {
        return defaultValue;
      } else if (typeof defaultValue === 'boolean') {
        return String(item).toLowerCase() === 'true';
      } else {
        return item;
      }
    }

    setpagesCategory(category) {
      localStorage.setItem('nw:site-audit:pages-category', category);
      this.set('pagesCategory', category);
    }

    toggleShowExtendedOverview() {
      const newShowSetting = !this.showExtendedOverview;
      localStorage.setItem('nw:site-audit:show-extended-overview', newShowSetting);
      this.set('showExtendedOverview', newShowSetting);
    }

  }

  _exports.default = SiteAuditOverviewStateService;
});