define("nightwatch-web/models/report", ["exports", "@ember-data/model", "nightwatch-web/constants/time-periods"], function (_exports, _model, _timePeriods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Report = (_dec = Ember.inject.service('report-mailer'), _dec2 = Ember.inject.service, _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)(), _dec9 = (0, _model.attr)(), _dec10 = (0, _model.attr)(), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)('number'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('boolean'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('date'), _dec18 = (0, _model.attr)('date'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('date'), _dec21 = (0, _model.attr)('number'), _dec22 = (0, _model.attr)('number'), _dec23 = (0, _model.attr)(), _dec24 = (0, _model.attr)('number'), _dec25 = (0, _model.attr)('number'), _dec26 = (0, _model.attr)(), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.belongsTo)('url', {
    async: true
  }), _dec29 = (0, _model.belongsTo)('dynamic-view', {
    async: true
  }), _dec30 = Ember.computed.equal('report_version', 2), _dec31 = Ember.computed.equal('schedule_interval', _timePeriods.DAILY), _dec32 = Ember.computed.equal('schedule_interval', _timePeriods.WEEKLY), _dec33 = Ember.computed('dynamicView.id', 'url', 'name'), (_class = class Report extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "reportMailerService", _descriptor, this);

      _initializerDefineProperty(this, "siteData", _descriptor2, this);

      _initializerDefineProperty(this, "local_report_day", _descriptor3, this);

      _initializerDefineProperty(this, "local_report_hour", _descriptor4, this);

      _initializerDefineProperty(this, "day_in_month", _descriptor5, this);

      _initializerDefineProperty(this, "report_format", _descriptor6, this);

      _initializerDefineProperty(this, "name", _descriptor7, this);

      _initializerDefineProperty(this, "recipients", _descriptor8, this);

      _initializerDefineProperty(this, "keyword_ids", _descriptor9, this);

      _initializerDefineProperty(this, "graph_ids", _descriptor10, this);

      _initializerDefineProperty(this, "scheduled", _descriptor11, this);

      _initializerDefineProperty(this, "schedule_interval", _descriptor12, this);

      _initializerDefineProperty(this, "report_interval", _descriptor13, this);

      _initializerDefineProperty(this, "customized_email_enabled", _descriptor14, this);

      _initializerDefineProperty(this, "custom_subject", _descriptor15, this);

      _initializerDefineProperty(this, "custom_body", _descriptor16, this);

      _initializerDefineProperty(this, "start_time", _descriptor17, this);

      _initializerDefineProperty(this, "end_time", _descriptor18, this);

      _initializerDefineProperty(this, "date_range_type", _descriptor19, this);

      _initializerDefineProperty(this, "last_sent_at", _descriptor20, this);

      _initializerDefineProperty(this, "keyword_count", _descriptor21, this);

      _initializerDefineProperty(this, "url_count", _descriptor22, this);

      _initializerDefineProperty(this, "columns", _descriptor23, this);

      _initializerDefineProperty(this, "whitelabel_logo_id", _descriptor24, this);

      _initializerDefineProperty(this, "report_version", _descriptor25, this);

      _initializerDefineProperty(this, "report_config", _descriptor26, this);

      _initializerDefineProperty(this, "template_name", _descriptor27, this);

      _initializerDefineProperty(this, "url", _descriptor28, this);

      _initializerDefineProperty(this, "dynamicView", _descriptor29, this);

      _initializerDefineProperty(this, "isNewVersion", _descriptor30, this);

      _initializerDefineProperty(this, "isDaily", _descriptor31, this);

      _initializerDefineProperty(this, "isWeekly", _descriptor32, this);
    }

    get description() {
      if (this.get('dynamicView.id')) {
        return `View report: ${this.name}`;
      } else {
        if (this.name) {
          return `URL report: ${this.name}`;
        } else {
          return 'URL report';
        }
      }
    }

    setEmailParams() {
      const {
        siteTitle
      } = this.siteData;

      if (!this.custom_subject) {
        this.custom_subject = `Your ${siteTitle} Report`;
      }

      if (!this.custom_body) {
        this.custom_body = `Hey, \n\nYour ${siteTitle} report is attached. \n\nHave a great day,\n- ${siteTitle} Team`;
      }
    }

    sendReport() {
      return this.reportMailerService.sendReport(this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "reportMailerService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "local_report_day", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "local_report_hour", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "day_in_month", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "report_format", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "recipients", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "keyword_ids", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "graph_ids", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "scheduled", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "schedule_interval", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "report_interval", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "customized_email_enabled", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "custom_subject", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "custom_body", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "start_time", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "end_time", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "date_range_type", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "last_sent_at", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "keyword_count", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "url_count", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "whitelabel_logo_id", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "report_version", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "report_config", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "template_name", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "dynamicView", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "isNewVersion", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "isDaily", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "isWeekly", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "description", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype)), _class));
  _exports.default = Report;
});