define("nightwatch-web/components/nw-form-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-info" ...attributes>
    <NwIcon @icon="icon-info" />
    {{yield}}
  </div>
  
  */
  {
    "id": "H9T+8sX5",
    "block": "[[[11,0],[24,0,\"form-info\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\"],[\"icon-info\"]],null],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"nw-icon\",\"yield\"]]",
    "moduleName": "nightwatch-web/components/nw-form-info.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});