define("nightwatch-web/templates/dashboard/url/keywords/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9k7VDj1z",
    "block": "[[[10,0],[14,0,\"main-area-navbar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main-area-container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"dashboard.url\",[30,0,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[[30,0,[\"url\",\"url\"]]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\"],[\"icon-chevron-right\"]],null],[1,\" Keywords\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,[[\"@keyword\",\"@url\",\"@onKeywordsAdd\"],[[30,1],[30,0,[\"url\"]],[30,0,[\"onKeywordsAdd\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@model\"],false,[\"link-to\",\"friendly-url\",\"nw-icon\",\"nw-page-content\",\"edit-keyword-form\"]]",
    "moduleName": "nightwatch-web/templates/dashboard/url/keywords/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});