define("nightwatch-web/routes/dashboard/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardOverviewRoute extends Ember.Route {
    beforeModel() {
      /* Pinned items can be removed by the backend as part of a graphs cleaning
       * process, in order to get an updated listing of pinned items we need to
       * first unload the ones already in the store. Note that calling `findAll`
       * with `reload: true` option will not clear the store, its purpose is to
       * change when the promise the function returns is resolved. However we
       * still need the `reload` functionality, without it, after calling
       * `unloadAll`, `findAll` will just return an empty set.
       *
       * More info:
       * https://www.emberjs.com/api/ember-data/release/classes/DS.Store/methods/findAll?anchor=findAll
       */
      this.store.unloadAll('pinned-item');
    }

    model() {
      return this.store.findAll('pinned-item', {
        reload: true
      });
    }

    activate() {
      this.controllerFor('dashboard').setSelectedUrl(null);
      this.controllerFor('dashboard').setSelectedDynamicView(null);
    }

    renderTemplate() {
      this.render({
        into: 'dashboard'
      });
    }

  }

  _exports.default = DashboardOverviewRoute;
});