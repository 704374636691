define("nightwatch-web/controllers/dashboard/url/site-audit/all/checks/show", ["exports", "nightwatch-web/mixins/site-audit/override-columns", "nightwatch-web/utils/site-audit-properties", "nightwatch-web/utils/refresh-route"], function (_exports, _overrideColumns, _siteAuditProperties, _refreshRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardUrlSiteAuditChecksShowController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('model.pages'), _dec4 = Ember.computed.alias('model.url'), _dec5 = Ember.computed.alias('model.checks'), _dec6 = Ember.computed.alias('model.crawlingSession'), _dec7 = Ember.computed.alias('model.comparisonCrawlingSession'), _dec8 = Ember.computed('newCheck', 'model.check'), _dec9 = Ember.computed('filters'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class DashboardUrlSiteAuditChecksShowController extends Ember.Controller.extend(_overrideColumns.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "pages", _descriptor3, this);

      _initializerDefineProperty(this, "url", _descriptor4, this);

      _initializerDefineProperty(this, "checks", _descriptor5, this);

      _initializerDefineProperty(this, "crawlingSession", _descriptor6, this);

      _initializerDefineProperty(this, "comparisonCrawlingSession", _descriptor7, this);

      _defineProperty(this, "queryParams", ['page', 'sort', 'direction', 'params', 'search', 'filters']);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 50);

      _defineProperty(this, "sort", 'created_at');

      _defineProperty(this, "direction", 'desc');

      _defineProperty(this, "search", null);

      _defineProperty(this, "filters", null);

      _defineProperty(this, "isLoading", false);

      _defineProperty(this, "availableFilters", _siteAuditProperties.AVAILABLE_FILTERS);
    }

    get checkResource() {
      if (this.newCheck) {
        return this.newCheck;
      } else {
        return this.get('model.check');
      }
    }

    get filtersObj() {
      if (!this.filters) {
        return {};
      }

      try {
        return JSON.parse(decodeURIComponent(this.filters));
      } catch (err) {
        return {};
      }
    }

    fetchStats() {
      const data = {
        crawling_session_id: this.get('crawlingSession.id'),
        comparison_crawling_session_id: this.get('comparisonCrawlingSession.id'),
        search: this.search
      };
      return this.fetch.request('/site_audit/stats', {
        data
      });
    }

    loadStats() {
      return this.fetchStats().then(stats => {
        this.set('stats', stats);
      });
    }

    prepareNewCheck() {
      const url = this.url;
      const check = this.store.createRecord('site-audit/audit-check', {
        url,
        category: 'warning',
        filterConfig: {}
      });
      this.set('filters', encodeURI(JSON.stringify({})));
      this.set('newCheck', check);
    }

    deleteCheck(check) {
      if (this.get('checks.length') == 1) {
        return this.notifications.error("Can't delete all checks. At least one must be present.", {
          autoClear: true
        });
      }

      check.destroyRecord().then(() => {
        this.notifications.success('Audit check deleted successfully.', {
          autoClear: true
        });
        (0, _refreshRoute.default)(this, 'dashboard.url.site-audit.all.checks');
        this.router.transitionTo('dashboard.url.site-audit.all.checks');
      }, () => {
        this.notifications.error('There was an error while deleting the audit check', {
          autoClear: true
        });
      });
    }

    saveCheck(check) {
      check.set('filterConfig', {
        filter_groups: this.get('filtersObj.filterGroups')
      });
      check.filtersSaving = true;
      const isNew = check.get('isNew');
      check.save().then(() => {
        check.filtersSaved = true;
        this.loadStats();
        this.notifications.success('Audit check saved successfully.', {
          autoClear: true
        });

        if (isNew) {
          this.set('newCheck', null);
          (0, _refreshRoute.default)(this, 'dashboard.url.site-audit.all.checks');
          this.router.transitionTo('dashboard.url.site-audit.all.checks.show', check.id);
        }
      }).catch(() => {
        this.notifications.error('There was an error while saving the audit check', {
          autoClear: true
        });
      }).finally(() => {
        check.filtersSaving = false;
      });
    }

    setFilterConfig(filterConfig) {
      this.set('filterConfig', filterConfig);
    }

    setFilters(filters) {
      if (filters && filters.filterGroups && filters.filterGroups.length === 1 && filters.filterGroups[0].filters[0].name == null) {
        this.setProperties({
          filters: null,
          page: 1,
          emptyFilters: true
        });
      } else {
        this.setProperties({
          page: 1
        });
        const urlSafeFilters = encodeURI(JSON.stringify(filters));
        this.setProperties({
          filters: urlSafeFilters,
          emptyFilters: false
        });
      }

      this.loadStats();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pages", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "checks", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "crawlingSession", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "comparisonCrawlingSession", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkResource", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "checkResource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filtersObj", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "filtersObj"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prepareNewCheck", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "prepareNewCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteCheck", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "deleteCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveCheck", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "saveCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFilterConfig", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setFilterConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFilters", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setFilters"), _class.prototype)), _class));
  _exports.default = DashboardUrlSiteAuditChecksShowController;
});