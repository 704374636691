define("nightwatch-web/components/stats-badge", ["exports", "ember-awesome-macros/raw", "ember-awesome-macros"], function (_exports, _raw, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <NwTooltip @placement="right">{{@tooltip}}</NwTooltip>
  <span class="badge {{this.computedBadgeClass}}">{{this.safeValue}}</span>
  
  */
  {
    "id": "QREKdP4s",
    "block": "[[[8,[39,0],null,[[\"@placement\"],[\"right\"]],[[\"default\"],[[[[1,[30,1]]],[]]]]],[1,\"\\n\"],[10,1],[15,0,[29,[\"badge \",[30,0,[\"computedBadgeClass\"]]]]],[12],[1,[30,0,[\"safeValue\"]]],[13],[1,\"\\n\"]],[\"@tooltip\"],false,[\"nw-tooltip\"]]",
    "moduleName": "nightwatch-web/components/stats-badge.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNameBindings: ['isValueTooBig:stats-badge--big-value'],
    classNames: ['stats-badge'],
    badgeClass: 'default',
    value: 0,
    computedBadgeClass: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.gt)('value', (0, _raw.default)(0)), 'badgeClass', (0, _raw.default)('default')),
    isValueTooBig: (0, _emberAwesomeMacros.gt)('value', (0, _raw.default)(9)),
    safeValue: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.isEmpty)('value'), (0, _raw.default)(0), (0, _emberAwesomeMacros.conditional)('isValueTooBig', (0, _raw.default)('9+'), 'value')),
    needTooltip: (0, _emberAwesomeMacros.notEmpty)('tooltip')
  }));

  _exports.default = _default;
});